import gql from "graphql-tag";

const createOrderFromOriginal = gql`
  mutation createOrderFromOriginal(
    $originalOrderIDs: [Int]
    $enterpriseID: Int
  ) {
    CreateOrderFromOriginal: createOrderFromOriginal(
      originalOrderIDs: $originalOrderIDs
      enterpriseID: $enterpriseID
    )
  }
`;
const undeleteOriginalOrder = gql`
  mutation undeleteOriginalOrder($ids: [ID!]!) {
    UndeleteOriginalOrder: undeleteOriginalOrder(ids: $ids) {
      message
      motive
      statusCode
    }
  }
`;

export { createOrderFromOriginal, undeleteOriginalOrder };
