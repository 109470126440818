import React from "react";
import { Input } from "antd";
import { CustomFormItemInputWithBorder } from "../Form";

const { Search } = Input;

class InputComponent extends React.Component {
  render() {
    const { title, style } = this.props;

    return (
      <div style={style}>
        <CustomFormItemInputWithBorder
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          <Search
            placeholder="Chazki"
            onSearch={value => console.log(value)}
            enterButton
          />
        </CustomFormItemInputWithBorder>
      </div>
    );
  }
}

export default InputComponent;
