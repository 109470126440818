import React from "react";
import { Row, Col, Form, Select, Button } from "antd";
import { withApollo } from "react-apollo"; // graphql
import { compose } from "recompose";
import moment from "moment";
import { withGlobal } from "../../../../component-global";
import {
  SelectWithBorderComponent,
  RangePickerWithBorderCompoment,
  InputComponentWithBorder,
} from "../../../../components/FormFields";
import { ContainerGlobal } from "../../../../components/Form/SearchContainer";
import { ROUTE_STATUSES, ROUTE_COMPLIANCE, ROUTE_VEHICLE, RISKS } from "../../../../utils";

const { Option } = Select;
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const filter = {};
      if (values.date && values.date.length === 2)
        filter.rangeBy = {
          createdAt: {
            lessEqual: moment(values.date[1])
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              })
              .format("YYYY-MM-DD HH:mm:ss ZZ"),
            greaterEqual: moment(values.date[0])
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .format("YYYY-MM-DD HH:mm:ss ZZ"),
          },
        };
      if (values.status) filter.routeStatus = values.status;
      if (values.routeCode) filter.routeCode = values.routeCode;
      if (values.trackCode) filter.trackCode = values.trackCode;
      if (values.vehicle) filter.vehicleType = values.vehicle;
      if (values.riskType) filter.riskType = values.riskType;
      if (values.percentage) filter.percentage = values.percentage;
      if (values.serviceType) filter.serviceType = values.serviceType;

      this.props.searchRouteByFilters(filter);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loadingBtnSearch, servicesName } = this.props;
    const { disabledButtonSearch } = this.state;

    const renderStatus = (ROUTE_STATUSES || []).map(status => (
      <Option key={status.subclass} value={status.subclass}>
        {status.subclass}
      </Option>
    ));

    const renderRouteCompliance = Object.entries(ROUTE_COMPLIANCE).map(([type, value]) => (
      <Option key={type} value={value.key}>
        {value.label}
      </Option>
    ));
    const renderVehicle = (ROUTE_VEHICLE || []).map(vehicle =>(
      <Option key={vehicle.subclass} value={vehicle.subclass}>
        {vehicle.subclass}
      </Option>
    ));

    const renderRisk = Object.entries(RISKS).map(([riskType]) => (
      <Option key={riskType} value={riskType}>
        {riskType}
      </Option>
    ));

    const renderService = (servicesName || []).map(service => (
      <Option key={service.name} value={service.name}>
        {service.name}
      </Option>
    ));

    return (
      <ContainerGlobal gutter={24}>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} >
              <InputComponentWithBorder
                allowClear 
                getFieldDecorator={getFieldDecorator}
                title="Código de ruta"
                decodator="routeCode"
                placeholder="Ingresar código de ruta"
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <InputComponentWithBorder
                allowClear 
                getFieldDecorator={getFieldDecorator}
                title="trackCode"
                decodator="trackCode"
                placeholder="Ingresa un trackCode"
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <RangePickerWithBorderCompoment
                title="Fecha de inicio de ruta"
                getFieldDecorator={getFieldDecorator}
                decodator="date"
                placeholder={["Inicio", "Fin "]}
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="Estado"
                decodator="status"
                options={renderStatus}
                placeholder="Selecciona estado"
              />
            </Col>

          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="Tipo de vehículo"
                decodator="vehicle"
                options={renderVehicle}
                placeholder="Seleccione tipo de vehículo"
              />
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="Tipo de servicio"
                decodator="serviceType"
                options={renderService}
                placeholder="Seleccione tipo de servicio"
              />
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="Priorización de ruta"
                decodator="riskType"
                options={renderRisk}
                placeholder="Seleccione"
              />
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="% Cumplimiento de ruta"
                decodator="percentage"
                options={renderRouteCompliance}
                placeholder="Seleccione%"
              />
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Button
                style={{
                  marginTop: "20px",
                  width: "100%",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "16px",
                  borderColor: "#307FE2",
                  color: "#307FE2",
                }}
                size="large"
                loading={loadingBtnSearch}
                htmlType="submit"
                disabled={disabledButtonSearch}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
      </ContainerGlobal>
    );
  }
}

const WraperUserProfile = withGlobal(Header);
const HeaderForm = Form.create()(WraperUserProfile);

export default compose(withApollo)(HeaderForm);
