import React from "react";
import { RouteMapLegend } from ".";

const colors = {
  Green: "#52c41a",
  Blue: "#40A9FF",
  Yellow: "#faad14",
  Purple: "#9b51e0",
  DustRed: "#cf1322",
};

const StatusLegend = () => (
  <RouteMapLegend.Container>
    <RouteMapLegend.Title>Puntos de recolección/entrega:</RouteMapLegend.Title>
    <RouteMapLegend.Description>
      <RouteMapLegend.Marker.Fill color={colors.Purple} />
      Recolección
    </RouteMapLegend.Description>
    <RouteMapLegend.Description>
      <RouteMapLegend.Marker.Fill color={colors.Blue} />
      Entrega
    </RouteMapLegend.Description>

    <RouteMapLegend.Title>Avance de la ruta:</RouteMapLegend.Title>
    <RouteMapLegend.Description>
      <RouteMapLegend.Marker.Fill color={colors.Green} />
      Parada en curso
    </RouteMapLegend.Description>
    <RouteMapLegend.Description>
      <RouteMapLegend.Marker.Outline color={colors.Green} />
      Entrega exitosa
    </RouteMapLegend.Description>
    <RouteMapLegend.Description>
      <RouteMapLegend.Marker.Outline color={colors.DustRed} />
      Entrega fallida
    </RouteMapLegend.Description>
  </RouteMapLegend.Container>
);

export default StatusLegend;
