import React, { FC } from "react";
import {
  CardInfoContainer,
  CardInfoContent,
  CardInfoTitle,
  CardInfoNumber,
} from "./styles";
import  PopoverInfo  from "../PopoverInfo";
import { CustomIcon } from "../PopoverInfo/styles";
interface CardInfoProps {
  color?: "blue" | "red";
  quantity: number;
  title: string;
  info:string;
  titleInfo:string;
}

const CardInfo: FC<CardInfoProps> = props => {
  const { color, quantity, title,info,titleInfo } = props;

  return (
    <CardInfoContainer>
      <CardInfoContent color={color || "blue"}>
        <CardInfoTitle>{title}
        <PopoverInfo description={info} title={titleInfo} >
          <CustomIcon type="info-circle"/>
        </PopoverInfo>
        </CardInfoTitle>
        <CardInfoNumber color={color || "blue"}>{quantity}</CardInfoNumber>
      </CardInfoContent>
    </CardInfoContainer>
  );
};

export default CardInfo;
