import styled from "styled-components";
import { Row, Col } from "antd";

const CustomRow = styled(Row)`
  & > * {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  & .title {
    
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    line-height: 32px;
    margin-bottom: 8px;
    color: #595959;
  }

  & .description {
    
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #8c8c8c;
  }
`;

const CustomCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const CustomColSearch = styled(Col)`
  & .ant-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export { CustomRow, CustomCol, CustomColSearch };
