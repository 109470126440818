import React from "react";
import { Select } from "antd";

const { Option } = Select;
const hourFormat = "HH:mm";

const statusBranchOffice = GeneralStatus => {
  return GeneralStatus.map(x => {
    return { label: x.subclass, value: x.subtype };
  });
};
const integration = [
  { label: "Sí", value: true },
  { label: "No", value: false },
];

const optionsWithDisabled = AllServicesActive => {
  return AllServicesActive.map(obj => {
    return {
      label: obj.name,
      value: parseInt(obj.id, 10),
    };
  });
};

const renderDepartments = departmentList => {
  return departmentList.map(obj => (
    <Option key={obj.codeDepartment} value={obj.codeDepartment}>
      {obj.department}
    </Option>
  ));
};

const renderCities = departmentList => {
  return departmentList.map(obj => (
    <Option key={parseInt(obj.id, 10)} value={parseInt(obj.id, 10)}>
      {obj.name}
    </Option>
  ));
};
const renderProvinces = provinceList => {
  return provinceList.map(obj => (
    <Option key={obj.codeProvince} value={obj.codeProvince}>
      {obj.province}
    </Option>
  ));
};

const renderDistricts = districtList => {
  return districtList.map(obj => (
    <Option key={obj.ubigeo} value={obj.ubigeo}>
      {obj.district}
    </Option>
  ));
};

const renderEnterprise = enterprise => {
  return enterprise.map(obj => (
    <Option key={parseInt(obj.id, 10)} value={parseInt(obj.id, 10)}>
      {obj.comercialName}
    </Option>
  ));
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const daysDecode = { L: 1, M: 2, X: 3, J: 4, V: 5, S: 6, D: 7 };
const daysEncode = { 1: "L", 2: "M", 3: "X", 4: "J", 5: "V", 6: "S", 7: "D" };

const daysDecodeFormat = attentionDaysEncoded => {
  let attentionDaysFormat = [];
  if (attentionDaysEncoded[0].length > 1) {
    // Lógica 'a posteriori' ticket 636
    attentionDaysFormat =
      attentionDaysEncoded.map(attentionDay =>
        JSON.parse(attentionDay).map(dayLetter => daysDecode[dayLetter])
      ) || [];
  } else {
    // Lógica 'a priori' pre-ticket 636
    attentionDaysFormat = [
      attentionDaysEncoded.map(dayLetter => daysDecode[dayLetter]),
    ];
  }
  return attentionDaysFormat;
};

// to view only
const days = [
  {
    id: 1,
    name: "L",
  },
  {
    id: 2,
    name: "M",
  },
  {
    id: 3,
    name: "M",
  },
  {
    id: 4,
    name: "J",
  },
  {
    id: 5,
    name: "V",
  },
  {
    id: 6,
    name: "S",
  },
  {
    id: 7,
    name: "D",
  },
];

export {
  hourFormat,
  statusBranchOffice,
  integration,
  optionsWithDisabled,
  renderDepartments,
  renderProvinces,
  renderDistricts,
  formItemLayoutWithOutLabel,
  renderEnterprise,
  days,
  daysDecode,
  daysEncode,
  daysDecodeFormat,
  renderCities,
};
