export const preaffiliate = "preaffiliate";
export const affiliates = "affiliates";
export const information = "information";
export const nacionalidad = "nacionalidad";
export const companies = "business";
export const branchOffices = "branchOffices";
export const services = "services";
export const users = "users";
export const vehicles = "vehicles";
export const boxes = "boxes";
export const vehicleCategories = "vehicleCategories";
export const sectors = "sectors";
export const departments = "departments";
export const provinces = "provinces";
export const districts = "districts";
export const generatePlatform = "api/shopify/url-register";
export const registerPlatform = "api/shopify/code-client";
export const addClientMultivende =
  "cfuntions-integration-multivende/api/multivende/add-client";

export const ROUTE_STATUSES = [
  {
    subtype: 174,
    subclass: "ASSIGNED",
  },
  {
    subtype: 173,
    subclass: "PLANNED",
  },
  {
    subtype: 215,
    subclass: "DELETED",
  },
  {
    subtype: 179,
    subclass: "COMPLETED",
  },
  {
    subtype: 178,
    subclass: "IN_PROGRESS",
  },
  {
    subtype: 176,
    subclass: "WARD",
  },
  {
    subtype: 350,
    subclass: "ONGOING",
  },
  {
    subtype: 175,
    subclass: "OFFERED",
  },
];

export const ROUTE_VEHICLE = [
  {
    subtype: 36,
    subclass: "Auto",
  },
  {
    subtype: 234,
    subclass: "Bicicleta",
  },
  {
    subtype: 37,
    subclass: "Moto",
  },
  {
    subtype: 38,
    subclass: "Van",
  },
  {
    subtype: 235,
    subclass: "Van Crossdocking",
  },
];

export const RISKS = {
  Ninguno: {
    color: "#52C41A",
    backgroundColor: "#F6FFED",
    borderColor: "#B7EB8F",
  },
  Bajo: {
    color: "#1890FF",
    backgroundColor: "#E6F7FF",
    borderColor: "#91D5FF",
  },
  Moderado: {
    color: "#13C2C2",
    backgroundColor: "#E6FFFB",
    borderColor: "#87E8DE",
  },
  Alto: {
    color: "#FAAD14",
    backgroundColor: "#FFFBE6",
    borderColor: "#FFE58F",
  },
  Urgente: {
    color: "#F5222D",
    backgroundColor: "#FFF1F0",
    borderColor: "#FFA39E",
  },
};

export const ROUTE_COMPLIANCE = {
  1: {
    key: "0,20",
    label: "0% hasta 20%",
  },
  2: {
    key: "20,40",
    label: "20% hasta 40%",
  },
  3: {
    key: "40,60",
    label: "40% hasta 60%",
  },
  4: {
    key: "60,80",
    label: "60% hasta 80%",
  },
  5: {
    key: "80,100",
    label: "80% hasta 100%",
  },
};

export const markersImages = {
  blue:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIcons%2FblueMarker.png?alt=media&token=8c2754a2-2dd2-4b42-a8d6-21b1a19add83",
  red:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIcons%2FredMarker.png?alt=media&token=e03d97bd-b236-44f2-a28a-c7745096452a",
  green:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIcons%2FgreenMarker.png?alt=media&token=4bad5f34-34ac-4b3b-adb3-0be633a274c8",
  yellow:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIcons%2FyellowMarker.png?alt=media&token=c4ba014d-1939-49b8-b66a-ff8d9817f1d3",
  bluesky:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIcons%2FblueskyMarker.png?alt=media&token=99aad2d2-3c6d-4cc3-8857-93e5bfabff4e",
  success:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/iconsMap%2Fentregaexitosa.png?alt=media&token=7a89738a-98ad-460d-acd1-af2756968636",
  failed:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/iconsMap%2Fentregafallida.png?alt=media&token=7a89738a-98ad-460d-acd1-af2756968636",
  default:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIcons%2FgreenMarker.png?alt=media&token=4bad5f34-34ac-4b3b-adb3-0be633a274c8",
};

export const chazkiColor = "#307FE2";
export const redColor = "#F5222D";
export const grayColor = "#8C8C8C";

export const defaultColors = [
  "#98C9E2",
  "#FFEAA0",
  "#FA96A1",
  "#FFC53D",
  "#597EF7",
  "#9254DE",
  "#F759AB",
  "#FFBB96",
  "#FFD591",
  "#FFE58F",
  "#B7EB8F",
  "#87E8DE",
  "#ADC6FF",
  "#D3ADF7",
  "#FFADD2",
  "#FF9C6E",
  "#FFC069",
  "#FFD666",
  "#95DE64",
  "#5CDBD3",
  "#85A5FF",
  "#B37FEB",
  "#FF85C0",
  "#FF7A45",
];
export const markerIconPath =
  "M12.9342 30.8858C12.6234 30.8858 12.3203 30.7887 12.0673 30.608C11.6129 30.2867 0.933348 22.5755 0.976694 12.9423C0.976694 6.35664 6.34114 0.992188 12.9342 0.992188C19.5273 0.992188 24.8918 6.35664 24.8918 12.9497C24.9351 22.5755 14.2555 30.2867 13.8012 30.608C13.5482 30.7887 13.2451 30.8858 12.9342 30.8858Z";
