import React, { Component } from "react";
import { message, Upload } from "antd";
// import { uploadToS3 } from "utils";
import { withFirebase } from "../../firebase";

const urlEndPoint = process.env.REACT_APP_API_ENDPOINT;
const functionUpload = process.env.REACT_APP_NAME_UPLOAD_ALL;

class UploaderFireBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validFormats: [
        "",
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    };
  }

  fileNameStamp = name => {
    const getDate = new Date();
    getDate.setHours(getDate.getHours() - 5);
    let timestamp = getDate.toISOString();
    timestamp = timestamp.replace(/:/g, "-");
    timestamp = timestamp.replace(/\./g, "-");
    return timestamp + name.replace(/ /g, "_");
  };

  beforeUpload = file => {
    if (this.state.validFormats) {
      if (this.state.validFormats.indexOf(file.type) >= 0) {
        return true;
      }
      message.error("Suba archivos válidos (.png, .jpg, .jpeg .pdf, .docx)");
      return false;
    }
    return true;
  };

  handleDelete = () => {
    this.props.onDeleteImgProfile();
  };

  customPost = ({
    // action,
    // data,
    file,
    // filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const {
      firebase,
      folderName,
      target,
      // uploadComplete = null,
      // uploadingFile = null,
      // uploadingError = null,
      onUpdateFields = null,
      onUpload = null,
    } = this.props;

    // uploadingFile && uploadingFile(true);

    const fileStamp = this.fileNameStamp(file.name);
    const key = `${folderName}/${target}/${fileStamp}`;

    const task = firebase.refStorage.child(key).put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        const percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress({ percent: percentage });
      },
      err => {
        console.log("error on upload", err);
        message.error("Error al subir archivo");
        // uploadingFile && uploadingFile(false);
        // uploadingError && uploadingError(err, file.uid);
        return onError(err);
      },
      () => {
        task.snapshot.ref.getDownloadURL().then(downloadURL => {
          const { name, uid, url = downloadURL, status = "done" } = file;
          onSuccess({ name, uid, url, status, file: downloadURL });
          onUpdateFields && onUpdateFields(url);
          onUpload && onUpload(url, name);
          message.success(`Archivo subido correctamente`);
        });
      }
    );
  };

  render() {
    const { disabled } = this.props;
    return (
      <Upload
        disabled={disabled}
        action={`${urlEndPoint}/${functionUpload}`}
        beforeUpload={this.beforeUpload}
        customRequest={this.customPost}
        data={{ hola: true }}
        headers={{
          "X-Requested-With": null,
        }}
        showUploadList={false}
      >
        {this.props.btn}
      </Upload>
    );
  }
}

const UploadFile = withFirebase(UploaderFireBase);

export default UploadFile;
