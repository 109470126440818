import React from "react";
import { Empty } from "antd";

const EmptyTable = props => {
  const { loadingTable } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 350px)",
        padding: "0 8px 0 0",
      }}
    >
      <Empty
        image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
        imageStyle={{
          height: 136,
        }}
        description={null}
      >
        <span
          style={{
            color: "#8C8C8C",
            
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
          }}
        >
          {loadingTable
            ? "Cargando..."
            : "No hemos encontrado información disponible."}
        </span>
      </Empty>
    </div>
  );
};

export default EmptyTable;
