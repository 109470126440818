import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import { Empty, Tabs, message, notification, Spin, } from "antd";
import Slider from "react-slick";
import axios from "axios";
import { Container, TitleGoBack } from "../../components/Container";
import { withGlobal } from "../../component-global";
import { SenHeader, SenReport, SenEvidence } from "./components";
import { CustomFilterContainer } from "../../components/Form/SearchContainer";
import { ContainerTab, ModalCustom, ContainerImg } from "./style";
import {
  getCitiesOfBranchOfficeByEnterpriseID,
  getAllServices,
  getStatusOrder,
  getZeldaReportquery,
  getZeldaReportDetailquery,
  getZeldaReportEvidencequery,
} from "../../graphQl/queries/reports";
import moment from "moment";
import { saveAs } from "file-saver";
let zip = require('jszip')();

const { TabPane } = Tabs;

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOptionsService: [],
      dataOptionsCities: [],
      dataOptionsStatus: [],
      loadingBtnSearch: false,
      loadingChart: false,
      loadingTable: false,
      limit: 10,
      pageE: 0,
      pageF: 0,
      currentFilterE: {},
      currentFilterF: {},
      loadingButonExport: false,
      loadingEvidence: false,
      loadingTableEvidence: false,
      pagination: { limit: 10, page: 0 },
      currentFilterEvidence: {},
      imagesSelected: [],
      loadingButonZip: false,
      donwloadEvidence: false,
    };
  }

  parseURI = async url => {
    const config = { responseType: 'blob' };
    const res = await axios.get(url, config);
    const blob = await res.data;
    const reader = new FileReader();    
    reader.readAsDataURL(blob);          
    return new Promise((res,rej)=> {  
      reader.onload = (e) => {        
        res(e.target.result)
      }
    })
  } 
  
  donwload = async (images, trackCode) => {
    const fileBase64 = [];
    for await (const result of images.map(async file => {return {
      base64: await this.parseURI(file.url),
      service: file.orderServiceID,
      name: `${file.id}.png`,
      trackCode: trackCode
    }})){
      fileBase64.push(result);
    }
    return fileBase64;
  }

  onExportZIP = async () => {
    try {
      if (!this.state.donwloadEvidence) {
        notification.warning({
          message: "Solo se puede descargar evidencias en un periodo de una semana!"
        })
        return
      }
      this.setState({
        loadingButonZip: true
      });
      const { dataEvidence } = this.state;
      let arrays = [];
      for await (const result of dataEvidence.map(file => this.donwload(file.OrdersImages, file.trackCode))){
        arrays = arrays.concat(result);
      }
      this.generateZIP(arrays);
    } catch (error) {
      this.setState({ loadingButonZip: false });
      notification.error({
        message: `Error al intentar descargar evidencias`,
      });
      console.log("error", error);
    }
  }

  generateZIP = async files => {
    const {
      trackCode,
      rangeBy
    } = this.state.currentFilterEvidence;
    if(trackCode) {
      files.forEach(item => {
        const folder = zip.folder(item.trackCode);
        const subFolder = folder.folder(item.service);
        const imageArray = item.base64.split(',')
        subFolder.file(item.name, imageArray[1], {base64: true});
      });
    } else if(rangeBy) {
      const dateInit = rangeBy.updatedAt.greaterEqual.split(' ');
      const dateFinish = rangeBy.updatedAt.lessEqual.split(' ');
      const folderContent = zip.folder(`Evidencias-del-${dateInit[0]}-al-${dateFinish[0]}`)
      files.forEach(item => {
        const folder = folderContent.folder(item.trackCode);
        const subFolder = folder.folder(item.service);
        const imageArray = item.base64.split(',')
        subFolder.file(item.name, imageArray[1], {base64: true});
      });
    }

    zip.generateAsync({type: 'blob'}).then(function(content) {
      saveAs(content, "Evidencias.zip");
    });
    zip = require('jszip')();
    this.setState({
      loadingButonZip: false
    });
    notification.success({
      message: "Descarga de reporte con éxito",
      description: `Se descargó la lista en el archivo Evidencias.zip`
    })
  }

  componentDidMount = async () => {
    try {
      this.setState({
        loadingBtnSearch: true,
        loadingChart: true,
        loadingTable: true,
      });
      if (
        this.props.userEnterpriseData &&
        this.props.userEnterpriseData.enterpriseID
      ) {
        const {
          data: { ZeldaReport: dataReportService },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getZeldaReportquery,
          variables: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
          },
        });

        const {
          data: { ZeldaReportDetail: dataReportServiceDetail },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getZeldaReportDetailquery,
          variables: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
            limit: this.state.limit,
            offset: 0,
          },
        });

        this.setState({
          loadingTable: false,
          loadingChart: false,
          datasetsE: dataReportService.datasets,
          datalabelsE: dataReportService.labels,
          datadetailE: dataReportServiceDetail.rows,
          datadetailtotalE: dataReportServiceDetail.count,
          datasetsF: dataReportService.datasets,
          datalabelsF: dataReportService.labels,
          datadetailF: dataReportServiceDetail.rows,
          datadetailtotalF: dataReportServiceDetail.count,
        });

        const {
          data: { getCitiesOfBranchOfficeByEnterpriseID: dataOptionsCities },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getCitiesOfBranchOfficeByEnterpriseID,
          variables: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
          },
        });

        const {
          data: { getAllServices: dataOptionsService },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getAllServices,
          variables: {},
        });

        const {
          data: { getStatusOrder: dataOptionsStatus },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getStatusOrder,
          variables: {},
        });

        this.setState({
          loadingBtnSearch: false,
          dataOptionsService,
          dataOptionsCities,
          dataOptionsStatus,
        });
      }
    } catch (err) {
      this.setState({
        loadingBtnSearch: false,
        loadingChart: false,
        loadingTable: false,
      });
      notification.error({message: "Error en la carga de datos."})
      message.error("Error en la carga de datos.");
    }
  };

  
  getEvidenceImages = evidenceImages => { 
    console.log(evidenceImages);
    this.setState({ loadingGallery: true });
    this.setState({
      visibleImgs: true,
      loadingGallery: false,
      imagesSelected: evidenceImages,
    });
  };

  onCloseModalImg = () => {
    this.setState({ visibleImgs: false, imagesSelected: [] });
  };

  onShowModalReoffertOrder = () => {
    this.setState({
      showModalReoffertOrder: true,
    });
  };

  onCloseModalReoffertOrder = () => {
    this.setState({
      showModalReoffertOrder: false,
    });
  };

  SearchEvidences = async filters => {
    try {
      if (Object.keys(filters).length === 0) {
        notification.warning({
          message: "No se ingresaron datos para la búsqueda."
        })
        return
      }
      let dateRange;
      if(filters.rangeBy) {
        const dateGreater = moment(filters.rangeBy.updatedAt.greaterEqual);
        const dateLess = moment(filters.rangeBy.updatedAt.lessEqual);
        dateRange = dateLess.diff(dateGreater, "days") + 1;
      }
      this.setState({
        loadingEvidence: true,
        loadingTableEvidence: true,
        pagination: { limit: 10, page: 0 },
        donwloadEvidence: filters.trackCode
          ? true : dateRange<=7,
      });
      const {
        data: { ZeldaReportEvidence: dataTable },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getZeldaReportEvidencequery,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          ...filters,
        },
      });
      console.log(dataTable);
      this.setState({
        currentFilterEvidence: filters,
        dataEvidence: dataTable.rows,
        paginateDataEvidence: dataTable.rows ? dataTable.rows.slice(0,this.state.pagination.limit): undefined,
        dataEvicenceTotal: dataTable.count,
      });

      this.setState({
        loadingEvidence: false,
        loadingTableEvidence: false,
      });
    } catch (err) {
      console.log("Error");
      this.setState({
        loadingEvidence: false,
        loadingTableEvidence: false,
      });
      message.error("Error en la carga de datos.");
    }
  };

  onPaginationEvidence = async (page, pageSize) => {
    console.log("page: ", page, "pageZise: ", pageSize);
    const indexRight = page*this.state.pagination.limit;
    const indexLeft = (page - 1)*this.state.pagination.limit;
    this.setState({
      paginateDataEvidence: this.state.dataEvidence.slice(indexLeft,indexRight),
      pagination: { limit: 10, page: page - 1 } 
    });
  };

  SearchOrders = async (filter, tipo) => {
    try {
      this.setState({
        loadingBtnSearch: true,
        loadingChart: true,
        loadingTable: true,
        pageE: 0,
      });
      const {
        data: { ZeldaReportDetail: dataReportServiceDetail },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getZeldaReportDetailquery,
        variables: {
          ...filter,
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          limit: this.state.limit,
          offset: 0,
        },
      });

      const {
        data: { ZeldaReport: dataReportService },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getZeldaReportquery,
        variables: {
          ...filter,
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
        },
      });

      if (tipo === 1) {
        this.setState({
          currentFilterE: filter,
          datasetsE: dataReportService.datasets,
          datalabelsE: dataReportService.labels,
          datadetailE: dataReportServiceDetail.rows,
          datadetailtotalE: dataReportServiceDetail.count,
        });
      } else if (tipo === 2) {
        this.setState({
          currentFilterF: filter,
          datasetsF: dataReportService.datasets,
          datalabelsF: dataReportService.labels,
          datadetailF: dataReportServiceDetail.rows,
          datadetailtotalF: dataReportServiceDetail.count,
        });
      }
      this.setState({
        loadingBtnSearch: false,
        loadingChart: false,
        loadingTable: false,
      });
    } catch (err) {
      this.setState({
        loadingBtnSearch: false,
        loadingChart: false,
        loadingTable: false,
      });
      message.error("Error en la carga de servicios.");
    }
  };

  onChangePagination1 = async (page, pageSize) => {
    this.setState({ loadingTable: true });
    const { limit } = this.state;
    try {
      const {
        data: { ZeldaReportDetail: dataReportServiceDetail },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getZeldaReportDetailquery,
        variables: {
          ...this.state.currentFilterE,
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          offset: (page - 1) * limit,
          limit,
        },
      });

      this.setState({
        pageE: page - 1,
        datadetailE: dataReportServiceDetail.rows,
        datadetailtotalE: dataReportServiceDetail.count,
        loadingTable: false,
      });
    } catch (err) {
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos.");
    }
  };

  onChangePagination2 = async (page, pageSize) => {
    this.setState({ loadingTable: true });
    const { limit } = this.state;
    try {
      const {
        data: { ZeldaReportDetail: dataReportServiceDetail },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getZeldaReportDetailquery,
        variables: {
          ...this.state.currentFilterF,
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          offset: (page - 1) * limit,
          limit,
        },
      });
      this.setState({
        pageF: page - 1,
        datadetailF: dataReportServiceDetail.rows,
        datadetailtotalF: dataReportServiceDetail.count,
        loadingTable: false,
      });
    } catch (err) {
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos.");
    }
  };

  getTag = key => {
    switch (key) {
      /* case "NEW":
        return { name: "Última Milla", color: "gray" }; */
      case "RETURNED":
        return { name: "Devuelto", color: "red" };
      /* case "PICKUP":
        return { name: "Recolección", color: "blue" }; */
      /* case "REPROGRAMMING":
        return { name: "Reprogramación", color: "orange" }; */
      case "DELIVERED":
        return { name: "Entregado", color: "green" };
      default:
        return { name: null, color: null };
    }
  };

  onExportCSV = async () => {
    this.setState({ loadingButonExport: true });
    const { currentFilterE } = this.state;
    console.log(currentFilterE);
    const data = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_PROVIDER}:${process.env.REACT_APP_AUTH_CLIENT_ID}`
      )
    );
    const token =
      data &&
      data.access_token &&
      data.token_type &&
      `${data.token_type} ${data.access_token}`;

    const config = {
      headers: {
        Authorization: token || "",
        "Content-Type": "application/json",
      },
    };
    const body = {
      idReport: "zeldaReport",
      argsQuery: {
        ...currentFilterE,
        enterpriseID: this.props.userEnterpriseData.enterpriseID,
      },
    };

    try {
      axios
        .post(
          `${process.env.REACT_APP_CFUNCTION_DOMAIN}/createCSVreportGenerator`,
          body,
          config
        )
        .then(res => {
          this.setState({ loadingButonExport: false });

          if (res.data) {
            console.log(res.data);
            notification.success({
              message: `El Reporte se esta descargando`,
            });
            const url = res.data.file[0].mediaLink;
            window.open(url, "_blank");
          }
        })
        .catch(error => {
          notification.error({
            message: `Error en el sistema, intentelo de nuevo.`,
          });
          this.setState({ loadingButonExport: false });
          console.log(error);
        });
    } catch (e) {
      this.setState({ loadingButonExport: false });
      notification.error({
        message: `Error en el sistema, intentelo de nuevo.`,
      });
      console.log("error", e);
    }
  };

  onDownloadReportHomologated = async filter => {
    const SONIC_KEY = process.env.REACT_APP_SONIC_KEY;
    const SONIC_ENDPOINT = process.env.REACT_APP_SONIC_REPORTS;

    // console.log("SONIC ENDPOINT => ", SONIC_ENDPOINT);
    // console.log("SONIC KEY => ", SONIC_KEY);
    this.setState({ loadingBtnSearch: true });
    // eslint-disable-next-line camelcase
    const { initial_date, finish_date } = filter;
    const { Enterprise } = this.props.userEnterpriseData;

    const config = {
      headers: {
        Token: SONIC_KEY,
        "Content-Type": "application/json",
      },
    };
    const body = {
      city_id: Enterprise.cityID,
      time_zone: "UTC",
      initial_date,
      finish_date,
      enterprise_id: Enterprise.id,
    };

    try {
      axios
        .post(`${SONIC_ENDPOINT}/homologated/order`, body, config)
        .then(res => {
          this.setState({ loadingBtnSearch: false });

          if (res.data) {
            notification.success({
              message: `El Reporte se esta descargando`,
            });
            this.checkReportStatus({
              config,
              id: res.data.id,
              filename: res.data.filename,
            });
          }
        })
        .catch(error => {
          notification.error({
            message: `Error en el sistema, intentelo de nuevo.`,
          });
          this.setState({ loadingBtnSearch: false });
          console.log(error);
        });
    } catch (e) {
      this.setState({ loadingBtnSearch: false });
      notification.error({
        message: `Error en el sistema, intentelo de nuevo.`,
      });
      console.log("error", e);
    }
  };

  checkReportStatus = async ({ config, id, filename }) => {
    const SONIC_FINANCE_URL = process.env.REACT_APP_SONIC_REPORTS;

    const body = {
      field: "id",
      id_list: [id],
    };

    await new Promise(res => setTimeout(res, 2000));

    try {
      axios
        .post(`${SONIC_FINANCE_URL}/cache/reports`, body, config)
        .then(async res => {
          if (res.data && res.data.length !== 0) {
            res.data.forEach(elem => {
              switch (elem.status) {
                case "Procesando":
                  break;
                case "Disponible":
                  notification.success({
                    message: "Descargando Reporte.",
                  });
                  // saveAs(elem.filename, filename);
                  window.open(elem.filename, "_blank");
                  break;
                case "Cancelado":
                  notification.error({
                    message: "Reporte Cancelado.",
                  });
                  break;
                case "Paso por error":
                  notification.error({
                    message: "Error en el sistema, inténtelo de nuevo.",
                  });
                  break;
                default:
                  break;
              }
            });
          } else this.checkReportStatus({ config, id, filename });
        })
        .catch(_ => {
          notification.error({
            message: "Error en el sistema, inténtelo de nuevo.",
          });
        });
    } catch (error) {
      notification.error({
        message: "Error en el sistema, inténtelo de nuevo.",
      });
    } finally {
      this.setState({ loadingBtnSearch: false });
    }
  };

  render() {
    const {
      dataOptionsCities,
      dataOptionsStatus,
      dataOptionsService,
      loadingBtnSearch,
      loadingTable,
      loadingChart,
      loadingButonExport,
      limit,

      datalabelsE,
      datasetsE,
      datadetailE,
      datadetailtotalE,
      currentFilterE,
      pageE,

      // datalabelsF,
      // datasetsF,
      // datadetailF,
      // datadetailtotalF,
      // currentFilterF,
      // pageF,
      loadingEvidence,
      loadingTableEvidence,
      currentFilterEvidence,
      paginateDataEvidence,
      dataEvicenceTotal,
      pagination,
      imagesSelected,
      loadingButonZip,
    } = this.state;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

   
    const listImgsOrderFormatted = () => {
      return (imagesSelected || []).map(obj => {
        const img = {
          id: obj.id,
          img: obj.url,
        };
        return img;
      });
    };

    const renderImgs = listImgsOrderFormatted().map(x => {
      return (
        <ContainerImg key={x.id.toString()}>
          <div>
            <img alt="img" src={x.img} />
          </div>
        </ContainerImg>
      );
    });

    return (
      <Container>
        <TitleGoBack title="Reportes" />
        <CustomFilterContainer
          style={{ padding: "24px ", marginBottom: "0px" }}
        >
          <ContainerTab defaultActiveKey="1">
            <TabPane
              tab={
                <div
                  style={{
                    
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Envíos
                </div>
              }
              key="1"
            >
              <SenHeader
                SearchOrders={this.SearchOrders}
                statusOptions={dataOptionsStatus}
                serviceOptions={dataOptionsService}
                cityOptions={dataOptionsCities}
                loadingBtnSearch={loadingBtnSearch}
                evidence={false}
              />
              {this.state.loadingBtnSearch ? (
                <div
                  style={{
                    height: "calc(90vh - 400px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Empty
                    image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
                    imageStyle={{
                      height: 136,
                    }}
                    description={
                      <span
                        style={{
                          color: "#8C8C8C",
                          
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "20px",
                        }}
                      >
                        Cargando...
                      </span>
                    }
                  />
                </div>
              ) : (
                <SenReport
                  reportDatalabel={datalabelsE || []}
                  reportDatasets={datasetsE || []}
                  reportDataDetail={datadetailE || []}
                  currentFilterE={currentFilterE}
                  loadingTable={loadingTable}
                  loadingChart={loadingChart}
                  currentPage={pageE + 1}
                  pageSize={limit}
                  onChange={this.onChangePagination1}
                  datadetailtotal={datadetailtotalE}
                  getTag={this.getTag}
                  onExportCSV={this.onExportCSV}
                  loadingButonExport={loadingButonExport}
                />
              )}
            </TabPane>

            {/* <TabPane
              tab={
                <div
                  style={{
                    
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Finanzas
                </div>
              }
              key="2"
            >
              <FinaHeader
                SearchOrders={this.SearchOrders}
                statusOptions={dataOptionsStatus}
                serviceOptions={dataOptionsService}
                cityOptions={dataOptionsCities}
                loadingBtnSearch={loadingBtnSearch}
              />
              <FinaReport
                reportDatalabel={datalabelsF || []}
                reportDatasets={datasetsF || []}
                reportDataDetail={datadetailF || []}
                loadingTable={loadingTable}
                loadingChart={loadingChart}
                currentPage={pageF + 1}
                pageSize={limit}
                onChange={this.onChangePagination2}
                datadetailtotal={datadetailtotalF}
                currentFilterF={currentFilterF}
              />
            </TabPane> */}
            <TabPane
              tab={
                <div
                  style={{
                    
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Evidencias
                </div>
              }
              key="2"
            >
              <SenHeader
                SearchOrders={this.SearchEvidences}
                loadingBtnSearch={loadingEvidence}
                evidence={true}
              />
              {this.state.loadingEvidence || !(dataEvicenceTotal && dataEvicenceTotal>0 )? (
                <div
                  style={{
                    height: "calc(90vh - 300px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Empty
                    image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
                    imageStyle={{
                      height: 136,
                    }}
                    description={
                      <span
                        style={{
                          color: "#8C8C8C",
                          
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "40px",
                        }}
                      > 
                      { this.state.loadingEvidence ?"Cargando...":"No se ha filtrado ninguna orden"}
                        
                      </span>
                    }
                  />
                </div>
              ) : (
                <SenEvidence
                  reportDataDetail={paginateDataEvidence || []}
                  datadetailtotal={dataEvicenceTotal}
                  currentFilterE={currentFilterEvidence}
                  loadingTable={loadingTableEvidence}

                  currentPage={pagination.page + 1}
                  pageSize={pagination.limit}
                  onChange={this.onPaginationEvidence}
                  getTag={this.getTag}
                  onExportZIP={this.onExportZIP}
                  loadingButonZip={loadingButonZip}
                  getEvidenceImages={this.getEvidenceImages}
                />
              )}
            </TabPane>
            <TabPane
              tab={
                <div
                  style={{
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Envíos Por Estados
                </div>
              }
              key="3"
            >
              <SenHeader
                loadingBtnSearch={loadingBtnSearch}
                onDownloadReportHomologated={this.onDownloadReportHomologated}
                ordersByStates
              />
            </TabPane>
          </ContainerTab>
          
          {/* Modal para la visualizacion de imagenes */}
          <ModalCustom
            title={null}
            footer={null}
            visible={this.state.visibleImgs}
            onCancel={this.onCloseModalImg}
            closable
            width={680}
            bodyStyle={{ height: "520px" }}
          >
            <Spin spinning={this.state.loadingGallery}>
              {renderImgs.length || this.state.loadingGallery ? (
                <Slider {...settings}>{renderImgs}</Slider>
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Empty
                    image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
                    description={<span>No se encontraron imágenes</span>}
                  />
                </div>
              )}
            </Spin>
          </ModalCustom>
        </CustomFilterContainer>
      </Container>
    );
  }
}

const WraperReports = withGlobal(Reports);

export default compose(withApollo)(WraperReports);
