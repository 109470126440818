import gql from "graphql-tag";

const getOrdersForB2B = gql`
  query getOrdersForB2B(
    $offset: Int
    $limit: Int
    $trackCode: String
    $enterpriseID: Int
    $startDate: Date
    $endDate: Date
    $printed: Boolean
  ) {
    GetOrdersForB2B: getOrdersForB2B(
      offset: $offset
      limit: $limit
      trackCode: $trackCode
      enterpriseID: $enterpriseID
      startDate: $startDate
      endDate: $endDate
      printed: $printed
    ) {
      count
      rows {
        id
        trackCode
        Enterprise {
          comercialName
        }
        createdAt
        Service {
          name
        }
        pickUpAddress
        dropAddress
        productPrice
        pickUpPrimaryReference
        dropPrimaryReference
        pickUpSecondaryReference
        dropSecondaryReference
        dropContactName
        packageQuantity
        dropAddressReference
        pickUpContactName
        pickUpAddressReference
        PaymentMethodID {
          subclass
        }
        productDescription
        dropNotes
        City {
          id
          name
        }
        printed
      }
    }
  }
`;

export { getOrdersForB2B };
