import React from "react";
import { Input } from "antd";
import { FormItemInvisible } from "../Form";

class InputComponent extends React.Component {
  render() {
    const {
      getFieldDecorator,
      title,
      decodator,
      placeholder,
      value,
      required,
      disabled,
      typeInput,
      autoCompleteInput,
    } = this.props;

    return (
      <div style={{ marginBottom: 20 }}>
        <FormItemInvisible
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: value || undefined,
            rules: [
              {
                required,
                message: ".",
              },
            ],
          })(
            <Input
              disabled={disabled}
              placeholder={placeholder}
              type={typeInput}
              autoComplete={autoCompleteInput}
            />
          )}
        </FormItemInvisible>
      </div>
    );
  }
}

export default InputComponent;
