import { Button, Col, Form, Row, Modal } from "antd";
import { withApollo } from "react-apollo";
import React from "react";
import { compose } from "recompose";

const DeleteModal = Form.create({ name: "form_in_modal" })(
  class extends React.Component {
    render() {
      const {
        onDelete,
        onCancel,
        visible,
        loadingDelete,
        message,
        nRegisters,
      } = this.props;
      return (
        // <ModalCreate>
        <Modal
          visible={visible}
          // title="Create a new collection"
          footer={null}
          width={384}
          // okText="Create"
          centered
          onCancel={onCancel}
          // onOk={onDelete}
        >
          <div style={{ margin: "0px 16px 0px 16px" }}>
            <h2
              style={{
                
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "24px",
                marginTop: "8px",
                lineHeight: "32px",
                color: "#595959",
                textAlign: "center",
              }}
            >
              {message}
            </h2>

            {nRegisters > 1 ? (
              <p style={{ textAlign: "center" }}>
                ¿Deseas eliminar los registros seleccionados?
              </p>
            ) : (
              <p style={{ textAlign: "center" }}>
                ¿Deseas eliminar el registro seleccionado?
              </p>
            )}

            <Form layout="vertical" onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Button
                    type="primary"
                    size="large"
                    ghost
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                    onClick={onCancel}
                    // htmlType="submit"
                    // loading={loadingUpdate}
                    // // loading={loadingBtnSearch}
                    // disabled={!(!!baseTariff && !!workingHours)}
                  >
                    Lo veré de nuevo
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Button
                    type="primary"
                    size="large"
                    // ghost
                    style={{
                      width: "100%",
                      // marginTop: "20px",
                      backgroundColor: "#CF1322",
                      borderColor: "#CF1322",
                      color: "#fff",
                      fontSize: "14px",
                    }}
                    onClick={onDelete}
                    // htmlType="submit"
                    loading={loadingDelete}
                    // // loading={loadingBtnSearch}
                    // disabled={!(!!baseTariff && !!workingHours)}
                  >
                    Sí, eliminar
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      );
    }
  }
);

export default compose(withApollo)(DeleteModal);
