const constants = {
  urlBack: process.env.REACT_APP_BACK,
  imgNewContact:
    "https://chazkistorage.blob.core.windows.net/resources/new_contact.svg",
};

const constantsOauth = {
  stsAuthority: process.env.REACT_APP_AUTH_PROVIDER,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  clientRoot: process.env.REACT_APP_URL,
  clientScope: "openid profile email role",
  apiRoot: `${process.env.REACT_APP_AUTH_PROVIDER}/token`,
};

export { constants, constantsOauth };
