import React from "react";
import { withApollo, graphql } from "react-apollo";
import { compose } from "recompose";
import { notification, Button } from "antd";
import TitleGoBack from "../../../components/Container/titleGoBack";
import { Container } from "../../../components/Container";
import BodyContainer from "../../../components/Layout/BodyContainer";
import Header from "./Headers";
import Body from "./Body";
import { getUsersEnterprise } from "../../../graphQl/queries/Accounts";
import { withGlobal } from "../../../component-global";
import { withGlobalContext } from "../../../utils/globalContext";
import { deleteUsers } from "../../../graphQl/mutations/accounts";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { captureException } from "../../../utils/sentry"
import ModalUser from "../../../components/Modals/BlockUser";
import { getGeneralStatusUser } from "../../../graphQl/queries/Accounts";

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataUsers: [],
      pagination: { limit: 10, page: 0, loadingTable: false },
      selectedRowKeys: [],
      loadingDelete: false,
      loadingButonChangeState: false,
      visibleDelete: false,
      visibleModal: false,
    };
  }

  componentDidMount = () => {
    this.getDataAccounts();
  };

  getDataAccounts = async () => {
    this.setState({ loading: true });
    const {
      data: { UsersEnterprise },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getUsersEnterprise,
      variables: {
        enterpriseID: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
        offset: 0,
        limit: 10,
      },
    });
    this.setState({
      dataUsers: UsersEnterprise.rows,
      totalOrders: UsersEnterprise.count,
      loading: false,
    });
  };

  onChange = async (page, pageSize) => {
    try {
      this.setState({ loading: true });
      const { pagination, filter } = this.state;
      const {
        data: { UsersEnterprise },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getUsersEnterprise,
        variables: {
          ...filter,
          enterpriseID: parseInt(
            this.props.userEnterpriseData.enterpriseID,
            10
          ),
          limit: pagination.limit,
          offset: (page - 1) * pagination.limit,
        },
      });

      this.setState({
        pagination: { limit: pageSize, page: page - 1 },
        dataUsers: UsersEnterprise.rows,
        totalOrders: UsersEnterprise.count,
        loading: false,
      });
    } catch (err) {
      // captureException(err);
      this.setState({ loading: false });
      console.log(err);
      notification.error({ message: "Error en la carga de servicios." });
    }
  };

  onDeleteBranches = () => {
    const { selectedRowKeys } = this.state;

    this.setState({ loadingDelete: true });

    const variables = {
      ids: (selectedRowKeys || []),
    };
    this.props.client
      .mutate({
        mutation: deleteUsers,
        update: (cache, { data: { DeleteUsers } }) => {
          if (selectedRowKeys.length === 1) {
            const found = this.state.dataUsers.find(element => element.id === selectedRowKeys[0]);
            notification.success({
              message: `Usuario eliminado con éxito.`,
              description: 'Has eliminado a ' + found.names + ' ' + found.lastname + ' del registro.'
            });
          } else if (selectedRowKeys.length > 1) {
            notification.success({
              message: `Usuario eliminado con éxito.`,
              description: 'Has eliminado a ' + selectedRowKeys.length + ' usuarios del registro.'
            });
          }
          this.setState({
            loadingDelete: false,
            visibleDelete: false
          });
          this.SearchUsers();
        },
        variables,
      })
      .catch(err => {
        captureException(err);
        notification.error({
          message: "No se pudo eliminar, error en el sistema.",
        });
        this.setState({ loadingDelete: false });
      });
    this.setState({ selectedRowKeys: [] });
  };

  SearchUsers = async filter => {
    const { pagination } = this.state;
    this.setState({ loading: true, loadingBtnSearch: true });
    const {
      data: { UsersEnterprise },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getUsersEnterprise,
      variables: {
        ...filter,
        enterpriseID: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
        limit: pagination.limit,
        offset: 0 * pagination.limit,
      },
    });

    this.setState({
      dataUsers: UsersEnterprise.rows,
      loading: false,
      loadingBtnSearch: false,
      selectedRowKeys: [],
    });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys, isSelectAll: false });
  };

  onSelectAllRows = selected => {
    this.setState({ isSelectAll: selected });
  };

  handleCancelModal = () => {
    this.setState({
      visibleModal: false,
    });
  };

  changeVisibleModal = () => {
    this.setState({
      visibleModal: true
    });

  };

  getUserData = (principalUserID) => {
    const dataUser = sessionStorage.getItem("principalUserEnterprise")

    return principalUserID === dataUser
  }

  render() {
    const { pagination, loading, selectedRowKeys, loadingButonChangeState, visibleModal } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      onSelectAll: this.onSelectAllRows,
    };
    const {
      allStatus: { GeneralStatus },
      authUser: {
        profile: {
          sub
        }
      }
    } = this.props;

    const isPrincipalUser = this.getUserData(sub)

    return (
      <Container>
        <TitleGoBack title="Roles" />
        <BodyContainer gutter={16} justify="center">
          <Header
            SearchUsers={this.SearchUsers}
            cleanSearch={this.cleanSearch}
            client={this.props.client}
            loadingBtnSearch={this.state.loadingBtnSearch}
            SearchUsersByName={[]}
          />
          <Body
            loading={loading}
            data={this.state.dataUsers.filter(row => !row.deleted)}
            totalOrders={this.state.totalOrders}
            currentPage={pagination.page + 1}
            pageSize={pagination.limit}
            onChange={this.onChange}
            rowSelection={rowSelection}
          />
          <Button
            type="primary"
            size="large"
            style={{ position: "absolute", bottom: 24, right: 125 }}
            loading={this.state.loadingDelete}
            disabled={!isPrincipalUser || !this.state.selectedRowKeys.length}
            onClick={this.changeVisibleModal}
          >
            Cambiar estado
          </Button>
          <Button
            type="danger"
            size="large"
            style={{ position: "absolute", bottom: 24, right: 24 }}
            loading={loadingButonChangeState}
            onClick={() => this.setState({ visibleDelete: true })}
            disabled={!this.state.selectedRowKeys.length}
          >
            Eliminar
          </Button>
          <DeleteModal
            visible={this.state.visibleDelete}
            loadingDelete={this.state.loadingDelete}
            onCancel={() => this.setState({ visibleDelete: false })}
            onDelete={this.onDeleteBranches}
            message="Eliminar usuario"
            nRegisters={this.state.selectedRowKeys.length}
          />
        </BodyContainer>

        <ModalUser
          onHandleCancelModal={this.handleCancelModal}
          visible={visibleModal}
          usersIDsSelected={selectedRowKeys}
          statusUsers={GeneralStatus}
          loadTable={this.SearchUsers}
        />

      </Container>
    );
  }
}

const WrapperBranches = withGlobal(Accounts);

export default compose(
  graphql(getGeneralStatusUser, {
    name: "allStatus",
    options: () => {
      return {
        notifyOnNetworkStatusChange: true,
        variables: {},
      };
    },
  }),
  withApollo,
  withGlobalContext
)(WrapperBranches);
