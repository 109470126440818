import { Button, Checkbox, Col, Form, Row, notification, Select } from "antd";
import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { withGlobal } from "../../../component-global";
import CustomCollapse from "../../../components/Collapse/CumstomCollapse";
import { getServices } from "../../../graphQl/queries/services";
import { Container, TitleGoBack } from "../../../components/Container";
import InputComponent from "../../../components/FormFields/InputWithBorderComponent";
import BodyContainer from "../../../components/Layout/BodyContainer";
import { withGlobalContext } from "../../../utils/globalContext";
import CheckBoxComponentWithBorderNew from "../../../components/FormFields/CheckBoxWithBorderComponentNew";
import SelectComponent from "../../../components/FormFields/SelectWithBorderComponent";
import SwitchComponent from "../../../components/FormFields/SwitchComponent";
import { createEnterprisePlatforms } from "../../../graphQl/mutations/enterprisePlatforms";
import { addClientMultivende, generatePlatform } from "../../../utils";

const { Option } = Select;

class IntegrationPlatforms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingOnSave: false,
      allServices: [],
    };
  }

  componentDidMount = async () => {
    await this.getAllServices();
  };

  getAllServices = async () => {
    const {
      data: { AllServices },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getServices,
    });
    this.setState({
      allServices: AllServices && AllServices.rows,
    });
  };

  onCreateEnterprisePlatform = async (
    namePlatform,
    idPlatform,
    services,
    branchDefaultPlatform,
    localShipping = false,
    manualPrice = false,
    automaticApproval = true
  ) => {
    try {
      const variables = {
        enterpriseID: this.props.userEnterpriseData.enterpriseID,
        namePlatform,
        idPlatform,
        services,
        branchDefaultPlatform,
        localShipping,
        manualPrice,
        automaticApproval,
      };

      const { data, errors } = await this.props.client.mutate({
        mutation: createEnterprisePlatforms,
        variables,
      });

      if (errors) {
        console.log(errors);
        notification.error({
          message: `No se pudo guardar, error en el sistema.`,
        });
        this.setState({ loadingOnSave: false });
      }
      return data ? data.CreateEnterprisePlatforms : null;
    } catch (err) {
      console.log(err);
      notification.error({
        message: `No se pudo guardar, error en el sistema.`,
      });
      this.setState({ loadingOnSave: false });

      return null;
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        if (err.shop)
          notification.error({
            message: "¡Dirección de tienda Shopify es requerido!",
          });
        else if (err.serviceIDs) {
          notification.error({
            message: "¡Seleccione almenos un servicio es requerido!",
          });
        }
        return;
      }
      if (
        (values.localShipping || values.manualPrice) &&
        values.serviceIDs.length > 1
      ) {
        notification.error({
          message: `Solo se puede seleccionar un servicio cuando tiene activo Precio manual o Envio local.`,
        });
        return;
      }

      const createPlatforms = await this.onCreateEnterprisePlatform(
        this.props.platform.toUpperCase(),
        values.shop,
        values.serviceIDs,
        values.switchSucursal,
        values.localShipping,
        values.manualPrice,
        values.automaticApproval
      );

      if (createPlatforms) {
        notification.success({
          message: `¡Plataforma creada con exito!`,
        });
        this.setState({ loadingOnSave: false });

        window.location.href =
          `${process.env.REACT_APP_CFUNCTION_DOMAIN}` +
          `/${process.env.REACT_APP_CFINTEGRATION}` +
          `/${generatePlatform}?shop=${values.shop.toLowerCase()}`;
      }
    });
  };

  handleSubmitMultivende = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        if (err.services)
          notification.error({
            message: "¡Tipo de servicio es requerido!",
          });
        return;
      }

      const createPlatforms = await this.onCreateEnterprisePlatform(
        this.props.platform.toUpperCase(),
        this.props.platform.toUpperCase(),
        [values.services],
        false,
        false,
        false,
        values.automaticApproval
      );

      if (createPlatforms) {
        notification.success({
          message: `¡Plataforma creada con exito!`,
        });

        this.setState({ loadingOnSave: false });
        window.location.href = `${process.env.REACT_APP_CFUNCTION_DOMAIN}/${addClientMultivende}`;
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const optionsCheckGroup = (AllServicesActive, spanSize) => {
      const arrayRender = AllServicesActive.map(obj => {
        return (
          <Col
            span={spanSize}
            key={`colCheck_${parseInt(obj.id, 10)}`}
            style={{
              marginBottom: 15,
              marginTop: 5,
            }}
          >
            <Checkbox value={parseInt(obj.id, 10)} key={parseInt(obj.id, 10)}>
              {obj.name}
            </Checkbox>
          </Col>
        );
      });
      return <Row>{arrayRender}</Row>;
    };

    const renderAllService = AllServicesActive => {
      if (!AllServicesActive) return [];

      return (AllServicesActive || []).map(obj => (
        <Option key={parseInt(obj.id, 10)} value={parseInt(obj.id, 10)}>
          {obj.name}
        </Option>
      ));
    };

    const GetPlatform = platform => {
      switch (platform.toUpperCase()) {
        case "SHOPIFY":
          return (
            <Form onSubmit={this.handleSubmit}>
              <CustomCollapse headerTitle="Datos de nueva integración">
                <Row gutter={[24, 16]}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <InputComponent
                      required
                      style={{ width: "100%" }}
                      getFieldDecorator={getFieldDecorator}
                      title="Url/dominio de tienda"
                      decodator="shop"
                      addonBefore="https://"
                      message="Url de tienda vacio"
                    />
                  </Col>
                </Row>
                <Row gutter={[24, 16]}>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <SwitchComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="switchSucursal"
                      title="Sucursal de shopify por default"
                      required
                      message="La sucursal"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <SwitchComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="localShipping"
                      title="Envio local"
                      required
                      message="Configuracion de envio local"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <SwitchComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="manualPrice"
                      title="Precio manual o propio"
                      required
                      message="Precio configurado por el cliente"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <SwitchComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="automaticApproval"
                      title="Aprobacion automatica de orden"
                      required
                      message="Aprobacion automatica"
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span style={{ color: "#595959" }}>Servicios</span>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <CheckBoxComponentWithBorderNew
                      getFieldDecorator={getFieldDecorator}
                      placeholder="Seleccione un servicio"
                      decodator="serviceIDs"
                      childOptions
                      childrenOptions={optionsCheckGroup(
                        this.state.allServices,
                        6
                      )}
                      required
                    />
                  </Col>
                </Row>
                <Row
                  gutter={24}
                  type="flex"
                  justify="end"
                  style={{ flexDirection: "row" }}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 9 }}
                    md={{ span: 6 }}
                    xl={{ span: 4 }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexContent: "flex-end",
                    }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="large"
                      style={{
                        marginTop: 20,
                        fontSize: "14px",
                        background: "#307fe2",
                        borderColor: "#307fe2",
                        color: "#fff",
                      }}
                      loading={this.state.loadingOnSave}
                    >
                      Agregar Tienda
                    </Button>
                  </Col>
                </Row>
              </CustomCollapse>
            </Form>
          );
        case "MULTIVENDE":
          return (
            <Form onSubmit={this.handleSubmitMultivende}>
              <CustomCollapse headerTitle="Datos de nueva integración">
                <Row gutter={24}>
                  <Col span={24}>
                    <p>
                      Seleccione servicio que se utilizará en la integración:
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <SelectComponent
                      style={{ width: "100%" }}
                      getFieldDecorator={getFieldDecorator}
                      title="Tipo de servicio"
                      decodator="services"
                      message="Ingrese tipo de servicio"
                      required
                      options={renderAllService(this.state.allServices)}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <SwitchComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="automaticApproval"
                      title="Aprobacion automatica de orden"
                      required
                      message="Aprobacion automatica"
                    />
                  </Col>
                </Row>
                <Row
                  gutter={24}
                  type="flex"
                  justify="end"
                  style={{ flexDirection: "row" }}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 9 }}
                    md={{ span: 6 }}
                    xl={{ span: 4 }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexContent: "flex-end",
                    }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="large"
                      style={{
                        marginTop: 20,
                        fontSize: "14px",
                        background: "#307fe2",
                        borderColor: "#307fe2",
                        color: "#fff",
                      }}
                      loading={this.state.loadingOnSave}
                    >
                      Agregar Tienda
                    </Button>
                  </Col>
                </Row>
              </CustomCollapse>
            </Form>
          );
        default:
          return <div>Opcion Incorrecta</div>;
      }
    };

    return (
      <Container>
        <TitleGoBack title={`Datos de conexion para ${this.props.platform}`} />
        <BodyContainer gutter={16} justify="center">
          {GetPlatform(this.props.platform)}
        </BodyContainer>
      </Container>
    );
  }
}

const WrapperIntegration = withGlobal(IntegrationPlatforms);
const FormNewIntegration = Form.create()(WrapperIntegration);

export default compose(
  withApollo,
  withGlobalContext
)(FormNewIntegration);
