import React from "react";
import { Modal, Row, Button, Col } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { CustomFormItemInputWithBorder } from "../../../components/Form";
import { withGlobal } from "../../../component-global";

import { MapsTwoPoint } from "../../../components/Maps";
import { LocationSearchInput } from "../../../components/Maps/LocationSearch";
import { TitleDrawer } from "./style";
import { InputComponentWithBorder } from "../../../components/FormFields";

class ModalMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      visible,
      handleOk,
      handleCancel,
      latLng,
      latLngPick,
      latLngDelivery,
      addressPick,
      onChangeAddressPick,
      addressDelivery,
      onChangeAddressDelivery,
      handleAddressSelectPick,
      handleAddressSelectDelivery,
      pickUpAddressLocatedFail,
      dropAddressLocatedFail,
      addressDeliverySelected,
      addressPickSelected,
      pickupGPS,
      onChangePickupGPS,
      isPickupGPSValid,
      deliveryGPS,
      onChangeDeliveryGPS,
      isDeliveryGPSValid,
    } = this.props;

    return (
      <Modal
        title={null}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width="684px"
      >
        <div
          style={{
            padding: 24,
          }}
        >
          <TitleDrawer>
            <p className="title-drawer">Actualizar dirección</p>
            <p className="descp-drawer">
              Ingresa la dirección correctamente para que la orden puede ser
              validada.
            </p>
          </TitleDrawer>
          <Row gutter={24} style={{ marginBottom: 16 }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomFormItemInputWithBorder
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                }}
                label="Dirección de recolección"
                validateStatus={
                  pickUpAddressLocatedFail !== null &&
                  pickUpAddressLocatedFail > -1 &&
                  !addressPickSelected
                    ? "error"
                    : null
                }
                help={
                  pickUpAddressLocatedFail !== null &&
                  pickUpAddressLocatedFail > -1 &&
                  !addressPickSelected
                    ? "*La dirección no se encuentra o está fuera del área de cobertura"
                    : null
                }
              >
                <LocationSearchInput
                  color="#347be3"
                  theme="filled"
                  address={addressPick}
                  onChange={onChangeAddressPick}
                  onAddressSelect={handleAddressSelectPick}
                  codeRegion={this.props.authUser.profile.regionCountry}
                />
              </CustomFormItemInputWithBorder>
              <InputComponentWithBorder
                title="Latitud y longitud"
                placeholder="Latitud y longitud"
                inputValue={pickupGPS}
                onChange={onChangePickupGPS}
                validateStatus={isPickupGPSValid ? null : "error"}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomFormItemInputWithBorder
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                }}
                label="Dirección de envío"
                validateStatus={
                  dropAddressLocatedFail !== null &&
                  dropAddressLocatedFail > -1 &&
                  !addressDeliverySelected
                    ? "error"
                    : null
                }
                help={
                  dropAddressLocatedFail !== null &&
                  dropAddressLocatedFail > -1 &&
                  !addressDeliverySelected
                    ? "*La dirección no se encuentra o está fuera del área de cobertura"
                    : null
                }
              >
                <LocationSearchInput
                  theme="filled"
                  color="#9b50e0"
                  address={addressDelivery}
                  onChange={onChangeAddressDelivery}
                  onAddressSelect={handleAddressSelectDelivery}
                  codeRegion={this.props.authUser.profile.regionCountry}
                />
              </CustomFormItemInputWithBorder>
              <InputComponentWithBorder
                title="Latitud y longitud"
                placeholder="Latitud y longitud"
                inputValue={deliveryGPS}
                onChange={onChangeDeliveryGPS}
                validateStatus={isDeliveryGPSValid ? null : "error"}
              />
            </Col>
          </Row>
          <div style={{ height: "400px", width: "100%", marginBottom: 16 }}>
            <MapsTwoPoint
              isMarkerShown
              draggable
              markerDragPick={this.props.markerDragPick}
              markerDragDelivery={this.props.markerDragDelivery}
              latLngPick={latLngPick}
              latLngDelivery={latLngDelivery}
              latLng={latLng}
            />
          </div>
          <Row gutter={24} type="flex" justify="center">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Button
                type="primary"
                ghost
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  height: 40,
                  width: "100%",
                }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Button
                type="primary"
                style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  height: 40,
                  width: "100%",
                }}
                onClick={handleOk}
              >
                Actualizar
              </Button>
            </Col>
          </Row>
        </div>{" "}
      </Modal>
    );
  }
}

const WraperModalMap = withGlobal(ModalMap);

export default compose(withApollo)(WraperModalMap);
