import React, { FC, ReactNode } from "react";
import { Popover } from "antd";

import "./info-popover-style.css";

interface PopoverInfo {
  children: ReactNode;
  title?: string;
  description: string;
}

const PopoverInfo: FC<PopoverInfo> = props => {
  const { children, title, description } = props;

  return (
    <Popover
      overlayClassName="info-popover"
      placement="right"
      trigger="hover"
      content={
        <>
          {title && (
            <div
              style={{
                marginBottom: 10,
                fontWeight: "bold",
                fontSize: 14,
                color: "#000",
              }}
            >
              {title}
            </div>
          )}
          <div style={{ fontSize: 13, color: "#000" }}>{description}</div>
        </>
      }
    >
      {children}
    </Popover>
  );
};

export default PopoverInfo;
