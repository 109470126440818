/* eslint-disable camelcase */
import React from "react";
import { Row, Col, Form, Button, Select, notification } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import moment from "moment";
import { toInteger } from "lodash";
import SelectComponent from "../../../components/FormFields/SelectWithBorderComponent";

import { RangePickerWithBorderCompoment, InputComponentWithBorder } from "../../../components/FormFields";

const { Option } = Select;
class SenHeader extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    if(this.props.evidence) {
      this.props.form.validateFields((err, values) => {
        const filters = {};
      if (values.date && values.date.length === 2)
        filters.rangeBy = {
          updatedAt: {
            lessEqual: moment(values.date[1])
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              })
              .format("YYYY-MM-DD HH:mm:ss ZZ"),
            greaterEqual: moment(values.date[0])
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .format("YYYY-MM-DD HH:mm:ss ZZ"),
          },
        };

        if (values.trackCode) filters.trackCode = values.trackCode;
        this.props.SearchOrders(filters);
      });
    } else if (this.props.ordersByStates) {
      this.props.form.validateFields((err, values) => {
        let filter;
        let initial_date = null;
        let finish_date = null;
        let diffDaysRangeOf = null;
        if (values.date && values.date.length === 2) {
          initial_date = moment(values.date[0]).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
          finish_date = moment(values.date[1]).set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          });
          filter = {
            initial_date: `${initial_date.format("YYYY-MM-DDTHH:mm:ss")}Z`,
            finish_date: `${finish_date.format("YYYY-MM-DDTHH:mm:ss")}Z`,
          };
        }

        diffDaysRangeOf =
          initial_date && finish_date && finish_date.diff(initial_date, "days");
        if (diffDaysRangeOf && diffDaysRangeOf <= 15) {
          this.props.onDownloadReportHomologated(filter);
        } else if (diffDaysRangeOf && diffDaysRangeOf > 15) {
          notification.error({
            message: `Intervalo mayor a 15 días.`,
          });
        } else {
          notification.error({
            message: `Debe ingresar el intervalo de fechas.`,
          });
        }
      });
    } else {
      this.props.form.validateFields((err, values) => {
        const filter = {};
  
        if (values.serviceID) {
          filter.serviceID = toInteger(values.serviceID);
        }
        if (values.cityID) {
          filter.cityID = toInteger(values.cityID);
        }
        if (values.statusID) {
          filter.statusID = toInteger(values.statusID);
        }
        if (values.date && values.date.length === 2) {
          filter.rangeDate = {
            createdAt: {
              greaterEqual: moment(values.date[0]).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              }).format("YYYY-MM-DD HH:mm:ss ZZ"),
              lessEqual: moment(values.date[1]).set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              }).format("YYYY-MM-DD HH:mm:ss ZZ"),
            },
          };
        }
        this.props.SearchOrders(filter, 1);
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      cityOptions,
      loadingBtnSearch,
      statusOptions,
      serviceOptions,
    } = this.props;

    const renderCity = (cityOptions || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    ));
    const renderStatus = (statusOptions || []).map(obj => (
      <Option key={obj.subtype} value={obj.subtype}>
        {obj.subclass}
      </Option>
    ));
    const renderService = (serviceOptions || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    ));

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row
          gutter={24}
          style={{
            marginBottom: 24,
          }}
        >
          {this.props.ordersByStates ? (
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <RangePickerWithBorderCompoment
                title="Fecha"
                getFieldDecorator={getFieldDecorator}
                decodator="date"
                placeholder={["Inicio", "Fin "]}
              />
            </Col>
          ) : (
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <RangePickerWithBorderCompoment
                title="Fecha"
                getFieldDecorator={getFieldDecorator}
                decodator="date"
                placeholder={["Inicio", "Fin "]}
              />
            </Col>
          )}
          {this.props.evidence ? (
            <>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <InputComponentWithBorder
                  style={{ width: "100%" }}
                  getFieldDecorator={getFieldDecorator}
                  title="TrackCode"
                  decodator="trackCode"
                  placeholder="Ingresa un Trackcode"
                />
              </Col>
            </>
          ) : this.props.ordersByStates ? (
            <></>
          ) : (
            <>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <SelectComponent
                  style={{ width: "100%" }}
                  getFieldDecorator={getFieldDecorator}
                  title="Tipo de envío"
                  decodator="serviceID"
                  options={renderService}
                  placeholder="Selecciona un tipo"
                  loading={loadingBtnSearch}
                />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <SelectComponent
                  style={{ width: "100%" }}
                  getFieldDecorator={getFieldDecorator}
                  title="Estado del paquete"
                  decodator="statusID"
                  options={renderStatus}
                  placeholder="Selecciona un estado"
                  loading={loadingBtnSearch}
                />
              </Col>
              {this.props.cityOptions && this.props.cityOptions.length > 1 ? (
                <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                  <SelectComponent
                    style={{ width: "100%" }}
                    getFieldDecorator={getFieldDecorator}
                    title="Ciudad"
                    decodator="cityID"
                    options={renderCity}
                    placeholder="Selecciona una ciudad"
                    loading={loadingBtnSearch}
                  />
                </Col>
              ) : (
                []
              )}
            </>
          )}
          <Col xs={22} sm={12} md={4} lg={4} xl={3}>
            {this.props.ordersByStates ? (
              <>
                <Button
                  type="primary"
                  ghost
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineSeight: "16px",
                    height: 40,
                    width: "100%",
                    marginTop: "21px",
                  }}
                  htmlType="submit"
                  loading={loadingBtnSearch}
                >
                  Descargar
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="primary"
                  ghost
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineSeight: "16px",
                    height: 40,
                    width: "100%",
                    marginTop: "21px",
                  }}
                  htmlType="submit"
                  loading={loadingBtnSearch}
                >
                  Buscar
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}

const HeaderForm = Form.create()(SenHeader);
export default compose(withApollo)(HeaderForm);
