import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Button, Col, Row, Card, Icon, Spin, Form, Select } from "antd";
import { Scatter } from "react-chartjs-2";
import { navigate } from "@reach/router";
import { withGlobalContext } from "../../utils/globalContext";
import { trackingPackage, uploadcsv, uniqueSend } from "../../routes/routes";
import {
  getDashboardB2bData,
  getTopIncidences,
  getHistorialDeliveredTrackcodes,
  getBranchOfficeByEnterpriseID,
} from "../../graphQl/queries/dashboard";
import { ContainerHome } from "./styles";
import { withGlobal } from "../../component-global";
import SelectComponent from "../../components/FormFields/SelectWithBorderComponent";
import { EntrerpriseException } from "../../utils/roles";
// import { captureException } from "../../utils/sentry";

const { Option } = Select;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TopIncidences: [],
      graphData: [],
      allCitiesBranch: [],
      loading: false,
      scatterCity: null,
      scatterTime: "MONTHLY",
      performanceCity: null,
      performanceTime: "ALL",
      allow: true,
    };
  }

  componentDidMount = async () => {
    this.getCityBranches();
    this.getTopIncidences(
      parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      null
    );

    this.getDataScatter(
      parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      null,
      "MONTHLY"
    );

    this.getPerformanceData(
      parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      null,
      "ALL"
    );

    this.isAllowed(parseInt(this.props.userEnterpriseData.enterpriseID, 10));
  };

  getDataScatter = async (enterpriseID, cityID, timeSpace) => {
    const {
      data: { HistorialDeliveredTrackcodes },
    } = await this.props.client
      .query({
        fetchPolicy: "network-only",
        query: getHistorialDeliveredTrackcodes,
        variables: {
          enterpriseID,
          cityID,
          timeSpace,
        },
      })
      .catch(error => {
        console.log(error);
      });

    const graphData = (HistorialDeliveredTrackcodes || []).map(obj => {
      return {
        y: obj.data,
        x: obj.label,
      };
    });
    this.setState({
      graphData,
    });
  };

  getTopIncidences = async (enterpriseID, cityID) => {
    // console.log(this.props);
    const variables = {
      enterpriseID,
      cityID,
    };
    const {
      data: { TopIncidences },
    } = await this.props.client
      .query({
        fetchPolicy: "network-only",
        query: getTopIncidences,
        variables,
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({ TopIncidences });
  };

  getPerformanceData = async (enterpriseID, cityID, timeSpace) => {
    const variables = {
      enterpriseID,
      cityID,
      timeSpace,
    };
    const {
      data: { DashboardB2bData },
    } = await this.props.client
      .query({
        fetchPolicy: "network-only",
        query: getDashboardB2bData,
        variables,
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({ DashboardB2bData });
  };

  getCityBranches = async () => {
    const {
      data: { AllBranchOffices },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getBranchOfficeByEnterpriseID,
      variables: {
        enterpriseID: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      },
    });
    // separado de los datos de las sucursales en array de datos
    // const { allCitiesBranch } = this.state;
    const allCitiesBranch = [];
    const citiesDetailsTemp = [
      ...new Set(
        AllBranchOffices.map(data => `${data.City.id}--|--${data.City.name}`)
      ),
    ];

    citiesDetailsTemp.forEach(cities => {
      const valCortado = cities.split("--|--");
      allCitiesBranch.push({
        id: valCortado[0],
        name: valCortado[1],
      });
    });

    this.setState({ allCitiesBranch });
  };

  changeCityPerformance = value => {
    console.log(value);
    const { performanceTime } = this.state;
    this.setState({ performanceCity: value });
    this.getPerformanceData(
      parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      parseInt(value, 10),
      performanceTime
    );
  };

  changeTimePerformance = value => {
    console.log(value);
    const { performanceCity } = this.state;
    this.setState({ performanceTime: value });
    this.getPerformanceData(
      parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      parseInt(performanceCity, 10),
      value
    );
  };

  changeCityScatter = value => {
    const { scatterTime } = this.state;
    this.setState({ scatterCity: value });
    this.getDataScatter(
      parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      parseInt(value, 10),
      scatterTime
    );
  };

  changeTimeScatter = value => {
    const { scatterCity } = this.state;
    this.setState({ scatterTime: value });
    this.getDataScatter(
      parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      parseInt(scatterCity, 10),
      value
    );
  };

  isAllowed = enterpriseID => {
    const { authUser } = this.props;
    EntrerpriseException.map(element => {
      if (
        element.id === enterpriseID &&
        element.users.indexOf(authUser.profile.email) === -1
      ) {
        this.setState({ allow: false });
      }
    });
  };

  render() {
    const { authUser } = this.props;
    const {
      TopIncidences,
      graphData,
      allCitiesBranch,
      DashboardB2bData,
      loading,
      allow,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    const displayTopFailedMotives = (TopIncidences || []).map(obj => {
      return <div key={obj.id}>{obj.name}</div>;
    });
    const timeGraphEmun = ["YEARLY", "QUARTER", "MONTHLY"];
    const timeEnum = ["ALL", "SIXMONTHS", "THREEMONTHS", "CURRENTMONTH"];

    const TimespaceGraphEnum = () => {
      return (timeGraphEmun || []).map(obj => (
        <Option key={obj} value={obj}>
          {obj}
        </Option>
      ));
    };

    const TimespaceEnum = () => {
      return (timeEnum || []).map(obj => (
        <Option key={obj} value={obj}>
          {obj}
        </Option>
      ));
    };

    const CitiesBranch = () => {
      return (allCitiesBranch || []).map(obj => (
        <Option key={obj.id} value={obj.id}>
          {obj.name}
        </Option>
      ));
    };
    const MensajesinDatos = () => {
      return (
        <div style={{ paddingTop: 200 }}>
          <h2
            style={{ textAlign: "center", color: "gray", fontWeight: "normal" }}
          >
            Aquí se mostrarán todos los pedidos que fueron registrados como
            fallidos por los Chazkis.
          </h2>
        </div>
      );
    };
    const MensajesinDatosTabla = () => {
      return (
        <div style={{ paddingTop: 200 }}>
          <h2
            style={{ textAlign: "center", color: "gray", fontWeight: "normal" }}
          >
            Aún no se ha registrado ninguna operación.
          </h2>
        </div>
      );
    };

    const dataScatter = {
      datasets: [
        {
          type: "line",
          label: "Line",
          backgroundColor: "#D9D9D9",
          borderColor: "#D9D9D9",
          pointHoverRadius: 8,
          pointHoverBackgroundColor: "#CF1322",
          pointHoverBorderColor: "#CF1322",
          pointHoverBorderWidth: 3,
          hoverRadius: 8,

          pointHitRadius: 0,
          data: graphData,
        },
      ],
    };

    const labelsY = graphData.map(obj => obj.x);
    console.log(labelsY);

    const options = {
      events: ["mousemove", "click"],
      responsive: true,
      maintainAspectRatio: false,

      scales: {
        xAxes: [
          {
            offset: true,
            ticks: {
              fontColor: "#8C8C8C",
              fontSize: 10,
              padding: 9,
            },
            type: "category",
            labels: labelsY,
            gridLines: {
              display: false,
              tickMarkLength: 50,
            },
            minor: {
              lineHeight: 1.5,
              fontColor: "red",
            },
          },
        ],
        yAxes: [
          {
            offset: true,
            ticks: {
              fontColor: "#8C8C8C",
              fontSize: 15,
              padding: 9,
            },
            type: "linear",
            gridLines: {
              display: false,
              tickMarkLength: 50,
            },
            minor: {
              lineHeight: 1.5,
              fontColor: "red",
            },
          },
        ],
      },
    };

    return (
      <ContainerHome>
        <Row gutter={[48, 16]} style={{ height: "100%" }}>
          <Col xs={24} sm={24} md={17} lg={17} xl={17} className="leftCol">
            <Row>
              <div className="welcomeBanner">
                <div style={{ position: "absolute", zIndex: 2 }}>
                  <h1>
                    Buenos días,{" "}
                    {authUser.profile.name ? authUser.profile.name : ""}
                  </h1>
                  <h3>Averigua cómo va el envío de tus paquetes.</h3>
                  <Button
                    type="primary"
                    ghost
                    style={{ marginTop: 10 }}
                    size="large"
                    onClick={() => navigate(`/dashboard/${trackingPackage}`)}
                  >
                    Hacer un seguimiento
                  </Button>
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 1,
                  }}
                >
                  <img
                    alt="chazki"
                    height="208px"
                    src={require("assets/images/banner_home.png")}
                  />
                </div>
              </div>
            </Row>
            <div className="peformanceHeader">
              <h3>Tu performance</h3>
              <div style={{ display: "flex" }}>
                {this.props.userEnterpriseData &&
                  this.props.userEnterpriseData.Enterprise &&
                  this.props.userEnterpriseData.Enterprise.multiplace && (
                    <SelectComponent
                      style={{ width: 150, marginRight: 10 }}
                      getFieldDecorator={getFieldDecorator}
                      placeholder="Ciudad"
                      decodator="city"
                      options={CitiesBranch()}
                      onChange={value => {
                        this.changeCityPerformance(value);
                      }}
                    />
                  )}
                <SelectComponent
                  style={{ width: 150 }}
                  getFieldDecorator={getFieldDecorator}
                  placeholder="Tiempo"
                  decodator="timeScale"
                  options={TimespaceEnum()}
                  onChange={value => {
                    this.changeTimePerformance(value);
                  }}
                />
              </div>
            </div>
            <div className="peformanceHeader">
              <Card className="cardPerformance">
                <Spin spinning={loading}>
                  <span>
                    {DashboardB2bData && DashboardB2bData.qTrackcodes}
                  </span>
                  <p>TrackCodes</p>
                </Spin>
              </Card>
              <Card className="cardPerformance">
                <Spin spinning={loading}>
                  <span>
                    {DashboardB2bData && DashboardB2bData.qDeliveredTrackcodes}
                  </span>
                  <p>Pedidos entregados</p>
                </Spin>
              </Card>
              <Card className="cardPerformance">
                <Spin spinning={loading}>
                  <span>
                    {DashboardB2bData && DashboardB2bData.qFailedTrackcodes}
                  </span>
                  <p>Pedidos fallidos</p>
                </Spin>
              </Card>
              <Card className="cardPerformance">
                <Spin spinning={loading}>
                  <span>
                    {DashboardB2bData && DashboardB2bData.qReturnedTrackcodes}
                  </span>
                  <p>Pedidos devueltos</p>
                </Spin>
              </Card>
            </div>
            <div className="peformanceHeader">
              <h3>Envíos realizados</h3>
              <div style={{ display: "flex" }}>
                {this.props.userEnterpriseData &&
                  this.props.userEnterpriseData.Enterprise &&
                  this.props.userEnterpriseData.Enterprise.multiplace && (
                    <SelectComponent
                      style={{ width: 150, marginRight: 10 }}
                      getFieldDecorator={getFieldDecorator}
                      placeholder="Ciudad"
                      decodator="city2"
                      options={CitiesBranch()}
                      onChange={value => {
                        if (value) this.changeCityScatter(value);
                      }}
                    />
                  )}

                <SelectComponent
                  style={{ width: 150 }}
                  getFieldDecorator={getFieldDecorator}
                  placeholder="Tiempo"
                  decodator="timeScale2"
                  options={TimespaceGraphEnum()}
                  onChange={value => {
                    if (value) this.changeTimeScatter(value);
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 15,
                background: "white",
                height: "100%",
              }}
            >
              {graphData.length > 0 && (
                <Scatter data={dataScatter} options={options} height={350} />
              ) ? (
                <Scatter data={dataScatter} options={options} height={350} />
              ) : (
                MensajesinDatosTabla()
              )}
            </div>
          </Col>

          <Col xs={24} sm={24} md={7} lg={7} xl={7} className="rightCol">
            {allow && (
              <div className="csvCard">
                <div>
                  <h2>¿Tienes una lista de órdenes por entregar?</h2>
                  <Button
                    type="link"
                    onClick={() => navigate(`/dashboard/${uploadcsv}`)}
                  >
                    Cargar CSV <Icon type="right" />
                  </Button>
                </div>
                <div>
                  <div className="blueCircle">
                    <Icon
                      type="copy"
                      style={{ fontSize: 28, color: "#1890ff" }}
                    />
                  </div>
                </div>
              </div>
            )}

            {allow && (
              <div className="expressCard">
                <div>
                  <h2>¿Deseas realizar un servicio único express?</h2>
                  <Button
                    type="link"
                    onClick={() => navigate(`/dashboard/${uniqueSend}`)}
                  >
                    Solicitar envío único
                    <Icon type="right" style={{ color: "#1890ff" }} />
                  </Button>
                </div>
                <div>
                  <div className="blueCircle">
                    <img
                      alt="motoIcon"
                      src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fmoto%20icon.png?alt=media&token=d45a94cf-cf35-4484-a47b-5dbcd5bbbd0d"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="failedMotives" style={{ height: "100%" }}>
              <h3>Principales motivos del fallo</h3>
              {displayTopFailedMotives.length > 0
                ? displayTopFailedMotives
                : MensajesinDatos()}
            </div>
          </Col>
        </Row>
      </ContainerHome>
    );
  }
}

const FormHome = Form.create()(Home);
const WrapperHome = withGlobal(FormHome);

export default compose(
  withApollo,
  withGlobalContext
)(WrapperHome);
