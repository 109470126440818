import styled from "styled-components";

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 9vw;
  gap: 16px;

  @media (max-width: 1400px) and (min-width: 1200px) {
    grid-template-rows: 10vw;
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    grid-template-rows: 12vw;
  }

  @media (max-width: 992px) and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 15vw);
  }

  @media (max-width: 768px) and (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 18vw);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 30vw);
  }
`;
