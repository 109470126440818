import React from "react";

const GlobalContext = React.createContext({});

export class GlobalContextProvider extends React.Component {
  state = {
    title: "",
    viewsAllowed: null,
  };

  componentDidMount() {
    this.loadTitle();
  }

  changeTitle = value => {
    this.setState({ title: value }, this.saveTitle(value));
  };

  loadTitle = () => {
    const title = localStorage.getItem("title");
    this.setState({ title });
  };

  saveTitle = value => localStorage.setItem("title", value);

  setViewsAllowed = value => localStorage.setItem("viewsAllowed", value);

  changeViewsAllowed = value => {
    this.setState({ viewsAllowed: value }, this.setViewsAllowed(value));
  };

  render() {
    return (
      <GlobalContext.Provider
        value={{
          title: this.state.title,
          viewsAllowed: this.state.viewsAllowed,
          changeTitle: this.changeTitle,
          setActionBack: this.setActionBack,
          changeViewsAllowed: this.changeViewsAllowed,
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

// create the consumer as higher order component
export const withGlobalContext = ChildComponent => props => (
  <GlobalContext.Consumer>
    {context => <ChildComponent {...props} global={context} />}
  </GlobalContext.Consumer>
);
