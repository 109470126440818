import styled from "styled-components";
import { Collapse } from "antd";

const CustomCollapse = styled(Collapse)`
  box-shadow: none;
  border: white;
  && .ant-collapse-item {
    width: 100%;
    border: white;
    border-radius: 4px;
    margin-top: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }
  && .ant-collapse-header {
    background-color: white;
    border: white;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #595959;
    padding 24px;
    font-style: normal;
  }
  & .ant-collapse-content {
    border-top: none;
  }
  & .ant-collapse-content-box {
    padding: 0px 24px 24px 24px;
  }
`;

const CustomExpandIcon = styled.div`
  && span {
    text-decoration: underline;
    color: #307fe2;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-right: 13px;
    font-style: normal;
  }
  && .anticon-up {
    color: #307fe2;
  }
`;
export { CustomCollapse, CustomExpandIcon };
