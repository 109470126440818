import gql from "graphql-tag";

const getAllServices = gql`
  query {
    getAllServices(orderBy: { name: ASC }) {
      id
      name
    }
  }
`;

const getCities = gql`
  query {
    getCities: getCitiesByToken {
      id
      name
    }
  }
`;

const getBranchOfficeByEnterpriseID = gql`
  query getBranchOfficeByEnterpriseID($id: Int!) {
    getBranchOfficeByEnterpriseID(enterpriseID: $id) {
      id
      branchOfficeCode
      branchOfficeName
      City {
        id
        name
      }
    }
  }
`;

const getStatusOrder = gql`
  query {
    getStatusOrder: searchGeneralTypesBy(type: 42, orderBy: { subclass: ASC }) {
      subtype
      subclass
    }
  }
`;

const getCitiesOfBranchOfficeByEnterpriseID = gql`
  query getCitiesOfBranchOfficeByEnterpriseID($enterpriseID: Int!) {
    getCitiesOfBranchOfficeByEnterpriseID(enterpriseID: $enterpriseID) {
      id
      name
    }
  }
`;

const getZeldaReportquery = gql`
  query getZeldaReportquery(
    $enterpriseID: Int!
    $serviceID: Int
    $statusID: Int
    $cityID: Int
    $rangeDate: OrdersDateRangeInput
    $totalPriceRange: TotalPriceRange
  ) {
    ZeldaReport: getZeldaReport(
      enterpriseID: $enterpriseID
      rangeDate: $rangeDate
      serviceID: $serviceID
      statusID: $statusID
      cityID: $cityID
      totalPriceRange: $totalPriceRange
    ) {
      datasets {
        label
        data
        backgroundColor
      }
      labels
    }
  }
`;

const getZeldaReportDetailquery = gql`
  query getZeldaReportDetailquery(
    $enterpriseID: Int!
    $serviceID: Int
    $statusID: Int
    $cityID: Int
    $rangeDate: OrdersDateRangeInput
    $totalPriceRange: TotalPriceRange
    $limit: Int!
    $offset: Int!
  ) {
    ZeldaReportDetail: getZeldaReportDetail(
      enterpriseID: $enterpriseID
      rangeDate: $rangeDate
      serviceID: $serviceID
      statusID: $statusID
      cityID: $cityID
      totalPriceRange: $totalPriceRange
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        trackCode
        createdAt
        Service {
          name
        }
        City {
          name
        }
        OrderService {
          lastStatusID
          dateLastStatus
        }
        Status {
          subclass
        }
        totalPrice
      }
    }
  }
`;

const getZeldaReportEvidencequery = gql`
  query getZeldaReportEvidencequery(
    $enterpriseID: Int!
    $rangeBy: ZoneDateRangeInput
    $trackCode: String
  ) {
    ZeldaReportEvidence: getZeldaReportEvidences(
      enterpriseID: $enterpriseID
      trackCode: $trackCode
      rangeBy: $rangeBy
    ) {
      count
      rows {
        id
        trackCode
        updatedAt
        OrdersImages {
          id
          orderID
          orderServiceID
          filename
          url
        }
        OrderService {
          id
          OrderServiceHistorial {
            id
            Incidence {
              id
              name
            }
            createdAt
          }
        }
      }
    }
  }
`;

export {
  getAllServices,
  getCities,
  getBranchOfficeByEnterpriseID,
  getStatusOrder,
  getCitiesOfBranchOfficeByEnterpriseID,
  getZeldaReportquery,
  getZeldaReportDetailquery,
  getZeldaReportEvidencequery,
};
