import styled from "styled-components";
import { Collapse, Spin, Icon } from "antd";

const SpinCustom = styled(Spin)`
  && .ant-spin-nested-loading {
    height: 100%;
  }
  && .ant-spin-container {
    height: 100%;
  }
`;

const CustomCollapse = styled(Collapse)`
  box-shadow: none;
  border: white;
  background-color: white;
  && .ant-collapse-item {
    border: white;
  }
  & .ant-collapse-header {
    font-weight: bold;
    font-size: 22px;
  }
  & .ant-collapse-content {
    border-top: none;
  }
`;

const CustomAccordion = styled.div`
  && span {
    font-size: 14px;
    font-style: bold;
    color: #307fe2;
  }
`;

const ContainerForm = styled.div`
  width: 100%;
  border-radius: 4px;
`;

export const CustomIcon = styled(Icon)`
  font-size: ${props => (props.size ? props.size : 16)}px;
  margin-right: 0 !important;
  margin-left: 6px;
`;

export { ContainerForm, SpinCustom, CustomCollapse, CustomAccordion };
