import React from "react";
import moment from "moment";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { navigate } from "@reach/router";
import { Button, Tooltip, message, Tag, Popover, Checkbox, Icon } from "antd";
import { withGlobal } from "../../../component-global";
import { Container, TitleGoBack } from "../../../components/Container";
import TableList from "../../../components/Table/tableList";
import { routeDetails } from "../../../routes/routes";
import { getRoutesForTracking } from "../../../graphQl/queries/routeTracking";
import { getRouteBasicInfo } from "../../../graphQl/queries/route";
import { captureException } from "../../../utils/sentry";
import { CustomFilterContainer } from "../../../components/Form/SearchContainer";

import Header from "./components/Header";
import { EmptyTable } from "../../../components/Utils";
import { getServicesNames } from "../../../graphQl/queries/services"
import { RiskSpanForRiskType } from "./style";
import { RISKS } from "../../../utils";

const columnsDefault = [
  "routeCode",
  "status",
  "createdAt",
  "pickUpAddress",
  "deliveries",
  "vehicleType",
  "serviceType",
  "percentage",
  "riskType"
];

const routeStatus = [ "DELETED", "COMPLETED", "WARD"];

class RouteTracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingTable: false,
      pagination: { limit: 10, page: 0 },
      currentFilter: {},
      dataResult: null,
      totalData: 0,
      servicesName: null,
      loadingButtonExport: false,
      columnsDefault: columnsDefault,
    };
  }

  componentDidMount = async () => {
    try {
      this.refreshTable();
    } catch (err) {
      this.setState({ loadingTable: false });
      captureException(err);
      message.error("Error en la carga de datos");
    }
  };

  refreshTable = async () => {
    this.setState({
      loadingTable: true,
    });
    const { pagination } = this.state;
    try {
      const {
        data: { AllServicesName },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getServicesNames,
        variables: {},
      });

      const {
        data: { getRoutesForTracking: dataTable },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getRoutesForTracking,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          cityID: this.props.authUser.profile.cityID,
          ...this.state.currentFilter,
          limit: pagination.limit,
          offset: 0 * pagination.limit,
        },
      });
      await Promise.all(dataTable.rows.map(async item => {
        if (!!item.originalRouteID) {
          const { data: { getRouteBasicInfo: routeCode } } = await this.props.client.query({
            fetchPolicy: "network-only",
            query: getRouteBasicInfo,
            variables: {
              routeID: item.originalRouteID,
            },
          });
          item.originalRouteCode = routeCode.routeCode
        }
      }));

      this.setState({
        loadingTable: false,
        dataResult: dataTable.rows,
        totalData: dataTable.count,
        pagination: { limit: pagination.limit, page: 0 },
        servicesName: AllServicesName.rows,
      });
    } catch (err) {
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos");
    }
  };

  searchRouteByFilters = async filters => {
    this.setState({
      currentFilter: filters,
      loadingTable: true,
    });
    const { pagination } = this.state;
    try {
      const {
        data: { getRoutesForTracking: dataTable },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getRoutesForTracking,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          cityID: this.props.authUser.profile.cityID,
          ...filters,
          limit: pagination.limit,
          offset: 0 * pagination.limit,
        },
      });
      await Promise.all(dataTable.rows.map(async item => {
        if (!!item.originalRouteID) {
          const { data: { getRouteBasicInfo: routeCode } } = await this.props.client.query({
            fetchPolicy: "network-only",
            query: getRouteBasicInfo,
            variables: {
              routeID: item.originalRouteID,
            },
          });
          item.originalRouteCode = routeCode.routeCode
        }
      }))

      this.setState({
        loadingTable: false,
        dataResult: dataTable.rows,
        totalData: dataTable.count,
        pagination: { limit: pagination.limit, page: 0 },
      });
    } catch (err) {
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos");
    }
  };

  onChangePagination = async (page, pageSize) => {
    this.setState({
      loadingTable: true,
    });
    const { pagination } = this.state;
    try {
      const {
        data: { getRoutesForTracking: dataTable },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getRoutesForTracking,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          cityID: this.props.authUser.profile.cityID,
          ...this.state.currentFilter,
          limit: pagination.limit,
          offset: (page - 1) * pagination.limit,
        },
      });
      await Promise.all(dataTable.rows.map(async item => {
        if (!!item.originalRouteID) {
          const { data: { getRouteBasicInfo: routeCode } } = await this.props.client.query({
            fetchPolicy: "network-only",
            query: getRouteBasicInfo,
            variables: {
              routeID: item.originalRouteID,
            },
          });
          item.originalRouteCode = routeCode.routeCode
        }
      }))
      this.setState({
        loadingTable: false,
        dataResult: dataTable.rows,
        totalData: dataTable.count,
        pagination: { limit: pageSize, page: page - 1 },
      });
    } catch (err) {
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos");
    }
  };

  selectColumns = (checkedValues) => {
    this.setState({columnsDefault: checkedValues});
  }

  render() {
    const { loadingTable, dataResult, totalData, pagination, columnsDefault, servicesName } = this.state;

    const columns = [
      {
        title: "Código ruta",
        dataIndex: "routeCode",
        key: "routeCode",
        width: "120px",
        render: (text, record) => (
          <Tooltip title={record.routeCode}>
            <Button
              style={{ fontSize: "14px", lineHeight: "20px" }}
              type="link"
              onClick={() => {
                navigate(`/dashboard/${routeDetails}/${record.routeCode}`);
              }}
            >
              {record.routeCode}
            </Button>
          </Tooltip>
        ),
      }, 
      {
        title: "Estado",
        dataIndex: "status",
        key: "status",
        width: "120px",
        render: (text, record) => (
          <Tooltip title={record.Status.subclass}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.Status ? record.Status.subclass : ""}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Fecha de creación",
        dataIndex: "createdAt",
        key: "createdAt",
        width: "120px",
        render: (text, record) => {
          const date = moment(record.createdAt).format("DD/MM/YYYY");
          return (
            <Tooltip title={date}>
              <span style={{ fontSize: "14px", lineHeight: "20px" }}>
                {date}
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: "Dirección de retiro",
        dataIndex: "pickUpAddress",
        key: "pickUpAddress",
        width: "250px",
        render: (text, record) => {
          const hasNotOrders =
            record.OrderServices && record.OrderServices.length === 0;
          const isMultiOrder = !hasNotOrders && record.OrderServices.length > 1;
          const isMultiPickup = isMultiOrder
            ? record.OrderServices[0].Orders.pickUpAddress !==
            record.OrderServices[1].Orders.pickUpAddress
            : false;

          const pickUpAddress = isMultiPickup
            ? record.OrderServices.map(
              orderService => orderService.Orders.pickUpAddress
            ).join(" | ")
            : !hasNotOrders
              ? record.OrderServices[0].Orders.pickUpAddress
              : "";

          return (
            <Tooltip title={pickUpAddress}>
              <span style={{ fontSize: "14px", lineHeight: "20px" }}>
                {pickUpAddress}
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: "Núm. de entregas",
        dataIndex: "deliveries",
        key: "deliveries",
        width: "120px",
        render: (text, record) => (
          <Tooltip title={record.OrderServices.length}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.OrderServices.length}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Tipo de vehículo",
        dataIndex: "",
        key: "vehicleType",
        width: "120px",
        render: (text, record) => {
          const vehicle = record.VehicleType.subclass 
            ? record.VehicleType.subclass
            : "";
          return (
            <Tooltip title={vehicle}>
              <span style={{ fontSize: "14px", lineHeight: "20px" }}>
                {vehicle}
              </span>
            </Tooltip>
          )
        }
      },
      {
        title: "Tipo de servicio",
        dataIndex: "",
        key: "serviceType",
        width: "120px",
        render: (text, record) => {
          const hasNotOrders =
            record.OrderServices && record.OrderServices.length === 0;
          const isMultiOrder = !hasNotOrders && record.OrderServices.length > 1;
          const isMultiService = isMultiOrder
            ? record.OrderServices[0].Orders.Service.name !==
            record.OrderServices[1].Orders.Service.name
            : false;

          const service = isMultiService
            ? record.OrderServices.map(
              orderService => orderService.Orders.Service.name
            ).join(" | ")
            : !hasNotOrders
              ? record.OrderServices[0].Orders.Service.name
              : "";

          return (
            <Tooltip title={service}>
              <span style={{ fontSize: "14px", lineHeight: "20px" }}>
                {service}
              </span>
            </Tooltip>
          );
        }
      },
      {
        title: "Cumplimiento de la ruta",
        dataIndex: "",
        key: "percentage",
        width: "120px",
        render: (text, record) => {
          const percentage = routeStatus.includes(record.Status.subclass)
            ? "-"
            : `${Number.parseFloat(record.percentage).toFixed(2)}%`;
          return (
            <Tooltip title={percentage}>
              <span style={{ fontSize: "14px", lineHeight: "20px" }}>
                {percentage}
              </span>
            </Tooltip>
          )
        }
      },
      {
        title: "Priorización de ruta",
        dataIndex: "",
        key: "riskType",
        width: "120px",
        render: (text, record) => {
          const risk = record.Risk && record.Risk.riskType
            ? record.Risk.riskType
            : "-"
          return (
          <Tooltip title={risk}>
            <RiskSpanForRiskType {...RISKS[risk]}>
              <span>{risk}</span>
            </RiskSpanForRiskType>
          </Tooltip>
         )
        }
      },
      {
        title: "Metadata",
        dataIndex: "metadata",
        key: "metadata",
        width: "100px",
        render: (text, record) => {
          const metadata = record.meta
            .map(e => `${e.key}: ${e.value}`)
            .join(", ");

          return (
            <Tooltip title={metadata}>
              <span style={{ fontSize: "14px", lineHeight: "20px" }}>
                {metadata}
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: "Ruta original",
        dataIndex: "originalRouteID",
        key: "originalRouteID",
        width: "100px",
        render: (text, record) => {
          const originalRouteID = record.originalRouteCode ? record.originalRouteCode : "";
          return (
            <Tooltip title={originalRouteID}>
              <span style={{ fontSize: "14px", lineHeight: "20px" }}>
                {originalRouteID ? (<Tag color="gold" style={{ fontSize: "14px" }}>{originalRouteID}</Tag>) : '-'}
              </span>
            </Tooltip>
          );
        },
      }
    ];

    const columnsName = columns.map(item => {
      return (
        <p key={item.key}>
          <Checkbox value={item.key}>{item.title}</Checkbox>
        </p>
      );
    });
    
    return (
      <Container>
        <TitleGoBack title="Seguimiento por ruta" />
        <CustomFilterContainer
          style={{ padding: "24px ", marginBottom: "0px" }}
        >
          <Header
            loadingBtnSearch={loadingTable}
            servicesName={servicesName}
            searchRouteByFilters={this.searchRouteByFilters}
          />
          <Popover placement="bottomRight" content={<Checkbox.Group onChange={this.selectColumns} defaultValue={columnsDefault}>{columnsName}</Checkbox.Group>} trigger="click">
            <Button type="link">Mostrar columnas<Icon type="down"/></Button>
          </Popover>

          {dataResult && dataResult.length > 0 ? (
            <TableList
              size="middle"
              data={dataResult}
              columns={columns.filter(item=>columnsDefault.includes(item.key))}
              loading={loadingTable}
              pageSize={pagination.limit}
              totalData={totalData}
              currentPage={pagination.page + 1}
              onChange={this.onChangePagination}
              heightcustom="calc(100vh - 490px)"
              scroll={{ y: "calc(100vh - 490px)" }}
            />
          ) : (
            <EmptyTable loadingTable={loadingTable} />
          )}
        </CustomFilterContainer>
      </Container>
    );
  }
}

const WraperRouteTracking = withGlobal(RouteTracking);

export default compose(withApollo)(WraperRouteTracking);
