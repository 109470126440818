import React from "react";
import styled from "styled-components";
import TitleGoBack from "./titleGoBack";

const ContainerWrapper = styled.section`
  padding: 24px 24px 0;
`;

const Container = props => (
  <ContainerWrapper>{props.children}</ContainerWrapper>
);

export { Container, TitleGoBack };
export { default as Paper } from "./Paper";
