import React from "react";
import { Row, Col, Form, Button, Select } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import SelectComponent from "../../../components/FormFields/SelectWithBorderComponent";
import moment from "moment";

import { RangePickerWithBorderCompoment } from "../../../components/FormFields";
import { toInteger } from "lodash";

const { Option } = Select;
class FinaHeader extends React.Component {

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const filter = {};

      if (values.serviceID) {
        filter.serviceID = toInteger(values.serviceID);
      }

      if (values.cityID) {
        filter.cityID = toInteger(values.cityID);
      }

      if (values.date && values.date.length > 0) {
        filter.rangeDate = {
          createdAt: {
            greaterEqual: moment(values.date[0]).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            }).format("YYYY-MM-DD HH:mm:ss ZZ"),
            lessEqual: moment(values.date[1]).set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            }).format("YYYY-MM-DD HH:mm:ss ZZ"),
          },
        };
      }
      this.props.SearchOrders(filter, 2);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { cityOptions, loadingBtnSearch, serviceOptions } = this.props;

    const renderCity = (cityOptions || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    ));

    const renderService = (serviceOptions || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    ));


    return (
      <Form onSubmit={this.handleSubmit}>
        <Row
          gutter={24}
          style={{
            marginBottom: 24,
          }}
        >
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <RangePickerWithBorderCompoment
              title="Fecha"
              getFieldDecorator={getFieldDecorator}
              decodator="date"
              placeholder={["Inicio", "Fin "]}
            />
          </Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <SelectComponent
              style={{ width: "100%" }}
              getFieldDecorator={getFieldDecorator}
              title="Valor neto de envío"
              decodator="serviceID"
              options={renderService}
              placeholder="Selecciona el valor neto de envío"
            />
          </Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <SelectComponent
              style={{ width: "100%" }}
              getFieldDecorator={getFieldDecorator}
              title="Ciudad"
              decodator="cityID"
              options={renderCity}
              placeholder="Selecciona una ciudad"
            />
          </Col>
          <Col xs={22} sm={12} md={4} lg={4} xl={3}>
            <Button
              type="primary"
              ghost
              style={{
                
                fontWeight: "500",
                fontSize: "14px",
                lineSeight: "16px",
                height: 40,
                width: "100%",
                marginTop: "21px",
              }}
              htmlType="submit"
              loading={loadingBtnSearch}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const HeaderForm = Form.create()(FinaHeader);
export default compose(withApollo)(HeaderForm);