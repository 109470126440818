import styled from "styled-components";

const Paper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  background-color: white;
  padding: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

export default Paper;
