import styled from "styled-components";

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media (max-width: 1400px) and (min-width: 1200px) {
    height: 34vw;
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    height: 30vw;
  }

  @media (max-width: 992px) {
    height: 40vw;
  }
`;
