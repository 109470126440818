import gql from "graphql-tag";

const reofferRequest = gql`
  mutation reofferRequest(
    $orderIDs: [Int]
    $enterpriseID: Int
    $rangeBy: ZoneDateRangeInput
    $serviceID: Int
    $cityID: Int
    $branchOfficeID: Int
    $statusID: Int
  ) {
    reofferRequest(
      orderIDs: $orderIDs
      enterpriseID: $enterpriseID
      rangeBy: $rangeBy
      serviceID: $serviceID
      cityID: $cityID
      branchOfficeID: $branchOfficeID
      statusID: $statusID
    ) {
      message
      motive
      statusCode
    }
  }
`;

export { reofferRequest };
