import styled from "styled-components";

interface ChartContainerProps {
  width: number;
}

export const ChartContainer = styled.div<ChartContainerProps>`
  width: 100%;
  background-color: #fff;
  border: 1px solid #edebe9;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: space-evenly;

  & > div > canvas {
    width: 90% !important;
    height: auto !important;
  }
`;
