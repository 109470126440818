import React from "react";
import { compose } from "recompose";
import { graphql, withApollo } from "react-apollo";
import { navigate } from "@reach/router";
import { Button, Row, Col, Form, Select, Icon, notification, Card, Checkbox } from "antd";
import {
  getEnterpriseByID,
  getAllEnterpriseDocuments,
  getEmailConfigurationByEnterpriseID,
} from "../../../../graphQl/queries/myCompany";
import { withGlobal } from "../../../../component-global";
import { withGlobalContext } from "../../../../utils/globalContext";
import BodyContainer from "../../../../components/Layout/BodyContainer";
import CustomCollapse from "../../../../components/Collapse/CumstomCollapse";
import InputComponent from "../../../../components/FormFields/InputWithBorderComponent";
import SelectComponent from "../../../../components/FormFields/SelectWithBorderComponent";
import SwitchComponent from "../../../../components/FormFields/SwitchComponent";
import {
  updateEnterprise,
  createEmailConfigurationByEnterprise,
  updateEmailConfigurationByEnterprise,
} from "../../../../graphQl/mutations/myCompany";
import ImageUpload from "../../../../components/UploadImg";
import { updateEnterprisePlatforms } from "../../../../graphQl/mutations/enterprisePlatforms";

const { Option } = Select;
const defaultImgProfile =
  "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2Fuser.svg?alt=media&token=93cce15e-8f5b-4303-b9c7-ce570b59bf2b";

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      contactNum: 0,
      loadingOnSave: false,
      urlImgProfile: null,
      EnterpriseData: null,

      listPlatforms: [],
      contacts: [
        {
          name: null,
          area: null,
          email: null,
          phone: null,
          id: Math.random()
            .toString(36)
            .substring(2, 15),
          currentContact: null,
        },
      ],
      configurationEmail: null,
      showConfiguration: false,
    };
  }

  componentDidMount = async () => {
    await this.getDataEnterprise();
    const { EnterpriseData } = this.state;
    this.setDataToForm(EnterpriseData);
    this.setState({
      showConfiguration: EnterpriseData.extra.allowSendEmail.active,
    });
  };

  selectIntegrationCase = (namesPlatform, deleted, enabled = false, id = null) => {
    switch(namesPlatform.toUpperCase()) {
      case "SHOPIFY": 
        return {
          name: namesPlatform.toUpperCase(),
          linkImage: "https://firebasestorage.googleapis.com/v0/b/chazki-link-dev.appspot.com/o/Images%2FShopify.png?alt=media&token=358d90b1-ea1e-4853-a9a3-7196377719df",
          deleted,
          enabled,
          id 
        }
        
      case "MULTIVENDE":
        return {
          name: namesPlatform.toUpperCase(),
          linkImage: "https://firebasestorage.googleapis.com/v0/b/chazki-link-dev.appspot.com/o/Images%2FMultivende.png?alt=media&token=9d3b07fe-5552-4842-a3c2-80803daf4885",
          deleted,
          enabled,
          id
        }
      default: 
        console.log(`Case not found ${namesPlatform.toUpperCase()}`)
        return null
    }
  }

  getDataEnterprise = async () => {
    const {
      data: { GetEnterpriseByID },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getEnterpriseByID,
      variables: {
        id: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      },
    });

    const {
      data: { GetEmailConfigurationByEnterpriseID },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getEmailConfigurationByEnterpriseID,
      variables: {
        enterpriseID: this.props.userEnterpriseData.enterpriseID,
      },
    });
/*
    const dataPlatforms = GetEnterpriseByID.extra
      ? GetEnterpriseByID.extra.integratePlatforms
        ? GetEnterpriseByID.extra.integratePlatforms
        : null
      : null;
*/
    this.setState({
      EnterpriseData: GetEnterpriseByID,
      contactNum: GetEnterpriseByID.contactPeople.length,
      contacts: GetEnterpriseByID.contactPeople,
    });
    if(this.state.EnterpriseData.serviceIDs.length>0){
      this.setState({configurationEmail:GetEmailConfigurationByEnterpriseID});
    }else{
      this.setState({configurationEmail:null});
    }
    for (let i = 0; i < this.state.contactNum; i++) {
      this.state.contacts[i].id = Math.random()
        .toString(36)
        .substring(2, 15);
    }
    this.state.listPlatforms = await Promise.all(["SHOPIFY", "MULTIVENDE"].map(
      itm => {
        if(GetEnterpriseByID.Platforms.length > 0) {
          const filters = GetEnterpriseByID.Platforms.filter(item => item.namePlatform.toUpperCase() === itm);
          if(filters.length > 0) return this.selectIntegrationCase(itm, true, filters[0].enabled, filters[0].id);

          return this.selectIntegrationCase(itm, false);
        }
        else {
          return this.selectIntegrationCase(itm, false);
        }
      }
    ))
  };

  setDataToForm = data => {
    const contacts = data.contactPeople.map(x => {
      return {
        name: x.name,
        area: x.area,
        email: x.email[0],
        phone: x.phone[0],
        id: Math.random()
          .toString(36)
          .substring(2, 15),
        currentContact: x.currentContact,
      };
    });
    this.setState({
      contacts,
    });

    const currentContact =
      data &&
      data.contactPeople &&
      data.contactPeople.findIndex(x => x.currentContact === true);
    this.setState({
      currentContact,
    });
  };

  addContact = async () => {
    this.setState(({ contacts }) => ({
      contacts: contacts.concat([
        {
          name: null,
          area: null,
          email: null,
          phone: null,
          currentContact: null,
          id: Math.random()
            .toString(36)
            .substring(2, 15),
        },
      ]),
    }));
  };

  deleteContact = async id => {
    this.setState(({ contacts }) => ({
      contacts: contacts.filter(x => x.id !== id),
    }));
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const { contacts } = this.state;

      const filter = {};

      if (values.documentType) {
        filter.documentType = parseInt(values.documentType, 10);
      }
      if (values.documentNumber) {
        filter.documentNumber = values.documentNumber;
      }
      if (values.businessName) {
        filter.businessName = values.businessName;
      }
      if (values.comercialName) {
        filter.comercialName = values.comercialName;
      }
      if (values.address) {
        filter.address = values.address;
      }
      if (this.state.urlImgProfile) {
        filter.photo = this.state.urlImgProfile;
      }

      const contactPeople = [];

      for (let i = 0; i < this.state.contacts.length; i++) {
        if (
          values[`names-${contacts[i].id}`] &&
          values[`area-${contacts[i].id}`] && [
            values[`email-${contacts[i].id}`],
          ] && [values[`phone-${contacts[i].id}`]]
        )
          contactPeople.push({
            name: values[`names-${contacts[i].id}`],
            area: values[`area-${contacts[i].id}`],
            email: [values[`email-${contacts[i].id}`]],
            phone: [values[`phone-${contacts[i].id}`]],
            currentContact: i === 0,
          });
      }

      filter.extra = {
        ...this.state.EnterpriseData.extra,
        allowSendEmail: {
          active: values.allowSendEmailActive,
          default: false,
          isAuthorization: true,
        },
      };

      if (contactPeople.length) {
        filter.contactPeople = contactPeople;

        if (
          !this.props.userEnterpriseData ||
          !this.props.userEnterpriseData.enterpriseID
        ) {
          this.onCreateEnterprise(filter);
          if (this.state.configurationEmail!=null && !this.state.configurationEmail.configurationExists) {
            this.onCreateEmailConfigurationByEnterprise(
              this.state.configurationEmail
            );
          } else {
            this.onUpdateEmailConfigurationByEnterprise(
              this.state.configurationEmail
            );
          }
        } else {
          this.onSaveChanges(filter);
          if (this.state.configurationEmail!=null && !this.state.configurationEmail.configurationExists) {
            this.onCreateEmailConfigurationByEnterprise(
              this.state.configurationEmail
            );
          } else {
            this.onUpdateEmailConfigurationByEnterprise(
              this.state.configurationEmail
            );
          }
        }
      } else {
        notification.error({
          message: "Se necesita por lo menos un contacto.",
        });
      }
    });
  };

  onCreateEnterprise = async filter => {
    this.setState({ loadingOnSave: true });
    const variables = {
      ...filter,
      id: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
    };

    await this.props.client
      .mutate({
        mutation: updateEnterprise,
        update: (cache, { data: { UpdateEnterprise } }) => {
          notification.success({
            message: `Los datos se guardaron con éxito.`,
          });
          this.setState({ loadingOnSave: false });
        },
        variables,
      })
      .catch(err => {
        console.log(err);
        notification.error({
          message: "No se pudo guardar, error en el sistema.",
        });
        this.setState({ loadingOnSave: false });
      });
  };

  onSaveChanges = async filter => {
    this.setState({ loadingOnSave: true });
    const variables = {
      ...filter,
      id: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
    };

    await this.props.client
      .mutate({
        mutation: updateEnterprise,
        update: (cache, { data: { UpdateEnterprise } }) => {
          notification.success({
            message: `Los datos se actualizaron con éxito.`,
          });
          this.setState({ loadingOnSave: false });
        },
        variables,
      })
      .catch(err => {
        console.log(err);
        notification.error({
          message: "No se pudo actualizar, error en el sistema.",
        });
        this.setState({ loadingOnSave: false });
      });
  };

  onCreateEmailConfigurationByEnterprise = async filter => {
    this.setState({ loadingOnSave: true });
    const variables = {
      enterpriseID: filter.emailConfigurationByEnterprise.enterpriseID,
      emailConfigurationServices:
        filter.emailConfigurationByEnterprise.emailConfigurationServices,
    };
    await this.props.client
      .mutate({
        mutation: createEmailConfigurationByEnterprise,
        update: (cache, { data: { CreateEmailConfigurationByEnterprise } }) => {
          this.setState({ loadingOnSave: false });
        },
        variables,
      })
      .catch(err => {
        console.log(err);
        notification.error({
          message: "No se pudo guardar, error en el sistema.",
        });
        this.setState({ loadingOnSave: false });
      });
  };

  onUpdateEmailConfigurationByEnterprise = async filter => {
    this.setState({ loadingOnSave: true });
    var variables=null
    if(this.state.EnterpriseData.serviceIDs.length > 0){
      variables = {
        enterpriseID: filter.emailConfigurationByEnterprise.enterpriseID,
        emailConfigurationServices:
          filter.emailConfigurationByEnterprise.emailConfigurationServices,
      };
    } else{
      variables = {
        enterpriseID: parseInt(this.state.EnterpriseData.id),
        emailConfigurationServices:null,
      };
    }
    await this.props.client
      .mutate({
        mutation: updateEmailConfigurationByEnterprise,
        update: (cache, { data: { UpdateEmailConfigurationByEnterprise } }) => {
          this.setState({ loadingOnSave: false });
        },
        variables,
      })
      .catch(err => {
        console.log(err);
        notification.error({
          message: "No se pudo actualizar, error en el sistema.",
        });
        this.setState({ loadingOnSave: false });
      });
  };

  onCreateEmailConfigurationByEnterprise = async filter => {
    this.setState({ loadingOnSave: true });
    const variables = {
      enterpriseID: filter.emailConfigurationByEnterprise.enterpriseID,
      emailConfigurationServices:
        filter.emailConfigurationByEnterprise.emailConfigurationServices,
    };
    await this.props.client
      .mutate({
        mutation: createEmailConfigurationByEnterprise,
        update: (cache, { data: { CreateEmailConfigurationByEnterprise } }) => {
          this.setState({ loadingOnSave: false });
        },
        variables,
      })
      .catch(err => {
        console.log(err);
        notification.error({
          message: "No se pudo guardar, error en el sistema.",
        });
        this.setState({ loadingOnSave: false });
      });
  };

  onUpdateEmailConfigurationByEnterprise = async filter => {
    this.setState({ loadingOnSave: true });
    var variables=null
    if(this.state.EnterpriseData.serviceIDs.length > 0){
      variables = {
        enterpriseID: filter.emailConfigurationByEnterprise.enterpriseID,
        emailConfigurationServices:
          filter.emailConfigurationByEnterprise.emailConfigurationServices,
      };
    } else{
      variables = {
        enterpriseID: parseInt(this.state.EnterpriseData.id),
        emailConfigurationServices:null,
      };
    }
    await this.props.client
      .mutate({
        mutation: updateEmailConfigurationByEnterprise,
        update: (cache, { data: { UpdateEmailConfigurationByEnterprise } }) => {
          this.setState({ loadingOnSave: false });
        },
        variables,
      })
      .catch(err => {
        console.log(err);
        notification.error({
          message: "No se pudo actualizar, error en el sistema.",
        });
        this.setState({ loadingOnSave: false });
      });
  };

  onUpdateFields = imgDefault => {
    this.setState({ urlImgProfile: imgDefault });
    // this.setState({ imgDefault });
  };

  onDeleteImgProfile = () => {
    this.setState({ urlImgProfile: defaultImgProfile });
    // this.setState({ imgDefault: null });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onchange = (val, serviceCurrent, statusCurrent) => {
    const { configurationEmail } = this.state;
    const configurationEmailTmp = configurationEmail;

    configurationEmailTmp.emailConfigurationByEnterprise.emailConfigurationServices.forEach(
      service => {
        if (service.serviceID === serviceCurrent.serviceID) {
          service.status.forEach(status => {
            if (status.statusID === statusCurrent.statusID) {
              if (!val.target.checked){
                status.active = false;
              }else{
                status.active = true;
              }
            }
          });
        }
      }
    );
    
    this.setState({
      configurationEmailTmp: configurationEmailTmp,
    });
  };

  capitalizeFirstLetter = string => {
    return string.charAt(0) + string.slice(1).toLowerCase();
  };

  showConfigurationEmail = val => {
    this.setState({ showConfiguration: !val });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      getAllEnterpriseDocuments: { AllDocuments },
    } = this.props;

    const { EnterpriseData, contacts, configurationEmail, } = this.state;

    const renderAllDocuments = () => {
      if (!AllDocuments) return [];
      return (AllDocuments || []).map(obj => (
        <Option key={obj.subtype} value={obj.subtype}>
          {obj.subclass}
        </Option>
      ));
    };

    const onConnectPlatform = (platform) => {
      return navigate(`/dashboard/integration/${platform}`)
    }

    const onConfigPlatform = (platform) => {
      return navigate(`/dashboard/edit-integration/${platform}`)
    }

    const onEnabledPlatform = async (id, enabled) => {
      let variables = {
          id,
          enabled: (!enabled),
      }
      const {data, errors} = await this.props.client.mutate({
          mutation: updateEnterprisePlatforms,
          variables
      })

      if(errors){
        console.log(errors);
        notification.error({
            message: `No se pudo guardar, error en el sistema.`,
        });
        return
      }
      const updatePlatforms = this.state.listPlatforms.map(
        (platform) => {
          if(platform.id && platform.id === id) 
            return {
              name: platform.name,
              linkImage: platform.linkImage,
              deleted: platform.deleted,
              enabled: !enabled,
              id: platform.id 
            }
          else return platform
        }
      )
      this.setState({listPlatforms: updatePlatforms})
      notification.success(data.UpdateEnterprisePlatforms)
      return
    }

    let renderConfigurationEmail = null;
    const renderContactRow = contacts.map((contact, index) => {
      return (
        <Row gutter={24} key={index.toString()}>
          <Col xs={24} sm={24} md={6} lg={6} xl={5} style={{ marginTop: 20 }}>
            <InputComponent
              getFieldDecorator={getFieldDecorator}
              title="Nombres y apellidos"
              placeholder="Ingresa los nombres y apellidos"
              decodator={`names-${contact.id}`}
              value={contact.name}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={5} style={{ marginTop: 20 }}>
            <InputComponent
              getFieldDecorator={getFieldDecorator}
              decodator={`email-${contact.id}`}
              title="Correo de contacto"
              placeholder="Ingresa el correo"
              value={contact.email}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={5} style={{ marginTop: 20 }}>
            <InputComponent
              getFieldDecorator={getFieldDecorator}
              decodator={`phone-${contact.id}`}
              placeholder="Ingresa el teléfono"
              title="Teléfono"
              value={contact.phone}
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={5} style={{ marginTop: 20 }}>
            <InputComponent
              getFieldDecorator={getFieldDecorator}
              decodator={`area-${contact.id}`}
              placeholder="Ingresa el área"
              title="Área"
              value={contact.area}
            />
          </Col>
          <div
            style={{
              position: "relative",
              height: 50,
              width: "100%",
            }}
          >
            <Button
              type="link"
              style={{ position: "absolute", right: 0, bottom: 0 }}
              onClick={
                index === 0
                  ? this.addContact
                  : () => this.deleteContact(contact.id)
              }
            >
              {index === 0 ? (
                <div style={{ color: "#307FE2", marginTop: 20 }}>
                  <p>
                    Añadir contacto{" "}
                    {
                      <Icon
                        type="plus"
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      />
                    }
                  </p>
                </div>
              ) : (
                <div style={{ color: "#F40103", marginTop: 20 }}>
                  <p>
                    Quitar contacto{" "}
                    {
                      <Icon
                        type="close"
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      />
                    }
                  </p>
                </div>
              )}
            </Button>
          </div>
        </Row>
      );
    });

    if (configurationEmail) {
      renderConfigurationEmail = configurationEmail.emailConfigurationByEnterprise.emailConfigurationServices.map(
        service => {
          return (
            <Row gutter={24}>
              <Col xs={24} sm={24} md={3} lg={3} xl={5} style={{ marginTop: 20 }}>
                <h4>{this.capitalizeFirstLetter(service.serviceName)}</h4>
              </Col>
              <Col xs={24} sm={20} md={20} lg={20} xl={18} style={{ marginTop: 20 }}>
                <Row gutter={24}>
                  {service.status.map(status => (
                      <Col xs={5} sm={5} md={4} lg={4} xl={4}>
                      {status.visible ? (
                      <Row gutter={24}>
                        <Col xs={5} sm={4} md={5} lg={5} xl={5} >
                          <Checkbox
                            onChange={val =>
                              this.onchange(val, service, status)
                            }
                            checked={status.active}
                          />
                        </Col>
                        <Col style={{"padding-right":"10px"}}>
                          <p>{status.statusName}</p>
                        </Col>
                      </Row>
                    ):(
                      <div></div>
                    )}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          );
        }
      );
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <BodyContainer gutter={16} justify="center">
          <div
            style={{
              height: "calc(100vh - 330px)",
              overflowY: "scroll",
            }}
          >
            <CustomCollapse headerTitle="Datos de la empresa">
              <div style={{ marginBottom: 20, width: 250 }}>
                <ImageUpload
                  imgDefault={
                    this.state.urlImgProfile ||
                    (EnterpriseData && EnterpriseData.photo)
                  }
                  accept="image/*,.pdf"
                  multiple
                  folderName="nintendo"
                  target="profilesUsers"
                  onUpdateFields={this.onUpdateFields}
                  onDeleteImgProfile={this.onDeleteImgProfile}
                  maxWidth={800}
                  maxHeight={800}
                />
              </div>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <SelectComponent
                    style={{ width: "100%" }}
                    getFieldDecorator={getFieldDecorator}
                    title="Tipo de documento"
                    decodator="documentType"
                    value={
                      EnterpriseData &&
                      EnterpriseData.DocumentType &&
                      EnterpriseData.DocumentType.subtype
                    }
                    options={renderAllDocuments()}
                  />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <InputComponent
                    getFieldDecorator={getFieldDecorator}
                    decodator="documentNumber"
                    title="Número de documento"
                    value={EnterpriseData && EnterpriseData.documentNumber}
                  />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <InputComponent
                    getFieldDecorator={getFieldDecorator}
                    decodator="businessName"
                    title="Razón Social"
                    value={EnterpriseData && EnterpriseData.businessName}
                  />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <InputComponent
                    getFieldDecorator={getFieldDecorator}
                    decodator="comercialName"
                    title="Nombre comercial"
                    value={EnterpriseData && EnterpriseData.comercialName}
                  />
                </Col>
              </Row>

              <Row gutter={24} style={{ marginTop: "16px" }}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <InputComponent
                    getFieldDecorator={getFieldDecorator}
                    title="País"
                    decodator="Country"
                    value={EnterpriseData && EnterpriseData.Country.name}
                    disabled
                  />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <InputComponent
                    getFieldDecorator={getFieldDecorator}
                    title="Ciudad"
                    decodator="City"
                    value={EnterpriseData && EnterpriseData.City.name}
                    disabled
                  />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <InputComponent
                    getFieldDecorator={getFieldDecorator}
                    decodator="address"
                    title="Dirección legal"
                    value={EnterpriseData && EnterpriseData.address}
                  />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <SwitchComponent
                    title="Envío comunicaciones de seguimiento"
                    getFieldDecorator={getFieldDecorator}
                    decodator="allowSendEmailActive"
                    value={
                      EnterpriseData &&
                      EnterpriseData.extra &&
                      EnterpriseData.extra.allowSendEmail &&
                      EnterpriseData.extra.allowSendEmail.active
                    }
                    onChange={() =>
                      this.showConfigurationEmail(this.state.showConfiguration)
                    }
                  />
                </Col>
              </Row>
            </CustomCollapse>
            {this.state.showConfiguration ? (
              <CustomCollapse
                headerTitle="Notificaciones del correo"
                style={{ marginTop: 20 }}
              >
                <div
                  style={{
                    minHeight: 130,
                    width: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <Row gutter={24}>
                    <Col
                      xs={24}
                      sm={24}
                      md={3}
                      lg={3}
                      xl={5}
                      style={{ marginTop: 10 }}
                    >
                      <h4>Servicios</h4>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={10}
                      style={{ marginTop: 10 }}
                    >
                      <h4>Estado de la orden</h4>
                    </Col>
                  </Row>
                  {renderConfigurationEmail}
                </div>
              </CustomCollapse>
            ) : (
              <div></div>
            )}
            <CustomCollapse
              headerTitle="Datos del contacto"
              style={{ marginTop: 20 }}
            >
              <div
                style={{
                  minHeight: 130,

                  width: "100%",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {renderContactRow}
              </div>
            </CustomCollapse>

            <CustomCollapse headerTitle="Datos de Interfaz">
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                  <InputComponent
                    style={{ width: "100%" }}
                    getFieldDecorator={getFieldDecorator}
                    title="Llave de la empresa"
                    decodator="enterpriseKey"
                    value={EnterpriseData && EnterpriseData.enterpriseKey}
                    disabled
                    options={renderAllDocuments()}
                  />
                </Col>
              </Row>
            </CustomCollapse>
            <CustomCollapse headerTitle="Datos de Plataformas Integradas">
              <Row gutter={24} >
                {
                  this.state.listPlatforms.map(
                    (itmPlatforms, idx) => {
                      return (
                        <Col key={`col-${itmPlatforms.name}${idx}`} xs={24} sm={24} md={6} lg={6} xl={4} >
                          <Card
                            key={`card-${itmPlatforms.name}${idx}`}
                            hoverable
                            style={{ width: '100%' }}
                            cover={<img alt="example" src={itmPlatforms.linkImage} />}
                          >
                            {itmPlatforms.deleted
                            ?
                            <React.Fragment>
                              <Button 
                                key={`buttonConfig-${itmPlatforms.name}${idx}`}
                                type="primary" 
                                style={{width: '100%'}} 
                                onClick={() => onConfigPlatform(itmPlatforms.name.toLowerCase())}
                              >
                                Configurar
                              </Button>
                              <Button 
                                key={`buttonDisconnect-${itmPlatforms.name}${idx}`}
                                style={{width: '100%', marginTop: '10px'}} 
                                onClick={() => onEnabledPlatform(itmPlatforms.id, itmPlatforms.enabled)}
                              >
                                {(itmPlatforms.enabled) ? `Desconectar` : `Habilitar`}
                              </Button>
                            </React.Fragment>
                            :
                            <Button 
                              key={`buttonConnect-${itmPlatforms.name}${idx}`}
                              type="primary" 
                              onClick={() => onConnectPlatform(itmPlatforms.name.toLowerCase())}
                              style={{width: '100%', marginTop: '20px', marginBottom: '20px'}} 
                            >Conectar</Button>
                            }
                          </Card>
                        </Col>
                      )
                    }
                  )
                }
              </Row>
            </CustomCollapse>
          </div>
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            ghost
            style={{ position: "absolute", bottom: 24, right: 24 }}
            loading={this.state.loadingOnSave}
          >
            Guardar
          </Button>
        </BodyContainer>
      </Form>
    );
  }
}

const WrapperBody = withGlobal(Body);
const FormBody = Form.create()(WrapperBody);

export default compose(
  graphql(getAllEnterpriseDocuments, {
    name: "getAllEnterpriseDocuments",
    options: () => {
      return {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
      };
    },
  }),
  withApollo,
  withGlobalContext
)(FormBody);
