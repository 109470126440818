import styled from "styled-components";

const Button = styled.button`

  font-size:20px;
  color:#000000;
  background-color:#ffffff;
  border-radius:50%;
  width:35px;
  height:35px;
  top:-5px;
  justify-content:center;
  align-items:center;
  display:flex;
  border: none;

&:hover{
  font-size:20px;
  color:#096DD9;
  background-color:#E7F3FF;
  border-radius:50%;
  width:35px;
  height:35px;
  top:-5px;
  justify-content:center;
  align-items:center;
  display:flex;
}
&:active {
  font-size:20px;
  color:#096DD9;
  background-color:#E7F3FF;
  border-radius:50%;
  width:35px;
  height:35px;
  top:-5px;
  justify-content:center;
  align-items:center;
  display:flex;
}
&:focus {
  font-size:20px;
  color:#096DD9;
  background-color:#E7F3FF;
  border-radius:50%;
  width:35px;
  height:35px;
  top:-5px;
  justify-content:center;
  align-items:center;
  display:flex;
}
`;

export {
    Button,
  };