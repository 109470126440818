import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import {
  Avatar,
  Button,
  Icon,
  Spin,
  notification,  
  Empty,
} from "antd";
import Slider from "react-slick";
import { withGlobal } from "../../../component-global";
import { ConfirmAction } from "../../../components/Modals";
import {
  BGContainer,
  ContainerImg,
  ContainerInfo,
  ModalSlideImg,
  Resalted,
  Regular,
} from "./style";
import {
  getEvidenceImages,
  getAccuseImages,
} from "../../../graphQl/queries/orderHistory";
import DrawerComments from "./DrawerComments";

class DetailOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleModal: false,
      groupImgSelected: null,
      confirmModalVisible: false,      
      accuseImgs: null,
      visibleDrawer: false,
    };
  }

  componentDidMount = async () => {
    this.getEvidences();
    this.getAcuse();
  };

  deleteImg = id => {
    console.log("delete Img", id);
    this.setState({ confirmModalVisible: true });
  };

  getEvidences = async () => {
    const variables = {
      orderServiceID: parseInt(this.props.orderService.id, 10),
    };
    const {
      data: { EvidenceImages },
    } = await this.props.client
      .query({
        fetchPolicy: "network-only",
        query: getEvidenceImages,
        variables,
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({ evidenceImgs: EvidenceImages });
  };

  getAcuse = async () => {
    const variables = {
      orderID: parseInt(this.props.data.id, 10),
    };
    const {
      data: { AccuseImage },
    } = await this.props.client
      .query({
        fetchPolicy: "network-only",
        query: getAccuseImages,
        variables,
      })
      .catch(error => {
        console.log(error);
      });
    this.setState({ accuseImgs: AccuseImage.urlFileAcuse });
  };  
  
  openDrawerComments = orderServiceID => {
    this.setState({
      visibleDrawer: true,
      orderServiceSelected: orderServiceID,
    });
  };

  onCloseDrawer = () => {
    this.setState({ visibleDrawer: false });
  };

  render() {
    const { data, orderService } = this.props;
    const { evidenceImgs, accuseImgs,visibleDrawer } = this.state;

    const dataInfo = {
      dropContactName: data.dropContactName,
      dropAddress: data.dropAddress,
      dropContactPhone: data.dropContactPhone,
      pickUpAddress: data.pickUpAddress,
      img:
        data &&
        orderService &&
        orderService.Routes &&
        orderService.Routes.Affiliates
          ? `${orderService.Routes.Affiliates.User.picture}`
          : null,
      telf:
        data &&
        orderService &&
        orderService.Routes &&
        orderService.Routes.Affiliates &&
        orderService.Routes.Affiliates.User.phone
          ? `${orderService.Routes.Affiliates.User.phone}`
          : "-",
      service: data && data.Service ? data.Service.name : "",
      enterprise: data && data.Enterprise ? data.Enterprise.comercialName : "",
      trackCode: data && data.trackCode ? data.trackCode : "",
      routeCode:
        data && orderService && orderService.Routes
          ? orderService.Routes.routeCode
          : "-",
      vehicleType:
        data &&
        orderService &&
        orderService.Routes &&
        orderService.Routes.AffiliateVehicles &&
        orderService.Routes.AffiliateVehicles.CategoryVehicle
          ? `${orderService.Routes.AffiliateVehicles.CategoryVehicle.subclass}`
          : "-",
      vehiclePlate:
        data &&
        orderService &&
        orderService.Routes &&
        orderService.Routes.AffiliateVehicles &&
        orderService.Routes.AffiliateVehicles.vehiclePlate
          ? `${orderService.Routes.AffiliateVehicles.vehiclePlate}`
          : "-",
      affiliate:
        data &&
        orderService &&
        orderService.Routes &&
        orderService.Routes.Affiliates ? (
          `${orderService.Routes.Affiliates.User.names} 
              ${orderService.Routes.Affiliates.User.lastname}`
        ) : (
          <h1
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "normal",
              fontSize: "15px",
              lineHeight: "32px",
              color: "gray",
              paddingTop: 10,
            }}
          >
            No hay Chazki asignado.
          </h1>
        ),
    };

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const arrayDataImgSelected = this.state.groupImgSelected
      ? this.state.groupImgSelected === "evidence"
        ? evidenceImgs
        : accuseImgs
      : [];
    const renderImgs = (arrayDataImgSelected || []).map((x, idx) => {
      return (
        <div key="idx">
          <ContainerImg key={x.id.toString()}>
            <img alt="img" src={x.url} />
          </ContainerImg>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "18px",
              fontWeight: "normal",
              fontSize: "12px",
              lineHeight: "16px",
              color: "#595959",
            }}
          >
            Evicendia {idx + 1} de {arrayDataImgSelected.length}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "32px",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            <Button
              onClick={() => this.deleteImg(x.id)}
              style={{ border: "none", color: "#CF1322" }}
            >
              Eliminar Imagen {"  "}
              <Icon type="delete" />
            </Button>
          </div>
        </div>
      );
    });

    return (
      <Spin spinning={this.props.loadingData}>
        <ModalSlideImg
          title={null}
          footer={null}
          visible={this.state.visibleModal}
          onCancel={() => {
            this.setState({ visibleModal: false });
          }}
          closable
        >
          {renderImgs.length > 0 ? (
            <Slider {...settings}>{renderImgs}</Slider>
          ) : (
            <div
              style={{
                marginTop: "32px",
                marginBottom: "32px",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Empty
                image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
                description={
                  <span style={{ color: "gray" }}>
                    No se encontraron evidencias.
                  </span>
                }
              />
            </div>
          )}
        </ModalSlideImg>       

        <ConfirmAction
          style={{ height: "280px" }}
          visible={this.state.confirmModalVisible}
          onCancel={() => this.setState({ confirmModalVisible: false })}
          title="Necesitará permisos de administrador"
          description="Para poder eliminar esta evidencia necesitamos que ingreses tus credenciales"
          cancelMessage="No soy admin"
          acceptMessage="Ingresar accesos"
          loading={false}
          acceptBtnStyle={{ backgroundColor: "#307FE2", width: "auto" }}
          onConfirm={() => console.log("conConfirm")}
        />
        <BGContainer>
          <div
            style={{
              position: "static",
              display: "flex",
              flexDirection: "row",
              marginBottom: "24px",
            }}
          >
            <div style={{ width: 86 }}>
              <Avatar
                size={86}
                src={
                  dataInfo.img ||
                  "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                }
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: 10,
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  lineHeight: "22px",
                }}
              >
                {dataInfo.affiliate}
              </span>

              <span
                style={{
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                Disponible
              </span>
            </div>
          </div>
          <ContainerInfo>
            <div style={{ display: "flex", marginBottom: "12px" }}>
              <Regular>Placa </Regular>
              <Resalted style={{ marginLeft: "auto" }}>
                {dataInfo.vehiclePlate}
              </Resalted>
            </div>
            <div style={{ display: "flex", marginBottom: "12px" }}>
              <Regular>Tipo de vehículo </Regular>
              <Resalted style={{ marginLeft: "auto" }}>
                {dataInfo.vehicleType}
              </Resalted>
            </div>
            <div style={{ display: "flex", marginBottom: "12px" }}>
              <Regular>Teléfono </Regular>
              <Resalted style={{ marginLeft: "auto" }}>
                {dataInfo.telf}
              </Resalted>
            </div>
            <div style={{ display: "flex", marginBottom: "12px" }}>
              <Regular>Tipo de servicio </Regular>
              <Resalted style={{ marginLeft: "auto" }}>
                {dataInfo.service}
              </Resalted>
            </div>
          </ContainerInfo>

          <ContainerInfo>
            <h6 style={{ marginBottom: "16px" }}>Datos del pedido</h6>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "15px",
              }}
            >
              <Regular>Dirección de recojo: </Regular>
              <Resalted>{dataInfo.pickUpAddress}</Resalted>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "15px",
              }}
            >
              <Regular>Dirección de entrega:</Regular>
              <Resalted>{dataInfo.dropAddress}</Resalted>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "15px",
              }}
            >
              <Regular>Cliente final:</Regular>
              <Resalted>{dataInfo.dropContactName}</Resalted>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "15px",
              }}
            >
              <Regular>Teléfono del cliente final: </Regular>
              <Resalted>{dataInfo.dropContactPhone}</Resalted>
            </div>
          </ContainerInfo>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="link"
              size="large"
              style={{ marginBottom: 10, color: "#307FE2" }}
              onClick={() => {
                this.openDrawerComments(orderService.id);
              }}
            >
              Comentarios del Chazki <Icon type="right" />
            </Button>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                size="large"
                ghost
                type="primary"
                style={{
                  width: "122px",
                  height: "68px",
                  marginRight: "20px",
                  border: "1px solid #307FE2",
                }}
                loading={this.props.loadingSaveBtn}
                onClick={() => {
                  this.setState({
                    visibleModal: true,
                    groupImgSelected: "evidence",
                  });
                }}
              >
                <div>
                  <Icon
                    style={{ fontSize: "20px", color: "#307FE2" }}
                    type="camera"
                  />
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#307FE2",
                    }}
                  >
                    Ver evidencia
                  </div>
                </div>
              </Button>
              <Button
                size="large"
                ghost
                type="primary"
                style={{
                  width: "122px",
                  height: "68px",
                  border: "1px solid #307FE2",
                }}
                loading={this.props.loadingSaveBtn}
                onClick={async () => {
                  if (accuseImgs) window.open(accuseImgs, "_blank");
                  else
                    notification.warning({
                      message: "La orden no posee un acuse",
                    });
                }}
              >
                <div>
                  <Icon
                    style={{ fontSize: "20px", color: "#307FE2" }}
                    type="file-done"
                  />
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "16px",
                      color: "#307FE2",
                    }}
                  >
                    Ver acuse
                  </div>
                </div>
              </Button>
            </div>
          </div>
          {visibleDrawer && (
          <DrawerComments
            orderServiceID={orderService.id}
            orderID={this.props.orderId}
            visible={visibleDrawer}
            onClose={this.onCloseDrawer}
          />
        )}
        </BGContainer>
      </Spin>
    );
  }
}

const WraperOrderTable = withGlobal(DetailOrder);

export default compose(withApollo)(WraperOrderTable);
