import {
  Button,
  Checkbox,
  Col,
  Empty,
  Form,
  Pagination,
  Row,
  Spin,
} from "antd";
import React from "react";
import { Link } from "@reach/router";
import { withApollo } from "react-apollo";
import { compose } from "recompose";

import { ContainerDelivery } from "./static";
import { ContainerCards, ContainerBtnFilter, ContainerLoading } from "./style";
import * as router from "../../../routes/routes";

// Components
import { CardPagination } from "../../../components/Table/styles";
import {
  RangePickerWithBorderCompoment,
  InputComponentWithBorder,
} from "../../../components/FormFields";

class Located extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // latLng: { lat: -12.062583, lng: -77.0482757 },
      activeDescriptionHistory: [],
      pagination: { limit: 12, page: 0 },
      pageData: null,
      // deliverySelected: null,
      // loadingUpdate: false,
    };
  }

  /* componentDidUpdate(prevProps) {
    if (
      prevProps.enterpriseId !== this.props.enterpriseId ||
      prevProps.deliveries.length !== this.props.deliveries.length
    )
      this.resetState();
  } */

  componentDidMount() {
    const { deliveries } = this.props;
    if (deliveries) {
      const newData = this.pagination(deliveries, 12, 1);
      this.setState({
        pageData: newData,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { deliveries } = this.props;
    const { pagination } = this.state;
    if (prevProps.deliveries !== deliveries) {
      const newDataDel = this.pagination(deliveries, pagination.limit, 1);
      this.setState({
        pageData: newDataDel,
        pagination: { limit: pagination.limit, page: 0 },
      });
    }
  }

  pagination = (array, pageSize, pageNumber) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  selectDelivery = delivery => {
    this.props.selectDelivery(delivery);
  };

  openDescription = id => {
    this.setState(({ activeDescriptionHistory }) => ({
      activeDescriptionHistory:
        activeDescriptionHistory.indexOf(id) > -1
          ? activeDescriptionHistory.filter(x => x !== id)
          : activeDescriptionHistory.concat([id]),
    }));
  };

  render() {
    const {
      deliveries,
      deliveriesSize,
      deleteDelivery,
      offer,
      deleteOriginal,
      located,
      loadingEvent,
      loadingDelete,
      loadinOriginOrder,
      loadingPrint,
      loadingGetDeliveries,
      idsChecked,
      onchange,
      onCheckAllChange,
      selectedDelvery,
      printPdf,
      getFieldDecorator,
      handleSubmit,
    } = this.props;

    const { activeDescriptionHistory, pagination, pageData } = this.state;

    const deliveriesRender = (pageData || []).map((x, idx) => {
      return (
        <div
          style={{
            display: "flex",
            overflowY: "auto",
            alignItems: "center",
          }}
          key={idx.toString()}
        >
          <Checkbox
            disabled={loadingGetDeliveries}
            style={{ marginRight: "16px" }}
            onChange={val => onchange(val, x.id)}
            checked={idsChecked.findIndex(y => y === x.id) > -1}
          />
          <ContainerDelivery
            className="containerDelivery"
            located={located}
            openDescription={this.openDescription}
            activeDescription={activeDescriptionHistory.indexOf(x.id) > -1}
            disable={x.blocked || loadingGetDeliveries}
            handleOnClick={!x.blocked ? () => this.selectDelivery(x) : null}
            handleDelte={() => deleteDelivery(x.id)}
            current={`${idx + 1}/${deliveries.length}`}
            active={x.id === selectedDelvery.id}
            data={x}
          />
        </div>
      );
    });

    const onChangePagination = async (page, pageSize) => {
      let newData = [];
      newData = await this.props.onPagChange(pageSize, page);
      this.setState({
        pagination: { limit: pageSize, page: page - 1 },
        pageData: newData,
      });
    };

    const messageGlobal = (
      <div>
        <p>
          No hemos encontrado información disponible o todas tus órdenes han
          sido validadas con éxito.
        </p>
        <p>¿Deseas ingresar nuevas solicitudes de envío? </p>
        {/* <Link to={`/dashboard/${router.prePlanner}`}>
          <span>Generar rutas</span>
        </Link> */}
        <Link to={`/dashboard/${router.uploadcsv}`}>
          <span>Cargar CSV </span>
        </Link>
      </div>
    );

    const cardPagination = (
      <CardPagination>
        <span
          style={{
            height: 32,
            padding: "0.5rem 2rem",
            alignSelf: "center",
          }}
        >
          {`Total ${deliveriesSize || 0} registros`}
        </span>
        <Pagination
          disabled={loadingGetDeliveries}
          simple
          pageSize={pagination.limit}
          current={pagination.page + 1}
          total={deliveriesSize || 0}
          onChange={onChangePagination}
        />
      </CardPagination>
    );

    return (
      <Form onSubmit={handleSubmit}>
        <Row gutter={4}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 24 }}>
            <ContainerBtnFilter>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                  <InputComponentWithBorder
                    style={{ width: "100%" }}
                    getFieldDecorator={getFieldDecorator}
                    title="TrackCode"
                    decodator="trackCode"
                  />
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <RangePickerWithBorderCompoment
                    getFieldDecorator={getFieldDecorator}
                    title="Rango de fecha de creación"
                    decodator="dates"
                    placeholder="Ingrese una fecha"
                    style={{
                      width: "100%",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                  <Button
                    htmlType="submit"
                    size="large"
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "16px",
                      marginRight: "15px",
                      color: "#307FE2",
                      borderColor: "#307FE2",
                    }}
                  >
                    Buscar
                  </Button>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ marginTop: 5 }}
                >
                  <Checkbox
                    indeterminate={
                      idsChecked.length > 0 &&
                      deliveriesSize > idsChecked.length
                    }
                    checked={
                      idsChecked.length > 0 &&
                      deliveriesSize === idsChecked.length
                    }
                    onChange={onCheckAllChange}
                  >
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        color: "#6A707E",
                      }}
                    >
                      Seleccionar todo
                    </span>
                  </Checkbox>
                </Col>
              </Row>
            </ContainerBtnFilter>
            {deliveriesRender && deliveriesRender.length > 0 ? (
              <>
                <Spin
                  tip="Cargando..."
                  size="large"
                  style={{
                    left: "50%",
                    position: "absolute",
                    textAlign: "center",
                    top: "40%",
                    transform: "translate(-50%, -50%)",
                    verticalAlign: "middle",
                  }}
                  spinning={loadingGetDeliveries}
                ></Spin>
                <ContainerCards>{deliveriesRender}</ContainerCards>
                {cardPagination}
              </>
            ) : (
              <ContainerLoading>
                <Empty
                  image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
                  imageStyle={{
                    height: 136,
                  }}
                  description={null}
                >
                  <span
                    style={{
                      color: "#8C8C8C",
                      
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "20px",
                    }}
                  >
                    {loadinOriginOrder ? "Cargando..." : messageGlobal}
                  </span>
                </Empty>
              </ContainerLoading>
            )}
            <div style={{ margin: "16px 0", textAlign: "right" }}>
              {located && (
                <Button
                  disabled={idsChecked.length === 0 || !idsChecked}
                  style={{
                    
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "16px",
                    marginRight: "15px",
                    color:
                      idsChecked.length === 0 || !idsChecked
                        ? "#D9D9D9"
                        : "#307FE2",
                    borderColor:
                      idsChecked.length === 0 || !idsChecked
                        ? "#D9D9D9"
                        : "#307FE2",
                  }}
                  onClick={printPdf} // imprimir etiquetas
                  loading={loadingPrint}
                >
                  Imprimir etiquetas
                </Button>
              )}
              <Button
                type="danger"
                disabled={idsChecked.length === 0 || !idsChecked}
                style={{
                  
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "16px",
                  marginRight: "15px",
                }}
                onClick={() => deleteOriginal(!!located)}
                loading={loadingDelete}
              >
                Rechazar y eliminar ordenes
              </Button>
              {located && (
                <Button
                  type="primary"
                  disabled={idsChecked.length === 0 || !idsChecked}
                  style={{
                    
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "16px",
                  }}
                  onClick={offer}
                  loading={loadingEvent}
                >
                  Aprobar y procesar ordenes
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default compose(withApollo)(Located);
