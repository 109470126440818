import React from "react";
import {
  ColorsLegendContainer,
  ColorsLegendTitle,
  ColorsLegendItemIcon,
  ColorsLegendItemTitle,
} from "./style";

interface Item {
  label: string;
  color: string;
}

const items: Item[] = [
  {
    label: "Retiro del pedido a cambiar",
    color: "#F5222D",
  },
  {
    label: "Entrega de nuevo pedido",
    color: "#69C0FF",
  },
];

const ColorsLegend = () => (
  <ColorsLegendContainer>
    <ColorsLegendTitle>Tipos de orden:</ColorsLegendTitle>
    {items.map(item => (
      <div key={item.label} style={{ display: "flex", alignItems: "center" }}>
        <ColorsLegendItemIcon color={item.color} />
        <ColorsLegendItemTitle>{item.label}</ColorsLegendItemTitle>
      </div>
    ))}
  </ColorsLegendContainer>
);

export default ColorsLegend;
