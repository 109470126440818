import React from "react";
import moment from "moment";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Button, Row, Col, Icon } from "antd";
import { ContainerTables } from "../../../components/Form/SearchContainer";
import TableList from "../../../components/Table/tableList";

class SenEvidence extends React.Component {
  render() {
    // TABLA
    const columns = [
      {
        title: "Fecha",
        dataIndex: "updatedAt",
        key: "updatedAt",
        align: "left",
        render: (text, record) => (
          <span>{moment(record.updatedAt).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        title: "TrackCode",
        dataIndex: "trackCode",
        key: "trackCode",
        align: "left",
        render: (text, record) => (
          <span>{record.trackCode ? record.trackCode : "--"}</span>
        ),
      },
      {
        title: "Cantidad de intentos",
        dataIndex: "OrderService",
        key: "OrderService",
        align: "left",
        render: (text, record) => (
          <span>
            {record.OrderService
              ? record.OrderService.length
              : "--"}
          </span>
        ),
      },
      {
        title: "Evidencia",
        dataIndex: "OrdersImages",
        key: "OrdersImages",
        align: "left",
        render: (text, record) => (
          <ContainerTables>
            <div style={{ textAlign: "center", display: "block" }}>
              <div style={{ paddingRight: 5 }}>{record.countAffiliates}</div>
              <Icon
              onClick={() => {
                //this.setState({ visibleImgs: true });                
                this.props.getEvidenceImages(record.OrdersImages);              
              }}
                //onClick={this.props.getEvidenceImages(record)}
                type="search"
                align="center"
                style={{
                  color: "#109CF1",
                  fontSize: "2rem",
                  cursor: "pointer",
                }}
              />
            </div>
          </ContainerTables>
        ),
      },
    ];

    return (
      <div>
            <div>
              <Row gutter={24} type="flex" justify="end">
                <Col span={12}>
                  <h1
                    style={{ fontSize: 15, fontWeight: "bold" }}
                    className="title"
                  >
                    Evidencia de ordenes
                  </h1>
                </Col>
                <Col span={12} align="right">
                  <div>
                    <Button
                      size="large"
                      type="primary"
                      ghost
                      onClick={this.props.onExportZIP}
                      style={{ marginRight: 10 }}
                      loading={this.props.loadingButonZip}
                    >
                      Descargar lista <Icon type="download" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            <ContainerTables>
              <TableList
                columns={columns}
                currentPage={this.props.currentPage}
                pageSize={this.props.pageSize}
                // scroll={{ y: "calc(100vh - 560px)" }}
                // heightcustom="calc(100vh - 560px)"
                data={this.props.reportDataDetail}
                onChange={this.props.onChange}
                totalData={this.props.datadetailtotal}
              />
            </ContainerTables>
      </div>
    );
  }
}

export default compose(withApollo)(SenEvidence);
