import React from "react";
import { compose, withProps } from "recompose";
import { withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps";
import { defaultMapOptions } from "./static";

const gmapsApiKey = "AIzaSyCAHe6y1MxSEcRPFsrgwzoxelOIWZH36w0";
const polylineOptions = {
  strokeColor: "#40A9FF",
  strokeOpacity: 0.5,
  strokeWeight: 4,
};

const MapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${gmapsApiKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(props => {
  const { center, zoom, polyline, markers, colorsLegend } = props;

  return (
    <>
      <GoogleMap
        center={center}
        defaultZoom={zoom || 12}
        defaultCenter={center}
        defaultOptions={defaultMapOptions}
      >
        {polyline && (
          <Polyline path={polyline} geodesic options={polylineOptions} />
        )}
        {markers &&
          markers.length > 0 &&
          markers.map((marker, index) => (
            <Marker
              defaultZIndex={100 * index}
              key={index.toString()}
              position={marker.position}
              icon={marker.icon}
              label={{
                color: "white",
                text: marker.label,
              }}
            />
          ))}
      </GoogleMap>
      {colorsLegend && colorsLegend}
    </>
  );
});

export default MapComponent;
