import gql from "graphql-tag";

const getEnterpriseByID = gql`
  query getEnterpriseByID($id: ID!) {
    GetEnterpriseByID: getEnterpriseByID(id: $id) {
      id
      DocumentType {
        subclass
        subtype
      }
      documentNumber
      address
      businessName
      comercialName
      multiplace
      status
      photo
      serviceIDs
      integration
      enterpriseKey
      contactPeople {
        name
        area
        email
        phone
        currentContact
      }
      photo
      User {
        id
        email
      }
      countryID
      Country {
        name
      }
      cityID
      City {
        name
      }
      extra {
        showKustomerRedirect
        keyPhase
        keyNumber
        color
        vehicleTariff
        purchasable
        textHistorial
        terminalState
        requiredDocument
        allowSendEmail {
          active
          isAuthorization
        }
        integratePlatforms {
          code
          hmac
          host
          name
          shop
          scope
          state
          access_token
          notificationOrders
        }
        superAdmin {
          active
          email
          originalPassword
          tmpPassword
          operatorUserID
        }
      }
      createdAt
      updatedAt
      extra {
        integratePlatforms {
          code
          hmac
          host
          name
          shop
          scope
          state
          access_token
          notificationOrders
        }
      }
      Platforms {
        id
        namePlatform
        enabled
        deleted
      }
    }
  }
`;

const getAllCountries = gql`
  query getAllCountries {
    AllCountries: getAllCountries {
      id
      name
      prefixPhone
      urlFlag
      Cities {
        id
        name
      }
    }
  }
`;

const getAllEnterpriseDocuments = gql`
  query searchGeneralTypesBy {
    AllDocuments: searchGeneralTypesBy(type: 11) {
      subclass
      subtype
    }
  }
`;

const getEmailConfigurationByEnterpriseID = gql`
  query getEmailConfigurationByEnterpriseID($enterpriseID: Int!) {
    GetEmailConfigurationByEnterpriseID: getEmailConfigurationByEnterpriseID(
      enterpriseID: $enterpriseID
    ) {
      configurationExists
      default
      emailConfigurationByEnterprise {
        id
        enterpriseID
        deleted
        emailConfigurationServices {
          active
          serviceID
          serviceName
          status {
            active
            statusID
            statusName
            template
            visible
          }
        }
      }
    }
  }
`;

export {
  getEnterpriseByID,
  getAllCountries,
  getAllEnterpriseDocuments,
  getEmailConfigurationByEnterpriseID,
};
