import styled from "styled-components";

const RiskSpanForRiskType = styled.div`
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.borderColor ? props.borderColor : "#ffffff"};
  width: 102px;
  height: 32px;
  box-sizing: border-box;
  padding: 8px;
  text-align: center;
  border-radius: 2px;
  display: grid;
  place-content: center;
`;

export {
  RiskSpanForRiskType,
};