import gql from "graphql-tag";

const getAllPackageSizes = gql`
  query getAllPackageSizes {
    PackageSizes: getAllPackageSizes {
      id
      name
    }
  }
`;

export { getAllPackageSizes };
