import React, { PureComponent } from 'react';
import { Popover, Icon, Tabs, Badge, Spin } from 'antd';
import classNames from 'classnames';
import List from './NoticeList';
import {Button} from './style';

const { TabPane } = Tabs;
export default class NoticeIcon extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      active: false,
    }

  }
  static Tab = TabPane;
  static defaultProps = {
    onItemClick: () => {},
    onPopupVisibleChange: () => {},
    onTabChange: () => {},
    onClear: () => {},
    loading: false,
    locale: {
      emptyText: 'Aún no hay nuevas notificaciones para mostrar',  
    },
  };

  onItemClick = (item, tabProps) => {
    const { onItemClick } = this.props;
    onItemClick(item, tabProps);
  };

  onTabChange = tabType => {
    const { onTabChange } = this.props;
    onTabChange(tabType);
  };
  
  getNotificationBox() {
    const { children, loading, locale, onClear } = this.props;
    if (!children) {
      return null;
    }
    const panes = React.Children.map(children, child => {   
      return (
          <div style={{padding:'10px'}}>
          <List
            style={{color: '#1890ff'}}
            {...child.props}
            data={child.props.list}
            onClick={item => this.onItemClick(item, child.props)}
            onClear={() => onClear(child.props.title)}
            title={child.props.title}
            locale={locale}           
          />
          </div>     
      );
    });
    return (
      <Spin spinning={loading} delay={0}>
        <div style={{ color:"#000000",
                      fontSize:"20px",
                      fontStyle:"normal",
                      fontWeight:"bold",
                      padding:'10px'
                    }}
                      >
                      Notificaciones </div>
        <div >
          {panes}
        </div>
      </Spin>
    );
  }
  
  render() {
    const { className, count, popupAlign, popupVisible, onPopupVisibleChange, bell } = this.props;
    const noticeButtonClass = classNames(className);
    const notificationBox = this.getNotificationBox();
    const NoticeBellIcon = bell || <Button className='styleButton'><Icon type="bell"/></Button>;
    const trigger = (
      <span className={noticeButtonClass}>
        <Badge count={count} style={{ boxShadow: 'none', top:'3px',right:'5px' }} >
          {NoticeBellIcon}
        </Badge>
      </span>
    );
    if (!notificationBox) {
      return trigger;
    }
    const popoverProps = {};
    if ('popupVisible' in this.props) {
      popoverProps.visible = popupVisible;
    }
    return (
      <Popover
        content={notificationBox}
        trigger="click"
        arrowPointAtCenter
        placement="bottom"
        popupAlign={popupAlign}
        onVisibleChange={onPopupVisibleChange}    
        {...popoverProps}    
      >
        {trigger}
      </Popover>
    );
  }
}
