import React from "react";
import { Row, Col, Form, Select, Button } from "antd";
import { withApollo } from "react-apollo"; // graphql
import { compose } from "recompose";
import moment from "moment";
import { withGlobal } from "../../../../component-global";
import {
  SelectWithBorderComponent,
  RangePickerWithBorderCompoment,
  InputComponentWithBorder,
} from "../../../../components/FormFields";
import { ContainerGlobal } from "../../../../components/Form/SearchContainer";

const { Option } = Select;
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const filter = {};
      if (values.date && values.date.length === 2)
        filter.rangeBy = {
          createdAt: {
            lessEqual: moment(values.date[1])
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              })
              .format("YYYY-MM-DD HH:mm:ss ZZ"),
            greaterEqual: moment(values.date[0])
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .format("YYYY-MM-DD HH:mm:ss ZZ"),
          },
        };
      if (values.service) filter.serviceID = parseInt(values.service, 10);
      if (values.city) filter.cityID = parseInt(values.city, 10);
      if (values.branchOffice)
        filter.branchOfficeID = parseInt(values.branchOffice, 10);
      if (values.status) filter.statusID = parseInt(values.status, 10);
      if (values.trackCode) filter.trackCode = values.trackCode;
      if (values.routeCode) filter.routeCode = values.routeCode;
      this.props.searchTrackingPackage(filter);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      serviceOptions,
      cityOptions,
      branchOptions,
      statusOptions,
      loadingBtnSearch,
      loadingData,
    } = this.props;
    const { disabledButtonSearch } = this.state;

    const renderService = (serviceOptions || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    ));
    const renderCity = (cityOptions || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    ));
    const renderBranch = (branchOptions || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.branchOfficeName}
      </Option>
    ));
    const renderStatus = (statusOptions || []).map(obj => (
      <Option key={obj.subtype} value={obj.subtype}>
        {obj.subclass}
      </Option>
    ));

    const anchoCampo = this.props.userEnterpriseData.Enterprise.multiplace
      ? 6
      : 8;
    const anchoCampoLargo = 6;
    return (
      <ContainerGlobal gutter={24}>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col
              xs={24}
              sm={24}
              md={anchoCampoLargo}
              lg={anchoCampoLargo}
              xl={anchoCampoLargo}
            >
              <InputComponentWithBorder
                getFieldDecorator={getFieldDecorator}
                title="TrackCode"
                decodator="trackCode"
                placeholder="Ingresa un TrackCode"
              />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={anchoCampoLargo}
              lg={anchoCampoLargo}
              xl={anchoCampoLargo}
            >
              <InputComponentWithBorder
                getFieldDecorator={getFieldDecorator}
                title="RouteCode"
                decodator="routeCode"
                placeholder="Ingresa un RouteCode"
              />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={anchoCampoLargo}
              lg={anchoCampoLargo}
              xl={anchoCampoLargo}
            >
              <RangePickerWithBorderCompoment
                title="Fecha"
                getFieldDecorator={getFieldDecorator}
                decodator="date"
                placeholder={["Inicio", "Fin "]}
                loading={loadingData}
              />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={anchoCampoLargo}
              lg={anchoCampoLargo}
              xl={anchoCampoLargo}
            >
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="Tipo de servicio"
                decodator="service"
                options={renderService}
                placeholder="Seleccione un tipo"
                loading={loadingData}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            {this.props.userEnterpriseData.Enterprise.multiplace && (
              <Col
                xs={24}
                sm={24}
                md={anchoCampo}
                lg={anchoCampo}
                xl={anchoCampo}
              >
                <SelectWithBorderComponent
                  style={{ width: "100%" }}
                  getFieldDecorator={getFieldDecorator}
                  title="Ciudad"
                  decodator="city"
                  options={renderCity}
                  placeholder="Selecciona una ciudad"
                  loading={loadingData}
                />
              </Col>
            )}

            <Col
              xs={24}
              sm={24}
              md={anchoCampo}
              lg={anchoCampo}
              xl={anchoCampo}
            >
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="Sucursal"
                decodator="branchOffice"
                options={renderBranch}
                placeholder="Selecciona una sucursal"
                loading={loadingData}
              />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={anchoCampo}
              lg={anchoCampo}
              xl={anchoCampo}
            >
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="Estado"
                decodator="status"
                options={renderStatus}
                placeholder="Selecciona un estado"
                loading={loadingData}
              />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={anchoCampo}
              lg={anchoCampo}
              xl={anchoCampo}
            >
              <Button
                type="primary"
                style={{
                  marginTop: "20px",
                  width: "100%",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
                size="large"
                loading={loadingData || loadingBtnSearch}
                htmlType="submit"
                disabled={disabledButtonSearch}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
      </ContainerGlobal>
    );
  }
}

const WraperUserProfile = withGlobal(Header);
const HeaderForm = Form.create()(WraperUserProfile);

export default compose(withApollo)(HeaderForm);
