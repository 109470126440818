import styled from "styled-components";
import { DatePicker, Form, Input, Select } from "antd";
import { brandColor } from "../Utils";

const { Search } = Input;

// /color: ${props => (props.transparent ? "white" : "black")};
const FormItemAddress = styled(Form.Item)`
  ${props => (props.label ? "" : "margin-top: 2rem;")}
  margin-bottom: 1rem;
  margin-top: 0;
  font-size: 14px;
  .autocomplete-dropdown-container {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    /* padding: 1rem; */
    position: absolute;
    top: 4.4rem;
    width: 100%;
    z-index: 5;
  }

  & .ant-select-selection-selected-value {
    color: #59616a;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
  }

  .autocomplete-dropdown-container:empty {
    display: none;
  }

  & .ant-form-item-label {
    padding: 0;
  }
  & .ant-form-item-children {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .ant-input {
    font-size: 1.2rem;
  }
  & label {
    font-size: 1.2rem;
    color: #818e9b;
  }
  & .ant-input-prefix {
    left: 4px;
  }
  & .ant-form-explain {
    display: block;
  }
  & .ant-calendar-picker {
    width: 100%;
  }
  & .ant-select-selection {
    ${props =>
      props.border
        ? "border: 1px solid #d3d8dd; border-radius: 1rem"
        : "border: none; border-bottom: 1px solid #d3d8dd; border-radius: 0;"};

    padding: 0;
    box-shadow: none !important;
    svg {
      color: #59616a;
    }
  }
  & .ant-select-selection__rendered {
    margin: 0;
    &:hover {
      ${props =>
        props.border
          ? "border: 1px solid #307fe2; border-radius: 1rem; margin-top:-1px; margin-left:-1px;"
          : "border:none;border-bottom: 1px solid #307fe2; padding-bottom: 1px;"};
    }
  }

  & .ant-input {
    height: 40px;
    box-shadow: none !important;
    font-size: 1.6rem;
    font-weight: 500;
    border: ${props => (props.border ? "1px solid #d3d8dd" : "none")};
    border-bottom: 1px solid #d3d8dd;
    /* border-radius: 0;
    padding: 0; */
    background: transparent;
    color: ${props => (props.transparent ? "white" : "#59616A")};
  }

  svg {
    color: ${props => (props.transparent ? "white" : "#818E9B")};
  }
  & .has-error {
    & .ant-checkbox-inner {
      border-color: #f5222d !important;
    }
  }
  & .transparent {
    background: transparent;
    color: white;
  }
  & .has-error .ant-select-selection,
  .has-error .ant-input:hover {
    ${props =>
      props.border
        ? "border: 1px solid #f5222d !important;"
        : "border-bottom: 1px solid #f5222d !important;"};
  }
  & .has-error .ant-input,
  .has-error .ant-input:hover {
    border-bottom: 1px solid #f5222d !important;
  }
`;
const FormItemInvisible = styled(Form.Item)`
  ${props => (props.label ? "" : "margin-top: 2rem;")}
  margin-bottom: 1rem;
  .autocomplete-dropdown-container {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    padding: 1rem;
    position: absolute;
    top: 3rem;
    width: 100%;
    z-index: 5;
  }

  & .ant-select-selection-selected-value {
    color: #59616a;
    font-weight: 500;
    font-size: 1.6rem;
    width: 100%;
  }

  .autocomplete-dropdown-container:empty {
    display: none;
  }

  & .ant-form-item-label {
    padding: 0;
  }
  & .ant-form-item-children {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  label {
    font-size: 1.2rem;
    color: #818e9b;
  }
  & .ant-input-prefix {
    left: 4px;
  }
  & .ant-form-explain {
    display: none;
  }
  & .ant-calendar-picker {
    width: 100%;
  }
  & .ant-select-selection {
    ${props =>
      props.border
        ? "border: 1px solid #d3d8dd; border-radius: 1rem"
        : "border: none; border-bottom: 1px solid #d3d8dd; border-radius: 0;"};

    padding: 0;
    box-shadow: none !important;
    svg {
      color: #59616a;
    }
  }
  & .ant-select-selection__rendered {
    margin: 0;
    &:hover {
      ${props =>
        props.border
          ? "border: 1px solid #40a9ff; border-radius: 1rem; margin-top:-1px; margin-left:-1px;"
          : "border:none;border-bottom: 1px solid #40a9ff; padding-bottom: 1px;"};
    }
  }

  & .ant-input {
    box-shadow: none !important;
    font-size: 1.6rem;
    font-weight: 500;
    border: ${props => (props.border ? "1px solid #d3d8dd" : "none")};
    border-bottom: 1px solid #d3d8dd;
    border-radius: 0;
    padding: 0;
    background: transparent;
    color: ${props => (props.transparent ? "white" : "#59616A")};
  }

  svg {
    color: ${props => (props.transparent ? "white" : "#818E9B")};
  }
  & .has-error {
    & .ant-checkbox-inner {
      border-color: #f5222d !important;
    }
  }
  & .transparent {
    background: transparent;
    color: white;
  }
  & .has-error .ant-select-selection,
  .has-error .ant-input:hover {
    ${props =>
      props.border
        ? "border: 1px solid #f5222d !important;"
        : "border-bottom: 1px solid #f5222d !important;"};
  }
  & .has-error .ant-input,
  .has-error .ant-input:hover {
    border-bottom: 1px solid #f5222d !important;
  }
`;

const CustomFormItem = styled(Form.Item)`
  ${props => (props.label ? "" : "margin-top: 2rem;")}
  margin-bottom: 1rem;
  .autocomplete-dropdown-container {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    padding: 1rem;
    position: absolute;
    top: 3.5rem;
    width: 100%;
    z-index: 5;
  }

  & .ant-select-selection-selected-value {
    color: #59616a;
    width: 100%;
  }

  .autocomplete-dropdown-container:empty {
    display: none;
  }

  & .ant-form-item-label {
    padding: 0;
  }
  & .ant-form-item-children {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  label {
    font-size: 1.2rem;
    /* color: #818e9b; */
  }
  & .ant-form-explain {
    display: none;
  }
  & .ant-calendar-picker {
    width: 100%;
  }
  
  & .ant-select-focused .ant-select-selection  {
    box-shadow: none;
  }

  & .has-error {
    & label {
      border-color: #f5222d !important;
    }
    & .ant-select-selection {
      border-color: #f5222d !important;
    }
  }



  & .ant-input {
    box-shadow: none;
    background: transparent;
    ${props =>
      props.secondary
        ? "border-top:none; border-left:none; border-right:none;border-radius: 0;"
        : ""}    
    color: ${props => (props.transparent ? "white" : "#59616A")};
    &:hover,
    :focus
    {
      box-shadow: none;
    }
    
  }
  & .has-error{
    & .ant-radio-group{
      & .ant-radio-inner{
        border-color: #f5222d;
      }
    }
  }
  & .transparent {
    background: transparent;
    color: white;
  }
`;

const CustomFormItemInputWithBorder = styled(Form.Item)`
  .autocomplete-dropdown-container {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    padding: 1rem;
    position: absolute;
    top: 3rem;
    width: 100%;
    z-index: 5;
    &:hover {
      border: 1px solid black;
    }
  }

  & .ant-form-explain {
    font-size: 12px;
  }

  & .ant-form-item-required::before {
    display: none;

    ::before {
      display: none;
    }
  }

  & .ant-select-selection-selected-value {
    color: #59616a;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
  }

  .autocomplete-dropdown-container:empty {
    display: none;
  }
  & .ant-form-item-label {
    padding: 0px;
    & label {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
    }
  }

  & .ant-input-group-addon {
    border-top-right-radius: ${props =>
      props.isnoborderradius === "true" ? "0px" : "4px"};
    border-bottom-right-radius: ${props =>
      props.isnoborderradius === "true" ? "0px" : "4px"};
    background: ${props => (props.isgraycolor ? "#F6F8FB" : "#ffffff")};
  }
  & .ant-input-number {
    border-radius: 4px;

    & span {
      border-bottom-right-radius: 5px;
    }
  }

  margin-bottom: 0px;
  & .ant-input {
    height: 40px;
  }

  & .ant-calendar-range-picker-input {
    height: 30px;
  }
  & input {
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #595959;
    height: 40px;
    ${props => (props.disabled
      ? "pointer-events:none;background: #f5f5f5;"
      : ""
    )};
  }

  & button {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  /* .ant-input-affix-wrapper .ant-input {
    padding-left: 40px;
  } */

  .ant-select-selection__rendered {
    text-align: center;
    display: flex;
    align-items: center;
  }

  & .ant-calendar-picker {
    width: 100%;
  }
`;

const FormItemInputWithBorder = styled(Form.Item)`
  & .ant-form-item-label {
    padding: 0px;
    & label {
      
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }

  & .ant-form-item-required::before {
    display: none;

    ::before {
      display: none;
    }
  }

  & .ant-select-selection__placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  & .ant-form-explain {
    font-size: 12px;
  }

  & .ant-select-selection-selected-value {
    font-weight: 500;
    font-size: 14px;
    color: #595959;
  }
  margin-bottom: 0px;
  & input {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #595959;
    border-radius: 10px;
    height: 40px;
  }
  & .ant-select-selection {
    border-radius: 4px;
  }
`;

const CustomFormItemInputSelect = styled(Form.Item)`
  & .ant-form-item-label {
    /* padding: 0px; */
    padding-bottom: 14px;
    & label {
      
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #595959;
    }
  }

  & .has-error {
    & .ant-checkbox-inner {
      border-color: #f5222d !important;
    }
  }

  /* & .ant-form label {
    font-size: 18px;
    display: block;
  } */
  & .ant-checkbox-wrapper {
    display: ${props => (props.isVertical ? "block" : "inline-block")};
    /* margin-right: 8px; */
  }

  & .ant-form-explain {
    display: none;
    font-size: 12px;
  }

  & .ant-form-item-required::before {
    display: none;
  }

  /* & .ant-select-selection__placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  & .ant-select-selection-selected-value {
    font-weight: 500;
    font-size: 14px;
    color: #595959;
  }
  margin-bottom: 0px; */
`;

const CustomFormItemTitle = styled(CustomFormItem)`
  display: flex;
  & .ant-select-selection {
    border-radius: 1.2rem;
    border-color: #c6d0eb;
    & .ant-select-selection__placeholder {
      color: #c6d0eb;
      text-align: center;
    }

    & .ant-select-selection-selected-value {
      color: #c6d0eb;
      text-align: center;
    }
    & .ant-select-search {
      color: #c6d0eb;
    }
  }
  & .ant-form-item-label {
    line-height: 30.9999px;
    text-align: left;
    & label {
      color: #109cf1;
      font-weight: 500;
      text-align: left;
      font-size: 1.8rem;
    }
    & label::after {
      content: "";
    }
  }
`;

const CustomFormItemBlueText = styled(CustomFormItem)`
  display: flex;
  & .ant-select-selection {
    border-radius: 1.2rem;
    border-color: #c6d0eb;
    & .ant-select-selection__placeholder {
      color: #c6d0eb;
      text-align: center;
    }

    & .ant-select-selection-selected-value {
      color: #c6d0eb;
      text-align: center;
    }
    & .ant-select-search {
      color: #c6d0eb;
    }
  }
  & .ant-form-item-label {
    line-height: 30.9999px;
    text-align: left;
    & label {
      color: #109cf1;
      font-weight: 500;
      text-align: left;
      font-size: 1.4rem;
    }
    & label::after {
      content: "";
    }
  }
`;

const CustomFormItemStatus = styled(CustomFormItem)`
  & .ant-radio-wrapper {
    margin-bottom: 6px;

    & span {
      font-size: 14px;
    }
  }
`;

const CustomFormItemTimer = styled(CustomFormItem)`
  /* display: flex; */
  & .ant-form-item-label {
    /* line-height: 28.9999px; */
    text-align: left;

    & label {
      color: #595959;
      font-weight: 500;
      /* text-align: left; */
      /* display: block; */
      /* font-size: 1.4rem; */
    }
  }
  & .ant-form-item-required::before {
    display: none;

    ::before {
      display: none;
    }
  }
  & .ant-form-explain {
    display: block;
    font-size: 12px;
  }
  & .ant-form-item-children {
    justify-content: right;

    & .ant-time-picker {
      margin-right: 0px;
    }
  }
  & .has-error {
    & .ant-time-picker-input {
      border-color: #f5222d !important;
    }
  }
`;

const CustomFormItemSwitch = styled(CustomFormItem)`
  /* display: flex; */
  /* & .ant-switch-checked {
    background-color: #44d62c;
  } */
  & .ant-form-item-required::before {
    display: none;

    ::before {
      display: none;
    }
  }

  & .ant-form-item-label {
    /* margin-bottom: 8px; */
    line-height: 25px;
    text-align: left;
  }
  & .ant-form-item-children {
    justify-content: left;
    height: 40px;
    align-items: center;

    & .ant-time-picker {
      margin-right: 3.9rem;

      & input {
        border-radius: 1.2rem;
        border-color: #c6d0eb;
        color: #c6d0eb;
      }
    }
  }
  & .has-error {
    & .ant-time-picker-input {
      border-color: #f5222d !important;
    }
  }
`;

const CustomFormItemCalendarFilter = styled(CustomFormItem)`
  display: flex;

  & .ant-form-item-label {
    line-height: 28.9999px;
    text-align: left;
  }

  & .ant-form-item-children {
    & .ant-calendar-picker {
      & input {
        font-size: 1rem;
        padding: 0 15px;
        border: 1px solid #c6d0eb;
        border-radius: 1.2rem;
        color: #c6d0eb;
      }

      & i {
        color: #c6d0eb;
      }
    }
  }
`;

const CustomFormItemSelectFilter = styled(CustomFormItem)`
  display: flex;
  margin-top: 0rem;
  & .has-error {
    & .ant-select-selection {
      border-color: #f5222d !important;
    }
  }

  & .ant-form-item-label {
    line-height: 28.9999px;
    text-align: left;
  }
  & .ant-select {
    
    .ant-select-selection__rendered::after {
      height: 48px;
    }
    .ant-select-selection {
      height: 48px;
      &:hover {
        border: 1px solid #1890ff;
      }
      font-size: 14px;
      font-weight: 500;
      border: 1px solid #d9d9d9;
      border-radius: 4px !important;
      & .ant-select-selection__placeholder {
        color: #595959;
      }

      & .ant-select-selection-selected-value {
        color: #595959;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 0;
      }
      & .ant-select-search {
        color: #595959;
      }
    }
  }
`;

const CustomFormItemSelectWithoutBorder = styled(CustomFormItem)`
  display: flex;
  margin-top: 0rem;
  margin-bottom: 0rem;

  & .has-error {
    & .ant-select-selection {
      border-color: #f5222d !important;
    }
  }

  & .ant-form-item-label {
    line-height: 28.9999px;
    text-align: left;
  }
  & .ant-select {
    .ant-select-selection {
      &:hover {
        border: none;
      }
      font-size: 1.2 rem;
      border: none;
      & .ant-select-selection__placeholder {
        color: #707683;
        
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }

      & .ant-select-selection-selected-value {
        color: #707683;
        font-weight: 500;
        text-align: center;
      }
      & .ant-select-search {
        color: #707683;
      }
    }
  }
`;

const CustomFormItemInputBlack = styled(CustomFormItem)`
  & .ant-form-item-label {
    line-height: 28.9999px;
    text-align: left;
    color: #707683;
    font-size: 1.2rem;
    font-weight: 500;
    
  }
`;

const CustomFormItemWithoutLabelInputBlack = styled(CustomFormItem)`
  margin-top: 0rem;
  margin-bottom: 0rem;
  & .ant-input[disabled] {
    background: #f7f7f8 !important;
    color: #c9c9c9 !important;
  }
`;

const CustomFormItemSearchFilter = styled(CustomFormItem)`
  flex-wrap: wrap;
  display: flex;
  margin-top: 0rem;
  & .ant-form-item-label {
    line-height: 28.9999px;
    text-align: left;
    & label {
      color: #109cf1;
      font-weight: 500;
    }
  }

  & .ant-select-selection__placeholder {
    color: #c6d0eb !important;
  }
  & input {
    font-size: 1.2rem;
    padding: 0 12px;
    border: 1px solid #c6d0eb;
    border-radius: 1.2rem !important;
    color: #c6d0eb !important;
  }

  & i {
    color: #c6d0eb;
  }
`;

const CustomForm = styled(Form)`
  & ${FormItemInvisible} {
    & .ant-input {
      border-bottom: ${props =>
        props.updating === "true"
          ? "1px solid transparent"
          : "1px solid #d3d8dd"};
      &:active,
      &:hover,
      &:focus {
        border-bottom: ${props =>
          props.updating === "true"
            ? "1px solid transparent !important"
            : `1px solid ${brandColor} !important`};
      }
    }
    & .ant-select-selection {
      width: 100%;
      border-bottom: ${props =>
        props.updating
          ? "1px solid transparent !important"
          : "1px solid #d3d8dd"};
      cursor: ${props => (props.updating ? "auto" : "pointer")};
      background-color: transparent;
      &:active,
      &:hover,
      &:focus {
      }
      & .ant-select-arrow {
        display: ${props => (props.updating ? "none" : "inline-block")};
      }
    }
    & .ant-calendar-picker-input {
      background-color: transparent;
      color: #59616a;
      cursor: ${props => (props.updating ? "auto" : "pointer")};
    }
    & .ant-calendar-picker-icon {
      display: ${props => (props.updating ? "none" : "inline-block")};
    }
  }

  & .section-title {
    margin-top: 2rem;
  }
  & .ant-upload-select-picture-card {
    margin: 0;
    border-radius: 50%;
  }
  & .photo-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .ant-btn {
      margin-bottom: 2rem;
    }
    & .ant-form-item {
      width: 100%;
    }
  }
  & .section-title {
    font-size: 1.8rem;
  }
`;

const InvisibleInput = styled(Input)``;

const InvisibleSelect = styled(Select)`
  & .ant-select-selection {
    border: none;
    box-shadow: none !important;
    border-radius: 0;
    padding: 0;
  }
  & .ant-select-selection__rendered {
    border-bottom: 1px solid #d3d8dd;
  }
`;

const InvisibleDatePicker = styled(DatePicker)`
  & .ant-input {
    border: none;
    box-shadow: none !important;
    text-align: right;
  }
  i {
    display: none;
  }
`;

const InputTariffBlack = styled(Input)`
  background: #eeeeef !important;
  border-radius: 10px;
  height: 30px;
`;

const InputSearch = styled(Search)`
  background: #ffffff !important;
  border-radius: 4px;
  height: 40px;
  
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
  min-height: 4rem;
  & .label {
    font-size: 1.6rem;
    padding: 4px 0px;
  }
  & .info {
    min-width: 150px;
    text-align: right;
    & .ant-select-selection-selected-value {
      float: right;
    }
    & .ant-select-selection__placeholder {
      text-align: right;
      padding-right: 1rem;
    }
  }
  & .ant-form-explain {
    display: none;
  }
  & .ant-form-item {
    margin-bottom: 0;
  }
  & .ant-input {
    border: none;
    border-radius: 0;
  }
  & .has-error .ant-input,
  .has-error .ant-input:hover {
    border-bottom: 1px solid #f5222d !important;
  }
`;

const InvisibleDatePickerLine = styled(CustomFormItem)`
  & .ant-input {
    box-shadow: none;
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    &:hover,
    :focus {
      box-shadow: none;
    }
  }
`;

const CustomFormItemTextArea = styled(CustomFormItem)`
  display: flex;

  & .ant-form-item-control-wrapper {
    width: 100% !important;
  }
  & .ant-form-item-label {
    line-height: 28.9999px;
    text-align: left;
  }
  & .ant-select {
    .ant-select-selection {
      &:hover {
        border: 1px solid #1890ff;
      }
      font-size: 1.2 rem;
      border: 1px solid #c6d0eb;
      border-radius: 1.2rem !important;
      & .ant-select-selection__placeholder {
        color: #c6d0eb;
        text-align: center;
      }

      & .ant-select-selection-selected-value {
        color: #c6d0eb;
        text-align: center;
      }
      & .ant-select-search {
        color: #c6d0eb;
      }
    }
  }
`;

export {
  InfoRow,
  InvisibleSelect,
  InvisibleDatePicker,
  InvisibleInput,
  InvisibleDatePickerLine,
  FormItemInvisible,
  FormItemAddress,
  CustomFormItem,
  CustomFormItemTitle,
  CustomFormItemBlueText,
  CustomFormItemTimer,
  CustomFormItemStatus,
  CustomFormItemCalendarFilter,
  CustomFormItemSelectFilter,
  CustomFormItemSearchFilter,
  CustomFormItemSelectWithoutBorder,
  CustomFormItemInputBlack,
  CustomFormItemInputWithBorder,
  CustomFormItemInputSelect,
  FormItemInputWithBorder,
  CustomFormItemWithoutLabelInputBlack,
  InputTariffBlack,
  CustomForm,
  CustomFormItemSwitch,
  CustomFormItemTextArea,
  InputSearch,
};

/*
const CustomSearchFormItem = styled(Form.Item)`
  margin-top: 1rem;
  margin-bottom: 1rem;

  .autocomplete-dropdown-container {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    padding: 1rem;
    position: absolute;
    top: 3.5rem;
    width: 100%;
    z-index: 5;
  }

  & .ant-select-selection-selected-value {
    color: #59616a;
    font-weight: 500;
    font-size: 1.6rem;
    width: 100%;
  }

  .autocomplete-dropdown-container:empty {
    display: none;
  }

  & .ant-form-item-label {
    padding: 0;
  }
  & .ant-form-item-children {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  label {
    font-size: 1.2rem;
    color: #818e9b;
  }
  & .ant-form-explain {
    display: none;
  }
  & .ant-calendar-picker {
    width: 100%;
  }

  & .ant-select-focused .ant-select-selection  {
    box-shadow: none;
  }

  & .ant-input {
    box-shadow: none;
    background: transparent;
    ${props =>
      props.secondary
        ? "border-top:none; border-left:none; border-right:none;border-radius: 0;"
        : ""}
    color: ${props => (props.transparent ? "white" : "#59616A")};
    &:hover,
    :focus
    {
      box-shadow: none;
    }

  }
  & .has-error{
    & .ant-radio-group{
      & .ant-radio-inner{
        border-color: #f5222d;
      }
    }
  }
  & .transparent {
    background: transparent;
    color: white;
  }
`; */
