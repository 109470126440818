import styled from "styled-components";
import { Steps, Badge } from "antd";

const { Step } = Steps;
const ContainerDeliveryStyle = styled.div`
  width: 100%;
  margin: 0 0 8px;
  cursor: pointer;

  ${props =>
    props.located
      ? `
      background: #ffffff;
      & .principal {
        padding: 16px 16px 0 16px;
        border: 1px solid #dddddd;
        box-sizing: border-box;
        border-radius: 4px;
        -webkit-box-shadow: 1px 1px 1px 1px #dddddd;
        -moz-box-shadow: 1px 1px 1px 1px #dddddd;
        box-shadow: 1px 1px 1px 1px #dddddd;
        width: 100%;
        height: 64px;
      }

      & .second {
        padding: 16px 16px 0 16px;
        border: 1px solid #dddddd;
        box-sizing: border-box;
        border-radius: 4px;
        -webkit-box-shadow: 1px 1px 1px 1px #dddddd;
        -moz-box-shadow: 1px 1px 1px 1px #dddddd;
        box-shadow: 1px 1px 1px 1px #dddddd;
        width: 100%;
        height: 108px;
      }`
      : `
      & .principal {
        background: #FFF1F0;
        padding: 16px 16px 0 16px;
        border: 1px solid #FFF1F0;
        box-sizing: border-box;
        border-radius: 4px;
        -webkit-box-shadow: 1px 1px 1px 1px #dddddd;
        -moz-box-shadow: 1px 1px 1px 1px #dddddd;
        box-shadow: 1px 1px 1px 1px #dddddd;
        width: 100%;
        height: 64px;
      }

      & .second {
        background: #ffe3e0;
        padding: 16px 16px 0 16px;
        border: 1px solid #dddddd;
        box-sizing: border-box;
        border-radius: 4px;
        -webkit-box-shadow: 1px 1px 1px 1px #dddddd;
        -moz-box-shadow: 1px 1px 1px 1px #dddddd;
        box-shadow: 1px 1px 1px 1px #dddddd;
        width: 100%;
        height: 108px;
      }
      `}

      ${props =>
        props.disable
          ? `
          & .principal {
            background: #FAFAFA;
            padding: 16px 16px 0 16px;
            border: 1px solid #dddddd;
            box-sizing: border-box;
            border-radius: 4px;
            -webkit-box-shadow: 1px 1px 1px 1px #FAFAFA;
            -moz-box-shadow: 1px 1px 1px 1px #FAFAFA;
            box-shadow: 1px 1px 1px 1px #FAFAFA;
            width: 100%;
            height: 64px;
          }
    
          & .second {
            background: #F5F5F5;
            padding: 16px 16px 0 16px;
            border: 1px solid #F5F5F5;
            box-sizing: border-box;
            border-radius: 4px;
            -webkit-box-shadow: 1px 1px 1px 1px #F5F5F5;
            -moz-box-shadow: 1px 1px 1px 1px #F5F5F5;
            box-shadow: 1px 1px 1px 1px #F5F5F5;
            width: 100%;
            height: 108px;
          }`
          : `
          `}
    

  


  ${props =>
    props.activeDescription && props.located && !props.disable
      ? `
      border: 1px solid #dddddd;
      box-sizing: border-box;
      border-radius: 4px;
      -webkit-box-shadow: 1px 1px 1px 1px #dddddd;
      -moz-box-shadow: 1px 1px 1px 1px #dddddd;
      box-shadow: 1px 1px 1px 1px #dddddd;
      & .principal {
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        
      }
    
      & .second {
        border: none;
        background: #FAFAFA;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
       
      }
        `
      : props.activeDescription && props.disable
      ? `
        border: none;
        box-sizing: border-box;
        border-radius: 4px;
        -webkit-box-shadow: 1px 1px 1px 1px #F5F5F5;
        -moz-box-shadow: 1px 1px 1px 1px #F5F5F5;
        box-shadow: 1px 1px 1px 1px #F5F5F5;
        & .principal {
          border: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          
        }
      
        & .second {
          border: none;
          background: #F5F5F5;

          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
       
      }
      `
      : props.activeDescription
      ? `
        border: none;
        box-sizing: border-box;
        border-radius: 4px;
        -webkit-box-shadow: 1px 1px 1px 1px #dddddd;
        -moz-box-shadow: 1px 1px 1px 1px #dddddd;
        box-shadow: 1px 1px 1px 1px #dddddd;
        & .principal {
          border: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          
        }
      
        & .second {
          border: none;
          background: #ffe3e0;

          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
       
      }`
      : ""}

  ${props =>
    props.active
      ? `
      border: #109cf1 1px solid !important;
      box-sizing: border-box;
      border-radius: 4px;
      & .principal {
        
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        
      }
    
      & .second {
       
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
       
      }
        `
      : ""}

  ${props =>
    props.disable
      ? `
        background: #eeeeef;
        `
      : `:hover {
        border-color: #109cf1 !important;
      }`} 
`;

const HeaderDelivery = styled.div`
  display: flex;
  justify-content: space-between;
  & .contact-name {
    
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 15px;

    color: #334d6e;
  }

  & .close {
    font-size: 1.5rem;
  }
`;

const ContainerLabelText = styled.div`
  padding-bottom: 16px;
  & .title {
    text-align: left;
    
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    margin: 0px;
    color: #8c8c8c;
  }

  & .text {
    
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 0px;
    color: #595959;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & button {
    
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #307fe2;
  }

  ${props =>
    props.disable
      ? `
      & .title {
        color: #BFBFBF;
      }
      & .text {
        color: #BFBFBF;
      }
      `
      : ``}
`;

const StepCustom = styled(Step)`
  ${props =>
    props.fail === "fail"
      ? `
      & .ant-steps-icon-dot {
        background: #F7685B !important;
      }
  
    `
      : `& .ant-steps-icon-dot {
        background: #44D62C !important;
      }`}
`;

const BadgeCustom = styled(Badge)`
  & .ant-badge-status-text {
    font-size: 10px;
  }
`;

const ContainerCards = styled.div`
  overflow-y: auto;
  height: calc(100vh - 440px);
  max-height: calc(100vh - 440px);
  padding: 0 8px 0 0;
`;

const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 440px);
  padding: 0 8px 0 0;
`;
const ContainerBtnFilter = styled.div`
  height: 15%;

  & .ant-form-item {
    margin-bottom: 0rem;
  }
  @media only screen and (max-width: 1400px) {
    height: 20%;
  }
`;

const ContainerInfoCard = styled.div`
  height: 100%;
  width: 100%;
  ${props =>
    props.located
      ? `
      & .container-form {
        border: 0.5px solid #c6d0eb;
        border-radius: 10px;
        padding: 2rem 3rem;
        height: 20%;
        overflow-y: auto;
      }

      & .container-map {
        height: 60%;
        width: 100%;
      }
    
        `
      : `
      & .container-form {
        border: 0.5px solid #c6d0eb;
        border-radius: 10px;
        padding: 2rem 3rem;
        height: 30%;
        overflow-y: auto;
      }
      & .container-map {
        height: 50%;
        width: 100%;
      }
    
        `}

  & .container-history {
    display: flex;
    height: 20%;
  }
`;

const TitleDrawer = styled.div`
  & .title-drawer {
    
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    color: #595959;
  }

  & .descp-drawer {
    
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    margin-bottom: 24px;
  }
`;

export {
  ContainerDeliveryStyle,
  HeaderDelivery,
  StepCustom,
  BadgeCustom,
  ContainerCards,
  ContainerBtnFilter,
  ContainerInfoCard,
  ContainerLabelText,
  TitleDrawer,
  ContainerLoading,
};
