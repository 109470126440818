import React from "react";
import { Select } from "antd";
import { FormItemInvisible } from "../Form";

class SelectComponent extends React.Component {
  render() {
    const {
      getFieldDecorator,
      title,
      decodator,
      options,
      value,
      disabled,
      mode,
      required,
      loading,
      onChange,
      placeholder,
    } = this.props;

    return (
      <div style={{ marginBottom: 20 }}>
        <FormItemInvisible
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: undefined || value,
            rules: [
              {
                required,
                message: "Por favor, ingrese el nombre comercial",
              },
            ],
          })(
            <Select
              mode={mode}
              showSearch
              disabled={disabled}
              loading={loading}
              onChange={onChange}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={placeholder}
            >
              {options}
            </Select>
          )}
        </FormItemInvisible>
      </div>
    );
  }
}

export default SelectComponent;
