import styled from "styled-components";
import { Collapse, Upload } from "antd";

const DrawerContainer = styled.div`
  width: 100%;
  padding: 15px 15px 50px 15px;
`;

const CustomCollapse = styled(Collapse)`
  box-shadow: none;
  background-color: white;
  & .ant-collapse-header {
    font-weight: bold;
    font-size: 15px;
  }
  & .ant-collapse-content {
    border-top: none;
  }
`;

const ContainerBotton = styled.div`
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 700px;
  height: 70px;
  padding: 10px;
  background-color: white;
  text-align: center;
`;

const CustomAccordion = styled.div`
  && span {
    font-size: 14px;
    font-style: bold;
    color: #307fe2;
  }
`;

const { Dragger } = Upload;
const ContainerDragger = styled(Dragger)`
  background: #ffffff !important;
`;

const TitleModal = styled.div`
  & .title-modal {
    
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
    color: #595959;
  }

  & .descp-modal {
    
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    margin-bottom: 12px;
  }
`;

export {
  DrawerContainer,
  CustomCollapse,
  ContainerBotton,
  CustomAccordion,
  ContainerDragger,
  TitleModal
};