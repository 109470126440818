import styled from "styled-components";
import { Carousel, Collapse } from "antd";

const { Panel } = Collapse;

export const Regular = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const Resalted = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #595959;
`;

export const StyledPanel = styled(Panel)`
  margin-top: 16px;
  background-color: white;
  padding: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border: 0;
  overflow: hidden;

  & .ant-collapse-extra {
    margin-top: 8px;
    color: #1890ff;
    font-weight: bold;
  }

  & .ant-collapse-arrow {
    color: #1890ff !important;
  }
`;

export const StyledCarousel = styled(Carousel)`
  .slick-next,
  .slick-prev {
    width: 30px;
    height: 30px;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #1890ff;
    font-size: 30px;
  }

  .slick-next:before {
    content: ">";
  }

  .slick-prev:before {
    content: "<";
  }
`;

export const RouteMapLegend = {
  Container: styled.div`
    position: absolute;
    z-index: 10;
    background-color: white;
    bottom: 5px;
    left: 16px;
    width: 250px;
    height: 185px;
    padding: 8px 12px 12px 12px;
  `,
  Title: styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    margin-top: 4px;
    color: black;
  `,
  Description: styled.div`
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  Marker: {
    Fill: styled.div`
      width: 12px;
      height: 12px;
      margin-right: 8px;
      border-radius: 6px;
      background-color: ${({ color }) => color || "#307fe2"};
    `,
    Outline: styled.div`
      width: 12px;
      height: 12px;
      margin-right: 8px;
      border-radius: 6px;
      border: 2px solid ${({ color }) => color || "#307fe2"};
    `,
  },
};
