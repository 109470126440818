import { Spin } from "antd";
import React from "react";

export default props => (
  <div
    style={{
      minHeight: props.height || 500,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Spin tip="Cargando Información" />
  </div>
);
