import React, { FC, useCallback, useState } from "react";
import { Select } from "antd";
import { Bar } from "react-chartjs-2";

import { ChartContainer } from "./styles";
import {
  ScaleY,
  DataElement,
  WheelCallback,
  GetOptionsForSelectFromList,
  FilterData,
  DataElementID,
  SelectOptions,
} from "types";
import { addScrollbar, defaultColors } from "utils";

const { Option } = Select;
const defaultVisibleBars = 5;

const getOptionsForSelectFromList: GetOptionsForSelectFromList = list =>
  list.map(element => (
    <Option key={element.id} value={element.id}>
      {element.name}
    </Option>
  ));

const filterData: FilterData = (selectedIDs, data) =>
  selectedIDs.length > 0
    ? data.filter(element => selectedIDs.includes(element.id))
    : data;

const preventScroll = (event: WheelEvent) => {
  event.preventDefault();
  event.stopPropagation();

  return false;
};

const stopDocumentWheelEvent = () =>
  document.addEventListener("wheel", preventScroll, { passive: false });

const continueDocumentWheelEvent = () =>
  document.removeEventListener("wheel", preventScroll);

interface HorizontalBarChartProps {
  visibleBars?: number;
  colors?: string[];
  data: DataElement[];
  width: number;
  title: string;
  placeholder: string;
}

const HorizontalBarChart: FC<HorizontalBarChartProps> = props => {
  const {
    visibleBars = defaultVisibleBars,
    colors = defaultColors,
    data,
    width,
    title,
    placeholder,
  } = props;
  const [scaleY, setScaleY] = useState<ScaleY>({
    min: 0,
    max: visibleBars - 1,
  });
  const [selectedIDs, setSelectedIDs] = useState<DataElementID[]>([]);
  const filteredData = filterData(selectedIDs, data);

  const selectOptions: SelectOptions = values => {
    setSelectedIDs(values);
    if (filterData.length <= visibleBars && scaleY.min > 0)
      setScaleY({
        min: 0,
        max: visibleBars - 1,
      });
  };

  const wheelCallback = useCallback<WheelCallback>(
    event => {
      if (filteredData.length <= visibleBars) return;
      const { deltaY } = event;

      setScaleY(scaleY => {
        if (deltaY > 0) {
          if (scaleY.max >= filteredData.length - 1)
            return {
              min: filteredData.length - visibleBars,
              max: filteredData.length - 1,
            };
          else
            return {
              min: scaleY.min + 1,
              max: scaleY.max + 1,
            };
        } else {
          if (scaleY.min <= 0)
            return {
              min: 0,
              max: visibleBars - 1,
            };
          else
            return {
              min: scaleY.min - 1,
              max: scaleY.max - 1,
            };
        }
      });
    },
    [filteredData.length, visibleBars]
  );

  return (
    <ChartContainer width={width}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 32,
        }}
      >
        <span style={{ fontSize: 14, color: "#595959" }}>{title}</span>
        <Select
          allowClear
          showSearch
          mode="multiple"
          maxTagCount={1}
          maxTagTextLength={2}
          maxTagPlaceholder={`+${selectedIDs.length - 1}`}
          style={{ flexGrow: 1, maxWidth: 500 }}
          onChange={selectOptions}
          placeholder={placeholder}
          filterOption={(input, option) => {
            const aux =
              option.props.children && typeof option.props.children === "string"
                ? option.props.children.toLowerCase()
                : "";

            return aux.indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {getOptionsForSelectFromList(data)}
        </Select>
      </div>
      <div
        style={{
          marginTop: 16,
          height: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Bar
          onMouseEnter={stopDocumentWheelEvent}
          onMouseLeave={continueDocumentWheelEvent}
          onWheel={wheelCallback}
          plugins={[addScrollbar(visibleBars)]}
          options={{
            scales: {
              y: {
                min: scaleY.min,
                max: scaleY.max,
                ticks: {
                  font: {
                    size: 14,
                  },
                },
              },
            },
            indexAxis: "y",
            responsive: true,
            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                display: false,
              },
            },
          }}
          data={{
            labels: filteredData.map(e => e.name),
            datasets: [
              {
                data: filteredData.map(e => e.quantity),
                backgroundColor: filteredData.map(
                  (e, index) => colors[index % colors.length]
                ),
              },
            ],
          }}
        />
      </div>
    </ChartContainer>
  );
};

export default HorizontalBarChart;
