import styled from "styled-components";
import {Icon } from "antd";

export const CustomIcon = styled(Icon)`
  font-size: ${props => (props.size ? props.size : 16)}px;
  margin-right: 0 !important;
  margin-left: 6px;
  &:hover{
    color:#1890FF;
    background:#E6F7FF;
    border-radius: 50%;
  }
`;

