export const getCurrencyFormatter = currency =>
  new Intl.NumberFormat("es-CL", {
    currency,
    style: "currency",
    currencyDisplay: "symbol",
  });

export const statusesColors = {
  // group 1
  167: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  154: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  158: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  165: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  182: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  197: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  216: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  218: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  221: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  157: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  198: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  199: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  160: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  156: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  155: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  170: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  162: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  164: {
    border: "#40A9FF",
    background: "#BAE7FF",
    textColor: "#40A9FF",
  },
  // group 2
  168: {
    border: "#f5222d",
    background: "#F0F0F0",
    textColor: "#f5222d",
  },
  171: {
    border: "#f5222d",
    background: "#F0F0F0",
    textColor: "#f5222d",
  },
  161: {
    border: "#f5222d",
    background: "#F0F0F0",
    textColor: "#f5222d",
  },
  163: {
    border: "#f5222d",
    background: "#F0F0F0",
    textColor: "#f5222d",
  },
  159: {
    border: "#f5222d",
    background: "#F0F0F0",
    textColor: "#f5222d",
  },
  169: {
    border: "#f5222d",
    background: "#F0F0F0",
    textColor: "#f5222d",
  },
  // group 3
  166: {
    border: "#44D62C",
    background: "#F0F0F0",
    textColor: "#595959",
  },
  172: {
    border: "#44D62C",
    background: "#F0F0F0",
    textColor: "#595959",
  },
  190: {
    border: "#44D62C",
    background: "#F0F0F0",
    textColor: "#595959",
  },
};

export const orderToCompleteColor = {
  border: "#73D13D",
  background: "#73D13D",
  textColor: "#FFFFFF",
};
