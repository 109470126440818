import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import { Form, Collapse } from "antd";

import { withGlobal } from "../../../../component-global";
import { MapsTwoPointDraggable } from "../../../../components/Maps";
import { SpinCustom, CustomCollapse, CustomAccordion } from "./style";
import { LocationPointFormComponent, OrderInformation, ColorsLegend } from ".";
import { getMarkersForUniqueOrder, getPolylinesForUniqueOrder } from "utils";

const { Panel } = Collapse;

class bodyUniqueSend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      allCitiesBranch,
      allBranchEnterprise,
      allServicesBranch,
      allServicesEnterprise,
      allPaymentMethod,
      allPaymentProof,
      allVehicleTypes,
      allPackageSizes,
      getFieldDecorator,
      updatePointsInputs,
      pointAddressDefault,
      pointAddressOrigin,
      pointAddressDestination,
      lastPointUpdate,
      polylineAddress,
      showTextBoxMap,
      titleTextBoxMap,
      detailTextBoxMap,
      loadingMap,
      markerDragAddressOrigin,
      markerDragAddressDestination,
      updateSelect,
      updateAddressOrigin,
      addressOrigin,
      updateAddressDestination,
      addressDestination,
      selectedOriginRequired,
      allServicesCity,
      loadingFields,
      disableSucursalOriginPoint,
      disableServiceOriginPoint,
      disableServiceOrder,
      disableVehicleTypeID,
      locationReferenceOrigin,
      locationContactPersonOrigin,
      locationCellPhoneNumberOrigin,
      locationPhoneNumberOrigin,
      locationReferenceDestination,
      locationContactPersonDestination,
      locationCellPhoneNumberDestination,
      locationPhoneNumberDestination,
      switchForReverseLogistics,
      updateCrossdockingOption,
      updateServiceOrderOption,
      valueToState,
      selectCrossdockingRequired,
      selectServiceOrderRequired,
      reverseLogistics,
      replacementOrder,
      reversedPolylineAddress,
      switchForReplacementOrder,
    } = this.props;

    return (
      <div
        style={{
          height: "calc(100vh - 360px)",
          display: "flex",
        }}
      >
        {/* bloque del form */}
        <div
          style={{
            width: "900px",
            display: "grid",
            overflow: "auto",
          }}
        >
          <CustomCollapse
            bordered={false}
            expandIconPosition="right"
            defaultActiveKey={["1", "2", "3"]}
            expandIcon={({ isActive }) => (
              <CustomAccordion>
                <span>{isActive ? "Ocultar" : "Ver detalle"}</span>
              </CustomAccordion>
            )}
          >
            <Panel header="Datos de la orden" key="1">
              <OrderInformation
                getFieldDecorator={getFieldDecorator}
                allServicesEnterprise={allServicesEnterprise}
                allServicesCity={allServicesCity}
                allPaymentMethod={allPaymentMethod}
                allPaymentProof={allPaymentProof}
                allVehicleTypes={allVehicleTypes}
                allPackageSizes={allPackageSizes}
                multiplaceEnterprise={
                  this.props.userEnterpriseData &&
                  this.props.userEnterpriseData.Enterprise &&
                  this.props.userEnterpriseData.Enterprise.multiplace
                }
                loadingFields={loadingFields}
                handleSwitchForReverseLogistics={switchForReverseLogistics}
                updateCrossdockingOption={updateCrossdockingOption}
                updateServiceOrderOption={updateServiceOrderOption}
                selectCrossdockingRequired={selectCrossdockingRequired}
                selectServiceOrderRequired={selectServiceOrderRequired}
                disableService={disableServiceOrder}
                disableVehicleTypeID={disableVehicleTypeID}
                reverseLogistics={reverseLogistics}
                replacementOrder={replacementOrder}
                handleSwitchForReplacementOrder={switchForReplacementOrder}
              />
            </Panel>
            <Panel header="Punto de origen" key="2">
              <LocationPointFormComponent
                showExtraFields={
                  this.props.userEnterpriseData &&
                  this.props.userEnterpriseData.Enterprise &&
                  this.props.userEnterpriseData.Enterprise.multiplace &&
                  !this.props.reverseLogistics
                }
                updatePointsInputs={updatePointsInputs}
                getFieldDecorator={getFieldDecorator}
                allCitiesBranch={allCitiesBranch}
                allBranchEnterprise={allBranchEnterprise}
                allServicesBranch={allServicesBranch}
                updateSelect={updateSelect}
                loadingFields={loadingFields}
                selectedLocationRequired={selectedOriginRequired}
                disableSucursal={disableSucursalOriginPoint}
                disableService={disableServiceOriginPoint}
                locationAddress={addressOrigin}
                updateLocationAddress={updateAddressOrigin}
                codeRegion={this.props.authUser.profile.regionCountry}
                valueToState={valueToState}
                locationReference={locationReferenceOrigin}
                locationContact={locationContactPersonOrigin}
                locationCell={locationCellPhoneNumberOrigin}
                locationTelf={locationPhoneNumberOrigin}
                locationType={"Origin"}
              />
            </Panel>
            <Panel header="Punto de destino" key="3">
              <LocationPointFormComponent
                showExtraFields={
                  this.props.userEnterpriseData &&
                  this.props.userEnterpriseData.Enterprise &&
                  this.props.userEnterpriseData.Enterprise.multiplace &&
                  this.props.reverseLogistics
                }
                updatePointsInputs={updatePointsInputs}
                getFieldDecorator={getFieldDecorator}
                allCitiesBranch={allCitiesBranch}
                allBranchEnterprise={allBranchEnterprise}
                allServicesBranch={allServicesBranch}
                updateSelect={updateSelect}
                loadingFields={loadingFields}
                selectedLocationRequired={selectedOriginRequired}
                disableSucursal={disableSucursalOriginPoint}
                disableService={disableServiceOriginPoint}
                locationAddress={addressDestination}
                updateLocationAddress={updateAddressDestination}
                codeRegion={this.props.authUser.profile.regionCountry}
                valueToState={valueToState}
                locationReference={locationReferenceDestination}
                locationContact={locationContactPersonDestination}
                locationCell={locationCellPhoneNumberDestination}
                locationTelf={locationPhoneNumberDestination}
                locationType={"Destination"}
              />
            </Panel>
          </CustomCollapse>
        </div>

        {/* bloque del mapa */}
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginLeft: "24px",
          }}
        >
          <SpinCustom style={{ height: "100%" }} spinning={loadingMap}>
            <div
              style={{
                height: "calc(100vh - 390px)",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <MapsTwoPointDraggable
                isMarkerShown
                markersDraggable
                showTextBox={showTextBoxMap}
                textBoxTitle={titleTextBoxMap}
                textBoxDescription={detailTextBoxMap}
                zoomMap={16}
                markerDragOrigin={markerDragAddressOrigin}
                markerDragDestination={markerDragAddressDestination}
                latLngDefault={pointAddressDefault}
                latLngStart={pointAddressOrigin}
                latLngEnd={pointAddressDestination}
                centerMap={lastPointUpdate}
                polylines={getPolylinesForUniqueOrder({
                  polylineAddress,
                  reversedPolylineAddress,
                })}
                colorsLegend={replacementOrder && <ColorsLegend />}
                markers={getMarkersForUniqueOrder({
                  pointAddressOrigin,
                  pointAddressDestination,
                  markerDragAddressOrigin,
                  markerDragAddressDestination,
                  replacementOrder,
                })}
              />
            </div>
          </SpinCustom>
        </div>
      </div>
    );
  }
}

const WrapperBodyUniqueSend = withGlobal(bodyUniqueSend);
const bodyUniqueSendForm = Form.create()(WrapperBodyUniqueSend);

export default compose(withApollo)(bodyUniqueSendForm);
