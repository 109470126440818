import React, { Component, Fragment } from "react";
import { Table, Pagination } from "antd";
import { compose } from "recompose";
import { CardPagination, CardTable } from "./styles";
import { withGlobalContext } from "../../utils/globalContext";

class TableList extends Component {
  render() {
    const {
      data,
      loading,
      totalData,
      currentPage,
      pageSize,
      onChange,
      columns,
      onlyShowList,
      rowSelection,
      scroll,
      size,
      showHeader,
      heightcustom,
      footer,
      heightempty,
      withoutscroll,
      backgroundcustom,
      expandedRowRender,
      expandRowByClick,
      expandIconColumnIndex,
      expandIcon,
      rowExpandable,
    } = this.props;

    return (
      <Fragment>
        <CardTable
          heightcustom={heightcustom}
          heightempty={heightempty}
          withoutscroll={withoutscroll}
          backgroundcustom={backgroundcustom}
        >
          <Table
            expandedRowRender={expandedRowRender}
            rowExpandable={rowExpandable}
            showHeader={showHeader}
            footer={footer}
            size={size}
            rowSelection={rowSelection}
            loading={loading}
            pagination={false}
            columns={columns.map(item => ({ ...item, ellipsis: true }))}
            dataSource={data}
            rowKey="id"
            scroll={
              scroll || {
                x: "fit-content",
              }
            }
            expandRowByClick={expandRowByClick}
            expandIconAsCell={false}
            expandIconColumnIndex={expandIconColumnIndex}
            expandIcon={expandIcon}
          />
        </CardTable>
        {!onlyShowList ? (
          <CardPagination>
            <span
              style={{
                height: 32,
                padding: "0.5rem 2rem",
                alignSelf: "center",
              }}
            >
              {`Total ${totalData || 0} registros`}
            </span>
            <Pagination
              simple
              pageSize={pageSize}
              // defaultCurrent={currentPage}
              current={currentPage}
              total={totalData}
              onChange={onChange}
            />
          </CardPagination>
        ) : null}
      </Fragment>
    );
  }
}

export default compose(withGlobalContext)(TableList);
