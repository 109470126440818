import styled from "styled-components";

const BodyContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 24px 24px 16px 24px;
  min-height: ${props => (props.height ? props.height : "calc(100vh - 230px)")};
  position: relative;
  margin-bottom: 2rem;
  padding-bottom: 10rem;
`;

export default BodyContainer;
