import gql from "graphql-tag";

const getRouteDetails = gql`
  query getRouteDetails($enterpriseID: Int!, $routeCode: String!) {
    getRouteDetails(enterpriseID: $enterpriseID, routeCode: $routeCode) {
      id
      routeCode
      priceEnterprise
      priceEnterpriseOriginal
      currentOrderToComplete
      vehicleTypeID
      cityID
      Affiliates {
        User {
          names
          lastname
          phone
          picture
        }
        currentVehicleID
        CurrentVehicle {
          vehiclePlate
          CategoryVehicle {
            subclass
          }
        }
      }
      OrderServices {
        LastStatus {
          subclass
          subtype
        }
        OrderServiceHistorial {
          createdAt
          statusID
        }
        Orders {
          id
          trackCode
          pickUpAddress
          pickUpPoint {
            coordinates
          }
          dropAddress
          dropPoint {
            coordinates
          }
          dropNotes
          dropAddressReference
          receptorAcuse
          receptorDocument
          enterpriseID
          statusID
          Service {
            name
          }
          Status {
            subclass
          }
          OrdersImages {
            id
            groupID
            url
          }
        }
      }
    }
  }
`;

export { getRouteDetails };
