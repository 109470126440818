import React, { Component } from "react";

export const Wrapper = React.createContext();

class GlobalContext extends Component {
  render() {
    const { children } = this.props;

    return (
      <Wrapper.Provider value={{ ...this.props }}>{children}</Wrapper.Provider>
    );
  }
}

export const GlobalContextConsumer = Wrapper.Consumer;

export default GlobalContext;
