import React from "react";
import { compose } from "recompose";
import styled from "styled-components";
import { Modal, Button } from "antd";
import { withApollo } from "react-apollo";
import { withGlobal } from "../../component-global";

const CustomModal = styled(Modal)`
  top: 150;

  display: flex;
  justify-content: center;
  text-align: center;

  && .ant-modal-body {
    padding: 0px;
  }
`;

class ConfirmAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      visible,
      onCancel,
      title,
      description,
      loading,
      cancelMessage,
      acceptMessage,
      style,
      cancelBtnStyle,
      acceptBtnStyle,
      onConfirm,
    } = this.props;

    return (
      <CustomModal footer={null} onCancel={onCancel} visible={visible}>
        <div
          style={{
            width: "384px",
            height: "248px",
            padding: "48px",
            display: "flex",
            flexDirection: "column",
            ...style,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#595959",
              marginBottom: "16px",
            }}
          >
            {title}
          </div>
          <div
            style={{
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "24px",
              color: "#8C8C8C",
            }}
          >
            {description}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              key="cancel"
              type="primary"
              ghost
              size="large"
              style={{
                border: "1px solid #307FE2",
                width: "132px",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "16px",
                ...cancelBtnStyle,
              }}
              onClick={() => onCancel()}
              disabled={loading}
            >
              {cancelMessage || "Lo veré de nuevo"}
            </Button>
            <Button
              key="delete"
              size="large"
              style={{
                color: "#FFFFFF",
                backgroundColor: "#CF1322",
                fontStyle: "normal",
                width: "132px",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "16px",
                ...acceptBtnStyle,
              }}
              onClick={onConfirm}
              loading={loading}
            >
              {acceptMessage || "Eliminar Ruta"}
            </Button>
          </div>
        </div>
      </CustomModal>
    );
  }
}

const WraperConfirmAction = withGlobal(ConfirmAction);

export default compose(withApollo)(WraperConfirmAction);
