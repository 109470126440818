import React, { Component } from "react";
import { message, Spin, notification } from "antd";
import { navigate } from "@reach/router";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import axios from "axios";
import { createSuperAdminRecord } from "../../graphQl/mutations/myCompany";
import DashboardLayout from "./DashboardLayout";
import { withGlobalContext } from "../../utils/globalContext";
import { AuthService } from "../../services/AuthService.ts";
import { getEnterpriseByID } from "../../graphQl/queries/myCompany";
import { getUserData } from "../../graphQl/queries/User";
import { ArrayPermissions, EntrerpriseException } from "../../utils/roles";
import { replaceAll } from "../../utils/functions";

const authService = new AuthService();

class DashboardWrapper extends Component {
  state = {
    user: null,
    viewsAllowed: null,
    userEnterpriseData: null,
    loadingData: false,
    showKustomerRedirect: false,
  };

  componentDidMount = async () => {
    await this.getUser();
    await this.getUserEnterprise();
    await this.filterByEnterprise();
  };

  //FILRO DE MODULO POR ENTERPRISE Y USUARIO
  filterByEnterprise = async () => {
    const { viewsAllowed } = this.state;
    const { userEnterpriseData, user } = this.state;
    //Recorre viewsAllowed.permissionsAllowed para eliminar los modulos nombrados en EntrerpriseException
    EntrerpriseException.map(element => {
      if (
        element.id === userEnterpriseData.enterpriseID &&
        element.users.indexOf(user.profile.email) === -1
      ) {
        element.exceptions.map(exception => {
          viewsAllowed.permissionsAllowed.map(permission => {
            var index = permission.resources.indexOf(exception);
            if (index > -1) {
              permission.resources.splice(index, 1);
            }
          });
        });
      }
    });
    //Actualiza la lista de viewsAllowed.menuAllowed
    viewsAllowed.menuAllowed = new Set(
      viewsAllowed.permissionsAllowed.map(pa => pa.resources).flat()
    );
    this.setState({ loadingData: false });
  };

  getUserEnterprise = async () => {
    const { user } = this.state;
    this.setState({ loadingData: true });
    try {
      const {
        data: { getUserByID },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getUserData,
        variables: {
          userID: user.profile.sub,
        },
      });
      this.pendoInitialice(this.state.user, getUserByID);
      this.setState({
        userEnterpriseData: getUserByID,
        //loadingData: false,
      });
    } catch (err) {
      this.setState({ loadingData: false });
      await this.getUser();
      console.log(err);
    }
  };

  getAllSubTree = subTree => {
    if (subTree.descendents) {
      let arrayOfNodes = [];
      subTree.descendents.forEach(obj => {
        arrayOfNodes = arrayOfNodes.concat(this.getAllSubTree(obj));
      });

      arrayOfNodes.push(subTree.value);
      return arrayOfNodes;
    }
    return [];
  };

  extractViewsAllowed = (arrayPermissions, permissions) => {
    const permissionsAllowed = permissions.map(p => {
      const submenus = this.getAllSubMenusPermissions(p.resources[0]);
      const permi = arrayPermissions
        .filter(ap => ap.match(replaceAll(p.resources[0], "*", ".*")))
        .concat(submenus.length === 1 ? submenus : submenus.slice(0, -1));
      return { actions: p.actions, resources: permi };
    });

    return {
      permissionsAllowed,
      menuAllowed: new Set(permissionsAllowed.map(pa => pa.resources).flat()),
    };
  };

  getAllSubMenusPermissions = per => {
    const temp = replaceAll(per, ":*", "").split(":");
    const arrayReturn = [];
    let tempString = "";
    temp.forEach(t => {
      tempString += t;
      arrayReturn.push(tempString);
      tempString += ":";
    });
    return arrayReturn;
  };

  isAllowedView = viewName => {
    if (!new Set(this.state.viewsAllowed.menuAllowed).has(viewName)) {
      notification.error({
        message: "No tiene los permisos para ver este contenido.",
      });
      navigate("/dashboard");
      return false;
    }
    return true;
  };

  getPermissionsView = viewName => {
    let actionsAllowed = [];
    this.state.viewsAllowed.permissionsAllowed.forEach(pa => {
      if (new Set(pa.resources).has(viewName)) actionsAllowed = pa.actions;
    });

    const respActionAllowed = {
      edit: actionsAllowed.includes("*") || actionsAllowed.includes("edit"),
      view: actionsAllowed.includes("*") || actionsAllowed.includes("view"),
    };

    return respActionAllowed;
  };

  getAuthorizationData = async accessToken => {
    let Authorization;
    if (typeof accessToken !== "string") return {};
    if (accessToken.substring(0, 7) === "Bearer ") Authorization = accessToken;
    else Authorization = `Bearer ${accessToken}`;

    const config = {
      headers: { Authorization },
      params: {
        scope: "openid role",
      },
    };
    const bodyParameters = {};
    const res = await axios
      .get(`${process.env.REACT_APP_AUTH_PROVIDER}me`, config, bodyParameters)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log("ERROR =>", error);
        return error;
      });
    const respu = res.status === 200 ? res.data : {};
    return respu;
  };

  getEnterprise = async userID => {
    try {
      const {
        data: { GetEnterpriseByID },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getEnterpriseByID,
        variables: {
          id: parseInt(userID, 10),
        },
      });
      return GetEnterpriseByID;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  checkSuperAdminMod = async (user, enterprise) => {
    const isLogin = localStorage.getItem("isFirstLogin");
    if (
      enterprise.extra.superAdmin &&
      enterprise.extra.superAdmin.active &&
      enterprise.extra.superAdmin.active === true &&
      isLogin === null
    ) {
      localStorage.setItem("isFirstLogin", false);

      const variables = {
        operationType: "INGRESO",
        operatorUserID: enterprise.extra.superAdmin.operatorUserID,
        clientUserID: user.profile.sub,
        enterpriseID: parseInt(enterprise.id, 10),
      };

      await this.props.client
        .mutate({
          mutation: createSuperAdminRecord,
          update: (cache, { data: { CreateSuperAdminRecord: response } }) => {
            console.log(response.message);
          },
          variables,
        })
        .catch(error => {
          console.log("Error: ", error);
        });
    }
  };

  pendoInitialice = async (user, enterpriseID) => {
    const myEnterprise = await this.getEnterprise(enterpriseID.enterpriseID);
    this.checkSuperAdminMod(user, myEnterprise);

    sessionStorage.setItem("principalUserEnterprise", myEnterprise.User.id);

    this.setState({
      showKustomerRedirect: myEnterprise.extra.showKustomerRedirect,
    });

    window.pendo.initialize({
      visitor: {
        id: user.profile.sub, // Required if user is logged in
        email: user.profile.email,
        full_name: `${user.profile.given_name} ${user.profile.family_name}`, // Recommended if using Pendo Feedback
        role: user.profile.role_name, // Optional
      },
      account: {
        id: user.profile.sub, // Highly recommended
        businessName: myEnterprise.businessName,
        commercialName: myEnterprise.comercialName,
        documentType: myEnterprise.DocumentType.subclass,
        businessDocument: myEnterprise.documentNumber,
        businessCountry: myEnterprise.Country.name,
        businessCity: myEnterprise.City.name,
        businessLegalAddress: myEnterprise.address,
        businessContactName:
          myEnterprise.contactPeople &&
          myEnterprise.contactPeople[0] &&
          myEnterprise.contactPeople[0].name,
        businessContactEmail:
          myEnterprise.contactPeople &&
          myEnterprise.contactPeople[0] &&
          myEnterprise.contactPeople[0].email &&
          myEnterprise.contactPeople[0].email[0],

        multiplace: myEnterprise.multiplace,

        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  };

  getUser = async () => {
    localStorage.setItem("redirectUrl", this.props.location.href);
    const userData = await authService.getUser().then(
      user => {
        return user;
      },
      () => {
        navigate("/");
      }
    );

    if (!userData) {
      authService.renewToken().then(
        user => {
          if (user) {
            message.success("Usuario autenticado.");
            this.setState({ user });

            this.getAuthorizationData(user.access_token).then(
              authDataUser => {
                if (authDataUser) {
                  const auth = authDataUser.permissions || [
                    user.profile.permissions,
                  ];
                  const viewsAllowed = this.extractViewsAllowed(
                    ArrayPermissions,
                    auth
                  );
                  this.setState({
                    viewsAllowed,
                  });
                  this.props.global.changeViewsAllowed(viewsAllowed);
                } else navigate("/");
              },
              () => {
                navigate("/");
              }
            );
          } else {
            navigate("/");
          }
        },
        () => {
          navigate("/");
        }
      );
    } else {
      const authPermissions = await this.getAuthorizationData(
        userData.access_token
      );
      message.success("Usuario autenticado.");
      // ---------Getting only allowed Views of User----------------
      const auth = authPermissions.permissions || [
        userData.profile.permissions,
      ];
      const viewsAllowed = this.extractViewsAllowed(ArrayPermissions, auth);
      this.setState({
        user: userData,
        viewsAllowed,
      });
      this.props.global.changeViewsAllowed(viewsAllowed);
    }
  };

  render() {
    const { children } = this.props;
    const { user, viewsAllowed, userEnterpriseData, loadingData } = this.state;
    return (
      <div>
        {user && viewsAllowed && userEnterpriseData && !loadingData ? (
          <DashboardLayout
            authUser={user}
            isAllowedView={this.isAllowedView}
            userEnterpriseData={userEnterpriseData}
            getPermissionsView={this.getPermissionsView}
            showKustomerRedirect={this.state.showKustomerRedirect}
            client={this.props.client}
          >
            {children}
          </DashboardLayout>
        ) : (
          <div>
            <Spin tip="Cargando..." />
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  withApollo,
  withGlobalContext
)(DashboardWrapper);
