import gql from "graphql-tag";

const getDashboardB2bData = gql`
  query getDashboardB2bData(
    $enterpriseID: Int!
    $cityID: Int
    $timeSpace: TimespaceEnum!
  ) {
    DashboardB2bData: getDashboardB2bData(
      enterpriseID: $enterpriseID
      cityID: $cityID
      timeSpace: $timeSpace
    ) {
      qTrackcodes
      qFailedTrackcodes
      qDeliveredTrackcodes
      qReturnedTrackcodes
    }
  }
`;

const getTopIncidences = gql`
  query getTopIncidences($enterpriseID: Int!, $cityID: Int) {
    TopIncidences: getTopIncidences(
      enterpriseID: $enterpriseID
      cityID: $cityID
    ) {
      id
      name
    }
  }
`;

const getHistorialDeliveredTrackcodes = gql`
  query getHistorialDeliveredTrackcodes(
    $enterpriseID: Int!
    $cityID: Int
    $timeSpace: TimespaceGraphicsEnum!
  ) {
    HistorialDeliveredTrackcodes: getHistorialDeliveredTrackcodes(
      enterpriseID: $enterpriseID
      cityID: $cityID
      timeSpace: $timeSpace
    ) {
      title
      label
      data
    }
  }
`;

const getBranchOfficeByEnterpriseID = gql`
  query getBranchOfficeByEnterpriseID($enterpriseID: Int) {
    AllBranchOffices: getBranchOfficeByEnterpriseID(
      enterpriseID: $enterpriseID
    ) {
      City {
        name
        id
      }
    }
  }
`;

export {
  getDashboardB2bData,
  getTopIncidences,
  getHistorialDeliveredTrackcodes,
  getBranchOfficeByEnterpriseID,
};
