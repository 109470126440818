import React, { Component, Fragment } from "react";
import "antd/dist/antd.css";

import { GlobalStyle } from "./globalStyles";
import { GlobalContextProvider } from "../utils/globalContext";
import Router from "../routes/Router";

class Root extends Component {
  render() {
    return (
      <Fragment>
        <GlobalStyle />
        <GlobalContextProvider>
          <Router />
        </GlobalContextProvider>
      </Fragment>
    );
  }
}

export default Root;
