import React from 'react';
import { Bar } from 'react-chartjs-2';
import moment from "moment";
import TableList from "../../../components/Table/tableList";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { ContainerTables } from "../../../components/Form/SearchContainer";
import { Empty } from "antd";

class FinaReport extends React.Component {
  render() {
    //GRAFICO
    const data = {
      labels: this.props.reportDatalabel,
      datasets: this.props.reportDatasets,
    };

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    const GroupedBar = () => (
      <>
        <div className='header'>
          <h1 className='title'>Chart: Valor neto</h1>
          <h2 style={{ textAlign: "center" }}>{this.props.currentFilterF.rangeDate ? "del " + moment(this.props.currentFilterF.rangeDate.createdAt.greaterEqual).format("DD/MM/YYYY") + " al " + moment(this.props.currentFilterF.rangeDate.createdAt.lessEqual).format("DD/MM/YYYY") : "Datos totales del año"}</h2>
        </div>
        <Bar data={data} options={options} height={40} width={140} />
      </>
    );

    const EmptyCard = () => (
      <div
        style={{
          height: "calc(90vh - 400px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Empty
          image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
          imageStyle={{
            height: 136,
          }}
          description={
            <span
              style={{
                color: "#8C8C8C",
                
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              Cargando ...
            </span>
          }
        />
      </div>
    )

    //TABLA
    const columns = [
      {
        title: "Código",
        dataIndex: "trackCode",
        key: "trackCode",
        render: (text, record) => <span>{record.trackCode}</span>,
      },
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        render: (text, record) => <span>{moment(record.createdAt).format("DD/MM/YYYY")}</span>,
      },
      {
        title: "Ciudad",
        dataIndex: "city",
        key: "city",
        align: "center",
        render: (text, record) => <span>{record.City && record.City.name}</span>,
      },
      {
        title: "Valor neto",
        dataIndex: "totalPrice",
        key: "totalPrice",
        render: (text, record) => <span>{record.totalPrice ? record.totalPrice : "--"}</span>,
      },
    ];


    return (
      <div>

        {this.props.loadingChart ? <EmptyCard /> : <GroupedBar />}
        {this.props.loadingTable ?
          <EmptyCard />
          :
          <div>
            <div style={{ paddingTop: 50 }}></div>
            <ContainerTables>
              <TableList

                columns={columns}
                currentPage={this.props.currentPage}
                pageSize={this.props.pageSize}
                scroll={{ y: "calc(100vh - 560px)" }}
                heightcustom="calc(100vh - 560px)"
                data={this.props.reportDataDetail}
                onChange={this.props.onChange}
                totalData={this.props.datadetailtotal}
              />
            </ContainerTables>
          </div>
        }
      </div>
    );

  }
}


export default compose(withApollo)(FinaReport);

