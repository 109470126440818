import {
  Button,
  Drawer,
  message,
  Form,
  Row,
  Col,
  Spin,
  notification,
  Select
} from "antd";
import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { withGlobal } from "../../../../component-global";
import { captureException } from "../../../../utils/sentry";
import { withGlobalContext } from "../../../../utils/globalContext";
import {
  SelectWithBorderComponent,
  InputComponentWithBorder,
} from "../../../../components/FormFields";

import { returnPackageNoLocated } from "../../../../graphQl/mutations/nonExistent";
import { ContainerBotton, DrawerContainer } from "./Style";

const { Option } = Select;

class DrawerRefund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      branchDestination: null,
      cityID: null,
    };
  }

  onChangeCity = async (id) => {
    const { setFieldsValue } = this.props.form;
    setFieldsValue({
      destinationBranchOffice: undefined,
      destinationAddress: undefined,
    });
    if (id) {
      this.setState({ cityID: id });
    }
  };

  onChangeBranch = async (id) => {
    const { setFieldsValue } = this.props.form;

    if (id) {
      const branch = this.props.branchOptions.find(b => b.id === id);
      this.setState({ branchDestination: branch });
      setFieldsValue({
        destinationCity: branch.City.name,
        destinationAddress: branch.branchOfficeAddress,
      });
    }
  };

  submitForm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        let orderNoLocatedIDs;
        if (this.props.orderIDs.length > 0) {
          orderNoLocatedIDs = this.props.orderIDs.map(id => parseInt(id));
        } else {
          orderNoLocatedIDs = [parseInt(this.props.order.id, 10)];
        }

        const variables = {
          orderNoLocatedIDs,
          addressReturn: {
            dropBranchID: parseInt(values.destinationBranchOffice, 10),
            dropAddress: values.destinationAddress,
            dropAddressReference: values.destinationReference,
            dropContactName: values.destinationContact,
            dropContactPhone: values.destinationCellphone,
            dropNotes: values.destinationDetail,
            dropPoint: this.state.branchDestination.branchOfficeAddressPoint,
          },
        };

        this.props.client
          .mutate({
            mutation: returnPackageNoLocated,
            update: (cache, { data: { returnPackageNoLocated: res } }) => {
              if (res.statusCode === 200) {
                notification.success({
                  message: "Trackcode devuelto con éxito",
                  description:
                    "El TC se ha devuelto al punto de destino elegido",
                });
                this.setState({ loading: false });
                this.props.onClose();
                this.props.refreshList({});
              } else {
                notification.error({ message: res.message });
                this.setState({ loading: false });
              }
            },
            variables,
          })
          .catch(error => {
            captureException(error);
            message.error("No se pudo guardar, error en el sistema.");
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, cityID } = this.state;
    const {
      orderIDs,
      visible,
      onClose,
      title,
      cityOptions,
      branchOptions,
    } = this.props;

    const renderCity = (cityOptions || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    ));
    const renderBranch = (branchOptions || [])
      .filter(obj => obj.City && obj.City.id === cityID)
      .map(obj => (
        <Option key={obj.id} value={obj.id}>
          {obj.branchOfficeName}
        </Option>
      ));

    return (
      <Drawer
        visible={visible}
        onClose={onClose}
        placement="right"
        closable
        width="700px"
        destroyOnClose
      >
        {loading ? (
          <Spin
            tip="Cargando..."
            size="large"
            style={{
              left: "50%",
              position: "absolute",
              textAlign: "center",
              top: "40%",
              transform: "translate(-50%, -50%)",
              verticalAlign: "middle",
            }}
            spinning={loading}
          />
        ) : (
          <DrawerContainer>
            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
              <h2>
                <b>{title}</b>
              </h2>
              <span>
                {orderIDs.length > 0
                  ? "Completa los campos de los X trackcodes seleccionados para que pueda ser procesados con éxito."
                  : "Completa los campos para procesar la solicitud con éxito."}
              </span>
              <div style={{ marginTop: 15 }}>
                <h3>
                  <b>Punto de devolver</b>
                </h3>
              </div>

              <Row gutter={24}>
                <Col span={12}>
                  <SelectWithBorderComponent
                    style={{ width: "100%" }}
                    getFieldDecorator={getFieldDecorator}
                    title="Ciudad"
                    placeholder="Selecciona una ciudad"
                    decodator={'destinationCity'}
                    onChange={id => this.onChangeCity(id)}
                    options={renderCity}
                    required
                  />
                </Col>
                <Col span={12}>
                  <SelectWithBorderComponent
                    style={{ width: "100%" }}
                    getFieldDecorator={getFieldDecorator}
                    title="Sucursal"
                    placeholder="Selecciona una sucursal"
                    decodator={'destinationBranchOffice'}
                    options={renderBranch}
                    onChange={id => this.onChangeBranch(id)}
                    required
                  />
                </Col>
              </Row>

              <Row gutter={24} style={{ marginTop: 10 }}>
                <Col span={24}>
                  <InputComponentWithBorder
                    getFieldDecorator={getFieldDecorator}
                    title={'Dirección de destino'}
                    placeholder="Ingresa la dirección de origen"
                    value={""}
                    decodator={'destinationAddress'}
                    required
                  />
                </Col>
              </Row>

              <Row gutter={24} style={{ marginTop: 10 }}>
                <Col span={24}>
                  <InputComponentWithBorder
                    getFieldDecorator={getFieldDecorator}
                    title="Referencia"
                    placeholder="Ingresa una referencia"
                    value={""}
                    decodator={'destinationReference'}
                    required
                  />
                </Col>
              </Row>

              <Row gutter={24} style={{ marginTop: 10 }}>
                <Col span={12}>
                  <InputComponentWithBorder
                    getFieldDecorator={getFieldDecorator}
                    title="Contacto"
                    placeholder="Ingresa un nombre de contacto"
                    value={""}
                    decodator={'destinationContact'}
                    required
                  />
                </Col>
                <Col span={12}>
                  <InputComponentWithBorder
                    getFieldDecorator={getFieldDecorator}
                    title="Celular"
                    placeholder="Ingresa un celular"
                    value={""}
                    decodator={'destinationCellphone'}
                    required
                  />
                </Col>
              </Row>

              <Row gutter={24} style={{ marginTop: 10 }}>
                <Col span={12}>
                  <InputComponentWithBorder
                    getFieldDecorator={getFieldDecorator}
                    title="Teléfono fijo"
                    placeholder="Ingresa un teléfono"
                    value={""}
                    decodator={'destinationPhone'}
                    required
                  />
                </Col>
              </Row>

              <Row gutter={24} style={{ marginTop: 10 }}>
                <Col span={24}>
                  <InputComponentWithBorder
                    getFieldDecorator={getFieldDecorator}
                    title="Detalle de producto a entregar"
                    placeholder="Ingresa un detalle"
                    value={""}
                    decodator={'destinationDetail'}
                    required
                  />
                </Col>
              </Row>
            </div>

            <ContainerBotton>
              <Row gutter={24} type="flex" justify="space-around">
                <Button
                  type="primary"
                  style={{
                    fontSize: "14px",
                    height: "40px",
                    width: "250px",
                  }}
                  onClick={this.submitForm}
                >
                  {orderIDs.length > 0
                    ? "Validar y procesar todos los TC"
                    : "Validar"}
                </Button>
              </Row>
            </ContainerBotton>
          </DrawerContainer>
        )}
      </Drawer>
    );
  }
}

const wraperDrawerRefund = withGlobal(DrawerRefund);
const DrawerRefundForm = Form.create()(wraperDrawerRefund);

export default compose(
  withApollo,
  withGlobalContext
)(DrawerRefundForm);
