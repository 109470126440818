import React, { FC, useEffect, useCallback, useState } from "react";
import {
  Spin,
  Icon,
  notification,
  DatePicker,
  Button,
  Row,
  Col,
  Tabs,
  Layout,
} from "antd";
import { withApollo, WithApolloClient } from "react-apollo";
import { captureException } from "@sentry/react";
import { compose } from "recompose";
import moment from "moment";
//import { Chart } from "react-chartjs-2";

import { Container, TitleGoBack } from "components";
import { withGlobal } from "component-global";
import { GeneralIndicators, IndicatorsByOrder } from "./components";

import {
  GetDataForDonutChart,
  GetDataForGeneralIndicators,
  GetIncidencesResponse,
  GetIndicatorsResponse,
  Incidences,
  Indicators,
  AttemptSuccessRate,
  GetAttemptSuccessRateResponse,
} from "types";
import {
  getOrderAttemptSuccessRateByEnterprise,
  getOrderIncidencesByEnterprise,
  getOrderIndicatorsByEnterprise,
} from "graphQl";
import { CustomFilterContainer } from "components/Form/SearchContainer";
import AttemptRate from "./components/AttemptRateChart";
import  PopoverInfo  from "./components/PopoverInfo";
import { CustomIcon } from "./components/PopoverInfo/styles";
const { Content } = Layout;

const { TabPane } = Tabs;

const getDataForGeneralIndicators: GetDataForGeneralIndicators = indicators => {
  if (!indicators) return [];

  return [
    {
      id: 0,
      name: "Órdenes creadas",
      quantity: indicators.createdOrders || 0,
      info:'Cantidad de órdenes ingresadas a la plataforma.',
      titleInfo:'Envíos ingresados'
    },
    {
      id: 1,
      name: "Órdenes ingresadas",
      quantity: indicators.enteredOrders || 0,
      info:'Cantidad de órdenes cargadas correctamente.',
      titleInfo:'Envíos cargados'
    },
    {
      id: 2,
      name: "Órdenes despachadas ",
      quantity: indicators.dispatchedOrders || 0,
      info:'Órdenes despachadas al cliente final.',
      titleInfo:'Envíos despachados'
    },
    {
      id: 3,
      name: "Reprogramaciones",
      quantity: indicators.reprogrammingOrders || 0,
      info:'Envíos que no pudieron entregarse y se reprogramarán.',
      titleInfo:'Reprogramaciones:'
    },
  ];
};

const getDataForDonutChart: GetDataForDonutChart = indicators => {
  if (!indicators) return [];

  return [
    {
      id: 0,
      name: "Despachado",
      quantity: indicators.dispatchedOrders || 0,
      info:'',
      titleInfo:''
    },
    {
      id: 1,
      name: "Sin despachar",
      quantity:
        (indicators.enteredOrders || 0) - (indicators.dispatchedOrders || 0),
        info:'',
        titleInfo:''
    },
    {
      id: 2,
      name: "Reprogramados",
      quantity: indicators.reprogrammingOrders || 0,
      info:'',
      titleInfo:''
    },
  ];
};

interface StatisticsProps {
  userEnterpriseData: {
    enterpriseID: number;
  };
}

type StatisticsPropsWithApollo = WithApolloClient<StatisticsProps>;

const Statistics: FC<StatisticsPropsWithApollo> = props => {
  const { client, userEnterpriseData } = props;
  const [loadingIndicators, setLoadingIndicators] = useState(false);
  const [loadingIncidences, setLoadingIncidences] = useState(false);
  const [loadingAttemptSuccessRate, setLoadingAttemptSuccessRate] = useState(
    false
  );
  const [indicators, setIndicators] = useState<Indicators>();
  const [incidences, setIncidences] = useState<Incidences[]>([]);
  const [attemptSuccessRate, setAttemptSuccessRate] = useState<
    AttemptSuccessRate
  >();
  const [rangeDate, setRangeDate] = useState({
    startDate: undefined,
    endDate: undefined,
  });
  const [rangeDate2, setRangeDate2] = useState({
    startDate: undefined,
    endDate: undefined,
  });
  const { RangePicker } = DatePicker;



  const getIndicators = useCallback(
    async date => {
      try {
        setLoadingIndicators(true);
        const response = await client.query<GetIndicatorsResponse>({
          fetchPolicy: "network-only",
          query: getOrderIndicatorsByEnterprise,
          variables: {
            enterpriseID: userEnterpriseData.enterpriseID,
            ...date,
          },
        });
        const indicators = response.data.data;
        setIndicators(indicators);
      } catch (error) {
        captureException(error);
        notification.error({
          message: "Ocurrió un error al obtener los indicadores",
        });
      } finally {
        setLoadingIndicators(false);
      }
    },
    [client, userEnterpriseData.enterpriseID]
  );

  const getIncidences = useCallback(
    async date => {
      try {
        setLoadingIncidences(true);
        const response = await client.query<GetIncidencesResponse>({
          fetchPolicy: "network-only",
          query: getOrderIncidencesByEnterprise,
          variables: {
            enterpriseID: userEnterpriseData.enterpriseID,
            ...date,
          },
        });
        const incidences = response.data.data;
        setIncidences(incidences);
      } catch (error) {
        captureException(error);
        notification.error({
          message: "Ocurrió un error al obtener los incidencias",
        });
      } finally {
        setLoadingIncidences(false);
      }
    },
    [client, userEnterpriseData.enterpriseID]
  );

  const getOrderAttemptSuccessRate = useCallback(
    async date => {
      try {
        setLoadingAttemptSuccessRate(true);
        const response = await client.query<GetAttemptSuccessRateResponse>({
          fetchPolicy: "network-only",
          query: getOrderAttemptSuccessRateByEnterprise,
          variables: {
            enterpriseID: userEnterpriseData.enterpriseID,
            ...date,
          },
        });
        const attemptAccesssRate = response.data.data;
        setAttemptSuccessRate(attemptAccesssRate);
      } catch (error) {
        captureException(error);
        notification.error({
          message: "Ocurrió un error al obtener la tasa de intento de éxito",
        });
      } finally {
        setLoadingAttemptSuccessRate(false);
      }
    },
    [client, userEnterpriseData.enterpriseID]
  );

  useEffect(() => {
    getIndicators(undefined);
    getIncidences(undefined);
    getOrderAttemptSuccessRate(undefined);
  }, [getIndicators, getIncidences, getOrderAttemptSuccessRate]);

  const firstLabels: string[] = [];
  const secondLabels: string[] = [];
  const firstDataBar: number[] = [];
  const secondDataBar: number[] = [];
  const firstDataLine: number[] = [];
  const secondDataLine: number[] = [];

  attemptSuccessRate &&
    attemptSuccessRate.firstVisit.forEach(e => {
      firstLabels.push(e.date);
      firstDataBar.push(e.deliveredOrders);
      const result = (100 * e.deliveredOrders) / e.totalOrders;
      firstDataLine.push(parseFloat(result.toFixed(2)));
    });
  attemptSuccessRate &&
    attemptSuccessRate.secondVisit.forEach(e => {
      secondLabels.push(e.date);
      secondDataBar.push(e.deliveredOrders);
      const result = (100 * e.deliveredOrders) / e.totalOrders;
      secondDataLine.push(parseFloat(result.toFixed(2)));
    });

  const handleDatePickerChange = (date: any, dateString: any) => {
    setRangeDate(rangeDate => ({
      ...rangeDate,
      startDate: dateString[0],
      endDate: dateString[1],
    }));
  };
  const handleDatePickerChange2 = (date: any, dateString: any) => {
    setRangeDate2(rangeDate2=> ({
      ...rangeDate,
      startDate: dateString[0],
      endDate: dateString[1],
    }));
  };

  const searchEvent = () => {
    getIndicators(rangeDate);
    getIncidences(rangeDate);
    // console.log("Line 238",rangeDate.startDate)
  };

  const searchEfectiveEvent = () => {
    getOrderAttemptSuccessRate(rangeDate2);
    console.log("line 247", rangeDate2)
    // console.log("Line 243",rangeDate.startDate)
  };

  const onChange = (key: string) => {
    console.log(key);
  };
  const dateFormat = 'YYYY-MM-DD';
  const currentDaY= new Date();

  const TitleToday = ()=>{
    return (
      <div className="title" 
                style={{ 
                    color: "#595959", 
                    fontSize: "32px", 
                    fontWeight:"bold" 
                    }}
              >
                    Hoy
            </div>
    )
  }
  const TitleCurrentDaY = () =>{   
    const formatCurrentDay = moment(currentDaY).format(dateFormat);
    const startDateRange =rangeDate.startDate
    
    return(
      startDateRange===undefined ||
      startDateRange==='' ||
      startDateRange===formatCurrentDay?
      (<TitleToday/>)
      :(<div></div>)
    ) 
  }
  const TitleCurrentDaY2 = () =>{
    const formatCurrentDay = moment(currentDaY).format(dateFormat);
    const startDateRange =rangeDate2.startDate 
    
    return(
      startDateRange===undefined ||
      startDateRange==='' ||
      startDateRange===formatCurrentDay?
      (<TitleToday/>)
      :(<div></div>)
    ) 
  }

  return (
    <Container>
      <TitleGoBack title={`Estadísticas`} />
      <CustomFilterContainer style={{ padding: "24px ", marginBottom: "0px" }}>
        <Tabs defaultActiveKey="1" onChange={onChange}>
          <TabPane tab="Órdenes" key="1">
            {loadingIncidences || loadingIndicators ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "20px 0",
                }}
              >
                <Spin
                  indicator={
                    <Icon type="loading" style={{ fontSize: 48 }} spin />
                  }
                />
              </div>
            ) : (
              <Content>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Row>Fecha</Row>
                    <RangePicker
                      onChange={(date, dateString) =>
                        handleDatePickerChange(date, dateString)
                      }
                      defaultValue={
                        rangeDate.startDate && rangeDate.endDate
                          ? [
                              moment(rangeDate.startDate),
                              moment(rangeDate.endDate),
                            ]
                          : [moment(currentDaY, dateFormat), moment(currentDaY, dateFormat)]
                      }
                      
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                      
                    />
                  </Col>
                  <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                    <Button
                      onClick={searchEvent}
                      size="large"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginRight: "15px",
                        color: "#307FE2",
                        borderColor: "#307FE2",
                      }}
                    >
                      Buscar
                    </Button>
                  </Col>
                  
                </Row>
                <Row>
                  <TitleCurrentDaY  />
                </Row>
                <GeneralIndicators
                  title="Indicadores generales"
                  data={getDataForGeneralIndicators(indicators)}
                />
                <IndicatorsByOrder
                  title="Indicadores por órdenes"
                  incidences={incidences}
                  indicators={getDataForDonutChart(indicators)}
                />               
              </Content>
            )}
          </TabPane>
          <TabPane tab="Efectividad" key="2">
            {loadingAttemptSuccessRate ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "20px 0",
                }}
              >
                <Spin
                  indicator={
                    <Icon type="loading" style={{ fontSize: 48 }} spin />
                  }
                />
              </div>
            ) : (
              <Content>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Row>Fecha</Row>
                    <RangePicker
                      onChange={(date, dateString) =>
                        handleDatePickerChange2(date, dateString)
                      }
                      defaultValue={
                        rangeDate2.startDate && rangeDate2.endDate 
                          ? [
                              moment(rangeDate2.startDate),
                              moment(rangeDate2.endDate),
                            ]
                          : [moment(currentDaY, dateFormat), moment(currentDaY, dateFormat)]
                      }
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                    <Button
                      onClick={searchEfectiveEvent}
                      size="large"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                        lineHeight: "16px",
                        marginRight: "15px",
                        color: "#307FE2",
                        borderColor: "#307FE2",
                      }}
                    >
                      Buscar
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <TitleCurrentDaY2 />
                </Row>
                {attemptSuccessRate ? (
                  <>
                    <AttemptRate
                      title="Entrega en primera visita - FASR"
                      labels={firstLabels}
                      dataBar={firstDataBar}
                      dataLine={firstDataLine}
                      info="Entrega en primera visita"
                      titleInfo="FASR (First Attempt Success Rate):"
                    />
                    <AttemptRate
                      title="Entrega en segunda visita - SASR"
                      labels={secondLabels}
                      dataBar={secondDataBar}
                      dataLine={secondDataLine}
                      info="Entrega en segunda visita"
                      titleInfo="SASR (Second Attempt Success Rate):"
                    />
                  </>
                ) : null}
              </Content>
            )}
          </TabPane>
        </Tabs>
      </CustomFilterContainer>
    </Container>
  );
};

export default compose<StatisticsPropsWithApollo, StatisticsProps>(
  withApollo,
  withGlobal
)(Statistics);
