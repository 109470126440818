import gql from "graphql-tag";

const getUserData = gql`
  query getUserByID($userID: ID!) {
    getUserByID(id: $userID) {
      id
      enterpriseID
      Enterprise {
        id
        documentNumber
        businessName
        comercialName
        multiplace
        serviceIDs
        cityID
        contactPeople {
          email
        }
        Services {
          id
          name
        }
        extra {
          superAdmin {
            active
            email
            operatorUserID
          }
        }
      }
    }
  }
`;

export { getUserData };
