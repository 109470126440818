import React from "react";
import { compose, withProps } from "recompose";
import { GoogleMap, Marker, withGoogleMap, Polyline } from "react-google-maps";
import { defaultMapOptions } from "./static";
import { TextBox, TitleBox, DescriptionBox } from "./styles";

const MapsTwoPoint = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCAHe6y1MxSEcRPFsrgwzoxelOIWZH36w0&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(props => {
  const {
    markers,
    polylines,
    isMarkerShown,
    markersDraggable,
    zoomMap,
    centerMap,
    latLngStart,
    latLngEnd,
    latLngDefault,
    showTextBox,
    textBoxTitle,
    textBoxDescription,
    colorsLegend,
  } = props;

  return (
    <>
      <GoogleMap
        defaultZoom={zoomMap ? zoomMap : 30}
        defaultCenter={latLngDefault}
        center={
          centerMap === "origin"
            ? latLngStart
            : centerMap === "destination"
            ? latLngEnd
            : latLngDefault
        }
        defaultOptions={{
          ...defaultMapOptions,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.TOP_RIGHT,
          },
        }}
      >
        {polylines &&
          polylines.map((polyline, index) => (
            <Polyline
              key={index}
              path={polyline.path}
              geodesic
              options={{
                strokeColor: polyline.color,
                strokeOpacity: 0.8,
                strokeWeight: 4,
              }}
            />
          ))}
        {isMarkerShown &&
          (markers &&
            markers.map((marker, index) => (
              <Marker
                key={index.toString()}
                defaultZIndex={100 * index}
                draggable={!!markersDraggable}
                onDragEnd={marker.onDragEnd}
                position={marker.position}
                icon={marker.icon}
                label={{
                  color: "#FFF",
                  text: marker.label,
                }}
                options={{
                  crossOnDrag: false,
                }}
              />
            )))}
      </GoogleMap>
      {showTextBox && (
        <TextBox>
          {textBoxTitle && <TitleBox>{textBoxTitle}</TitleBox>}
          <DescriptionBox>{textBoxDescription}</DescriptionBox>
        </TextBox>
      )}
      {colorsLegend && colorsLegend}
    </>
  );
});

export default MapsTwoPoint;
