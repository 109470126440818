import gql from "graphql-tag";

const updateUser = gql`
  mutation updateUser(
    $id: ID!
    $names: String
    $lastname: String
    $email: String
    $emailVerified: Boolean
    $password: String
    $phone: String
    $picture: String
    $statusID: Int
    $roleID: Int
    $countryID: Int
    $cityID: Int
    $polygonID: Int
    $allowedDownTime: Int
    $timeToDisplayCounter: Int
  ) {
    UpdateUser: updateUser(
      id: $id
      names: $names
      lastname: $lastname
      email: $email
      emailVerified: $emailVerified
      password: $password
      phone: $phone
      picture: $picture
      statusID: $statusID
      roleID: $roleID
      countryID: $countryID
      cityID: $cityID
      polygonID: $polygonID
      allowedDownTime: $allowedDownTime
      timeToDisplayCounter: $timeToDisplayCounter
    ) {
      id
    }
  }
`;

const updateStatusUsers = gql`
  mutation updateStatusUsers($userIDs: [String!]!, $statusID: Int!) {
    updateStatusUsers(userIDs: $userIDs, statusID: $statusID) {
      message
      motive
      statusCode
    }
  }
`;

const createUserByEnterprise = gql`
  mutation createUserByEnterprise(
    $names: String!
    $lastname: String!
    $email: String!
    $password: String!
    $enterpriseID: Int!
    $principalEnterpriseUser: Boolean!
    $picture: String
    $allowedDownTime: Int
    $timeToDisplayCounter: Int
  ) {
    CreateUserByEnterprise: createUserByEnterprise(
      names: $names
      lastname: $lastname
      email: $email
      password: $password
      enterpriseID: $enterpriseID
      principalEnterpriseUser: $principalEnterpriseUser
      picture: $picture
      allowedDownTime: $allowedDownTime
      timeToDisplayCounter: $timeToDisplayCounter
    ) {
      motive
      message
      statusCode
    }
  }
`;

const deleteUser = gql`
  mutation deleteUser($id: ID!) {
    DeleteUser: deleteUser(id: $id) {
      message
      motive
      statusCode
    }
  }
`;

const deleteUsers = gql`
  mutation deleteUsers(
    $ids: [String!]
    $userNames: String
    $statusID: Int
    $rangeBy: UserDateRangeInput
  ) {
    DeleteUsers: deleteUsers(
      ids: $ids
      userNames: $userNames
      statusID: $statusID
      rangeBy: $rangeBy
    ) {
      message
      motive
      statusCode
    }
  }
`;

export {
  updateUser,
  createUserByEnterprise,
  deleteUser,
  deleteUsers,
  updateStatusUsers,
};
