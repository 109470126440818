import gql from "graphql-tag";

const getAllEnterprises = gql`
  query getAllEnterprises {
    AllEnterprises: getAllEnterprises {
      count
      rows {
        id
        comercialName
        documentNumber
      }
    }
  }
`;

const getBranchOfficeByEnterpriseID = gql`
  query getBranchOfficeByEnterpriseID($enterpriseID: Int!) {
    AllBranchOffices: getBranchOfficeByEnterpriseID(
      enterpriseID: $enterpriseID
    ) {
      id
      branchOfficeCode
      branchOfficeName
      branchOfficeAddress
      cityID
      serviceIDs
      Services {
        id
        name
      }
      City {
        id
        name
      }
      Enterprise {
        id
        address
        businessName
        comercialName
      }
      createdAt
      updatedAt
    }
  }
`;

export { getAllEnterprises, getBranchOfficeByEnterpriseID };
