import { Col, message, Spin, Icon, notification } from "antd";
import React from "react";
import axios from "axios";
import { CustomRow } from "../../../components/Grid";
import { withFirebase } from "../../../firebase";
import { ContainerDragger } from "./style";

const urlEndPoint = process.env.REACT_APP_API_ENDPOINT;
const functionUpload = process.env.REACT_APP_NAME_UPLOAD_ALL;
class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      folderName: "csv",
      validFormats: [
        "",
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    };
  }

  fileNameStamp = name => {
    const getDate = new Date();
    getDate.setHours(getDate.getHours() - 5);
    let timestamp = getDate.toISOString();
    timestamp = timestamp.replace(/:/g, "-");
    timestamp = timestamp.replace(/\./g, "-");
    return timestamp + name.replace(/ /g, "_");
  };

  beforeUpload = file => {
    if (this.state.validFormats) {
      console.log(
        this.state.validFormats,
        file.type,
        this.state.validFormats.indexOf(file.type)
      );
      if (this.state.validFormats.indexOf(file.type) >= 0) {
        return true;
      }
      message.error("El archivo no es válido.");
      return false;
    }
    return true;
  };

  customPost = ({
    // action,
    // data,
    file,
    // filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const { folderName } = this.state;
    const { firebase, handleSubmit } = this.props;

    // uploadingFile && uploadingFile(true);

    const fileStamp = this.fileNameStamp(file.name);
    const key = `${folderName}/${fileStamp}`;

    const task = firebase.refStorage.child(key).put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        const percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress({ percent: percentage });
      },
      err => {
        console.log("error on upload", err);
        message.error("Error al subir archivo");
        // uploadingFile && uploadingFile(false);
        // uploadingError && uploadingError(err, file.uid);
        return onError(err);
      },
      () => {
        task.snapshot.ref.getDownloadURL().then(downloadURL => {
          const { name, uid, url = downloadURL, status = "done" } = file;
          onSuccess({ name, uid, url, status, file: downloadURL });
          console.log("url", url);
          handleSubmit(url, file.name, fileStamp);
          // uploadingFile && uploadingFile(false);
          // uploadComplete && uploadComplete({ name, uid, url, status });
          // message.success(`Archivo subido correctamente`);
        });
      }
    );
  };

  onDownload = () => {
    this.setState({ downloading: true });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = {
      countryID: this.props.countryID,
    };

    axios
      .post(
        `${process.env.REACT_APP_CFUNCTION_DOMAIN}/templateScheme`,
        body,
        config
      )
      .then(res => {
        if (res.data) {
          notification.success({ message: "Descarga exitosa" });
          const url = res.data.urlTemplate;
          window.open(url, "_blank");
          this.setState({ downloading: false });
        }
      })
      .catch(e => {
        this.setState({ downloading: false });
        message.error("Error en la descarga CSV, intentelo de nuevo.");
      });
  };

  render() {
    const { downloading } = this.state;
    return (
      <CustomRow gutter={24} type="flex" justify="center">
        <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 8 }}>
          <p className="title">Carga un archivo CSV</p>
          <p className="description">
            <span
              style={{
                color: '#1890ff',
                cursor: 'pointer'
              }}
              onClick={() => this.onDownload()}
            >
              Descargar plantilla {downloading ? <Spin size="small" /> : <Icon type="download" style={{ fontSize: "16px" }} />}
            </span>
          </p>
          <ContainerDragger
            action={`${urlEndPoint}/${functionUpload}`}
            beforeUpload={this.beforeUpload}
            customRequest={this.customPost}
            data={{ hola: true }}
            headers={{
              "X-Requested-With": null,
            }}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <img
                alt="img"
                src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FVector.png?alt=media&token=f0fd9cc9-b8a1-4e68-9bb2-44e2b41fffac"
                width="32px"
                height="43px"
              />
            </p>
            <p
              className="ant-upload-text"
              style={{
                color: "#8C8C8C",
                
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "8px",
              }}
            >
              Arrastra el archivo aquí{" "}
            </p>
            <p
              className="ant-upload-text"
              style={{
                color: "#8C8C8C",
                
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "6px",
              }}
            >
              o{" "}
            </p>

            <p
              className="ant-upload-text"
              style={{
                
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                textDecorationLine: "underline",
                color: "#307FE2",
                cursor: "pointer",
              }}
            >
              Elige un archivo{" "}
            </p>
          </ContainerDragger>
        </Col>
      </CustomRow>
    );
  }
}

const UploadFileWraper = withFirebase(UploadFile);
export default UploadFileWraper;
