import { Button, Checkbox, Col, Form, Row, notification, Select } from "antd";
import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { navigate } from "@reach/router";
import { withGlobal } from "../../../component-global";
import CustomCollapse from "../../../components/Collapse/CumstomCollapse";
import {
  getEnterprisePlatformsByName,
  getServices,
} from "../../../graphQl/queries/services";
import { Container, TitleGoBack } from "../../../components/Container";
import InputComponent from "../../../components/FormFields/InputWithBorderComponent";
import BodyContainer from "../../../components/Layout/BodyContainer";
import { withGlobalContext } from "../../../utils/globalContext";
import CheckBoxComponentWithBorderNew from "../../../components/FormFields/CheckBoxWithBorderComponentNew";
import SelectComponent from "../../../components/FormFields/SelectWithBorderComponent";
import SwitchComponent from "../../../components/FormFields/SwitchComponent";
import { updateEnterprisePlatforms } from "../../../graphQl/mutations/enterprisePlatforms";
import * as route from "../../../routes/routes";

const { Option } = Select;

class EditPlatforms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingOnSave: false,
      allServices: [],
      platforms: {},
    };
  }

  componentDidMount = async () => {
    await this.getAllServices();
    await this.getInfoPlatform();
  };

  getAllServices = async () => {
    const {
      data: { AllServices },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getServices,
    });
    this.setState({
      allServices: AllServices && AllServices.rows,
    });
  };

  getInfoPlatform = async () => {
    const {
      data: { GetEnterprisePlatformsByName },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getEnterprisePlatformsByName,
      variables: {
        id: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
        name: this.props.platform.toUpperCase(),
      },
    });
    this.setState({
      platforms:
        GetEnterprisePlatformsByName || GetEnterprisePlatformsByName.rows,
    });
  };

  sendUpdateGraph = async variables => {
    const { data, errors } = await this.props.client.mutate({
      mutation: updateEnterprisePlatforms,
      variables,
    });

    if (errors) {
      console.log(errors);
      notification.error({
        message: `No se pudo guardar, error en el sistema.`,
      });
      this.setState({ loadingOnSave: false });
    }

    return data ? data.UpdateEnterprisePlatforms : null;
  };

  onSaveEnterprisePlatform = async (platform, values) => {
    let variables = {
      id: this.state.platforms.id,
    };

    switch (platform.toUpperCase()) {
      case "SHOPIFY":
        if (values.shop !== this.state.platforms.idPlatform)
          variables = {
            ...variables,
            idPlatform: values.shop,
          };
        if (values.switchBranch !== this.state.platforms.branchDefaultPlatform)
          variables = {
            ...variables,
            branchDefaultPlatform: values.switchBranch,
          };
        if (values.serviceIDs)
          variables = {
            ...variables,
            services: values.serviceIDs,
          };
        if (values.automaticApproval !== this.state.platforms.automaticApproval)
          variables = {
            ...variables,
            automaticApproval: values.automaticApproval,
          };
        if (values.localShipping !== this.state.platforms.localShipping)
          variables = {
            ...variables,
            localShipping: values.localShipping,
          };
        if (values.manualPrice !== this.state.platforms.manualPrice)
          variables = {
            ...variables,
            manualPrice: values.manualPrice,
          };

        if (
          (values.manualPrice || values.localShipping) &&
          values.serviceIDs.length > 1
        ) {
          notification.error({
            message: `Solo se puede seleccionar un servicio cuando tiene activo Precio manual o Envio local.`,
          });
          return;
        }

        if (Object.keys(variables).length > 1) {
          const savePlatform = await this.sendUpdateGraph(variables);
          if (savePlatform) notification.success(savePlatform);
        }

        return;
      case "MULTIVENDE":
        if (values.automaticApproval !== this.state.platforms.automaticApproval)
          variables = {
            ...variables,
            automaticApproval: values.automaticApproval,
          };

        if (values.services !== this.state.platforms.services[0])
          variables = {
            ...variables,
            services: Array.isArray(values.services)
              ? values.services
              : [values.services],
          };

        if (Object.keys(variables).length > 1) {
          const savePlatformMulti = await this.sendUpdateGraph(variables);
          if (savePlatformMulti) notification.success(savePlatformMulti);
        }

        return;
      default:
        console.log("NOT OPTIONS FOR PLATFORM IN SAVE.")
    }
  };

  onDeleteEnterprisePlatforms = async () => {
    const variables = {
      id: this.state.platforms.id,
      deleted: true,
    };
    const deletedPlatform = await this.sendUpdateGraph(variables);
    if (deletedPlatform) {
      notification.success(deletedPlatform);
      navigate(`/${route.dashboard}/${route.myCompany}`);
    }
  };

  onEnabledEnterprisePlatforms = async () => {
    const enabled = !this.state.platforms.enabled;
    const variables = {
      id: this.state.platforms.id,
      enabled,
    };

    const enabledPlatform = await this.sendUpdateGraph(variables);
    if (enabledPlatform) {
      notification.success(enabledPlatform);
      this.state.platforms.enabled = enabled;
      this.setState(this.state.platforms);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        if (err.shop)
          notification.error({
            message: "¡Dirección de tienda Shopify es requerido!",
          });
        else if (err.serviceIDs) {
          notification.error({
            message: "¡Seleccione almenos un servicio es requerido!",
          });
        }
        return;
      }
      this.onSaveEnterprisePlatform(this.props.platform, values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { platforms } = this.state;

    const optionsCheckGroup = (AllServicesActive, spanSize) => {
      const arrayRender = AllServicesActive.map(obj => {
        return (
          <Col
            span={spanSize}
            key={`colCheck_${parseInt(obj.id, 10)}`}
            style={{
              marginBottom: 15,
              marginTop: 5,
            }}
          >
            <Checkbox value={parseInt(obj.id, 10)} key={parseInt(obj.id, 10)}>
              {obj.name}
            </Checkbox>
          </Col>
        );
      });
      return <Row>{arrayRender}</Row>;
    };

    const renderAllService = AllServicesActive => {
      if (!AllServicesActive) return [];
      return (AllServicesActive || []).map(obj => (
        <Option key={parseInt(obj.id, 10)} value={parseInt(obj.id, 10)}>
          {obj.name}
        </Option>
      ));
    };

    const GetPlatform = (platform, data) => {
      switch (platform.toUpperCase()) {
        case "SHOPIFY":
          return (
            <Form onSubmit={this.handleSubmit}>
              <CustomCollapse headerTitle="Datos de nueva integración">
                <Row gutter={[24, 16]}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <InputComponent
                      required
                      style={{ width: "100%" }}
                      getFieldDecorator={getFieldDecorator}
                      title="Url/dominio de tienda"
                      decodator="shop"
                      addonBefore="https://"
                      message="Url de tienda vacio"
                      value={data.idPlatform}
                    />
                  </Col>
                </Row>
                <Row gutter={[24, 16]}>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <SwitchComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="switchBranch"
                      title="Sucursal default de shopify"
                      required
                      message="La sucursal"
                      value={data.branchDefaultPlatform}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <SwitchComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="localShipping"
                      title="Envio local"
                      required
                      message="Configuracion de envio local"
                      value={data.localShipping}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <SwitchComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="manualPrice"
                      title="Precio manual o propio"
                      required
                      message="Precio configurado por el cliente"
                      value={data.manualPrice}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <SwitchComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="automaticApproval"
                      title="Aprobacion automatica de orden"
                      required
                      message="Aprobacion automatica"
                      value={data.automaticApproval}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span style={{ color: "#595959" }}>Servicios</span>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <CheckBoxComponentWithBorderNew
                      getFieldDecorator={getFieldDecorator}
                      placeholder="Seleccione un servicio"
                      decodator="serviceIDs"
                      childOptions
                      childrenOptions={optionsCheckGroup(
                        this.state.allServices,
                        6
                      )}
                      required
                      value={data.services}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <InputComponent
                      required
                      style={{ width: "100%" }}
                      getFieldDecorator={getFieldDecorator}
                      title="Token"
                      decodator="token"
                      value={data.token}
                      disabled
                    />
                  </Col>
                </Row>
                <Row
                  gutter={24}
                  type="flex"
                  justify="end"
                  style={{ flexDirection: "row" }}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 9 }}
                    md={{ span: 6 }}
                    xl={{ span: 4 }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexContent: "flex-end",
                    }}
                  >
                    <Button
                      htmlType="button"
                      type="secondary"
                      style={{
                        marginTop: "20px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "20px",
                        borderColor: "#F5222D",
                        color: "#F5222D",
                        height: "40px",
                      }}
                      onClick={() => this.onDeleteEnterprisePlatforms()}
                    >
                      Eliminar Tienda
                    </Button>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 9 }}
                    md={{ span: 6 }}
                    xl={{ span: 5 }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexContent: "flex-end",
                    }}
                  >
                    <Button
                      htmlType="button"
                      type="secondary"
                      style={{
                        marginTop: "20px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "20px",
                        borderColor: "#f2bd10",
                        color: "#f2bd10",
                        height: "40px",
                      }}
                      onClick={() => this.onEnabledEnterprisePlatforms()}
                    >
                      {this.state.platforms.enabled
                        ? `Desconectar Tienda`
                        : `Habilitar Tienda`}
                    </Button>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 9 }}
                    md={{ span: 6 }}
                    xl={{ span: 4 }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexContent: "flex-end",
                    }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="large"
                      style={{
                        marginTop: 20,
                        fontSize: "14px",
                        background: "#307fe2",
                        borderColor: "#307fe2",
                        color: "#fff",
                      }}
                      loading={this.state.loadingOnSave}
                    >
                      Guardar Tienda
                    </Button>
                  </Col>
                </Row>
              </CustomCollapse>
            </Form>
          );
        case "MULTIVENDE":
          return (
            <Form onSubmit={this.handleSubmit}>
              <CustomCollapse headerTitle="Datos de nueva integración">
                <Row gutter={[24, 16]}>
                  <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                    <InputComponent
                      required
                      style={{ width: "100%" }}
                      getFieldDecorator={getFieldDecorator}
                      title="Id Merchant"
                      decodator="shop"
                      value={data.idPlatform}
                      disabled
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                    <InputComponent
                      style={{ width: "100%" }}
                      getFieldDecorator={getFieldDecorator}
                      title="Token"
                      decodator="token"
                      value={data.token}
                      disabled
                    />
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                    <InputComponent
                      style={{ width: "100%" }}
                      getFieldDecorator={getFieldDecorator}
                      title="Refresh Token"
                      decodator="refreshToken"
                      value={data.refreshToken}
                      disabled
                    />
                  </Col>
                </Row>
                <Row gutter={[24, 16]}>
                  <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                    <SelectComponent
                      style={{ width: "100%" }}
                      getFieldDecorator={getFieldDecorator}
                      title="Tipo de servicio"
                      decodator="services"
                      message="Ingrese tipo de servicio"
                      required
                      options={renderAllService(this.state.allServices)}
                      value={data.services}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <SwitchComponent
                      getFieldDecorator={getFieldDecorator}
                      decodator="automaticApproval"
                      title="Aprobacion automatica de orden"
                      required
                      message="Aprobacion automatica"
                      value={data.automaticApproval}
                    />
                  </Col>
                </Row>
                <Row
                  gutter={24}
                  type="flex"
                  justify="end"
                  style={{ flexDirection: "row" }}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 9 }}
                    md={{ span: 6 }}
                    xl={{ span: 4 }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexContent: "flex-end",
                    }}
                  >
                    <Button
                      htmlType="button"
                      type="secondary"
                      style={{
                        marginTop: "20px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "20px",
                        borderColor: "#F5222D",
                        color: "#F5222D",
                        height: "40px",
                      }}
                      onClick={() => this.onDeleteEnterprisePlatforms()}
                    >
                      Eliminar Tienda
                    </Button>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 9 }}
                    md={{ span: 6 }}
                    xl={{ span: 4 }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexContent: "flex-end",
                    }}
                  >
                    <Button
                      htmlType="button"
                      type="secondary"
                      style={{
                        marginTop: "20px",
                        fontWeight: "bold",
                        fontSize: "14px",
                        lineHeight: "20px",
                        borderColor: "#f2bd10",
                        color: "#f2bd10",
                        height: "40px",
                      }}
                      onClick={() => this.onEnabledEnterprisePlatforms()}
                    >
                      {this.state.platforms.enabled
                        ? `Desconectar Tienda`
                        : `Habilitar Tienda`}
                    </Button>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 9 }}
                    md={{ span: 6 }}
                    xl={{ span: 4 }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexContent: "flex-end",
                    }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="large"
                      style={{
                        marginTop: 20,
                        fontSize: "14px",
                        background: "#307fe2",
                        borderColor: "#307fe2",
                        color: "#fff",
                      }}
                      loading={this.state.loadingOnSave}
                    >
                      Guardar Tienda
                    </Button>
                  </Col>
                </Row>
              </CustomCollapse>
            </Form>
          );
        default:
          return <div>Opcion Incorrecta</div>;
      }
    };

    return (
      <Container>
        <TitleGoBack
          title={`Datos de conexion para ${this.props.platform}`}
          linkBack={`/dashboard/myCompany`}
        />
        <BodyContainer gutter={16} justify="center">
          {GetPlatform(this.props.platform, platforms)}
        </BodyContainer>
      </Container>
    );
  }
}

const WrapperEditIntegration = withGlobal(EditPlatforms);
const FormNewEditIntegration = Form.create()(WrapperEditIntegration);

export default compose(
  withApollo,
  withGlobalContext
)(FormNewEditIntegration);
