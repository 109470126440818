import React from "react";
import { Row, Col, Form, Button, Icon } from "antd";
// import { Button, Row, Icon, Col, Form, AutoComplete, Select, DatePicker } from "antd";

import { compose } from "recompose";
// import { withApollo } from "react-apollo"; // graphql

import { navigate } from "@reach/router";
import { InputComponentWithBorder } from "../../../components/FormFields";
import { ContainerGlobal } from "../../../components/Form/SearchContainer";
import { withGlobalContext } from "../../../utils/globalContext";
import { withGlobal } from "../../../component-global";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  cleanFields = () => {
    this.props.form.resetFields();
    this.props.cleanSearch();
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      const filter = {};
      if (values.name) filter.userNames = values.name;
      this.props.SearchUsers(filter);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loadingBtnSearch } = this.props;

    return (
      <ContainerGlobal gutter={24} justify="center">
        <Row gutter={16}>
          <Form onSubmit={this.handleSubmit}>
            <Col xs={24} sm={24} md={20} lg={20} xl={20}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <InputComponentWithBorder
                  title="Nombre de usuario"
                  getFieldDecorator={getFieldDecorator}
                  placeholder="Ingresa un nombre"
                  decodator="name"
                />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <Button
                  type="primary"
                  size="large"
                  ghost
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    backgroundColor: "#307FE2",
                    borderColor: "#307FE2",
                    color: "#307FE2",
                    fontSize: "14px",
                  }}
                  htmlType="submit"
                  loading={loadingBtnSearch}
                  // disabled
                >
                  Buscar
                </Button>
              </Col>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Button
                htmlType="button"
                type="secondary"
                style={{
                  width: "100%",
                  marginTop: "20px",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#307FE2",
                  height: "40px",
                }}
                onClick={() => navigate(`/dashboard/newAccount`)}
              >
                Nuevo Usuario{" "}
                <Icon
                  type="plus"
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    color: "#307FE2",
                  }}
                />
              </Button>
            </Col>
          </Form>
        </Row>
      </ContainerGlobal>
    );
  }
}
const WrapperHeader = withGlobal(Header);
const HeaderForm = Form.create()(WrapperHeader);

export default compose(withGlobalContext)(HeaderForm);
