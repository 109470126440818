import React from "react";
import { Avatar } from "antd";
import { Paper } from "../../../../components/Container";
import { Regular, Resalted } from ".";

const affiliatePhotoWidth = 86;

const AffiliateInfo = props => {
  const { affiliate } = props;

  return (
    <Paper>
      <div style={{ display: "flex" }}>
        <div style={{ width: affiliatePhotoWidth }}>
          <Avatar
            size={affiliatePhotoWidth}
            src={
              affiliate && affiliate.User && affiliate.User.picture
                ? affiliate.User.picture
                : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
            }
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: 20,
          }}
        >
          {affiliate && affiliate.User ? (
            <span
              style={{
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {[affiliate.User.names, affiliate.User.lastname].join(" ")}
            </span>
          ) : (
            <span
              style={{
                fontWeight: "normal",
                color: "gray",
                fontSize: 16,
              }}
            >
              No hay chazki asignado
            </span>
          )}
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <div style={{ display: "flex", marginBottom: 12 }}>
          <Regular>Placa:</Regular>
          <Resalted style={{ marginLeft: "auto" }}>
            {affiliate &&
            affiliate.CurrentVehicle &&
            affiliate.CurrentVehicle.vehiclePlate
              ? affiliate.CurrentVehicle.vehiclePlate
              : "-"}
          </Resalted>
        </div>
        <div style={{ display: "flex", marginBottom: 12 }}>
          <Regular>Tipo de vehículo:</Regular>
          <Resalted style={{ marginLeft: "auto" }}>
            {affiliate &&
            affiliate.CurrentVehicle &&
            affiliate.CurrentVehicle.CategoryVehicle
              ? affiliate.CurrentVehicle.CategoryVehicle.subclass
              : "-"}
          </Resalted>
        </div>
        <div style={{ display: "flex", marginBottom: 12 }}>
          <Regular>Teléfono:</Regular>
          <Resalted style={{ marginLeft: "auto" }}>
            {affiliate && affiliate.User && affiliate.User.phone
              ? affiliate.User.phone
              : "-"}
          </Resalted>
        </div>
        {/* TODO: Define this field */}
        {/* <div style={{ display: "flex" }}>
          <Regular>Tipo de servicio:</Regular>
          <Resalted style={{ marginLeft: "auto" }}>To define</Resalted>
        </div> */}
      </div>
    </Paper>
  );
};

export default AffiliateInfo;
