import React from "react";
import { Drawer } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { ContentInfo, ContainerCols } from "./style";

class DrawerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const {} = this.state;
    const {
      onClose,
      visible,
      selectedDelvery,
      // onOpenModalEditAddress,
    } = this.props;

    return (
      <Drawer
        title={null}
        width={520}
        onClose={onClose}
        visible={visible} // visibleDrawer
      >
        <div
          style={{
            width: "100%",
            padding: "48px",
          }}
        >
          <ContentInfo>
            <p className="title-drawer">Datos de la orden</p>
            <ContainerCols
              label="TrackCode:"
              text={(selectedDelvery && selectedDelvery.trackCode) || "-"}
            />
            <ContainerCols
              label="Nombre del cliente:"
              text={
                (selectedDelvery &&
                  selectedDelvery.Enterprise &&
                  selectedDelvery.Enterprise.comercialName) ||
                "-"
              }
            />
            <ContainerCols
              label="Tipo de servicio:"
              text={
                (selectedDelvery &&
                  selectedDelvery.Service &&
                  selectedDelvery.Service.name) ||
                "-"
              }
            />
            <ContainerCols
              label="Cantidad de bultos:"
              text={(selectedDelvery && selectedDelvery.packageQuantity) || "-"}
            />
            <ContainerCols
              label="Método de pago:"
              text={
                (selectedDelvery &&
                  selectedDelvery.PaymentMethodID &&
                  selectedDelvery.PaymentMethodID.subclass) ||
                "-"
              }
            />
            <ContainerCols
              label="Descripción del producto:"
              text={
                (selectedDelvery && selectedDelvery.productDescription) || "-"
              }
            />
            <ContainerCols
              label="Notas de envío:"
              text={(selectedDelvery && selectedDelvery.dropNotes) || "-"}
            />
            <ContainerCols
              label="Ciudad:"
              text={(selectedDelvery && selectedDelvery.City.name) || "-"}
            />
          </ContentInfo>
          <ContentInfo>
            <p className="title-drawer">Datos de recolección</p>
            <ContainerCols
              label="Departamento:"
              text={
                (selectedDelvery && selectedDelvery.pickUpSecondaryReference) ||
                "-"
              }
            />
            <ContainerCols
              label="Distrito:"
              text={
                (selectedDelvery && selectedDelvery.pickUpPrimaryReference) ||
                "-"
              }
            />
            <ContainerCols
              label="Dirección:"
              text={(selectedDelvery && selectedDelvery.pickUpAddress) || "-"}
            />
            <ContainerCols
              label="Contacto:"
              text={
                (selectedDelvery && selectedDelvery.pickUpContactName) || "-"
              }
            />
            <ContainerCols
              label="Referencia:"
              text={
                (selectedDelvery && selectedDelvery.pickUpAddressReference) ||
                "-"
              }
            />
          </ContentInfo>
          <ContentInfo>
            <p className="title-drawer">Datos de envío</p>
            <ContainerCols
              label="Departamento:"
              text={
                (selectedDelvery && selectedDelvery.dropSecondaryReference) ||
                "-"
              }
            />
            <ContainerCols
              label="Distrito:"
              text={
                (selectedDelvery && selectedDelvery.dropPrimaryReference) || "-"
              }
            />
            <ContainerCols
              label="Dirección:"
              text={(selectedDelvery && selectedDelvery.dropAddress) || "-"}
            />
            <ContainerCols
              label="Contacto:"
              text={(selectedDelvery && selectedDelvery.dropContactName) || "-"}
            />
            <ContainerCols
              label="Referencia:"
              text={
                (selectedDelvery && selectedDelvery.dropAddressReference) || "-"
              }
            />
          </ContentInfo>
        </div>
      </Drawer>
    );
  }
}

export default compose(withApollo)(DrawerComponent);
