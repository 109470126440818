import React from "react";
import { Row, Col, Form, Button, Spin, notification, Select } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { navigate } from "@reach/router";
import TitleGoBack from "../../../../components/Container/titleGoBack";
import { Container } from "../../../../components/Container";
import BodyContainer from "../../../../components/Layout/BodyContainer";
import CustomCollapse from "../../../../components/Collapse/CumstomCollapse";
import InputComponent from "../../../../components/FormFields/InputWithBorderComponent";
import { withGlobal } from "../../../../component-global";
import { withGlobalContext } from "../../../../utils/globalContext";
import {
  getUserByID,
  getTypesTimeCounter,
  getTypesDownTime,
} from "../../../../graphQl/queries/Accounts";
import {
  updateUser,
  createUserByEnterprise,
} from "../../../../graphQl/mutations/accounts";
import ImageUpload from "../../../../components/UploadImg";
import { accounts } from "../../../../routes/routes";
import SelectWithBorderComponent from "../../../../components/FormFields/SelectWithBorderComponent";

const defaultImgProfile =
  "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2Fuser.svg?alt=media&token=93cce15e-8f5b-4303-b9c7-ce570b59bf2b";

const { Option } = Select;

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataUser: null,
      allowedDownTime: null,
      timeToDisplayCounter: null,
    };
    // console.log(this.props);
  }

  componentDidMount = async () => {
    this.getUserData();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const filter = {};

        if (values.names) {
          filter.names = values.names;
        }
        if (values.lastname) {
          filter.lastname = values.lastname;
        }
        if (values.email) {
          filter.email = values.email;
        }
        if (values.password) {
          filter.password = values.password;
        }
        if (this.state.urlImgProfile) {
          filter.picture = this.state.urlImgProfile;
        }
        if (values.allowedDownTime) {
          filter.allowedDownTime = values.allowedDownTime;
        }
        if (values.timeToDisplayCounter) {
          filter.timeToDisplayCounter = values.timeToDisplayCounter;
        }

        if (this.props.userID) this.onSaveChanges(filter);
        else {
          this.onCreateUser(filter);
        }
      } else {
        notification.error({
          message: "Por favor, complete campos requeridos.",
        });
      }
    });
  };

  getUserData = async () => {
    try {
      this.setState({ loading: true });

      let dataUser = null;
      if (this.props.userID) {
        const {
          data: { UserByID },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getUserByID,
          variables: {
            id: this.props.userID,
          },
        });
        dataUser = UserByID;
      }
      const dataTypesDownTime = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getTypesDownTime,
        variables: {},
      });

      const dataTypesTimeCounter = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getTypesTimeCounter,
        variables: {},
      });

      this.setState({
        dataUser,
        allowedDownTime: dataTypesDownTime.data.GeneralStatus,
        timeToDisplayCounter: dataTypesTimeCounter.data.GeneralStatus,
        loading: false,
      });
    } catch (error) {
      this.setState({
        dataUser: null,
        allowedDownTime: [],
        timeToDisplayCounter: [],
        loading: false,
      });
      console.log(error);
    }
  };

  onUpdateFields = imgDefault => {
    this.setState({ urlImgProfile: imgDefault });
    // this.setState({ imgDefault });
  };

  onDeleteImgProfile = () => {
    this.setState({ urlImgProfile: defaultImgProfile });
    // this.setState({ imgDefault: null });
  };

  onSaveChanges = filter => {
    this.setState({ loadingOnSave: true });
    const variables = {
      ...filter,
      id: this.props.userID,
    };
    this.props.client
      .mutate({
        mutation: updateUser,
        update: (cache, { data: { UpdateUser } }) => {
          console.log(UpdateUser);
          notification.success({
            message: `Se actualizó el usuario con éxito`,
          });
          this.setState({ loadingOnSave: false });
          navigate(`/dashboard/${accounts}`);
        },
        variables,
      })
      .catch(err => {
        // captureException(err);
        console.log(err);
        notification.error({
          message: "No se pudo guardar, error en el sistema.",
        });
        this.setState({ loadingOnSave: false });
      });
  };

  onCreateUser = filter => {
    this.setState({ loadingOnSave: true });
    const variables = {
      ...filter,
      id: this.props.userID,
      enterpriseID: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
      principalEnterpriseUser: false,
    };
    this.props.client
      .mutate({
        mutation: createUserByEnterprise,
        update: (cache, { data: { CreateUserByEnterprise } }) => {
          if (CreateUserByEnterprise.statusCode === 200)
            notification.success({ message: CreateUserByEnterprise.message });
          else notification.error({ message: CreateUserByEnterprise.message });

          this.setState({ loadingOnSave: false });
          navigate(`/dashboard/${accounts}`);
        },
        variables,
      })
      .catch(err => {
        // captureException(err);
        console.log(err);
        notification.error({
          message: "No se pudo guardar, error en el sistema.",
        });
        this.setState({ loadingOnSave: false });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { dataUser } = this.state;

    return (
      <Container>
        <TitleGoBack
          title={
            dataUser
              ? `${dataUser && dataUser.names} ${dataUser &&
                  dataUser.lastname} `
              : "Datos del contacto"
          }
        />
        <Form onSubmit={this.handleSubmit}>
          <BodyContainer gutter={16} justify="center">
            <div
              style={{
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "3rem",
                padding: 5,
              }}
            >
              <CustomCollapse headerTitle="Datos generales">
                <Spin spinning={this.state.loading}>
                  <div style={{ marginBottom: 30, width: 250 }}>
                    <ImageUpload
                      imgDefault={
                        this.state.urlImgProfile ||
                        (dataUser && dataUser.picture)
                      }
                      accept="image/*,.pdf"
                      multiple
                      folderName="nintendo"
                      target="profilesUsers"
                      onUpdateFields={this.onUpdateFields}
                      onDeleteImgProfile={this.onDeleteImgProfile}
                      maxWidth={800}
                      maxHeight={800}
                    />
                  </div>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <InputComponent
                        getFieldDecorator={getFieldDecorator}
                        decodator="names"
                        title="Nombres"
                        required
                        value={dataUser && dataUser.names}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <InputComponent
                        getFieldDecorator={getFieldDecorator}
                        decodator="lastname"
                        title="Apellidos"
                        required
                        value={dataUser && dataUser.lastname}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <InputComponent
                        getFieldDecorator={getFieldDecorator}
                        decodator="email"
                        title="Correo"
                        required
                        value={dataUser && dataUser.email}
                      />
                    </Col>
                    {!this.props.userID && (
                      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <InputComponent
                          getFieldDecorator={getFieldDecorator}
                          decodator="password"
                          title="Contraseña"
                          required
                          value={dataUser && dataUser.password}
                          isPassword
                        />
                      </Col>
                    )}
                  </Row>
                </Spin>
              </CustomCollapse>

              <CustomCollapse headerTitle="Seguridad">
                <Spin spinning={this.state.loading}>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <SelectWithBorderComponent
                        getFieldDecorator={getFieldDecorator}
                        title="Tiempo de inactividad permitido"
                        decodator="allowedDownTime"
                        required
                        options={
                          this.state.allowedDownTime &&
                          this.state.allowedDownTime.map(obj => (
                            <Option
                              key={obj.subtype}
                              value={parseInt(obj.subtype, 10)}
                            >
                              {obj.subclass} min
                            </Option>
                          ))
                        }
                        value={dataUser && dataUser.allowedDownTime}
                        placeholder="Selecciona"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <SelectWithBorderComponent
                        getFieldDecorator={getFieldDecorator}
                        title="Mostrar el contador faltando"
                        decodator="timeToDisplayCounter"
                        required
                        options={
                          this.state.timeToDisplayCounter &&
                          this.state.timeToDisplayCounter.map(obj => (
                            <Option
                              key={obj.subtype}
                              value={parseInt(obj.subtype, 10)}
                            >
                              {obj.subclass} min
                            </Option>
                          ))
                        }
                        value={dataUser && dataUser.timeToDisplayCounter}
                        placeholder="Selecciona"
                      />
                    </Col>
                  </Row>
                </Spin>
              </CustomCollapse>
            </div>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              ghost
              style={{ position: "absolute", bottom: 24, right: 24 }}
              loading={this.state.loadingOnSave}
            >
              Guardar
            </Button>
          </BodyContainer>
        </Form>
      </Container>
    );
  }
}

const WrapperAccounts = withGlobal(Accounts);
const FormAccounts = Form.create()(WrapperAccounts);

export default compose(
  withApollo,
  withGlobalContext
)(FormAccounts);
