export const home = "/";
export const uniqueSend = "uniqueSend";
export const dashboard = "dashboard";
export const myCompany = "myCompany";
export const branches = "branches";
export const trackingPackage = "trackingPackage";
export const routeTracking = "routeTracking";
export const routeDetails = "routeDetails";
export const nonExistent = "non-existent";
export const uploadcsv = "upload-csv";
export const branchOffice = "branchOffice";
export const editBranch = "editBranch";
export const labelPrint = "labelPrint";
export const previewcsv = "preview-csv";
export const orderHistory = "orderHistory";
export const accounts = "accounts";
export const newAccount = "newAccount";
export const editAccount = "editAccount";
export const Reports = "Reports";
export const SaveIntegration = "save-integration";
export const Integration = "integration";
export const EditIntegration = "edit-integration";
export const RegisterShopify = "shopify";
export const statistics = "statistics";
