import React from "react";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import { Col, Form, Row, Select } from "antd";
import { ContainerForm } from "./style";
import {
  InputComponentWithBorder,
  InputWithBorderLocationSearch,
  SelectWithBorderComponent,
} from "../../../../components/FormFields";
import { FormItemInvisible } from "../../../../components/FormFields/styles";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { captureException } from "../../../../utils/sentry";

const { Option } = Select;

class LocationPointFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleAddressSelect = address => {
    console.log("handleAdressSelect -> ", address);
    geocodeByAddress(address)
      .then(async results => {
        return getLatLng(results[0]);
      })
      .then(latLng => {
        this.props.updateLocationAddress(address);
        this.props.updatePointsInputs(
          `pointAddress${this.props.locationType}`,
          latLng
        );
      })
      .catch(error => {
        captureException(error);
        console.error("Error", error);
      });
  };

  render() {
    const {
      showExtraFields,
      getFieldDecorator,
      allCitiesBranch,
      allBranchEnterprise,
      //allServicesBranch,
      updateSelect,
      loadingFields,
      selectedLocationRequired,
      disableSucursal,
      //disableService,
      locationAddress,
      updateLocationAddress,
      codeRegion,
      valueToState,
      locationReference,
      locationContact,
      locationCell,
      locationTelf,
    } = this.props;
    const renderCities = (allCitiesBranch || [])
      .filter(data => data.showInList === true)
      .map(obj => (
        <Option key={obj.id} value={obj.id}>
          {obj.name}
        </Option>
      ));

    const renderBranch = (allBranchEnterprise || [])
      .filter(data => data.showInList === true)
      .map(obj => (
        <Option key={obj.id} value={obj.id}>
          {obj.branchOfficeName}
        </Option>
      ));

    /* const renderServices = (allServicesBranch || [])
      .filter(data => data.showInList === true)
      .map(obj => (
        <Option key={obj.id} value={obj.id}>
          {obj.name}
        </Option>
      ));*/

    return (
      <ContainerForm
        style={{
          marginBottom: "10px",
          marginTop: "5px",
        }}
      >
        {showExtraFields ? (
          <>
            <Row gutter={16} style={{ marginBottom: 5 }}>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ marginTop: "5px" }}
              >
                <SelectWithBorderComponent
                  style={{ width: "100%" }}
                  getFieldDecorator={getFieldDecorator}
                  title="Ciudad"
                  decodator="locationCity"
                  options={renderCities}
                  placeholder="Seleccione la ciudad"
                  onChange={e => updateSelect("city", e)}
                  required
                  loading={loadingFields}
                />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ marginTop: "5px" }}
              >
                <SelectWithBorderComponent
                  style={{ width: "100%" }}
                  getFieldDecorator={getFieldDecorator}
                  title="Sucursal"
                  decodator="locationBranch"
                  options={renderBranch}
                  placeholder="Seleccione la sucursal"
                  onChange={e => updateSelect("branch", e)}
                  required={selectedLocationRequired}
                  loading={loadingFields}
                  disabled={disableSucursal}
                />
              </Col>
            </Row>
            {/*<Row gutter={16} style={{ marginBottom: 5 }}>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ marginTop: "5px" }}
              >
                <SelectWithBorderComponent
                  style={{ width: "100%" }}
                  getFieldDecorator={getFieldDecorator}
                  title="Tipo de servicio"
                  decodator="locationService"
                  options={renderServices}
                  placeholder="Seleccione el tipo de servicio"
                  required={selectedLocationRequired}
                  loading={loadingFields}
                  disabled={disableService}
                />
              </Col>
            </Row>*/}
          </>
        ) : (
          ""
        )}

        <Row gutter={16} style={{ marginBottom: 5 }}>
          <Col style={{ marginTop: "5px" }}>
            <FormItemInvisible>
              <InputWithBorderLocationSearch
                getFieldDecorator={getFieldDecorator}
                title="Dirección"
                decodator={`locationAddress${this.props.locationType}`}
                placeholder="Ingresa la dirección"
                address={locationAddress}
                required
                onAddressSelect={this.handleAddressSelect}
                onChange={e => updateLocationAddress(e)}
                codeRegion={codeRegion}
                disabled={showExtraFields}
              />
            </FormItemInvisible>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 5 }}>
          <Col style={{ marginTop: "5px" }}>
            <InputComponentWithBorder
              getFieldDecorator={getFieldDecorator}
              title="Referencia"
              decodator={`locationReference${this.props.locationType}`}
              placeholder="Ingresa una referencia"
              value={locationReference}
              onChange={e => valueToState(e.target)}
              style={{ marginBottom: "0px" }}
              required
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 5 }}>
          <Col style={{ marginTop: "5px" }}>
            <InputComponentWithBorder
              getFieldDecorator={getFieldDecorator}
              title="Contacto"
              decodator={`locationContactPerson${this.props.locationType}`}
              placeholder="Ingresa un contacto"
              onChange={e => valueToState(e.target)}
              value={locationContact}
              required
            />
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: 5 }}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "5px" }}
          >
            <InputComponentWithBorder
              getFieldDecorator={getFieldDecorator}
              title="Celular"
              decodator={`locationCellPhoneNumber${this.props.locationType}`}
              placeholder="Ingresa un celular"
              value={locationCell}
              onChange={e => valueToState(e.target)}
              required
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "5px" }}
          >
            <InputComponentWithBorder
              getFieldDecorator={getFieldDecorator}
              title="Teléfono fijo"
              decodator={`locationPhoneNumber${this.props.locationType}`}
              placeholder="Ingresa un teléfono"
              onChange={e => valueToState(e.target)}
              value={locationTelf}
              required
            />
          </Col>
        </Row>
      </ContainerForm>
    );
  }
}

const LocationPointForm = Form.create()(LocationPointFormComponent);

export default compose(withApollo)(LocationPointForm);
