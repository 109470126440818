import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { navigate } from "@reach/router";
import {
  Button,
  Tooltip,
  Icon,
  message,
  Empty,
  Row,
  notification,
  Spin,
} from "antd";
import Slider from "react-slick";
import axios from "axios";
import { withGlobal } from "../../../component-global";
import { Container, TitleGoBack } from "../../../components/Container";
import TableList from "../../../components/Table/tableList";
import { orderHistory } from "../../../routes/routes";
import {
  getAllServices,
  getBranchOfficeByEnterpriseID,
  getStatusOrder,
  getTrackingPackage,
  getAccuseImages,
  getEvidenceImages,
} from "../../../graphQl/queries/trackingPackage";
import { reofferRequest } from "../../../graphQl/mutations/trackingPackage";
import { captureException } from "../../../utils/sentry";
import { CustomFilterContainer } from "../../../components/Form/SearchContainer";

import {
  CustomCol,
  CustomRow,
  ContainerImg,
  ModalCustom,
} from "./components/style";
import Header from "./components/Header";
import ModalReoffert from "./components/ModalReoffert";

class TranckingPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOptionsService: null,
      dataOptionsCities: null,
      dataOptionsBranch: null,
      dataOptionsStatus: null,
      loadingBtnSearch: false,
      loadingData: false,
      loadingTable: false,
      pagination: { limit: 10, page: 0 },
      currentFilter: {},
      dataResult: null,
      totalData: 0,
      selectedAllRows: false,
      selectedRowKeys: [],
      selectRowData: [],
      loadingReprogram: false,
      imagesSelected: [],
      showModalReoffertOrder: false,
      loadingReoffertOrder: false,
      loadingButonExport: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ loadingData: true });
    try {
      if (
        this.props.userEnterpriseData &&
        this.props.userEnterpriseData.enterpriseID
      ) {
        const {
          data: { getBranchOfficeByEnterpriseID: dataOptionsBranch },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getBranchOfficeByEnterpriseID,
          variables: {
            id: this.props.userEnterpriseData.enterpriseID,
          },
        });

        const {
          data: { getAllServices: dataOptionsService },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getAllServices,
          variables: {},
        });

        const dataOptionsCities = [];
        const citiesDetailsTemp = [
          ...new Set(
            (dataOptionsBranch || []).map(
              data => `${data.City.id}--|--${data.City.name}`
            )
          ),
        ];
        citiesDetailsTemp.forEach(cities => {
          const valCortado = cities.split("--|--");
          dataOptionsCities.push({
            id: valCortado[0],
            name: valCortado[1],
          });
        });

        const {
          data: { getStatusOrder: dataOptionsStatus },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getStatusOrder,
          variables: {},
        });

        this.setState({
          dataOptionsService,
          dataOptionsCities,
          dataOptionsBranch,
          dataOptionsStatus,
          loadingData: false,
        });

        this.refreshTable();
      }
    } catch (err) {
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos.");
      captureException(err);
      console.log(err);
    }
  };

  refreshTable = async () => {
    this.setState({
      loadingTable: true,
    });
    const { pagination } = this.state;
    try {
      const {
        data: { getTrackingPackage: dataTable },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getTrackingPackage,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          ...this.state.currentFilter,
          limit: pagination.limit,
          offset: 0 * pagination.limit,
        },
      });

      this.setState({
        loadingTable: false,
        dataResult: dataTable.rows,
        totalData: dataTable.count,
        pagination: { limit: pagination.limit, page: 0 },
      });
    } catch (err) {
      console.log("Error");
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos.");
    }
  };

  searchTrackingPackage = async filters => {
    this.setState({
      currentFilter: filters,
      loadingTable: true,
    });
    const { pagination } = this.state;
    try {
      const {
        data: { getTrackingPackage: dataTable },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getTrackingPackage,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          ...filters,
          limit: pagination.limit,
          offset: 0 * pagination.limit,
        },
      });

      // * RouteCode filter on frontend
      let routeOrders;
      if (filters.routeCode)
        routeOrders = dataTable.rows.filter(
          row =>
            row.OrderService &&
            row.OrderService[0] &&
            row.OrderService[0].Routes &&
            row.OrderService[0].Routes.routeCode === filters.routeCode
        );

      this.setState({
        loadingTable: false,
        dataResult: filters.routeCode ? routeOrders : dataTable.rows,
        totalData: dataTable.count,
        pagination: { limit: pagination.limit, page: 0 },
        selectedAllRows: false,
        selectedRowKeys: [],
        selectRowData: [],
      });
    } catch (err) {
      console.log("Error");
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos.");
    }
  };

  onChangePagination = async (page, pageSize) => {
    this.setState({
      loadingTable: true,
    });
    const { pagination } = this.state;
    try {
      const {
        data: { getTrackingPackage: dataTable },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getTrackingPackage,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          ...this.state.currentFilter,
          limit: pagination.limit,
          offset: (page - 1) * pagination.limit,
        },
      });

      this.setState({
        loadingTable: false,
        dataResult: dataTable.rows,
        totalData: dataTable.count,
        pagination: { limit: pageSize, page: page - 1 },
      });
    } catch (err) {
      console.log("Error");
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos.");
    }
  };

  getEvidenceImages = orderServiceID => {
    // this.props.openRow(this.props.data.id);
    this.setState({ loadingGallery: true });
    const variables = {
      orderServiceID: parseInt(orderServiceID, 10),
    };
    this.props.client
      .query({
        fetchPolicy: "network-only",
        query: getEvidenceImages,
        variables,
      })
      .then(({ data: { EvidenceImages } }) => {
        this.setState({
          loadingGallery: false,
          imagesSelected: EvidenceImages,
        });
      })
      .catch(err => {
        notification.error({
          message: `Hubo un problema al obtener las imagenes`,
        });
        captureException(err);
      });
  };

  getAccuseImages = orderServiceID => {
    this.setState({ loadingGallery: true });
    const variables = {
      orderServiceID: parseInt(orderServiceID, 10),
    };
    this.props.client
      .query({
        fetchPolicy: "network-only",
        query: getAccuseImages,
        variables,
      })
      .then(({ data: { AccuseImages } }) => {
        this.setState({ loadingGallery: false, imagesSelected: AccuseImages });
      })
      .catch(err => {
        notification.error({
          message: `Hubo un problema al obtener las images`,
        });
        this.setState({ loadingGallery: false });
        captureException(err);
      });
  };

  onReoffertOrders = () => {
    const { selectRowData, selectedAllRows, currentFilter } = this.state;
    if (selectRowData.length > 0 || selectedAllRows) {
      this.setState({ loadingReoffertOrder: true });
      this.props.client
        .mutate({
          mutation: reofferRequest,
          update: (cache, { data: { reofferRequest: respuesta } }) => {
            console.log("UpdateService", respuesta);
            if (respuesta.statusCode === 200) {
              notification.success({
                message: respuesta.message,
              });
              this.setState({
                loadingReoffertOrder: false,
                selectedAllRows: false,
                selectedRowKeys: [],
                selectRowData: [],
                showModalReoffertOrder: false,
              });
            } else {
              notification.error({
                message: respuesta.message,
              });
              this.setState({
                loadingReoffertOrder: false,
              });
            }
          },
          variables: {
            ...(!selectedAllRows && {
              orderIDs: selectRowData.map(order => parseInt(order.id, 10)),
            }),
            ...(selectedAllRows && { ...currentFilter }),
          },
        })
        .catch(error => {
          captureException(error);
          message.error("No se pudo guardar, error en el sistema.");
          this.setState({
            loadingReoffertOrder: false,
            showModalReoffertOrder: false,
          });
        });
    }
  };

  onSelectionRow = (selectedRowKeys, selectData) => {
    this.setState({
      selectedRowKeys,
      selectRowData: selectData,
    });
  };

  expandedRowRender = record => {
    return (
      <>
        <CustomRow>
          <CustomCol style={{ fontWeight: "bold" }}>
            Cantidad de bultos
          </CustomCol>
          <CustomCol style={{ fontWeight: "bold" }}>
            Dirección de recojo
          </CustomCol>
          <CustomCol style={{ fontWeight: "bold" }}>
            Dirección de entrega
          </CustomCol>
          <CustomCol style={{ fontWeight: "bold" }}>
            Nombre de contacto
          </CustomCol>
          <CustomCol style={{ fontWeight: "bold" }}>
            Teléfono de contacto
          </CustomCol>
        </CustomRow>
        <CustomRow>
          <CustomCol>
            <Tooltip title="data">{record.packageQuantity}</Tooltip>
          </CustomCol>
          <CustomCol>
            <Tooltip title="data">{record.pickUpAddress}</Tooltip>
          </CustomCol>
          <CustomCol>
            <Tooltip title="data">{record.dropAddress}</Tooltip>
          </CustomCol>
          <CustomCol>
            <Tooltip title="data">{record.dropContactName}</Tooltip>
          </CustomCol>
          <CustomCol>
            <Tooltip title="data">{record.dropContactPhone}</Tooltip>
          </CustomCol>
        </CustomRow>
      </>
    );
  };

  onExportCSV = async () => {
    this.setState({ loadingButonExport: true });
    const { currentFilter } = this.state;
    console.log(currentFilter);
    const data = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_PROVIDER}:${process.env.REACT_APP_AUTH_CLIENT_ID}`
      )
    );
    const token =
      data &&
      data.access_token &&
      data.token_type &&
      `${data.token_type} ${data.access_token}`;

    const config = {
      headers: {
        Authorization: token || "",
        "Content-Type": "application/json",
      },
    };
    const body = {
      idReport: "zeldaTrackingReport",
      argsQuery: {
        ...currentFilter,
        enterpriseID: this.props.userEnterpriseData.enterpriseID,
      },
    };

    try {
      axios
        .post(
          `${process.env.REACT_APP_CFUNCTION_DOMAIN}/createCSVreportGenerator`,
          body,
          config
        )
        .then(res => {
          this.setState({ loadingButonExport: false });

          if (res.data) {
            notification.success({
              message: `El Reporte se esta descargando`,
            });
            const url = res.data.file[0].mediaLink;
            window.open(url, "_blank");
          }
        })
        .catch(error => {
          notification.error({
            message: `Error en el sistema, intentelo de nuevo.`,
          });
          this.setState({ loadingButonExport: false });
          console.log(error);
        });
    } catch (e) {
      this.setState({ loadingButonExport: false });
      notification.error({
        message: `Error en el sistema, intentelo de nuevo22.`,
      });
      console.log("error", e);
    }
  };

  onCloseModalImg = () => {
    this.setState({ visibleImgs: false, imagesSelected: [] });
  };

  onShowModalReoffertOrder = () => {
    this.setState({
      showModalReoffertOrder: true,
    });
  };

  onCloseModalReoffertOrder = () => {
    this.setState({
      showModalReoffertOrder: false,
    });
  };

  render() {
    const {
      loadingBtnSearch,
      loadingData,
      loadingTable,
      dataOptionsService,
      dataOptionsCities,
      dataOptionsBranch,
      dataOptionsStatus,
      dataResult,
      totalData,
      pagination,
      selectedRowKeys,
      loadingReprogram,
      imagesSelected,
      showModalReoffertOrder,
      loadingReoffertOrder,
      selectRowData,
      selectedAllRows,
      currentFilter,
    } = this.state;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectionRow,
      getCheckboxProps: record => {
        if (
          record.Status &&
          [169, 161].includes(parseInt(record.Status.subtype, 10))
        )
          return {
            disabled: false,
          };
        return {
          disabled: true,
        };
      },
      onSelectAll: selected => {
        this.setState({ selectedAllRows: selected });
      },
    };

    const listImgsOrderFormatted = () => {
      return (imagesSelected || []).map(obj => {
        const img = {
          id: obj.id,
          img: obj.url,
        };
        return img;
      });
    };

    const renderImgs = listImgsOrderFormatted().map(x => {
      return (
        <ContainerImg key={x.id.toString()}>
          <div>
            <img alt="img" src={x.img} />
          </div>
        </ContainerImg>
      );
    });

    const columns = [
      {
        title: "TrackCode",
        dataIndex: "trackCode",
        key: "trackCode",
        render: (text, record) => (
          <Tooltip title={record.createdAt}>
            <Button
              style={{ fontSize: "14px", lineHeight: "20px" }}
              type="link"
              onClick={() => {
                navigate(`/dashboard/${orderHistory}/${record.id}`);
              }}
            >
              {record.trackCode}
            </Button>
          </Tooltip>
        ),
      },
      {
        title: "RouteCode",
        dataIndex: "routeCode",
        key: "routeCode",
        render: (text, record) => {
          const routeCode =
            record.OrderService &&
            record.OrderService[0] &&
            record.OrderService[0].Routes
              ? record.OrderService[0].Routes.routeCode
              : "";

          return (
            <Tooltip title={record.createdAt}>
              <span style={{ fontSize: "14px", lineHeight: "20px" }}>
                {routeCode}
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: "Ciudad",
        dataIndex: "city",
        key: "city",
        width: "120px",
        render: (text, record) => (
          <Tooltip title={record.createdAt}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.City ? record.City.name : ""}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Sucursal",
        dataIndex: "pickUpBranch",
        key: "pickUpBranch",
        render: (text, record) => (
          <Tooltip title={record.createdAt}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.PickUpBranch ? record.PickUpBranch.branchOfficeName : ""}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Tipo de servicio",
        dataIndex: "service",
        key: "service",

        render: (text, record) => (
          <Tooltip title={record.createdAt}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.Service ? record.Service.name : ""}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Chazki",
        dataIndex: "affiliate",
        key: "affiliate",
        render: (text, record) => (
          <Tooltip title={record.createdAt}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.OrderService &&
              record.OrderService.length > 0 &&
              record.OrderService[0].Routes &&
              record.OrderService[0].Routes.Affiliates
                ? `${record.OrderService[0].Routes.Affiliates.User.names} ${record.OrderService[0].Routes.Affiliates.User.lastname}`
                : ""}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Estado",
        dataIndex: "status",
        key: "status",
        width: "120px",
        render: (text, record) => (
          <Tooltip title={record.createdAt}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.Status ? record.Status.subclass : ""}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Motivo de fallo",
        dataIndex: "incidence",
        key: "incidence",
        render: (text, record) => (
          <Tooltip title={record.createdAt}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.OrderService &&
              record.OrderService.length > 0 &&
              record.OrderService[0].OrderServiceHistorial &&
              record.OrderService[0].OrderServiceHistorial.length > 0 &&
              record.OrderService[0].OrderServiceHistorial[0].Incidence
                ? record.OrderService[0].OrderServiceHistorial[0].Incidence.name
                : ""}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Evidencia",
        dataIndex: "evidence",
        key: "evidence",
        width: "92px",
        render: (text, record) => (
          <div style={{ textAlign: "center" }}>
            <Icon
              onClick={() => {
                this.setState({ visibleImgs: true });
                this.getEvidenceImages(record.OrderService[0].id);
              }}
              style={{ fontSize: "15px", color: "#307FE2" }}
              type="camera"
            />
          </div>
        ),
      },
      {
        title: "Detalle",
        dataIndex: "moreinfo",
        key: "moreinfo",
        width: "120px",
      },
    ];

    return (
      <Container>
        <TitleGoBack title="Seguimiento de envío" />
        <CustomFilterContainer
          style={{ padding: "24px ", marginBottom: "0px" }}
        >
          <Header
            serviceOptions={dataOptionsService}
            cityOptions={dataOptionsCities}
            branchOptions={dataOptionsBranch}
            statusOptions={dataOptionsStatus}
            loadingBtnSearch={loadingBtnSearch}
            loadingData={loadingData}
            searchTrackingPackage={this.searchTrackingPackage}
          ></Header>

          {dataResult && dataResult.length > 0 ? (
            <div>
              <TableList
                size="middle"
                rowSelection={rowSelection}
                data={dataResult}
                columns={columns}
                loading={loadingTable || loadingBtnSearch}
                pageSize={pagination.limit}
                totalData={totalData}
                currentPage={pagination.page + 1}
                onChange={this.onChangePagination}
                heightcustom="calc(100vh - 490px)"
                scroll={{ y: "calc(100vh - 490px)" }}
                expandedRowRender={this.expandedRowRender}
                expandIconAsCell={false}
                expandIconColumnIndex={9}
                expandIcon={({ expanded, onExpand, record }) =>
                  expanded ? (
                    <Button
                      type="link"
                      style={{
                        color: "#307FE2",
                        // fontSize: "14px",
                        cursor: "pointer",
                      }}
                      activeclassname="active"
                      onClick={e => onExpand(record, e)}
                    >
                      <span style={{ textDecoration: "underline" }}>
                        Ocultar
                      </span>
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      style={{
                        color: "#307FE2",
                        // fontSize: "14px",
                        cursor: "pointer",
                      }}
                      activeclassname="active"
                      onClick={e => onExpand(record, e)}
                    >
                      <span style={{ textDecoration: "underline" }}>
                        Ver detalle
                      </span>
                    </Button>
                  )
                }
              />
              <Row gutter={24} type="flex" justify="end">
                <Button
                  size="large"
                  type="primary"
                  ghost
                  onClick={this.onExportCSV}
                  style={{ marginRight: 10 }}
                  loading={this.state.loadingButonExport}
                >
                  Exportar <Icon type="download" />
                </Button>
                <Button
                  size="large"
                  type="danger"
                  onClick={this.onShowModalReoffertOrder}
                  disabled={!selectedRowKeys.length}
                  loading={loadingReprogram}
                >
                  Reprogramar
                </Button>
              </Row>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "calc(100vh - 350px)",
                padding: "0 8px 0 0",
              }}
            >
              <Empty
                image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
                imageStyle={{
                  height: 136,
                }}
                description={null}
              >
                <span
                  style={{
                    color: "#8C8C8C",
                    
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                >
                  {loadingTable || loadingData
                    ? "Cargando..."
                    : "No hemos encontrado información disponible."}
                </span>
              </Empty>
            </div>
          )}

          {/* Modal para la visualizacion de imagenes */}
          <ModalCustom
            title={null}
            footer={null}
            visible={this.state.visibleImgs}
            onCancel={this.onCloseModalImg}
            closable
            width={680}
            bodyStyle={{ height: "520px" }}
          >
            <Spin spinning={this.state.loadingGallery}>
              {renderImgs.length || this.state.loadingGallery ? (
                <Slider {...settings}>{renderImgs}</Slider>
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Empty
                    image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
                    description={<span>No se encontraron imágenes</span>}
                  />
                </div>
              )}
            </Spin>
          </ModalCustom>

          {/* modal para el reofertado de las ordenes */}
          <ModalReoffert
            onCancel={() => {
              this.onCloseModalReoffertOrder();
            }}
            visible={showModalReoffertOrder}
            onConfirm={this.onReoffertOrders}
            loading={loadingReoffertOrder}
            acceptMessage="Si, reprogramar"
            cancelMessage="Ya no"
            selectedRowKeys={selectedRowKeys}
            selectRowData={selectRowData}
            selectedAllRows={selectedAllRows}
            currentFilter={currentFilter}
          />
        </CustomFilterContainer>
      </Container>
    );
  }
}

const WraperTranckingPackage = withGlobal(TranckingPackage);

export default compose(withApollo)(WraperTranckingPackage);
