import React from "react";
import { Radio } from "antd";
import { CustomFormItemStatus } from "../Form";

const { Group: RadioGroup } = Radio;

class RadioGroupStatus extends React.Component {
  render() {
    const {
      getFieldDecorator,
      decodator,
      value,
      required,
      disabled,
      options,
    } = this.props;

    return (
      <div style={{ marginBottom: 20 }}>
        <CustomFormItemStatus>
          {getFieldDecorator(decodator, {
            initialValue: value || undefined,
            rules: [
              {
                required,
                message: ".",
              },
            ],
          })(
            <RadioGroup
              disabled={disabled}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              options={options}
              // onChange={this.onChange1}
            />
          )}
        </CustomFormItemStatus>
      </div>
    );
  }
}

export default RadioGroupStatus;
