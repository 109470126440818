const { google } = window;
const data = {
  trackCode: "y96x5",
  Service: null,
  Enterprise: {
    comercialName: "Cereales Comerciales",
  },
  OrderService: [
    {
      id: "1",
      promiseTime: "2020-05-12T15:57:39.594Z",
      startTime: null,
      endTime: null,
      deleted: false,
      OrderServiceHistorial: [
        {
          id: "1",
          motive: "",
          deleted: false,
          Status: {
            subtype: "154",
            type: 42,
            class: "Estado de la Orden",
            subclass: "NEW",
          },
        },
      ],
      Routes: {
        id: "1",
        routeCode: "dn9mfb",
        Activities: null,
        Affiliates: {
          userID: "40352e78-4041-4b7d-b012-daa03a35fa6d",
          User: {
            names: "Leandro",
            lastname: "Ruiz",
          },
        },
      },
    },
  ],
};

const data2 = [
  {
    incidents: "",
    state: "Orden ingresada",
    date: "18/09/2019",
    time: "07:30 PM",
  },
  {
    state: "En programacion",
    date: "18/09/2019",
    time: "07:30 PM",
    incidents: "",
  },
  {
    state: "En transito a recoger el pedido",
    date: "18/09/2019",
    time: "07:30 PM",
    incidents: "",
  },
  {
    state: "En punto de recolección",
    date: "18/09/2019",
    time: "07:30 PM",
    incidents: "",
  },
  {
    state: "Fallado en recolección",
    date: "18/09/2019",
    time: "07:30 PM",
    incidents: "FT - Falta de tiempo",
  },
];

const decodeGeometry = encoded => {
  let index = 0;
  const points = [];
  const len = encoded.length;
  let lat = 0;
  let lng = 0;
  while (index < len) {
    let b;
    let shift = 0;
    let result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63; // finds ascii                                                                                    //and substract it by 63
      // eslint-disable-next-line no-bitwise
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);

    // eslint-disable-next-line no-bitwise
    const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;
    shift = 0;
    result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      // eslint-disable-next-line no-bitwise
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    // eslint-disable-next-line no-bitwise
    const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    points.push({ lat: lat / 1e5, lng: lng / 1e5 });
  }
  return points;
};

const iconPickUpURL =
  "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/iconsMap%2FiconPickUp.png?alt=media&token=7a89738a-98ad-460d-acd1-af2756968636";

const iconDeliveryURL =
  "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/iconsMap%2FiconDelyvery.png?alt=media&token=947d1325-d23c-4eed-8952-19b03f0868ea";

const iconAffiliateURL =
  "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2Fchazki-en-ruta.png?alt=media&token=ade3d4c2-4d5d-44dc-9240-8a5902f38a71";

const iconAffiliate2URL =
  "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2Fchazki-en-ruta2.png?alt=media&token=7c1c2a05-b391-44c1-be7f-4bf326caca54";

const IconAffiliate = {
  url: iconAffiliateURL,
  scaledSize: new google.maps.Size(60, 60),
};

const IconPickUp = {
  url: iconPickUpURL,
  scaledSize: new google.maps.Size(25, 28),
};

const IconDelivery = {
  url: iconDeliveryURL,
  scaledSize: new google.maps.Size(25, 28),
};

const IconAffiliate2 = {
  url: iconAffiliate2URL,
  scaledSize: new google.maps.Size(25, 28),
};

export {
  data,
  data2,
  IconPickUp,
  IconDelivery,
  IconAffiliate,
  IconAffiliate2,
  decodeGeometry,
};
