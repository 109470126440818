import React from 'react'
import { Modal, Button, Icon } from 'antd'
import { withApollo } from "react-apollo";
import styled from "styled-components";
import { compose } from "recompose";

const CustomModal = styled(Modal)`
  .ant-modal-header {
    border: none;
  }

  .ant-modal-footer {
    border: none;
    display:none;
  }

  .ant-modal-body {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  h3 {
    font-size: 3rem;
    font-weight: bold !important;
    font-family: 'Roboto';
  }
  p {
    font-size: 16px;
  }
`

const CustomIcon = styled(Icon)`
  font-size:24px;
  color:#40A9FF;
  margin: 0 0 1.5rem;
`
const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  button {
    font-size:16px;
    height: 4rem;
  }
`

const ConfirmSessionClosure = props => {
  return (
    <>
      <CustomModal centered visible={props.isModalVisible} onCancel={props.hideModal}>
        <ModalContent>
          <CustomIcon type="clock-circle" />
          <h3>¡Tu sesión está por terminar!</h3>
          <p>Parece que no has realizado ninguna operación durante los últimos minutos ¿Deseas continuar?</p>
        </ModalContent>
        <ButtonContainer>
          <Button type="primary" onClick={props.logout} ghost>
            Ya terminé
          </Button>
          <Button type="primary" onClick={props.hideModal}>
            Deseo continuar
          </Button>
        </ButtonContainer>
      </CustomModal>
    </>
  )
}

export default compose(withApollo)(ConfirmSessionClosure);