import React from "react";
import { Form, Drawer, Spin, Avatar, Tag } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import styled from "styled-components";
import moment from "moment";
import { withGlobal } from "../../../component-global";
import { getCommentariesOrder } from "../../../graphQl/queries/orderHistory";

const CustomDrawer = styled(Drawer)`
  overflow-y: scroll;

  .title {
    /* H4 */

    
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  .ant-drawer-body {
    padding: 48px;
  }

  .text-description {
    
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .text-area-input {
    margin-bottom: 32px;
  }

  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #595959;
    margin-bottom: 20px;
  }
`;

const Comment = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 12px;
  margin-top: 15px;

  .avatar {
    width: 40px;
  }

  .userName {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
  }

  .content {
    margin-left: 8px;
  }

  .comentText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .tags {
    margin-top: 3px;
    margin-bottom: 9px;
  }

  .dateComment {
    margin-left: 20px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px
    color: #BFBFBF;;
  }
`;


class DrawerComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acuseInfoState: {
        loadingData: false,
      },
      dataComments: [],
      
    };
  }

  componentDidMount() {
    this.getDataComments();
  }

  getDataComments = async () => {
    this.setState({ loadingData: true });
    const variables = {
      orderServiceID: parseInt(this.props.orderServiceID, 10),
      orderID: parseInt(this.props.orderID, 10),
    };

    const {
      data: { CommentariesOrder },
    } = await this.props.client
      .query({
        fetchPolicy: "network-only",
        query: getCommentariesOrder,
        variables,
      })
      .catch(error => {
        console.log(error);
      });
    this.setState({ dataComments: CommentariesOrder, loadingData: false });
  };

  handleChange = async value => {
    const { acuseInfoState } = this.state;
    await this.setState({
      acuseInfoState: { ...acuseInfoState, ...value },
    });
  };

  render() {    
    const { dataComments } = this.state;
    const displayComments = () =>
      (dataComments || []).map(obj => {
        return (
          <Comment key={obj.id}>
            <div className="avatar">
              <Avatar size={40} src={obj.UserCreated.picture} icon="user" />
            </div>
            <div className="content">
              <div>
                <span className="userName">{`${obj.UserCreated.names} ${obj.UserCreated.lastname}`}</span>
                <span className="dateComment">
                  {moment(obj.createdAt).format("DD/MM/YYYY")}
                </span>
              </div>
              <div className="tags">
                <Tag
                  color={
                    obj.TypeUserCommentary.subtype === "122" ? "green" : "blue"
                  }
                >
                  {obj.TypeUserCommentary.subclass}
                </Tag>
              </div>
              <div className="comentText">{obj.commentary}</div>
            </div>
          </Comment>
        );
      });
    return (
      <CustomDrawer
        visible={this.props.visible}
        width={408}
        onClose={this.props.onClose}
      >       
        <div>
          <h4 className="title">Historial de comentarios</h4>
        </div>        
        <Spin spinning={this.state.loadingData}>{displayComments()}</Spin>        
      </CustomDrawer>
    );
  }
}

const WraperDrawerComments = withGlobal(DrawerComments);
const DrawerCommentsForm = Form.create()(WraperDrawerComments);

export default compose(withApollo)(DrawerCommentsForm);
