import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Form, notification, Result } from "antd";
import { navigate } from "@reach/router";
import { withGlobalContext } from "../../utils/globalContext";
import { withGlobal } from "../../component-global";
import * as route from "../../routes/routes";
import { updateEnterprisePlatformsByEnterprise } from "../../graphQl/mutations/enterprisePlatforms";

class RegisterShopify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingOnSave: true,
      checkFirst: true,
    };
  }

  registerCode = async () => {
    const search = new URLSearchParams(this.props.location.search);
    const variables = {
      enterpriseID: this.props.userEnterpriseData.enterpriseID,
      namePlatform: this.props.platform.toUpperCase(),
      idPlatform: search.get("id"),
      token: search.get("token"),
      refreshToken: search.get("refreshToken"),
      extra: {
        code: search.get("code"),
        campo1: search.get("campo1"),
        campo2: search.get("campo2"),
      },
    };

    if (this.state.checkFirst) {
      const { data, errors } = await this.props.client.mutate({
        mutation: updateEnterprisePlatformsByEnterprise,
        variables,
      });

      if (data) {
        this.setState({ loadingOnSave: false, checkFirst: false });
        navigate(
          `/${route.dashboard}/` +
            `${route.EditIntegration}/` +
            `${this.props.platform.toLowerCase()}`
        );
      }

      if (errors) {
        console.log(errors);
        notification.error({
          message: `No se pudo guardar, error en el sistema.`,
        });
        this.setState({ loadingOnSave: true, checkFirst: false });
      }
    }
  };

  render() {
    this.registerCode();

    return (
      <Result
        status="success"
        title={
          this.state.loadingOnSave
            ? "Por favor espere un momento, estamos integrando su plataforma!!"
            : "Se guardo correctamente, será redireccionado"
        }
      />
    );
  }
}

const WrapperShopify = withGlobal(RegisterShopify);
const FormNewShopify = Form.create()(WrapperShopify);

export default compose(
  withApollo,
  withGlobalContext
)(FormNewShopify);
