import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import { Spin, notification } from "antd"; // notification
import moment from "moment";
import axios from "axios";
import { withGlobal } from "../../../component-global";
import MapComponent from "../../../components/Maps/Maps";
import TableList from "../../../components/Table/tableList";
import { IconAffiliate, IconAffiliate2 } from "./static";

class MapAndStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    this.getDirections();
  };

  getDirections = async () => {
    const { data, cityID } = this.props;
    const coordinates =
      data.Activities &&
      data.Activities.map(obj => {
        return {
          lat: obj.addressPoint.coordinates[1],
          lng: obj.addressPoint.coordinates[0],
        };
      });

    const vehicleTypeID =
      this.props.orderService &&
      this.props.orderService.Routes &&
      this.props.orderService.Routes.AffiliateVehicles &&
      this.props.orderService.Routes.AffiliateVehicles.CategoryVehicle.subtype;

    if (vehicleTypeID) {
      const body = {
        cityID,
        coordinates,
        vehicleTypeID,
      };

      const getToken = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH_PROVIDER}:${process.env.REACT_APP_AUTH_CLIENT_ID}`
        )
      );
      const token =
        getToken &&
        getToken.access_token &&
        getToken.token_type &&
        `${getToken.token_type} ${getToken.access_token}`;

      const config = {
        headers: {
          Authorization: token || "",
          "Content-Type": "application/json",
        },
      };

      try {
        axios
          .post(`${process.env.REACT_APP_POLYLINEURL}`, body, config)
          .then(res => {
            if (res.data) {
              this.setState({ polylineLocal: res.data.points });
            }
          })
          .catch(error => {
            notification.error({
              message: `Error en el sistema, intentelo de nuevo.`,
            });
            console.log(error);
          });
      } catch (e) {
        notification.error({
          message: `Error en el sistema, intentelo de nuevo.`,
        });
        console.log("error", e);
      }
    }
  };

  render() {
    const { markers, orderService, authUser } = this.props;
    // const { polylineLocal } = this.state;

    const latLng = authUser.profile
      ? {
        lat: authUser.profile && authUser.profile.cityCoords[1],
        lng: authUser.profile && authUser.profile.cityCoords[0],
      }
      : null;

    const columns = [
      {
        title: "",
        dataIndex: "icon",
        key: "icon",
        width: 50,
        render: () => (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="Circle"
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FblueCircle.png?alt=media&token=797065f9-dbbd-4d56-a46a-1752c742fe2c"
            />
          </div>
        ),
      },
      {
        title: "Estado",
        dataIndex: "state",
        key: "Estado",
        width: 150,
        render: (text, record) => (
          <span
            style={{
              fontSize: "14px",
              lineHeight: "20px",
              color: "#8C8C8C",
            }}
          >
            {record.Status.subclass}
          </span>
        ),
      },
      {
        title: "Fecha",
        dataIndex: "date",
        key: "Fecha",
        width: 96,
        render: (text, record) => (
          <span
            style={{
              fontSize: "14px",
              lineHeight: "20px",
              color: "#8C8C8C",
            }}
          >
            {moment(record.createdAt).format("DD/MM/YYYY")}
          </span>
        ),
      },
      {
        title: "Hora",
        dataIndex: "time",
        key: "Hora",
        width: 120,
        render: (text, record) => (
          <span
            style={{
              fontSize: "14px",
              lineHeight: "20px",
              color: "#8C8C8C",
            }}
          >
            {moment(record.createdAt).format("h:mm a")}
          </span>
        ),
      },
      {
        title: "Indicencias",
        dataIndex: "incidents",
        key: "Incidencia",
        // width: 150,
        render: (text, record) => (
          <div>
            <span
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                color: "#8C8C8C",
              }}
            >
              {record.Incidence ? record.Incidence.name : "-"}
            </span>
          </div>
        ),
      },
    ];

    const allOrderService =
      (orderService && orderService.OrderServiceHistorial) || [];

    const statusLast =
      orderService &&
      orderService.OrderServiceHistorial &&
      orderService.OrderServiceHistorial.slice(-1)[0] &&
      orderService.OrderServiceHistorial.slice(-1)[0].Status;

    const statusOrder = (statusLast && statusLast.subclass) || "";
    let affiliateMarker = null;
    if (
      orderService &&
      orderService.Routes &&
      orderService.Routes.Affiliates &&
      orderService.Routes.Affiliates.LocationAffiliate &&
      orderService.Routes.Affiliates.LocationAffiliate[0]
    ) {
      const location =
        orderService.Routes.Affiliates &&
        orderService.Routes.Affiliates.LocationAffiliate[0].locationPoint
          .coordinates;

      if (location) {
        const marker = {
          key: "aff",
          position: {
            lat: location[1],
            lng: location[0],
          },
          icon:
            statusLast && statusLast.subclass === "DELIVERING"
              ? IconAffiliate2
              : IconAffiliate,
        };

        affiliateMarker = marker;
      }
    }

    // console.log(orderService);

    return (
      <div
        style={{
          width: "100%",
          marginLeft: "24px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Spin spinning={this.props.loadingMap}>
          <div style={{ height: "300px" }}>
            {statusOrder === "DELIVERING" ||
              statusOrder === "GOTO_PICK" ||
              statusOrder === "GOTO_DELIVER" ? (
              <MapComponent
                latLng={latLng}
                markers={
                  affiliateMarker && markers
                    ? [...markers, affiliateMarker]
                    : []
                }
                onMarkerSelect={e => {
                  console.log("Marker", e);
                }}
                polyline={[]} // polylineLocal ||
              />
            ) : (
              <MapComponent
                latLng={latLng}
                polyline={this.state.polylineLocal || []} // polylineLocal ||
                markers={markers ? [...markers] : []}
              />
            )}
          </div>
          <TableList
            onlyShowList
            data={allOrderService}
            loading={false}
            columns={columns}
            heightcustom="calc(100vh - 600px)"
            scroll={{ y: "calc(100vh - 500px)" }}
          />
        </Spin>
      </div>
    );
  }
}

const WraperMapAndStatus = withGlobal(MapAndStatus);

export default compose(withApollo)(WraperMapAndStatus);
