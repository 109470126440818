import styled from "styled-components";

export const SearchContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 0;
  padding: 2rem 6rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 56.25em) {
    padding: 2.5rem 4rem;
  }
  @media only screen and (max-width: 50em) {
    padding: 2rem 2rem;
  }
  h2 {
    color: #109cf1;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  & .ant-form > .ant-row {
    width: 100%;
  }
  .actions {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    button {
      margin-right: 1rem;
    }
  }
  & .ant-form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    & .ant-form-item-label {
      line-height: 1rem;
      label {
        color: rgba(0, 0, 0, 0.65);
        font-size: 1.1rem;
      }
    }
    & .ant-select-selection {
      border: none;
      border-bottom: 1px solid #109cf1;
      border-radius: 0 !important;
      padding-right: 0;
      padding-left: 0;
      font-size: 1.25rem;
      &:focus {
        box-shadow: none;
      }
      & .ant-select-selection__rendered {
        margin: 0;
      }
    }
    & .ant-calendar-picker {
      width: 100%;
    }
    & .ant-form-item {
      min-width: 150px;
      margin-bottom: 1rem;
    }
    & .ant-form-item-control {
      line-height: initial;
    }
    & input {
      border: none;
      border-bottom: 1px solid #109cf1;
      border-radius: 0;
      padding-right: 0;
      padding-left: 0;
      font-size: 1.25rem;
      &:focus {
        box-shadow: none;
      }
    }
    & .ant-select-selection {
      border-radius: 10px;
    }
  }
`;

const ContainerTables = styled.div`
  padding: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
`;

const CustomFilterContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  padding: 2.6rem 1.5rem 0rem;
  margin-bottom: 2rem;

  & .ant-upload {
    width: 100%;
  }
`;

const ContainerGlobal = styled.div`
  /* background: #ffffff; */
  /* box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06); */
  /* padding: 2.6rem 1.5rem; */
  margin-bottom: 2rem;
`;

export { ContainerTables, CustomFilterContainer, ContainerGlobal };
