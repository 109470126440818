import React from "react";
import { Badge, Icon, Col, Button } from "antd";
import { DivIcon } from "../../../components/Icon";
import { ContainerDeliveryStyle, ContainerLabelText } from "./style";
import { CustomRow } from "../../../components/Grid";

const ContainerCountByStatus = props => {
  const { text, count, active, handleOnClick } = props;
  return (
    <DivIcon
      onClick={handleOnClick}
      style={
        active
          ? {
              width: "154px",
              height: "40px",
              background: "#109CF1",
              border: "1px solid #109CF1",
              boxSizing: "border-box",
              borderRadius: "10px",
              display: "flex",
              color: "#ffffff",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "11px",
              cursor: "pointer",
              paddingTop: "1rem",
              justifyContent: "space-evenly",
            }
          : {
              width: "154px",
              height: "40px",
              border: "1px solid #109CF1",
              background: "#ffffff",
              boxSizing: "border-box",
              borderRadius: "10px",
              display: "flex",
              color: "#109CF1",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "11px",
              cursor: "pointer",
              paddingTop: "1rem",
              justifyContent: "space-evenly",
            }
      }
    >
      <span>{text}</span>
      <Badge
        count={count}
        style={
          active
            ? {
                backgroundColor: "#fff",
                color: "#999",
                boxShadow: "0 0 0 1px #d9d9d9 inset",
              }
            : {
                backgroundColor: "#109CF1",
                borderColor: "#109CF1",
                color: "#ffffff",
                boxShadow: "0 0 0 1px #d9d9d9 inset",
              }
        }
      />
    </DivIcon>
  );
};

const ContainerDelivery = props => {
  const {
    data,
    handleOnClick,
    active,
    disable,
    activeDescription,
    openDescription,
    located,
  } = props; // handleDelte
  const {
    orderInfo: {
      dropContactName: contactName,
      dropContactPhone: contactPhone,
      dropContactEmail: contactEmail,
      pickUpAddressReference,
      dropAddressReference,
      PackageSize: { name: sizeName },
      Service: { name: serviceName },
      productDescription: packageDescription,
      pickUpAddress,
      dropAddress,
      trackCode,
      // dropBranchID,
      // DropBranch,
      // pickUpBranchID,
      // PickUpBranch,
      packageQuantity,
    },
    id,
    // errors,
    // pickUpAddressLocated,
    // dropAddressLocated,
  } = data;

  /* const pickUpAddressLocatedFail =
    errors &&
    errors.findIndex(
      x => x.field === "pickUpBranchID" || x.field === "pickUpAddress"
    );
  const dropAddressLocatedFail =
    errors &&
    errors.findIndex(
      x => x.field === "dropBranchID" || x.field === "dropAddress"
    ); */
  return (
    <ContainerDeliveryStyle
      active={active}
      disable={disable}
      located={located}
      activeDescription={activeDescription}
    >
      <div className="principal">
        <CustomRow gutter={24} className="containerDelivery">
          <Col
            style={{ marginBottom: 0 }}
            xs={4}
            sm={4}
            xl={4}
            onClick={!disable ? handleOnClick : null}
          >
            <ContainerLabelText disable={disable}>
              <p className="title">TrackCode</p>
              <p className="text" title={trackCode}>
                {trackCode}
              </p>
            </ContainerLabelText>
          </Col>
          <Col
            style={{ marginBottom: 0 }}
            xs={3}
            sm={3}
            xl={3}
            onClick={!disable ? handleOnClick : null}
          >
            <ContainerLabelText disable={disable}>
              <p className="title">Cantidad de bultos</p>
              <p className="text">{packageQuantity}</p>
            </ContainerLabelText>
          </Col>
          <Col
            style={{ marginBottom: 0 }}
            xs={3}
            sm={3}
            xl={3}
            onClick={!disable ? handleOnClick : null}
          >
            <ContainerLabelText disable={disable}>
              <p className="title">Tipo de servicio</p>
              <p className="text">{serviceName}</p>
            </ContainerLabelText>
          </Col>
          <Col
            style={{ marginBottom: 0 }}
            xs={3}
            sm={3}
            xl={4}
            onClick={!disable ? handleOnClick : null}
          >
            <ContainerLabelText disable={disable}>
              <p className="title">Nombre del cliente</p>
              <p className="text" title={contactName}>
                {contactName}
              </p>
            </ContainerLabelText>
          </Col>
          <Col
            style={{ marginBottom: 0 }}
            xs={3}
            sm={3}
            xl={3}
            onClick={!disable ? handleOnClick : null}
          >
            <ContainerLabelText disable={disable}>
              <p className="title">Teléfono</p>
              <p className="text" title={contactPhone}>
                {contactPhone}
              </p>
            </ContainerLabelText>
          </Col>
          <Col
            style={{ marginBottom: 0 }}
            xs={3}
            sm={3}
            xl={3}
            onClick={!disable ? handleOnClick : null}
          >
            <ContainerLabelText disable={disable}>
              <p className="title">Correo</p>
              <p className="text" title={contactEmail}>
                {contactEmail}
              </p>
            </ContainerLabelText>
          </Col>
          <Col style={{ marginBottom: 0 }} xs={4} sm={4} xl={4}>
            <ContainerLabelText
              disable={disable}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                className="ButtonSeeDetails"
                ghost
                onClick={() => openDescription(id)}
                style={
                  !located && !disable
                    ? { color: "#CF1322", borderColor: "#CF1322" }
                    : disable
                    ? { color: "#BFBFBF", borderColor: "#BFBFBF" }
                    : null
                }
              >
                Ver detalle
                <Icon type={activeDescription ? "up" : "down"} />
              </Button>
            </ContainerLabelText>
          </Col>
        </CustomRow>
      </div>
      {activeDescription && (
        <div className="second">
          <CustomRow gutter={24}>
            <Col
              style={{ marginBottom: 0 }}
              xs={7}
              sm={7}
              xl={7}
              onClick={!disable ? handleOnClick : null}
            >
              <ContainerLabelText disable={disable}>
                <p className="title">Dirección de recolección</p>
                <p className="text" title={pickUpAddress}>
                  {pickUpAddress}
                </p>
              </ContainerLabelText>
              <ContainerLabelText disable={disable}>
                <p className="title">Dirección de envío</p>
                <p className="text" title={dropAddress}>
                  {dropAddress}
                </p>
              </ContainerLabelText>
            </Col>
            <Col
              style={{ marginBottom: 0 }}
              xs={7}
              sm={7}
              xl={7}
              onClick={!disable ? handleOnClick : null}
            >
              <ContainerLabelText disable={disable}>
                <p className="title">Referencia</p>
                <p className="text" title={{ pickUpAddressReference }}>
                  {pickUpAddressReference}
                </p>
              </ContainerLabelText>
              <ContainerLabelText disable={disable}>
                <p className="title">Referencia</p>
                <p className="text" title={dropAddressReference}>
                  {dropAddressReference}
                </p>
              </ContainerLabelText>
            </Col>
            <Col
              style={{ marginBottom: 0 }}
              xs={10}
              sm={10}
              xl={10}
              onClick={!disable ? handleOnClick : null}
            >
              <ContainerLabelText disable={disable}>
                <p className="title">Tamaño de paquete</p>
                <p className="text">{sizeName}</p>
              </ContainerLabelText>
              <ContainerLabelText disable={disable}>
                <p className="title">Contenido de paquete</p>
                <p style={{width:200}} className="text" title={packageDescription}>
                  {packageDescription}
                </p>
              </ContainerLabelText>
            </Col>
          </CustomRow>
        </div>
      )}
    </ContainerDeliveryStyle>
  );
};

const deliveriesEmpty = (
  <div
    style={{
      background: "#eeeeef",
      textAlign: "center",
      height: "100%",
    }}
  >
    <div style={{ position: "relative", top: "calc(50% - 10px)" }}>
      <Icon
        type="info-circle"
        style={{
          marginRight: 15,
          marginBottom: 15,
          fontSize: "2.5rem",
        }}
      />
      <p
        style={{
          fontSize: "1.2rem",
        }}
      >
        La lista esta vacía.
      </p>
    </div>
  </div>
);

const deliveriesDeSelect = (
  <div
    style={{
      background: "#eeeeef",
      textAlign: "center",
      height: "100%",
    }}
  >
    <div style={{ position: "relative", top: "calc(50% - 10px)" }}>
      <Icon
        type="info-circle"
        style={{
          marginRight: 15,
          marginBottom: 15,
          fontSize: "2.5rem",
        }}
      />
      <p
        style={{
          fontSize: "1.2rem",
        }}
      >
        Debe seleccionar un trackcode.
      </p>
    </div>
  </div>
);
export {
  ContainerCountByStatus,
  ContainerDelivery,
  deliveriesEmpty,
  deliveriesDeSelect,
};
