import styled from "styled-components";
import { Modal, Row } from "antd";

const CustomRow = styled.div`
  width: 100%;
  position: relative;
  background: #ffffff;
  display: table;
  table-layout: fixed;
  border-bottom: 1px solid #f5f5f5;
`;

const CustomCol = styled.div`
  position: relative;
  max-width: 300px;
  min-width: 60px;
  height: 36px;
  display: table-cell;
  white-space: nowrap;
  color: #8c8c8c;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px 10px 12px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const ContainerImg = styled.div`
  width: 500px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  && img {
    width: auto;
    height: auto;
    max-height: 450px;
    max-width: 500px;
    margin: 0 auto;
  }
`;

const ModalCustom = styled(Modal)`
  & .slick-arrow {
    ::before {
      font-size: 22px;
      color: #000;
    }
  }

  .ant-modal-body {
    padding: 46px 28px;
    margin: auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .slick-slider {
    width: 500px;
  }
`;

const CustomRowModal = styled(Row)`
  & > * {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  & .title {
    
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    line-height: 32px;
    margin-bottom: 8px;
    color: #595959;
  }

  & .description {
    
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #8c8c8c;
  }
`;

export { CustomRow, CustomCol, ContainerImg, ModalCustom, CustomRowModal };
