import React from "react";
import { Icon, Tooltip } from "antd";
import { navigate } from "@reach/router";
import TableList from "../../../components/Table/tableList";
import { editBranch } from "../../../routes/routes";
import { CustomEmpty } from "../../../components/DataDisplay";

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }

  onClickEditBranch = e => {
    console.log("onClick Edit", e);
    navigate(`/dashboard/${editBranch}/${e}`);
  };

  onSelectionRow = (selectedRowKeys, record) => {
    this.setState({ selectedRowKeys });
    this.props.setSelectedRows(record);
  };

  render() {
    const columns = [
      {
        title: "Código",
        dataIndex: "branchOfficeCode",
        key: "branchOfficeCode",
      },
      {
        title: "Nombre de sucursal",
        dataIndex: "branchOfficeName",
        key: "branchOfficeName",
      },

      {
        title: "Cliente",
        dataIndex: "client",
        key: "client",
        render: (text, record) => (
          <Tooltip title={record.Enterprise.comercialName}>
            <span style={{ fontSize: "14px" }}>
              {record.Enterprise.comercialName}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Nombre de contacto",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Tooltip
            title={
              record.contactPeople &&
              record.contactPeople.length &&
              record.contactPeople.find(obj => obj.currentContact)
                ? record.contactPeople.find(obj => obj.currentContact).name
                : "-"
            }
          >
            <span style={{ fontSize: "14px" }}>
              {record.contactPeople &&
              record.contactPeople.length &&
              record.contactPeople.find(obj => obj.currentContact)
                ? record.contactPeople.find(obj => obj.currentContact).name
                : "-"}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Teléfono de contacto",
        dataIndex: "phone",
        key: "phone",
        render: (text, record) => (
          <Tooltip
            title={
              record.contactPeople &&
              record.contactPeople.length &&
              record.contactPeople.find(obj => obj.currentContact)
                ? record.contactPeople.find(obj => obj.currentContact).phone
                : "-"
            }
          >
            <span style={{ fontSize: "14px" }}>
              {record.contactPeople &&
              record.contactPeople.length &&
              record.contactPeople.find(obj => obj.currentContact)
                ? record.contactPeople.find(obj => obj.currentContact).phone
                : "-"}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Editar",
        dataIndex: "edit",
        key: "edit",
        render: (text, record) => (
          <Icon
            type="edit"
            style={{ fontSize: 20, color: "#307FE2" }}
            onClick={() => this.onClickEditBranch(record.id)}
          />
        ),
      },
    ];

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectionRow,
    };

    return (
      <div>
        {this.props.data.length ? (
          <TableList
            rowSelection={rowSelection}
            data={this.props.data}
            columns={columns}
            currentPage={this.props.currentPage}
            pageSize={this.props.pageSize}
            onChange={this.props.onChange}
            scroll={{ y: "calc(100vh - 560px)" }}
            heightempty="500px"
            heightcustom="calc(100vh - 560px)"
            loading={this.props.loading}
            totalData={this.props.totalOrders}
          />
        ) : (
          <div
            style={{
              height: "calc(100vh - 500px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomEmpty
              loading={this.props.loading}
              description="No hemos encontrado información disponible."
            ></CustomEmpty>
          </div>
        )}
      </div>
    );
  }
}

export default Body;
