import {
  message,
  notification,
  Progress,
} from "antd";
import React from "react";
import { withFirebase } from "../../../../firebase";
import { ContainerDragger } from "./Style";
import { csvIcon } from "./Util";

const urlEndPoint = process.env.REACT_APP_CFUNCTION_DOMAIN;

const styles = {
  text: {
    color: "#8C8C8C",
    
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "8px",
  },
  link: {
    
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    textDecorationLine: "underline",
    color: "#307FE2",
    cursor: "pointer",
  }
}

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      folderName: "csv",
      uploaded: false,
      validFormats: [
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    };
  }

  fileNameStamp = name => {
    const getDate = new Date();
    getDate.setHours(getDate.getHours() - 5);
    let timestamp = getDate.toISOString();
    timestamp = timestamp.replace(/:/g, "-");
    timestamp = timestamp.replace(/\./g, "-");
    return timestamp + name.replace(/ /g, "_");
  };

  beforeUpload = file => {
    if (this.state.validFormats) {
      if (this.state.validFormats.indexOf(file.type) >= 0) {
        return true;
      }
      message.error("El archivo no es válido.");
      return false;
    }
    return true;
  };

  customRequest = ({
    file,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const { folderName } = this.state;
    const { firebase, setFile } = this.props;
    const fileStamp = this.fileNameStamp(file.name);
    const key = `${folderName}/${fileStamp}`;
    const task = firebase.refStorage.child(key).put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress({ percent: percentage });
      },
      err => {
        message.error("Error al subir archivo");
        notification.error({ message: "Error al subir archivo" });
        return onError(err);
      },
      () => {
        task.snapshot.ref.getDownloadURL().then(downloadURL => {
          const { name, uid, url = downloadURL, status = "done" } = file;
          onSuccess({ name, uid, url, status, file: downloadURL });
          setFile({
            url,
            fileStamp,
            filename: file.name,
          })
          this.setState({ uploaded: true })
        });
      }
    );
  };

  render() {
    const { uploaded } = this.state;
    return (
      <ContainerDragger
        action={`${urlEndPoint}/processCSVOrdersNoLocated`}
        beforeUpload={this.beforeUpload}
        customRequest={this.customRequest}
        headers={{ "X-Requested-With": null }}
        showUploadList={true}
        multiple={false}
      >
        {uploaded ?
          <div style={{ height: 171, padding: 20, paddingTop: 60, borderColor: 'red', borderWidth: 2, }}>
            <p>Cargado CSV al <b>100%</b></p>
            <Progress percent={100} />
          </div>
          :
          <>
            <p className="ant-upload-drag-icon">
              <img
                alt="img"
                src={csvIcon}
                width="32px"
                height="43px"
              />
            </p>
            <p className="ant-upload-text" style={styles.text}>Arrastra el archivo aquí</p>
            <p className="ant-upload-text" style={styles.text}>o</p>
            <p className="ant-upload-text" style={styles.link}>Elige un archivo</p>
          </>
        }
      </ContainerDragger>
    );
  }
}

const UploadWraper = withFirebase(Upload);
export default UploadWraper;
