import { Card, Pagination } from "antd";
import styled from "styled-components";

const CardTable = styled(Card)`
  border: 0px solid #e8e8e8;

  & .ant-card-body {
    padding: 0;
  }
  & .ant-table-tbody > tr.ant-table-row-selected td {
    color: inherit;
    background: #e6f7ff;
  }

  ${props =>
    props.heightempty
      ? `
    & .ant-table-placeholder{
      height: ${props.heightempty} !important;
    }
    `
      : ``}

  ${props =>
    props.heightcustom
      ? `
  & .ant-table-body {
    height: ${props.heightcustom};
  }
  `
      : ``}

  ${props =>
    props.withoutscroll
      ? `
      & .ant-table-body {
        overflow-x: hidden !important;
        overflow-y: hidden !important;
      }
      `
      : ``}
  ${props =>
    props.backgroundcustom
      ? `
      & .ant-table-body {
        & td {
          background: ${props.backgroundcustom} !important;
        }
      }
      `
      : ``}
      

  & .ant-table-thead {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06) !important;
    border-radius: 4px !important;
    & th {
      background: #fafafa;
      padding: 18px 16px;
    }
    & .ant-table-column-title {
      color: #595959;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      font-style: normal;
      white-space: normal;
    }
  }

  & .ant-table-row {
    & td {
      background: white;
      border-bottom: 1px solid #f5f5f5;
    }
  }
  & .ant-table-tbody {
    & td {
      font-size: 1.3rem;
    }
    & tr {
      border: 1px solid #f5f5f5;
    }
    & .green {
      border-left: 4px solid green;
    }
    & .red {
      border-left: 4px solid red;
    }
    & .blue {
      border-left: 4px solid blue;
    }
    & .orange {
      border-left: 4px solid orange;
    }
    & .green {
      border-left: 4px solid #44d62c;
    }
    & .mediumslateblue {
      border-left: 4px solid mediumslateblue;
    }
    & .violet {
      border-left: 4px solid #f84691;
    }
  }
`;

const CardPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  padding: 1rem;
  // phone
  @media only screen and (max-width: 37.5em) {
    flex-direction: column;
  }
  .ant-pagination-next {
    boder: 1px;
  }

  .ant-pagination {
    .ant-pagination-total-text {
      color: black;
    }
    .ant-pagination-simple-pager {
      input {
        pointer-events: none;
      }
    }
  }
`;

const CustomPagination = styled(Pagination)`
  padding: 1rem;
  float: rigth;
  .ant-pagination {
    .ant-pagination-total-text {
      color: black;
    }
  }
`;

const HeaderTableWithFIlter = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 0rem;
  background: #ffffff;

  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
`;

const RowTable = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  margin-top: 4px;
`;

export {
  CardTable,
  CardPagination,
  CustomPagination,
  HeaderTableWithFIlter,
  RowTable,
};
