import React from "react";
import { Row, Col, Form, Button,Select } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import moment from "moment";
// import moment from "moment";
import {
  InputComponentWithBorder,
  RangePickerWithBorderCompoment,
} from "../../../components/FormFields";
import SelectComponent from "../../../components/FormFields/SelectWithBorderComponent"

const { Option } = Select;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const filter = { ...values };

      if (values.trackCode) {
        filter.trackCode = values.trackCode;
      }

      if (values.date && values.date.length > 0) {
        const startDate = moment(filter.date[0]).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }).format("YYYY-MM-DD HH:mm:ss ZZ");
        const endDate = moment(filter.date[1]).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 0,
        }).format("YYYY-MM-DD HH:mm:ss ZZ");
        filter.startDate = startDate;
        filter.endDate = endDate;
        delete filter.date;
      }

      this.props.SearchPrePlanner(filter);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loadingBtnSearch } = this.props;

    const renderPrintedOptions = () => {
      return [
        <Option key={true} value={true}>
          {"Impresa"}
        </Option>,
        <Option key={false} value={false}>
          {"No impresa"}
        </Option>,
      ];
    };

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row
          gutter={24}
          style={{
            marginBottom: 24,
          }}
        >
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <InputComponentWithBorder
              getFieldDecorator={getFieldDecorator}
              title="TrackCode"
              decodator="trackCode"
              placeholder="Ingresa un TrackCode"
            />
          </Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <RangePickerWithBorderCompoment
              title="Fecha"
              getFieldDecorator={getFieldDecorator}
              decodator="date"
              placeholder={["Inicio", "Fin "]}
            />
          </Col>
          <Col xs={24} sm={24} md={5} lg={4} xl={4}>
            <SelectComponent
              style={{ width: "100%" }}
              getFieldDecorator={getFieldDecorator}
              title="Estado de etiqueta"
              decodator="printed"
              options={renderPrintedOptions()}
            />
          </Col>
          <Col xs={22} sm={12} md={4} lg={4} xl={3}>
            <Button
              type="primary"
              ghost
              style={{
                
                fontWeight: "500",
                fontSize: "14px",
                lineSeight: "16px",
                height: 40,
                width: "100%",
                marginTop: "21px",
              }}
              htmlType="submit"
              loading={loadingBtnSearch}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const HeaderForm = Form.create()(Header);
export default compose(withApollo)(HeaderForm);
