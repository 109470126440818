import { Col, Select } from "antd";
import React from "react";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import { CustomFormItemSelectFilter } from "../../../components/Form";
import { CustomRow } from "../../../components/Grid";
import { CustomFilterContainer } from "../../../components/Form/SearchContainer";
import { withGlobal } from "../../../component-global";

const { Option } = Select;

class SearchCompanyHistoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  cleanFields = () => {
    this.props.form.resetFields();
    this.props.cleanSearch();
  };

  render() {
    // const { getFieldDecorator } = this.props.form;
    const { citiesData, getFieldDecorator } = this.props;

    const renderEnterprises = () => {
      return (citiesData || []).map(obj => (
        <Option key={obj.id} value={obj.id}>
          {obj.name}
        </Option>
      ));
    };

    return (
      <CustomFilterContainer
        style={{ height: 224, padding: "40px", marginBottom: "24px" }}
      >
        <CustomRow gutter={24} type="flex" justify="center">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 8 }}>
            <p className="title">Selecciona una ciudad</p>
            <p className="description">
              Antes de subir el CSV es necesario saber a qué ciudad deseas
              cargarlo.
            </p>
            <CustomFormItemSelectFilter
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
              }}
            >
              {getFieldDecorator("cityID", {
                rules: [
                  {
                    required: false,
                    message: "Por favor, seleccione una ciudad",
                  },
                ],
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Ciudad"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {renderEnterprises()}
                </Select>
              )}
            </CustomFormItemSelectFilter>
          </Col>
        </CustomRow>
      </CustomFilterContainer>
    );
  }
}

// export default compose(withGlobalContext)(SearchCompanyHistory);

const WraperSearchCompanyHistoryForm = withGlobal(SearchCompanyHistoryForm);
// const SearchCompanyHistory = Form.create()(WraperSearchCompanyHistoryForm);

export default compose(withApollo)(WraperSearchCompanyHistoryForm);
