import { Modal } from "antd";
import styled from "styled-components";

const ContainerHistory = styled.div`
  padding: 0 30px;
  & .label-with-text {
    margin: 0 0 20px;
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #109cf1;
  }

  & .label-with-text {
    margin: 0 0 20px;
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #109cf1;
  }

  & .service-type {
    display: flex;
    align-items: center;

    margin: 35px 0 15px;
    
    font-style: normal;
    font-weight: 500;

    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;

    & .service-name {
      color: #109cf1;
    }

    & .affiliate-name {
      color: #707683;
      
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
    }
    & .img-vehicle {
      margin-left: 30px;
      width: 59px;
      height: 59px;
    }
    & .img-edi {
      margin-right: 30px;
      width: 46px;
      height: 48px;
    }
  }
`;

const BGContainer = styled.div`
  width: 312px;
  flex-shrink: 0;
  height: calc(100vh - 230px);
  background-color: white;
  padding: 24px 24px 16px 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow-y: auto;
`;

const ContainerInfo = styled.div`
  margin-bottom: 44px;
  & > h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #595959;
  }
  & > h7 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
  }
`;
const Regular = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
`;

const Resalted = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #595959;
`;

const ModalSlideImg = styled(Modal)`
  width: 680px;
  height: 582px;
  & .ant-modal-content {
  }
  & .slick-arrow {
    ::before {
      font-size: 22px;
      color: #000;
    }
  }
  & .slick-slide {
    display: flex;
    justify-content: center;
  }
  & .ant-modal-body {
    width: 100%;
    height: 100%;
    padding: 46px 50px 24px 50px;
  }
`;

const ContainerImg = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export {
  ContainerHistory,
  BGContainer,
  ContainerImg,
  ContainerInfo,
  ModalSlideImg,
  Resalted,
  Regular,
};
