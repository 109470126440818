import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import { Divider, Spin, Popover, Icon } from "antd";
import { Container } from "../../../components/Container";
import { withGlobal } from "../../../component-global";
import { getOrderHistorial } from "../../../graphQl/queries/orderHistory";
import DetailOrder from "./DetailOrder";
import MapAndStatus from "./MapAndStatus";
import TitleGoBack from "../../../components/Container/titleGoBack";
import { IconDelivery, IconPickUp } from "./static";

const getMarkers = dataMarkers => {
  const markers = dataMarkers.map(obj => {
    return {
      key: obj.id,
      position: {
        lat: obj.addressPoint.coordinates[1],
        lng: obj.addressPoint.coordinates[0],
      },
      icon: obj.Type.subtype === "180" ? IconPickUp : IconDelivery,
    };
  });

  return markers;
};

class OrdersHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      accuseImgs: [],
      evidenceImgs: [],
      loadingData: false,
      loadingMap: false,
      affiliateMarker: null,
      orderID: null,
    };

    // Verify Allowed View for user
  }

  componentDidUpdate = () => {
    if (this.state.orderID !== this.props.orderId) {
      console.log("CAmbio el ID");
      this.setState({ orderID: this.props.orderId });
      this.loadData();
    }
  };

  componentDidMount = async () => {
    this.loadData();
  };

  componentWillUnmount = () => {
    this.setState({ data: null });
  };

  loadData = async () => {
    this.setState({
      loadingData: true,
      loadingMap: true,
      orderID: this.props.orderId,
    });
    const variables = {
      orderID: parseInt(this.props.orderId, 10),
    };
    const {
      data: { OrderHistorial },
    } = await this.props.client
      .query({
        fetchPolicy: "network-only",
        query: getOrderHistorial,
        variables,
      })
      .catch(error => {
        console.log(error);
      });
    console.log(OrderHistorial);

    this.setState({ loadingData: false, loadingMap: false });
    if (OrderHistorial && OrderHistorial[0]) {
      this.setState({ data: OrderHistorial[0] });
    }
    this.setState({ loadingData: false, loadingMap: false });
  };

  render() {
    const {
      data,
      evidenceImgs,
      accuseImgs,
      loadingData,
      loadingMap,
      affiliateMarker,
    } = this.state;
    const trackCode = data && data ? data.trackCode : "";
    const renderHistorial = data && data.OrderService.slice().reverse()
    const renderOrdenServices = (data &&
      renderHistorial.map((x, idx) => {
        return [
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx}>
            <div
              key="1"
              style={{
                width: "100%",
                height: "calc(100vh - 230px)",
                display: "flex",
                alignItems: "stretch",
              }}
            >
              <DetailOrder
                orderService={x}
                loadingData={loadingData}
                data={data}
                evidenceImgs={evidenceImgs}
                accuseImgs={accuseImgs}
              />
              <MapAndStatus
                orderService={x}
                data={data}
                cityID={this.props.authUser.profile.cityID}
                markers={getMarkers(data.Activities) || []}
                affiliateMarker={affiliateMarker}
                loadingMap={loadingMap}
              />
            </div>
          </div>,
          // eslint-disable-next-line react/no-array-index-key
          <Divider key={`${idx}Div`}>
            {idx === 0 && data.OrderService.length > 1 && (
              <div>
                Intentos Fallidos anteriores <Icon type="down" />
              </div>
            )}
          </Divider>,
        ];
      })) || (
      <div style={{ width: "100%", textAlign: "center", marginTop: 34 }}>
        <Spin size="large" />
      </div>
    );

    return (
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitleGoBack title={`Historial de TrackCode: ${trackCode}`} />

          <Popover
            placement="left"
            title="Nueva función"
            content="Ahora podrás realizar más de un intento de entrega."
            overlayStyle={{ width: 224 }}
          >
            <div
              style={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "16px",
                border: "1px solid #307FE2",
                borderRadius: 4,
                padding: "10px 12px 10px 12px",
                color: "#307FE2",
              }}
            >
              Intentos de entrega {renderOrdenServices.length}
            </div>
          </Popover>
        </div>
        {renderOrdenServices}
      </Container>
    );
  }
}

const WraperOrdersHistory = withGlobal(OrdersHistory);

export default compose(withApollo)(WraperOrdersHistory);
