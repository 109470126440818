import styled from "styled-components";
import { Upload } from "antd";

const { Dragger } = Upload;

const ContainerDragger = styled(Dragger)`
  background: #ffffff !important;
`;

export { ContainerDragger };
