import React from "react";
import { Input, Icon } from "antd";
import { CustomFormItemInputWithBorder } from "../Form";

class InputComponent extends React.Component {
  render() {
    const {
      getFieldDecorator,
      title,
      decodator,
      value,
      required,
      disabled,
      placeholder,
      suffix,
      addonAfter,
      addonBefore,
      allowClear,
      onChange,
      onPressEnter,
      refProp,
      size,
      onClick,
      validateStatus,
      help,
      prefix,
      isPassword,
      message,
      validator,
      onBlur,
      isgraycolor,
      inputValue,
    } = this.props;

    return (
      <div>
        <CustomFormItemInputWithBorder
          isgraycolor={isgraycolor}
          isnoborderradius="true"
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
          validateStatus={validateStatus}
          help={help}
        >
          {getFieldDecorator ? (
            getFieldDecorator(decodator, {
              initialValue: value || undefined,
              rules: [
                {
                  required,
                  message: message || "Campo obligatorio",
                },
                {
                  validator,
                },
              ],
            })(
              isPassword ? (
                <Input.Password
                  // prefix={
                  //   prefix && (
                  //     <Icon type={prefix} style={{ color: "rgba(0,0,0,.25)" }} />
                  //   )
                  // }
                  name={decodator}
                  onClick={onClick}
                  size={size || "large"}
                  ref={refProp}
                  onPressEnter={onPressEnter}
                  onChange={onChange}
                  allowClear={allowClear}
                  disabled={disabled}
                  placeholder={placeholder}
                  suffix={suffix}
                  addonAfter={addonAfter}
                  addonBefore={addonBefore}
                  onBlur={onBlur}
                />
              ) : (
                <Input
                  prefix={
                    prefix && (
                      <Icon
                        type={prefix}
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    )
                  }
                  name={decodator}
                  onClick={onClick}
                  size={size || "large"}
                  ref={refProp}
                  onPressEnter={onPressEnter}
                  onChange={onChange}
                  allowClear={allowClear}
                  disabled={disabled}
                  placeholder={placeholder}
                  suffix={suffix}
                  addonAfter={addonAfter}
                  addonBefore={addonBefore}
                  onBlur={onBlur}
                />
              )
            )
          ) : (
            <Input
              prefix={
                prefix && (
                  <Icon type={prefix} style={{ color: "rgba(0,0,0,.25)" }} />
                )
              }
              name={decodator}
              onClick={onClick}
              size={size || "large"}
              ref={refProp}
              onPressEnter={onPressEnter}
              onChange={onChange}
              allowClear={allowClear}
              value={inputValue}
              disabled={disabled}
              placeholder={placeholder}
              suffix={suffix}
              addonAfter={addonAfter}
              addonBefore={addonBefore}
              onBlur={onBlur}
            />
          )}
        </CustomFormItemInputWithBorder>
      </div>
    );
  }
}

export default InputComponent;
