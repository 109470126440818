import gql from "graphql-tag";

export const getOrderIncidencesByEnterprise = gql`
  query getOrderIncidencesByEnterprise(
    $enterpriseID: Int!
    $startDate: Date
    $endDate: Date
  ) {
    data: getOrderIncidencesByEnterprise(
      enterpriseID: $enterpriseID
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      name
      quantity
    }
  }
`;

export const getOrderIndicatorsByEnterprise = gql`
  query getOrderIndicatorsByEnterprise(
    $enterpriseID: Int!
    $startDate: Date
    $endDate: Date
  ) {
    data: getOrderIndicatorsByEnterprise(
      enterpriseID: $enterpriseID
      startDate: $startDate
      endDate: $endDate
    ) {
      createdOrders
      enteredOrders
      dispatchedOrders
      reprogrammingOrders
    }
  }
`;

export const getOrderAttemptSuccessRateByEnterprise = gql`
  query getOrderAttemptSuccessRateByEnterprise(
    $enterpriseID: Int!
    $startDate: Date
    $endDate: Date
  ) {
    data: getOrderAttemptSuccessRateByEnterprise(
      enterpriseID: $enterpriseID
      startDate: $startDate
      endDate: $endDate
    ) {
      firstVisit { 
        date
        deliveredOrders
        totalOrders
      }
      secondVisit  { 
        date
        deliveredOrders
        totalOrders
      }
    }
  }
`;
