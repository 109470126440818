import React from "react";
import { Modal, Row, Button, Col } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";

import { withGlobal } from "../../../../component-global";
import MapComponent from "../../../../components/Maps/MapsOnePointDraggable";
import { CustomFormItemInputWithBorder } from "../../../../components/Form";
import { LocationSearchInput } from "../../../../components/Maps/LocationSearch";
import { InputComponentWithBorder } from "../../../../components/FormFields";

import { TitleModal } from "./Style";

class ModalMap extends React.Component {
  render() {
    const {
      title,
      subtitle,
      visible,
      coordinate,
      onChangeCoordinate,
      isValidCoordinate,
      onCancel,
      onConfirm,
      onChangeAddress,
      onSelectAddress,
      onDragAddress,
      address,
    } = this.props;

    return (
      <Modal
        title={null}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width="684px"
      >
        <div style={{ padding: 24 }}>
          <TitleModal>
            <p className="title-modal">{title}</p>
            <p className="descp-modal">{subtitle}</p>
          </TitleModal>

          <Row gutter={24} style={{ marginBottom: 16 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <CustomFormItemInputWithBorder
                labelCol={{ xs: { span: 24 }, sm: { span: 24 }, lg: { span: 24 }, xl: { span: 24 } }}
                wrapperCol={{ xs: { span: 24 }, sm: { span: 24 }, lg: { span: 24 }, xl: { span: 24 } }}
                label="Dirección de recolección"
              >
                <LocationSearchInput
                  color="#347be3"
                  theme="filled"
                  address={address}
                  onChange={onChangeAddress}
                  onAddressSelect={onSelectAddress}
                  codeRegion={this.props.authUser.profile.regionCountry}
                />
              </CustomFormItemInputWithBorder>
              <InputComponentWithBorder
                title="Latitud y longitud"
                placeholder="Latitud y longitud"
                inputValue={coordinate ? `${coordinate.lat}, ${coordinate.lng}` : ""}
                onChange={onChangeCoordinate}
                validateStatus={isValidCoordinate ? null : "error"}
              />
            </Col>
          </Row>
          <div style={{ height: "400px", width: "100%", marginBottom: 16 }}>
            <MapComponent
              isMarkerShown
              zoomMap={16}
              latLng={coordinate}
              markerDrag={onDragAddress}
            />
          </div>
          <Row gutter={24} type="flex" justify="center">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Button
                type="primary"
                ghost
                style={{
                  
                  fontWeight: "500",
                  fontSize: "14px",
                  height: 40,
                  width: "100%",
                }}
                onClick={onCancel}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Button
                type="primary"
                style={{
                  
                  fontWeight: "500",
                  fontSize: "14px",
                  height: 40,
                  width: "100%",
                }}
                onClick={onConfirm}
              >
                Guardar cambios
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

const WrapperModalMap = withGlobal(ModalMap);
export default compose(withApollo)(WrapperModalMap);
