// import {  init, captureMessage, captureException } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://0b5d534212f94aba862a0b8a800af027@sentry.chazki.com/6",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const captureMessage = msj => {
  Sentry.captureMessage(msj);
};

const captureException = msj => {
  Sentry.captureException(new Error(msj));
};

export { captureMessage, captureException };
