import React from "react";
import { Typography, Collapse, Icon } from "antd";

import { Paper } from "../../../../components/Container";
import { StyledPanel, OrderInfo } from ".";

const { Title } = Typography;

const OrdersInfo = props => {
  const { orders, orderToComplete, chooseMarkerIcon } = props;
  const hasNotOrders = orders && orders.length === 0;
  const multiOrder = !hasNotOrders && orders.length > 1;
  const multiPickUp =
    multiOrder &&
    orders[0].Orders.pickUpAddress !== orders[1].Orders.pickUpAddress;

  const ordersAccordion = (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      defaultActiveKey={orders.map(order => order.Orders.id)}
      expandIcon={({ isActive }) => (
        <Icon type="down" rotate={isActive ? 90 : 0} />
      )}
    >
      {orders.map((order, index) => (
        <StyledPanel
          header={
            <Title
              level={4}
              style={{
                color: "#595959",
                margin: 0,
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 32,
                  width: 24,
                  backgroundImage: `url("${chooseMarkerIcon(
                    orderToComplete,
                    parseInt(order.Orders.id, 10),
                    parseInt(order.LastStatus.subtype, 10)
                  )}")`,
                  backgroundSize: "cover",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundPosition: "center",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  {index + 1}
                </span>
              </div>
              <span style={{ marginTop: 5, marginLeft: 10 }}>
                TrackCode: {order.Orders.trackCode}
              </span>
            </Title>
          }
          key={order.Orders.id}
          extra="Ocultar"
        >
          <OrderInfo
            order={order}
            orderToComplete={
              parseInt(order.Orders.id, 10) === orderToComplete &&
              [162, 164].includes(parseInt(order.LastStatus.subtype, 10))
            }
          />
        </StyledPanel>
      ))}
    </Collapse>
  );

  return hasNotOrders ? (
    <Paper>Esta ruta no posee órdenes asociadas</Paper>
  ) : multiPickUp ? (
    <Paper>{ordersAccordion}</Paper>
  ) : (
    <>
      <Paper>
        <Title
          level={3}
          style={{ color: "#595959", fontWeight: "bold", margin: 0 }}
        >
          Recolección
        </Title>
        <Title level={4} style={{ color: "#595959", margin: 0 }}>
          {orders[0].Orders.pickUpAddress}
        </Title>
      </Paper>
      {ordersAccordion}
    </>
  );
};

export default OrdersInfo;
