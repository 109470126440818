import {
  Button,
  Col,
  Drawer,
  message,
  Select,
  Form,
  Row,
  Collapse,
  notification,
  Spin,
} from "antd";
import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { withGlobal } from "../../../../component-global";
import { captureException } from "../../../../utils/sentry";
import { withGlobalContext } from "../../../../utils/globalContext";
import {
  SelectWithBorderComponent,
  InputComponentWithBorder,
  InputNumberWithBorderComponent,
} from "../../../../components/FormFields";

import {
  createOrderFromOrderNoLocated,
} from "../../../../graphQl/mutations/nonExistent";
import {
  CustomCollapse,
  ContainerBotton,
  DrawerContainer,
  CustomAccordion,
} from "./Style";
import { renderPoint } from "./Util";
const { Panel } = Collapse;
const { Option } = Select;

class DrawerEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: false,
    };
  }

  submitForm = () => {
    const { addressDrop, addressPick, order } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const variables = {
          orderNoLocatedID: parseInt(order.id, 10),
          order: {
            trackCode: values.trackcode,
            serviceID: parseInt(values.service, 10),
            paymentMethodID: parseInt(values.payment, 10),
            paymentProofID: parseInt(values.billing, 10),
            productPrice: values.price,
            pickUpAddress: addressPick.address,
            pickUpPoint: {
              type: "Point",
              coordinates: [
                addressPick.coordinate.lng,
                addressPick.coordinate.lat
              ]
            },
            pickUpAddressReference: values.originReference,
            pickUpContactName: values.originContact,
            pickUpContactPhone: values.originCellphone,
            dropAddress: addressDrop.address,
            dropPoint: {
              type: "Point",
              coordinates: [
                addressDrop.coordinate.lng,
                addressDrop.coordinate.lat
              ]
            },
            dropAddressReference: values.destinationReference,
            dropContactName: values.destinationContact,
            dropContactPhone: values.destinationCellphone,
            dropNotes: values.destinationDetail,
          }
        };

        this.props.client
          .mutate({
            mutation: createOrderFromOrderNoLocated,
            update: (cache, { data: { createOrderFromOrderNoLocated: res } }) => {
              if (res.statusCode === 200) {
                notification.success({
                  message: "Trackcode completado con éxito",
                  description: "Se guardado y procesado en nuestro sistema",
                });
                this.setState({ loading: false });
                this.props.onClose();
                this.props.refreshList({});
              } else {
                notification.error({ message: res.message });
                this.setState({ loading: false });
              }
            },
            variables,
          })
          .catch(error => {
            captureException(error);
            message.error("No se pudo guardar, error en el sistema.");
            this.setState({ loading: false });
          });
      }
    })
  }

  onClose = () => {
    this.setState({ loading: false })
    this.props.onClose();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      order,
      title,
      visible,
      serviceOptions,
      paymentMethodOptions,
      paymentProofOptions,
      cityOptions,
      openMapLocation,
      addressPick,
      addressDrop,
    } = this.props;
    const { loading } = this.state;

    const renderService = (serviceOptions || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    ));

    const renderPaymentMethod = (paymentMethodOptions || []).map(obj => (
      <Option key={obj.subtype} value={obj.subtype}>
        {obj.subclass}
      </Option>
    ));

    const renderPaymentProof = (paymentProofOptions || []).map(obj => (
      <Option key={obj.subtype} value={obj.subtype}>
        {obj.subclass}
      </Option>
    ));

    return (
      <Drawer
        visible={visible}
        onClose={this.onClose}
        placement="right"
        closable
        width="700px"
        destroyOnClose
      >

        {loading ? (
          <Spin
            tip="Cargando..."
            size="large"
            style={{
              left: "50%",
              position: "absolute",
              textAlign: "center",
              top: "40%",
              transform: "translate(-50%, -50%)",
              verticalAlign: "middle",
            }}
            spinning={loading}
          />
        ) : (
          <DrawerContainer>
            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
              <h2><b>{title}</b></h2>
              <span>Completa los campos para procesar la solicitud con éxito</span>

              <Row gutter={24} style={{ marginTop: 10 }}>
                <Col span={12}>
                  <InputComponentWithBorder
                    getFieldDecorator={getFieldDecorator}
                    title="TrackCode"
                    value={order ? order.trackCode : ""}
                    decodator="trackcode"
                    disabled
                  />
                </Col>
                <Col span={12}>
                  <SelectWithBorderComponent
                    style={{ width: "100%" }}
                    getFieldDecorator={getFieldDecorator}
                    title="Tipo de servicio"
                    placeholder="Selecciona un tipo de servicio"
                    decodator="service"
                    options={renderService}
                    required
                  />
                </Col>
              </Row>

              <Row gutter={24} style={{ marginTop: 10 }}>
                <Col span={12}>
                  <SelectWithBorderComponent
                    style={{ width: "100%" }}
                    getFieldDecorator={getFieldDecorator}
                    title="Método de pago"
                    placeholder="Selecciona un método"
                    decodator="payment"
                    options={renderPaymentMethod}
                    required
                  />
                </Col>
                <Col span={12}>
                  <SelectWithBorderComponent
                    style={{ width: "100%" }}
                    getFieldDecorator={getFieldDecorator}
                    title="Facturación"
                    placeholder="Selecciona un tipo"
                    decodator="billing"
                    options={renderPaymentProof}
                    required
                  />
                </Col>
              </Row>

              <Row gutter={24} style={{ marginTop: 10 }}>
                <Col span={12}>
                  <InputNumberWithBorderComponent
                    getFieldDecorator={getFieldDecorator}
                    title="Precio del producto"
                    placeholder="Valor"
                    value=""
                    decodator="price"
                    required
                  />
                </Col>
              </Row>
            </div>

            <CustomCollapse
              className="seeDetailsButton"
              bordered={false}
              expandIconPosition="right"
              defaultActiveKey={["1", "2"]}
              expandIcon={({ isActive }) => (
                <CustomAccordion>
                  <span>{isActive ? "Ocultar" : "Ver detalle"}</span>
                </CustomAccordion>
              )}
            >
              <Panel header="Punto de origen" key="1" style={{ marginTop: 15 }}>
                {renderPoint(
                  cityOptions,
                  getFieldDecorator,
                  "origin",
                  openMapLocation,
                  addressPick
                )}
              </Panel>

              <Panel
                header="Punto de destino"
                key="2"
                style={{ marginTop: 15 }}
              >
                {renderPoint(
                  cityOptions,
                  getFieldDecorator,
                  "destination",
                  openMapLocation,
                  addressDrop
                )}
              </Panel>
            </CustomCollapse>

            <ContainerBotton>
              <Row gutter={24} type="flex" justify="space-around">
                <Button
                  type="primary"
                  style={{
                    fontSize: "14px",
                    height: "40px",
                    width: "200px",
                  }}
                  onClick={this.submitForm}
                >
                  Validar
                </Button>
              </Row>
            </ContainerBotton>
          </DrawerContainer>
        )}
      </Drawer>
    );
  }
}

const wraperDrawerEdit = withGlobal(DrawerEdit);
const DrawerEditForm = Form.create()(wraperDrawerEdit);

export default compose(
  withApollo,
  withGlobalContext
)(DrawerEditForm);
