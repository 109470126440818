import React from "react";
import { Select } from "antd";
import { FormItemInputWithBorder } from "../Form";

class SelectComponent extends React.Component {
  render() {
    const {
      getFieldDecorator,
      title,
      decodator,
      options,
      value,
      disabled,
      mode,
      required,
      loading,
      onChange,
      message,
      placeholder,
      style,
      size,
      maxTagCount,
      optionLabelProp,
    } = this.props;

    return (
      <div>
        <FormItemInputWithBorder
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: undefined || value,
            rules: [
              {
                required,
                message: message || "Campo obligatorio",
              },
            ],
          })(
            <Select
              size={size || "large"}
              allowClear
              style={style}
              mode={mode}
              showSearch
              disabled={disabled}
              loading={loading}
              onChange={onChange}
              optionFilterProp="children"
              optionLabelProp={optionLabelProp}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder={placeholder}
              maxTagCount={maxTagCount}
            >
              {options}
            </Select>
          )}
        </FormItemInputWithBorder>
      </div>
    );
  }
}

export default SelectComponent;
