import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Button, Form, notification } from "antd";
import { getBranchOffices } from "../../../graphQl/queries/branches";
import { withGlobal } from "../../../component-global";
import { withGlobalContext } from "../../../utils/globalContext";
import TitleGoBack from "../../../components/Container/titleGoBack";
import { Container } from "../../../components/Container";
import BodyContainer from "../../../components/Layout/BodyContainer";
import { deleteBranchOffice } from "../../../graphQl/mutations/branches";
import DeleteModal from "../../../components/Modals/DeleteModal";
import Body from "./Body";
import Header from "./Header";

class Branches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: { limit: 10, page: 0 },
      loading: false,
      selectedRows: [],
      loadingDelete: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    const {
      data: { AllBranchOffices },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getBranchOffices,
      variables: {
        enterpriseID: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
        offset: 0,
        limit: 10,
      },
    });

    this.setState({
      AllBranchOffices,
      loading: false,
      totalOrders: AllBranchOffices.count,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("Values", values);
    });
  };

  SearchPrePlanner = async filter => {
    try {
      this.setState({ loadingBtnSearch: true, currentFilter: filter });
      const { pagination } = this.state;
      const {
        data: { AllBranchOffices },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getBranchOffices,
        variables: {
          ...filter,
          enterpriseID: parseInt(
            this.props.userEnterpriseData.enterpriseID,
            10
          ),
          limit: pagination.limit,
          offset: 0 * pagination.limit,
        },
      });

      this.setState({
        pagination: { limit: pagination.limit, page: 0 },
        data: AllBranchOffices.rows,
        totalOrders: AllBranchOffices.count,
        loadingBtnSearch: false,
      });
    } catch (err) {
      console.log("Error");
      this.setState({ loadingBtnSearch: false });
      notification.error({ message: "Error en la carga de servicios." });
    }
  };

  onChange = async (page, pageSize) => {
    try {
      this.setState({ loadingTable: true });
      const { pagination, currentFilter } = this.state;
      const {
        data: { AllBranchOffices },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getBranchOffices,
        variables: {
          ...currentFilter,
          enterpriseID: parseInt(
            this.props.userEnterpriseData.enterpriseID,
            10
          ),
          limit: pagination.limit,
          offset: (page - 1) * pagination.limit,
        },
      });

      this.setState({
        pagination: { limit: pageSize, page: page - 1 },
        data: AllBranchOffices.rows,
        loadingTable: false,
        totalOrders: AllBranchOffices.count,
      });
    } catch (err) {
      // captureException(err);
      this.setState({ loadingTable: false });
      console.log(err);
      notification.error({ message: "Error en la carga de servicios." });
    }
  };

  setSelectedRows = records => {
    this.setState({ selectedRows: records });
  };

  onDeleteBranches = () => {
    const { selectedRows } = this.state;

    this.setState({ loadingDelete: true });

    const variables = {
      branchIDs: (selectedRows || []).map(obj => parseInt(obj.id, 10)),
      enterpriseID: parseInt(this.props.userEnterpriseData.enterpriseID, 10),
    };
    this.props.client
      .mutate({
        mutation: deleteBranchOffice,
        update: (cache, { data: { DeleteBranchOffice } }) => {
          console.log(DeleteBranchOffice);
          notification.success({
            message: `Se eliminaron las sucursales con éxito.`,
          });
          this.setState({ loadingDelete: false, visibleDelete: false });
          this.SearchPrePlanner(this.state.currentFilter);
        },
        variables,
      })
      .catch(err => {
        // captureException(err);
        console.log(err);
        notification.error({
          message: "No se pudo guardar, error en el sistema.",
        });
        this.setState({ loadingDelete: false });
      });
    this.setState({ selectedRows: [] });
  };

  render() {
    const { AllBranchOffices } = this.state;

    const { pagination, loading } = this.state;
    return (
      <Container>
        <TitleGoBack title="Sucursales" />
        <BodyContainer gutter={16} justify="center">
          <Header
            loadingBtnSearch={this.state.loadingBtnSearch}
            SearchPrePlanner={this.SearchPrePlanner}
          />
          <Body
            data={
              this.state.data ||
              (AllBranchOffices ? AllBranchOffices.rows : []) ||
              []
            }
            totalOrders={this.state.totalOrders}
            currentPage={pagination.page + 1}
            pageSize={pagination.limit}
            loading={
              this.state.loadingTable || this.state.loadingBtnSearch || loading
            }
            setSelectedRows={this.setSelectedRows}
            onChange={this.onChange}
          />
          <Button
            type="danger"
            size="large"
            style={{ position: "absolute", bottom: 24, right: 24 }}
            loading={this.state.loadingDelete}
            onClick={() => this.setState({ visibleDelete: true })}
            disabled={!this.state.selectedRows.length}
          >
            Eliminar
          </Button>
        </BodyContainer>
        <DeleteModal
          visible={this.state.visibleDelete}
          loadingDelete={this.state.loadingDelete}
          onCancel={() => this.setState({ visibleDelete: false })}
          onDelete={this.onDeleteBranches}
          message="Eliminar sucursales"
          nRegisters={this.state.selectedRows.length}
        />
      </Container>
    );
  }
}

const WrapperBranches = withGlobal(Branches);
const FormMyBranches = Form.create()(WrapperBranches);

export default compose(
  withApollo,
  withGlobalContext
)(FormMyBranches);
