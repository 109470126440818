import { Plugin, ArcProps } from "chart.js";
import { grayColor } from "utils";

type PointProps = {
  [key in keyof ArcProps]: number;
};

const scrollbarWidth = 6;

const getInnerProduct = (a: number[], b: number[]): number =>
  a[0] * b[0] + a[1] * b[1];
const getNorm = (a: number[]): number =>
  Math.sqrt(Math.pow(a[0], 2) + Math.pow(a[1], 2));

export const showQuantityLabel: Plugin<"doughnut"> = {
  id: "showQuantityLabel",
  afterDraw: chart => {
    const { ctx, data } = chart;

    data.datasets.forEach((element, elementIndex) => {
      chart.getDatasetMeta(elementIndex).data.forEach((point, pointIndex) => {
        // * Circumference center point
        const { x: xC, y: yC } = point;
        // * Tooltip point from start of canvas (x: 0, y: 0)
        const { x: xT, y: yT } = point.tooltipPosition();
        // * Tooltip point from center of circumference (x: xC, y: yC)
        const posTC = [xT - xC, yT - yC];

        // * Circumference radius
        const { outerRadius, startAngle, endAngle } = point.getProps([
          "outerRadius",
          "startAngle",
          "endAngle",
        ]) as PointProps;

        // * Right point of circumference
        const right = [xC, 0];
        // * Angle between right point and tooltip point
        let theta = Math.acos(
          getInnerProduct(posTC, right) / (getNorm(posTC) * getNorm(right))
        );
        // * Change the direction of angle
        theta = yT > yC ? theta : -theta;

        const [x, y] = [
          xC + outerRadius * Math.cos(theta),
          yC + outerRadius * Math.sin(theta),
        ];

        if (endAngle - startAngle !== 0) {
          // * Set line length
          const lineLength = 32;
          // * End of line point
          const [xL, yL] = [
            x + lineLength * Math.cos(theta),
            y + lineLength * Math.sin(theta),
          ];

          // * Draw a line from the circumference to the outside
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(xL, yL);
          ctx.strokeStyle = grayColor;
          ctx.stroke();

          // * Set the quantity as label
          const label = (element.data[pointIndex] || "").toString();
          const { width } = ctx.measureText(label);
          const margin = yT > yC ? 15 : -15;

          ctx.font = "16px Arial";
          ctx.fillStyle = grayColor;
          ctx.fillText(label, xL - width / 2, yL + margin);
        }
      });
    });

    // * Save modifications on canvas
    ctx.save();
  },
};

export const addScrollbar = (visibleBars: number): Plugin<"bar"> => ({
  id: "addScrollbar",
  afterDraw: chart => {
    const {
      ctx,
      data,
      chartArea: { right, top, height },
    } = chart;
    // ! If data not exceed visible bars
    // ! quantity, does not draw scrollbar
    if (data.datasets[0].data.length <= visibleBars) return;

    // * Draw scrollbar
    ctx.beginPath();
    ctx.fillStyle = "#E4E4E4";
    ctx.rect(right, top, scrollbarWidth, height);
    ctx.fill();
    ctx.closePath();

    // * Draw movable bar
    let start =
      top + (height / data.datasets[0].data.length) * chart.scales.y.min;
    const movableBarHeight =
      (height / data.datasets[0].data.length) * visibleBars;
    ctx.beginPath();
    ctx.fillStyle = "#CCCCCC";
    ctx.rect(right, start, scrollbarWidth, movableBarHeight);
    ctx.fill();
    ctx.closePath();

    ctx.save();
  },
});

export const floatingChartDataLabels: Plugin<"bar"> = {
  id: "floatingChartDataLabels",  
  afterDatasetsDraw(chart){
    const { ctx } = chart;

    for(let i = 0; i< chart.config.data.datasets[1].data.length; i++) {
      const xPosition = chart.getDatasetMeta(1).data.length > 0 ? chart.getDatasetMeta(1).data[i].x: 0;
      const yPosition = chart.getDatasetMeta(1).data.length > 0 ? chart.getDatasetMeta(1).data[i].y: 0;
      const dataPorcentageText = chart.config.data.datasets[2].data[i] === null ? 0 : chart.config.data.datasets[2].data[i];
      const dataText = chart.config.data.datasets[0].data[i] === null ? 0 : chart.config.data.datasets[0].data[i];
      ctx.save();     
         
      ctx.textAlign = 'center'
      ctx.font = 'bolder 12px Arial';
      if( dataPorcentageText !== null) {
        if(chart.isDatasetVisible(1) === true) {
          ctx.fillStyle = '#52C41A';
        } else {
          ctx.fillStyle = 'transparent';
        }; 
        ctx.fillText(dataPorcentageText.toString()+" %", xPosition, yPosition - 40)
      }
      if( dataText !== null) {
        if(chart.isDatasetVisible(0) === true) {
          ctx.fillStyle = 'rgb(250, 150, 161)';
        } else {
          ctx.fillStyle = 'transparent';
        }; 
        ctx.fillText(dataText.toString(), xPosition, yPosition - 15)
      }      
    }     
  }
}