import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import { ContainerTables } from "../Form/SearchContainer";
import MapComponent from "./Maps";
// import { captureException } from "../../../utils/sentry";
// import Loading from "../../../components/Utils/Loading";

class MapDragAnDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: -12.062583,
        lng: -77.0482757,
      },
      // currenChazki: [],
    };
  }

  render() {
    const {
      directions,
      style,
      markers,
      onMarkerSelect,
      polyline,
      latLng,
    } = this.props;
    const { center } = this.state; // currenChazki

    return (
      <ContainerTables>
        <div className="container-map" style={style}>
          <MapComponent
            latLng={latLng || center}
            directions={directions}
            polyline={polyline}
            markers={markers}
            onMarkerSelect={onMarkerSelect}
          />
        </div>
      </ContainerTables>
    );
  }
}

export default compose(withApollo)(MapDragAnDrop);
