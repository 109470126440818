import React, { Component } from "react";
import { Dropdown, Icon, Menu, message, Spin, Upload, Avatar } from "antd";
// import { uploadToS3 } from "utils";
import { ClearFix, PreviewImage, ContainerCamera } from "./style";
import { withFirebase } from "../../firebase";

const urlEndPoint = process.env.REACT_APP_API_ENDPOINT;
const functionUpload = process.env.REACT_APP_NAME_UPLOAD_ALL;

const logoImage =
  "https://chazkistorage.blob.core.windows.net/resources/user.png";

class UploaderFireBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveBackState: false,
    };
  }

  fileNameStamp = name => {
    const getDate = new Date();
    getDate.setHours(getDate.getHours() - 5);
    let timestamp = getDate.toISOString();
    timestamp = timestamp.replace(/:/g, "-");
    timestamp = timestamp.replace(/\./g, "-");
    return timestamp + name.replace(/ /g, "_");
  };

  beforeUpload = file => {
    this.setState({ saveBackState: true });
    const isLt2M =
      file.size / (this.props.maxWidth || 500) / (this.props.maxHeight || 500) <
      2;
    if (!isLt2M) {
      this.setState({ saveBackState: false });
      message.error(
        `Archivo debe ser de ${this.props.maxWidth || 500} x ${this.props
          .maxHeight || 500}`
      );
    }

    return isLt2M;
  };

  handleDelete = () => {
    this.props.onDeleteImgProfile();
  };

  customPost = ({
    // action,
    // data,
    file,
    // filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const {
      firebase,
      folderName,
      target,
      // uploadComplete = null,
      // uploadingFile = null,
      // uploadingError = null,
      onUpdateFields = null,
    } = this.props;

    // uploadingFile && uploadingFile(true);

    const fileStamp = this.fileNameStamp(file.name);
    const key = `${folderName}/${target}/${fileStamp}`;

    const task = firebase.refStorage.child(key).put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        const percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress({ percent: percentage });
      },
      err => {
        console.log("error on upload", err);
        message.error("Error al subir archivo");
        // uploadingFile && uploadingFile(false);
        // uploadingError && uploadingError(err, file.uid);
        this.setState({ saveBackState: false });
        return onError(err);
      },
      () => {
        task.snapshot.ref.getDownloadURL().then(downloadURL => {
          const { name, uid, url = downloadURL, status = "done" } = file;
          onSuccess({ name, uid, url, status, file: downloadURL });
          onUpdateFields && onUpdateFields(url);
          this.setState({ saveBackState: false });
          message.success(`Archivo subido correctamente`);
        });
      }
    );
  };

  render() {
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Upload
            action={`${urlEndPoint}/${functionUpload}`}
            beforeUpload={this.beforeUpload}
            customRequest={this.customPost}
            data={{ hola: true }}
            headers={{
              "X-Requested-With": null,
            }}
            showUploadList={false}
          >
            <span>Subir foto</span>
          </Upload>
        </Menu.Item>
        <Menu.Item key="1" onClick={this.handleDelete}>
          <span>Eliminar</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <ClearFix>
        <PreviewImage>
          <div className="imgDiv">
            {this.state.saveBackState ? (
              <Spin
                delay={1000}
                size="large"
                style={{
                  display: "table-cell",
                  left: "50%",
                  position: "absolute",
                  textAlign: "center",
                  top: "40%",
                  transform: "translate(-50%, -50%)",
                  verticalAlign: "middle",
                }}
              />
            ) : null}
            <Avatar
              size={95}
              src={this.props.imgDefault || logoImage}
              style={
                this.state.saveBackState
                  ? { filter: "blur(5px)", opacity: "0.6" }
                  : {}
              }
            />
            <div>
              <Dropdown overlay={menu} trigger={["click"]}>
                <ContainerCamera>
                  Subir imagen
                  <Icon
                    type="upload"
                    style={{ fontSize: 20, color: "#307fe2", marginLeft: 5 }}
                  />
                </ContainerCamera>
              </Dropdown>
            </div>
          </div>
        </PreviewImage>
      </ClearFix>
    );
  }
}

const ImageUpload = withFirebase(UploaderFireBase);

export default ImageUpload;
