import React from "react";
import { Checkbox } from "antd";
import styled from "styled-components";
import { CustomFormItemInputSelect } from "../Form";

const CheckboxWrapper = styled.div`
  margin-bottom: 20;
  /* .ant-checkbox-inner {
    border: 1px solid ${props => (props.isEmpty ? "red" : "#BFBFBF")};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid ${props => (props.isEmpty ? "red" : "#BFBFBF")};
  } */
`;

class CheckBoxComponentWithBorderNew extends React.Component {
  state = { isEmpty: null };

  render() {
    const {
        getFieldDecorator,
        decodator,
        title,
        value,
        options,
        onChange,
        childrenOptions,
        required,
    } = this.props;

    return (
        <CheckboxWrapper isEmpty={this.state.isEmpty}>
        <CustomFormItemInputSelect
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: value || undefined,
            rules: [
              {
                required,
              },
            ],
          })(
            <Checkbox.Group
              options={options}
              onChange={onChange}
              style={{ width: "100%" }}
            >
              {childrenOptions}
            </Checkbox.Group>
          )}
        </CustomFormItemInputSelect>
      </CheckboxWrapper>
    );
  }
}

export default CheckBoxComponentWithBorderNew;
