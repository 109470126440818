import React from "react";
import { compose, withProps } from "recompose";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  // withScriptjs, con este incrustas el googleURL pero como ya esta incrustado alv
} from "react-google-maps";
import { defaultMapOptions } from "./static";

const IconPick = {
  url:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/iconsMap%2FpickUpIcon.png?alt=media&token=ac0d41f9-4620-47f5-8c35-19cba0ec99b7",
  scaledSize: { height: 30, width: 25 },
};

const IconDelivery = {
  url:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/iconsMap%2FdropOdIcon.png?alt=media&token=abb639dc-14b0-4580-bfea-06e6b9d62f61",
  scaledSize: { height: 30, width: 25 },
};

const MapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCAHe6y1MxSEcRPFsrgwzoxelOIWZH36w0&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  // withScriptjs,
  withGoogleMap
)(props => {
  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={props.latLng}
      defaultOptions={defaultMapOptions}
      center={props.latLng}
    >
      {props.isMarkerShown && props.latLngPick && (
        <Marker
          draggable={props.draggable}
          onDragEnd={props.markerDragPick}
          position={props.latLngPick}
          icon={IconPick}
        />
      )}
      {props.isMarkerShown && props.latLngDelivery && (
        <Marker
          draggable={props.draggable}
          onDragEnd={props.markerDragDelivery}
          position={props.latLngDelivery}
          icon={IconDelivery}
        />
      )}
    </GoogleMap>
  );
});

export default MapComponent;
