import React, { FC } from "react";
import { Typography } from "antd";
import { Chart } from "react-chartjs-2";
import { DataAttemptRate, DataAttemptRateLabel } from "types";
import { floatingChartDataLabels } from "utils";
import  PopoverInfo  from "../PopoverInfo";
import { CustomIcon } from "../PopoverInfo/styles";

const { Title } = Typography;

interface AttemptRateProps {
  title: string;
  labels: DataAttemptRateLabel[];
  dataBar: DataAttemptRate[];
  dataLine: DataAttemptRate[];
  info:string;
  titleInfo:string;
}

const AttemptRate: FC<AttemptRateProps> = props => {
  const { title, labels, dataBar, dataLine,info,titleInfo } = props;
  return (
    <div style={{ marginTop: 16 }}>
      <Title level={3} style={{ color: "#595959" }}>
        {title}
        <PopoverInfo description={info} title={titleInfo} >
          <CustomIcon type="info-circle"/>
        </PopoverInfo>
      </Title>
      <Chart
        plugins={[floatingChartDataLabels]}
        options={{
          scales: {
            x: {
              stacked: true,
            },
            y: {
              beginAtZero: true,
              stacked: true,
              grace: "5%",
            },
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              position: "bottom",
              labels: {
                usePointStyle: true,
                padding: 30,
                filter: item => {
                  return item.text !== "porcentaje";
                },
              },
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              type: "bar",
              label: "Paquetes Entregados",
              data: dataBar,
              borderColor: "rgb(250, 150, 161)",
              backgroundColor: "rgba(255, 99, 132, 0.2)",
            },
            {
              type: "line",
              label: "% Eficiencia de paquetes entregados",
              data: dataBar,
              borderColor: "#52C41A",
              backgroundColor: "#52C41A",
              pointRadius: 0,
            },
            {
              label: "porcentaje",
              data: dataLine,
              hidden: true,
            },
          ],
        }}
        type="line"
      />
    </div>
  );
};

export default AttemptRate;
