import styled from "styled-components";

const ContainerHome = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  padding: 40px 24px;

  .welcomeBanner {
    width: 100%;
    height: 208px;
    background-color: white;
    border-radius: 4px;
    position: relative;
    padding: 24px 24px;

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      color: #595959;
    }
    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #8C8C8C;
    }
  }

  .leftCol{
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .peformanceHeader {
      position: relative;
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: row;

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #595959;
      }
      justify-content: space-between;
    }
    .performanceKpis {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .cardPerformance{
      border-radius: 4px;
      border-color: white;
      width: 192px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        color: #000000;
        margin-bottom: 5px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #8C8C8C;
      }
    }
  }

  .rightCol {
    display: flex
    flex-direction: column;
    height: 100%;
    
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #595959;
    }

    .blueCircle{
      width: 68px;
      height: 68px;
      border-radius: 34px;
      background-color: #E6F7FF;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .csvCard {
      width: 100%;
      height: 116px;
      background-color: white;
      margin-bottom: 16px;
      border-radius: 4px;
      padding: 24px;
      display: flex;
      flex-direction: row;
    }

    .expressCard {
      width: 100%;
      height: 116px;
      background-color: white;
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 24px;
      display: flex;
      flex-direction: row;
    }
    .failedMotives {
      background-color: white;
      padding: 24px;
      display: flex;
      flex-direction: column;

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

export { ContainerHome };
