import { Icon, Layout, Menu, Modal } from "antd";
import React, { Component } from "react";
import { Link } from "@reach/router";
import { compose } from "recompose";

import styled from "styled-components";
import * as router from "../../routes/routes";
import { withGlobalContext } from "../../utils/globalContext";

import { AuthService } from "../../services/AuthService.ts";

const { SubMenu } = Menu;
const { Sider } = Layout;

const CustomSider = styled(Sider)`
  box-shadow: rgba(0, 0, 0, 0.15) 4px 2px 4px 0px;
  background: white;
  z-index: 60;
  height: 100vh;
  position: fixed;
  left: 0;
  & .sidebar-menu {
    height: calc(100vh - 8rem);
    overflow-x: hidden;
    overflow-y: auto;
  }
  overflow: inherit;
`;

const Logo = styled.div`
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconToggle = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  right: -20px;
  z-index: 9999999999;
  overflow: visible;
  box-shadow: rgba(0, 0, 0, 0.15) 4px 2px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const iconStyles = {
  fontSize: 18,
};
const authService = new AuthService();

class Sidebar extends Component {
  state = {
    openKeys: [],
    selectedKeysLocal: [],
  };

  rootSubmenuKeys = [];

  logout = () => {
    authService.logout();
  };

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(
      key => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
    // if(this.props.showSideBar===false)
    if (this.props.showSideBar === false && openKeys.length === 1) {
      this.props.toggle();
      this.props.closeSideBar();
    }
  };

  onSelect = selectedKeys => {
    this.setState({ selectedKeysLocal: [selectedKeys.key] });
  };

  confirm = () => {
    Modal.confirm({
      title: "¿Esta seguro que desea cerrar sesion?",
      okText: "Si",
      cancelText: "No",
      onOk: this.logout,
    });
  };

  render() {
    const { openKeys, selectedKeysLocal } = this.state;
    const {
      closeSideBar,
      showSideBar,
      kustomerUserToken,
      showKustomerRedirect,
    } = this.props;

    const isAllowed = tagView => {
      if (this.props.global && this.props.global.viewsAllowed) {
        return this.props.global.viewsAllowed.menuAllowed.has(tagView);
      }
      return false;
    };

    return (
      <CustomSider
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}
        width={256}
      >
        <div style={{ backgroundColor: "#010616" }}>
          <Logo collapsed={this.props.collapsed}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2Fblanco.png?alt=media&token=d2d96ed5-e8ab-4c3d-84e9-b97333a155c6"
              width="110px"
              alt="Chazki"
              style={
                this.props.showSideBar
                  ? {}
                  : { visibility: "hidden", display: "none" }
              }
            />

            <img
              src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FresponseLogo.png?alt=media&token=b75db00a-56fc-45cd-977e-6875ee991757"
              width="25px"
              alt="Chazki"
              style={
                !this.props.showSideBar
                  ? {}
                  : { visibility: "hidden", display: "none" }
              }
            />

            <IconToggle
              onClick={() => {
                this.props.toggle();
                closeSideBar();
              }}
            >
              <Icon
                style={{
                  ...iconStyles,
                  fontWeight: "bold",
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#307FE2",
                }}
                className="trigger"
                type={this.props.collapsed ? "menu-unfold" : "menu-fold"}
              />
            </IconToggle>
          </Logo>
        </div>

        <Menu
          className="sidebar-menu"
          mode="inline"
          openKeys={
            // showSideBar ? (openKeys.length > 0 ? openKeys : openMenus) : []
            showSideBar ? (openKeys.length > 0 ? openKeys : []) : []
          }
          selectedKeys={
            // selectedKeysLocal.length > 0 ? selectedKeysLocal : selectedMenu
            selectedKeysLocal.length > 0 ? selectedKeysLocal : []
          }
          onOpenChange={this.onOpenChange}
          onSelect={this.onSelect}
        >
          {isAllowed("zelda") && (
            <Menu.Item key="home">
              <Link to={`/dashboard${router.home}`}>
                <span>
                  <Icon style={iconStyles} type="home" />
                  <span>Inicio</span>
                </span>
              </Link>
            </Menu.Item>
          )}

          {isAllowed("zelda") && (
            <Menu.Item key={router.statistics}>
              <Link to={router.statistics}>
                <span>
                  <Icon style={iconStyles} type="stock" />
                  <span>Estadísticas</span>
                </span>
              </Link>
            </Menu.Item>
          )}

          {isAllowed("zelda:uploadcsv") && (
            <Menu.Item key="zelda:uploadcsv">
              <Link to={`${router.uploadcsv}`}>
                <span>
                  <Icon style={iconStyles} type="file" />
                  <span>Carga de CSV</span>
                </span>
              </Link>
            </Menu.Item>
          )}

          {isAllowed("zelda:preview-csv") && (
            <Menu.Item key="zelda:preview-csv">
              <Link to={`${router.previewcsv}`}>
                <span>
                  <Icon style={iconStyles} type="snippets" />
                  <span>Órdenes cargadas</span>
                </span>
              </Link>
            </Menu.Item>
          )}

          {isAllowed("zelda:operations") && (
            <SubMenu
              key="zelda:operations"
              title={
                <span>
                  <Icon style={iconStyles} type="folder" />
                  <span>Operaciones</span>
                </span>
              }
            >
              {isAllowed("zelda:operations:uniquesend") && (
                <Menu.Item key="zelda:operations:uniquesend">
                  <Link to={`${router.uniqueSend}`}>
                    <span>
                      <Icon style={iconStyles} type="plus-circle-o" />
                      <span>Solicitud de envío único</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {isAllowed("zelda:operations:packagetracking") && (
                <Menu.Item key="zelda:operations:packagetracking">
                  <Link to={`${router.trackingPackage}`}>
                    <span>
                      <Icon style={iconStyles} type="inbox" />
                      <span>Seguimiento de envío</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {isAllowed("zelda:operations:routeTracking") && (
                <Menu.Item key="zelda:operations:routeTracking">
                  <Link to={`${router.routeTracking}`}>
                    <span>
                      <Icon style={iconStyles} type="environment" />
                      <span>Seguimiento por ruta</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {isAllowed("zelda:operations:nonExistent") && (
                <Menu.Item key="zelda:operations:nonExistent">
                  <Link to={`${router.nonExistent}`}>
                    <span>
                      <Icon style={iconStyles} type="alert" />
                      <span>TrackCodes inexistentes</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}

          {isAllowed("zelda:labelPrint") && (
            <Menu.Item key="zelda:labelPrint">
              <Link to={`${router.labelPrint}`}>
                <span>
                  <Icon style={iconStyles} type="barcode" />
                  <span>Impresión de etiquetas</span>
                </span>
              </Link>
            </Menu.Item>
          )}

          {isAllowed("zelda:Reports") && (
            <Menu.Item key="zelda:Reports">
              <Link to={`${router.Reports}`}>
                <span>
                  <Icon style={iconStyles} type="audit" />
                  <span>Reportes</span>
                </span>
              </Link>
            </Menu.Item>
          )}

          {isAllowed("zelda:settings") && (
            <SubMenu
              key="zelda:settings"
              title={
                <span>
                  <Icon style={iconStyles} type="setting" />
                  <span>Configuraciones</span>
                </span>
              }
            >
              {isAllowed("zelda:settings:enterprise") && (
                <Menu.Item key="zelda:settings:enterprise">
                  <Link to={`${router.myCompany}`}>
                    <span>
                      <Icon style={iconStyles} type="shop" />
                      <span>Mi empresa</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}
              {isAllowed("zelda:settings:branchOffices") && (
                <Menu.Item key="zelda:settings:branchOffices">
                  <Link to={`${router.branches}`}>
                    <span>
                      <Icon style={iconStyles} type="apartment" />
                      <span>Sucursales</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}

          {isAllowed("zelda:usersAdmin") && (
            <SubMenu
              key="zelda:usersAdmin"
              title={
                <span>
                  <Icon style={iconStyles} type="team" />
                  <span>Gestión de usuarios</span>
                </span>
              }
            >
              {isAllowed("zelda:usersAdmin:users") && (
                <Menu.Item key="zelda:usersAdmin:users">
                  <Link to={`${router.accounts}`}>
                    <span>
                      <Icon style={iconStyles} type="user" />
                      <span>Usuarios</span>
                    </span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}

          {showKustomerRedirect && (
            <Menu.Item key="zelda:kustomer">
              <a
                href={`https://chazki.kustomer.help/login?referralUrl=https://chazki.kustomer.help/lang/es&externalToken=${kustomerUserToken}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  <Icon style={iconStyles} type="question-circle" />
                  <span>Kustomer</span>
                </span>
              </a>
            </Menu.Item>
          )}
        </Menu>
      </CustomSider>
    );
  }
}

export default compose(withGlobalContext)(Sidebar);
