import React from "react";
import { Form, Tabs, Badge, notification } from "antd"; // Col, Row, Select
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import axios from "axios";
import { Container, TitleGoBack } from "../../components/Container";
import { withGlobal } from "../../component-global";
import { Located } from "./componets";
import {
  // ContainerPreview,
  CustomFilterContainer,
} from "../../components/Form/SearchContainer";
// import { CustomFormItemSelectFilter } from "../../../components/Form";
import {
  getOriginalOrderPreview,
  getOriginalOrderByID,
} from "../../graphQl/queries/previewCsv";
// import { ContainerTabs } from "./static";
import { captureException } from "../../utils/sentry";
import {
  createOrderFromOriginal,
  deleteOriginalOrders,
} from "../../graphQl/mutations/previewCsv";
import { ContainerTab } from "./style";
import DrawerComponent from "./componets/DrawerComponent";
import { parsedDateUTC5 } from "../../utils/functions";

const { TabPane } = Tabs;
// const { Option } = Select;
class PreviewDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idsChecked: [],
      idsCheckedWrong: [],
      deliveries: [],
      deliveriesSize: 0,
      deliveriesWrong: [],
      deliveriesWrongSize: 0,
      loadingGetDeliveries: false,
      // enterpriseId: null,
      loadingEvent: false,
      loadinOriginOrder: false,
      loadingPrint: false,
      loadingDelete: false,
      pagination: { limit: 12, page: 0 },
      dates: [],
      datesWrong: [],
      validFilter: "",
      unvalidFilter: "",
    };
    // props.isAllowedView("uploadcsv");
    // props.changeTitle("CSV-operaciones");
    props.changeMenu(["operations"], ["uploadcsv"]);
    props.changeUrlGoToBack(null);
    props.setOption(true);
  }

  getOriginalOrderPreview = async()=>{
    this.setState({
      loadingGetDeliveries: true,
      loadinOriginOrder: true,
    });
    const {
      data: { OriginalOrderPreview },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getOriginalOrderPreview,
      variables: {
        enterpriseID: this.props.userEnterpriseData.enterpriseID,
        limit: this.state.pagination.limit,
        offset: this.state.pagination.page,
      },
    });

    this.setState({
      deliveries: OriginalOrderPreview.validatedOrders,
      deliveriesWrong: OriginalOrderPreview.unvalidatedOrders.sort(
        (a, b) => a.blocked - b.blocked
      ),
      deliveriesWrongSize: OriginalOrderPreview.unvalidatedCount,
      deliveriesSize: OriginalOrderPreview.validatedCount,
      loadingGetDeliveries: false,
      loadinOriginOrder: false,
    });
  }

  componentDidMount = async () => {
    try {
      this.setState({
        loadinOriginOrder: true,
      });

      if (
        this.props.userEnterpriseData &&
        this.props.userEnterpriseData.enterpriseID
      ) {
        this.getOriginalOrderPreview();
      }
    } catch (err) {
      this.setState({
        loadinOriginOrder: false,
      });
      notification.error("No se pudo cargar los datos de la empresa");
      captureException(err);
      console.log(err);
    }
  };

  updateData = (id, type, newdata) => {
    const { deliveries, deliveriesWrong } = this.state;
    const positionDeliveries = deliveries.findIndex(elem => elem.id === id);
    const positionDeliveriesWrong = deliveriesWrong.findIndex(
      elem => elem.id === id
    );

    if (positionDeliveries > -1) {
      if (type === "unlocated") {
        deliveries.splice(positionDeliveries, 1);
        deliveriesWrong.push(newdata);
        this.setState({
          deliveries,
        });
      } else {
        const newDeliveries = deliveries.map(x => {
          if (x.id === id) {
            return newdata;
          }
          return x;
        });
        this.setState({ deliveries: newDeliveries });
      }
    } else if (positionDeliveriesWrong > -1) {
      if (type === "located") {
        deliveriesWrong.splice(positionDeliveriesWrong, 1);
        deliveries.push(newdata);
        this.setState({
          deliveriesWrong,
        });
      } else {
        const newDeliveriesWrong = deliveriesWrong.map(x => {
          if (x.id === id) {
            return newdata;
          }
          return x;
        });
        this.setState({ deliveriesWrong: newDeliveriesWrong });
      }
    }
  };

  updateDelivery = (
    latLngPick,
    latLngDelivery,
    pickUpAddress,
    dropAddress,
    pickUpPostalCode,
    dropPostalCode
  ) => {
    this.setState({ 
      loadingUpdateOrder: true,
      loadingGetDeliveries:true 
    });
    const { userEnterpriseData } = this.props;
    const { selectedDelvery } = this.state;
    // e.preventDefault();
    const { updateData } = this; //
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // this.setState({ loadingUpdate: true });
        console.log("values", values);
        const valuesLocal = { ...values };
        delete valuesLocal.company;
        delete valuesLocal.deliveryAddress;
        delete valuesLocal.pickUpAddress;
        const variables = { ...valuesLocal };
        if (pickUpPostalCode) variables.pickUpPostalCode = pickUpPostalCode;
        if (dropPostalCode) variables.dropPostalCode = dropPostalCode;
        if (latLngPick && latLngPick.lng && latLngPick.lat) {
          variables.pickUpPoint = [latLngPick.lng, latLngPick.lat];
        }
        if (pickUpAddress) {
          variables.pickUpAddress = pickUpAddress;
        }
        if (latLngDelivery && latLngDelivery.lng && latLngDelivery.lat) {
          variables.dropPoint = [latLngDelivery.lng, latLngDelivery.lat];
        }
        if (dropAddress) {
          variables.dropAddress = dropAddress;
        }

        const data2 = {
          enterpriseID: parseInt(userEnterpriseData.enterpriseID, 10),
          originalOrderID: parseInt(selectedDelvery.id, 10),
          validateAddress: true,
          values: { ...variables },
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios
          .post(
            `${process.env.REACT_APP_CFUNCTION_DOMAIN}/validateErrorscsv`,
            data2,
            config
          )
          .then(res => {
            if (res.data.success) {
              this.getOriginalOrderPreview();
              this.props.client
                .query({
                  query: getOriginalOrderByID,
                  fetchPolicy: "network-only",
                  variables: { id: parseInt(selectedDelvery.id, 10),
                    notify:true },
                })
                .then(({ data: { GetOriginalOrderByID } }) => {
                  console.log("GetOriginalOrderByID", GetOriginalOrderByID);
                  // this.setState({
                  //   loadingUpdateOrder: false,
                  //   loadingGetDeliveries:false,
                  //   visibleDrawer: false,
                  //   selectedDelvery: null,
                  // });
                  if (GetOriginalOrderByID && GetOriginalOrderByID.errors) {
                    notification.error({
                      message: GetOriginalOrderByID.errors[0].messages[0],
                    });
                    updateData(
                      selectedDelvery.id,
                      "unlocated",
                      GetOriginalOrderByID
                    );
                    this.setState({
                      loadingUpdateOrder: false,
                      loadingGetDeliveries:false,
                      visibleDrawer: false,
                      selectedDelvery: null,
                    });
                  } else {
                    notification.success({
                      message: "Datos actualizados.",
                    });
                    updateData(
                      selectedDelvery.id,
                      "located",
                      GetOriginalOrderByID
                    );
                    this.setState({
                      loadingUpdateOrder: false,
                      loadingGetDeliveries:false,
                      visibleDrawer: false,
                      selectedDelvery: null,
                    });
                  }
                })
                .catch(error => {
                  captureException(error);
                  console.log("error", error);
                  this.setState({ 
                    loadingUpdateOrder: false,
                    loadingGetDeliveries:false
                  });
                });
            }
          })
          .catch(error => {
            notification.error({
              message: `No se pudo guardar, error en el sistema.`,
            });
            captureException(error);
            console.log(error);
            this.setState({
              loadingUpdateOrder: false,
              loadingGetDeliveries:false
            });
          });
      }
    });
  };

  onCloseDrawer = () => {
    this.setState({ visibleDrawer: false, selectedDelvery: null });
  };

  onchange = (val, id) => {
    if (val.target.checked)
      this.setState(({ idsChecked }) => ({
        idsChecked: idsChecked.concat([id]),
      }));
    else
      this.setState(({ idsChecked }) => ({
        idsChecked: idsChecked.filter(x => x !== id),
      }));
  };

  onCheckAllChange = async val => {
    if (val.target.checked) {
      const Gooddeliverys = await this.onChekAll(1);
      this.setState({
        idsChecked: Gooddeliverys.map(x => x.id),
      });
    } else
      this.setState({
        idsChecked: [],
      });
  };

  onchangeWrong = (val, id) => {
    if (val.target.checked)
      this.setState(({ idsCheckedWrong }) => ({
        idsCheckedWrong: idsCheckedWrong.concat([id]),
      }));
    else
      this.setState(({ idsCheckedWrong }) => ({
        idsCheckedWrong: idsCheckedWrong.filter(x => x !== id),
      }));
  };

  onCheckAllChangeWrong = async val => {
    if (val.target.checked) {
      const Wrongdeliverys = await this.onChekAll(2);
      this.setState({
        idsCheckedWrong: Wrongdeliverys.map(x => x.id),
      });
    } else
      this.setState({
        idsCheckedWrong: [],
      });
  };

  onChekAll = async Tipo => {
    try {
      this.setState({
        loadingGetDeliveries: true,
      });
      const { deliveriesSize, deliveriesWrongSize } = this.state;
      const {
        data: { OriginalOrderPreview: result },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getOriginalOrderPreview,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          limit: Tipo === 1 ? deliveriesSize : deliveriesWrongSize,
          offset: 0,
        },
      });
      this.setState({
        loadingGetDeliveries: false,
      });
      if (Tipo === 1) return result.validatedOrders;
      return result.unvalidatedOrders;
    } catch (err) {
      captureException(err);
      this.setState({
        loadingGetDeliveries: false,
      });
      notification.error({
        message: "Error al seleccionar los Trackcodes",
      });
    }
  };

  selectDelivery = delivery => {
    this.setState({
      selectedDelvery: delivery,
      visibleDrawer: true,
    });
  };

  sendEmail = emails => {
    const { idsCheckedWrong } = this.state;
    const { userEnterpriseData } = this.props;

    const data2 = {
      originalOrderIDs: idsCheckedWrong.map(x => parseInt(x, 10)),
      enterpriseID: parseInt(userEnterpriseData.enterpriseID, 10),
      emails,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    this.setState({ loadingEvent: true });

    axios
      .post(
        `${process.env.REACT_APP_CFUNCTION_DOMAIN}/sendEmailErrors`,
        data2,
        config
      )
      .then(res => {
        console.log("res.data", res.data);
        notification.success({
          message: `Se envió el correo al cliente con éxito.`,
        });
        this.setState(({ deliveriesWrong }) => ({
          loadingEvent: false,
          visibleModalEmail: false,
          idsCheckedWrong: [],
          deliveriesWrong: deliveriesWrong.filter(
            x => idsCheckedWrong.findIndex(y => y === x.id) === -1
          ),
        }));
      })
      .catch(error => {
        notification.error({
          message: `Error en actualizar order, intentelo de nuevo.`,
        });
        this.setState({ loadingEvent: false });

        console.log(error);
      });
  };

  openCloseModalEmail = () => {
    this.setState(({ visibleModalEmail }) => ({
      visibleModalEmail: !visibleModalEmail,
    }));
  };
  // llamado a la mutacion para que se creen con estado new
  offer = () => {
    const { idsChecked } = this.state;
    const { userEnterpriseData } = this.props;
    // console.log("deliveries", deliveries);
    const variables = {
      originalOrderIDs: idsChecked.map(x => parseInt(x, 10)),
      enterpriseID: parseInt(userEnterpriseData.enterpriseID, 10),
    };

    this.setState({ loadingEvent: true, loadingGetDeliveries: true });
    this.props.client
      .mutate({
        mutation: createOrderFromOriginal,
        update: async (cache, { data: { CreateOrderFromOriginal } }) => {
          console.log("CreateOrderFromOriginal", CreateOrderFromOriginal);
          notification.success({
            message: `Órdenes procesadas con éxito: ${idsChecked.length} órden${
              idsChecked.length > 1 ? "es" : ""
            }.`,
          });
          await this.onSearch("", 1);
          this.setState({
            loadingEvent: false,
            idsChecked: [],
            loadingGetDeliveries: false,
          });
        },
        variables,
      })
      .catch(error => {
        captureException(error);
        notification.error({
          message: `No se pudo procesar, error en el sistema`,
        });
        this.setState({ loadingEvent: false, loadingGetDeliveries: false });
      });
  };

  deleteOriginal = status => {
    const { idsChecked, idsCheckedWrong } = this.state;

    const variables = {
      originalOrderIDs: status
        ? idsChecked.map(x => parseInt(x, 10))
        : idsCheckedWrong.map(x => parseInt(x, 10)),
    };
    const idsLength = status ? idsChecked.length : idsCheckedWrong.length;

    this.setState({ loadingDelete: true });
    this.props.client
      .mutate({
        mutation: deleteOriginalOrders,
        update: (cache, { data: { DeleteOriginalOrders } }) => {
          console.log("DeleteOriginalOrders", DeleteOriginalOrders);
          notification.success({
            message: `Hemos eleminado con éxito ${idsLength} orden${
              idsLength > 1 ? "es" : ""
            }.`,
          });

          if (status) {
            this.setState({
              loadingDelete: false,
              idsChecked: [],
            });
            this.onSearch("", 1);
          } else {
            this.setState({
              loadingDelete: false,
              idsCheckedWrong: [],
            });
            this.onSearch("", 2);
          }
        },
        variables,
      })
      .catch(error => {
        captureException(error);
        notification.error({
          message: `No se pudo procesar, error en el sistema`,
        });
        this.setState({ loadingDelete: false });
      });
  };

  deleteDelivery = id => {
    this.setState(({ deliveries }) => ({
      deliveries: deliveries.filter(x => x.id !== id),
    }));
  };

  onSearch = async (trackCode, Tipo) => {
    const initDates = {
      initDate: null,
      endDate: null,
    };

    const { dates, datesWrong } = this.state;

    const datesSelected = Tipo === 1 ? dates : datesWrong;

    const datesSelectedQuery =
      datesSelected && datesSelected.length > 0
        ? {
            initDate: parsedDateUTC5(datesSelected[0], true),
            endDate: parsedDateUTC5(datesSelected[1], false),
          }
        : initDates;

    try {
      this.setState({
        loadingGetDeliveries: true,
      });
      const { pagination } = this.state;
      const {
        data: { OriginalOrderPreview: result },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getOriginalOrderPreview,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          trackCode,
          initDate: datesSelectedQuery.initDate,
          endDate: datesSelectedQuery.endDate,
          limit: pagination.limit,
          offset: pagination.page,
        },
      });
      if (Tipo === 1) {
        this.setState({
          deliveries: result.validatedOrders,
          validFilter: trackCode,
          deliveriesSize: result.validatedCount,
          loadingGetDeliveries: false,
        });
      } else {
        this.setState({
          deliveriesWrong: result.unvalidatedOrders,
          unvalidFilter: trackCode,
          deliveriesWrongSize: result.unvalidatedCount,
          loadingGetDeliveries: false,
        });
      }
    } catch (err) {
      captureException(err);
      this.setState({
        loadingGetDeliveries: false,
      });
      notification.error({
        message: "Error en la busqueda del Trackcode",
      });
    }
  };

  searchingTrackCode = trackCodeValue => {
    if (
      !trackCodeValue ||
      trackCodeValue === "" ||
      trackCodeValue === undefined
    ) {
      this.setState({ validFilter: "" });
    }
    this.onSearch(trackCodeValue, 1);
  };

  searchingTrackCodeWrong = trackCodeValue => {
    if (
      !trackCodeValue ||
      trackCodeValue === "" ||
      trackCodeValue === undefined
    ) {
      this.setState({ unvalidFilter: "" });
    }
    this.onSearch(trackCodeValue, 2);
  };

  searchTrackCode = e => {
    const { value } = e.target;
    if (!value || value === "" || value === undefined) {
      this.setState({ validFilter: "" });
    }
    this.onSearch(value, 1);
  };

  searchTrackCodeWrong = e => {
    const { value } = e.target;
    if (!value || value === "" || value === undefined) {
      this.setState({ unvalidFilter: "" });
    }
    this.onSearch(value, 2);
  };

  deleteDeliveryWrong = id => {
    this.setState(({ deliveriesWrong }) => ({
      deliveriesWrong: deliveriesWrong.filter(x => x.id !== id),
    }));
  };

  printPdf = () => {
    const { idsChecked } = this.state;
    if (idsChecked && idsChecked.length > 0) {
      this.setState({ loadingPrint: true });
      const dataToken = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH_PROVIDER}:${process.env.REACT_APP_AUTH_CLIENT_ID}`
        )
      );
      const token =
        dataToken &&
        dataToken.access_token &&
        dataToken.token_type &&
        `${dataToken.token_type} ${dataToken.access_token}`;
      // const { filter } = this.props;
      const config = {
        headers: {
          Authorization: token || "",
          "Content-Type": "application/json",
        },
      };

      const dataAxios = {
        originalOrdersIDs: idsChecked,
      };

      axios
        .post(
          `${process.env.REACT_APP_CFUNCTION_DOMAIN}/createPDFByOrders`,
          dataAxios,
          config
        )
        .then(res => {
          this.setState({ loadingPrint: false });
          if (res.data.success) {
            window.open(res.data.file, "_blank");
          } else {
            notification.error({
              message: `Hubo un error al generar las etiquetas`,
            });
          }
        })
        .catch(error => {
          notification.error({
            message: `Error en el sistema`,
          });
          this.setState({ loadingPrint: false });

          console.log(error);
        });
    } else {
      notification.error({
        message: `Selecciona una orden.`,
      });
    }
  };

  onPagChange = async (pageSize, page) => {
    try {
      this.setState({
        loadingGetDeliveries: true,
      });
      const { validFilter } = this.state;
      const {
        data: { OriginalOrderPreview: result },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getOriginalOrderPreview,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          trackCode: validFilter,
          limit: pageSize,
          offset: page - 1,
        },
      });
      this.setState({
        loadingGetDeliveries: false,
      });
      return result.validatedOrders;
    } catch (err) {
      captureException(err);
      this.setState({
        loadingGetDeliveries: false,
      });
      notification.error({
        message: "Error en la carga de datos",
      });
    }
  };

  onPagChangeWrong = async (pageSize, page) => {
    try {
      this.setState({
        loadingGetDeliveries: true,
      });
      const { unvalidFilter } = this.state;
      const {
        data: { OriginalOrderPreview: result },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getOriginalOrderPreview,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          trackCode: unvalidFilter,
          limit: pageSize,
          offset: page - 1,
        },
      });
      this.setState({
        loadingGetDeliveries: false,
      });
      return result.unvalidatedOrders;
    } catch (err) {
      this.setState({
        loadingGetDeliveries: false,
      });
      captureException(err);
      notification.error({
        message: "Error en la carga de datos",
      });
    }
  };

  handleSubmitCorrect = e => {
    e.preventDefault();
    this.props.form.validateFields(async (error, values) => {
      const filter = {};
      if (values.dates && values.dates.length > 0) {
        filter.initDate = parsedDateUTC5(values.dates[0], true);
        filter.endDate = parsedDateUTC5(values.dates[1], false);
        this.setState({
          dates: {
            initDate: parsedDateUTC5(values.dates[0], true),
            endDate: parsedDateUTC5(values.dates[1], true),
          },
        });
      }

      try {
        this.setState({
          loadingGetDeliveries: true,
        });
        const { pagination } = this.state;
        const {
          data: { OriginalOrderPreview: result },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getOriginalOrderPreview,
          variables: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
            trackCode: values.trackCode,
            initDate: filter.initDate,
            endDate: filter.endDate,
            limit: pagination.limit,
            offset: pagination.page,
          },
        });
        this.setState({
          deliveries: result.validatedOrders,
          validFilter: values.trackCode,
          deliveriesSize: result.validatedCount,
          loadingGetDeliveries: false,
        });
      } catch (err) {
        captureException(err);
        this.setState({
          loadingGetDeliveries: false,
        });
        notification.error({
          message: "Error en la busqueda del Trackcode",
        });
      }
    });
  };

  handleSubmitWrong = e => {
    e.preventDefault();
    this.props.form.validateFields(async (error, values) => {
      const filter = {};
      if (values.dates && values.dates.length > 0) {
        filter.initDate = parsedDateUTC5(values.dates[0], true);
        filter.endDate = parsedDateUTC5(values.dates[1], false);
        this.setState({
          datesWrong: {
            initDate: parsedDateUTC5(values.dates[0], true),
            endDate: parsedDateUTC5(values.dates[1], false),
          },
        });
      }

      try {
        this.setState({
          loadingGetDeliveries: true,
        });
        const { pagination } = this.state;
        const {
          data: { OriginalOrderPreview: result },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getOriginalOrderPreview,
          variables: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
            trackCode: values.trackCode,
            initDate: filter.initDate,
            endDate: filter.endDate,
            limit: pagination.limit,
            offset: pagination.page,
          },
        });
        this.setState({
          deliveriesWrong: result.unvalidatedOrders,
          unvalidFilter: values.trackCode,
          deliveriesWrongSize: result.unvalidatedCount,
          loadingGetDeliveries: false,
        });
      } catch (err) {
        captureException(err);
        this.setState({
          loadingGetDeliveries: false,
        });
        notification.error({
          message: "Error en la busqueda del Trackcode",
        });
      }
    });
  };

  render() {
    
    const params = new URLSearchParams(window.location.search);
    const idparams = params.get('id');
    const {
      deliveries,
      deliveriesWrong,
      loadingGetDeliveries,
      visibleModalEmail,
      idsChecked,
      idsCheckedWrong,
      loadingEvent,
      visibleDrawer,
      selectedDelvery,
      loadingUpdateOrder,
      loadinOriginOrder,
      loadingPrint,
      loadingDelete,
      deliveriesWrongSize,
      deliveriesSize,
    } = this.state;
    const { userEnterpriseData } = this.props;
    const { getFieldDecorator, validateFieldsAndScroll } = this.props.form;
    let emails = [];
    userEnterpriseData &&
      userEnterpriseData.Enterprise &&
      userEnterpriseData.Enterprise.contactPeople.forEach(element => {
        emails = emails.concat(element.email);
      });

    return (
      <Container>
        <TitleGoBack
          title={`Órdenes cargadas del CSV: ${(userEnterpriseData &&
            userEnterpriseData.Enterprise.comercialName) ||
            ""}`}
        />
        <CustomFilterContainer
          style={{ padding: "0px 24px", marginBottom: "0px" }}
        >
          <ContainerTab defaultActiveKey={idparams}>
            <TabPane
              tab={
                <div
                  style={{          
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Validados <Badge count={deliveriesSize} />
                </div>
              }
              key="1"
            >
              {" "}
              <Located
                selectedDelvery={selectedDelvery || {}}
                onPagChange={this.onPagChange}
                selectDelivery={this.selectDelivery}
                idsChecked={idsChecked}
                onchange={this.onchange}
                onCheckAllChange={this.onCheckAllChange}
                loadinOriginOrder={loadinOriginOrder}
                loadingGetDeliveries={loadingGetDeliveries}
                loadingPrint={loadingPrint}
                loadingDelete={loadingDelete}
                deliveries={deliveries}
                deliveriesSize={deliveriesSize}
                deleteDelivery={this.deleteDelivery}
                searchTrackCode={this.searchTrackCode}
                searchingTrackCode={this.searchingTrackCode}
                getFieldDecorator={getFieldDecorator}
                validateFieldsAndScroll={validateFieldsAndScroll}
                offer={this.offer}
                deleteOriginal={this.deleteOriginal}
                updateData={this.updateData}
                enterpriseId={
                  this.props.userEnterpriseData &&
                  this.props.userEnterpriseData.enterpriseID
                }
                loadingEvent={loadingEvent}
                printPdf={this.printPdf}
                handleSubmit={this.handleSubmitCorrect}
                located
              />
            </TabPane>
            <TabPane
              tab={
                <div
                  style={{
                    
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Por validar <Badge count={deliveriesWrongSize} />
                </div>
              }
              key="2"
            >
              <Located
                selectedDelvery={selectedDelvery || {}}
                onPagChange={this.onPagChangeWrong}
                selectDelivery={this.selectDelivery}
                idsChecked={idsCheckedWrong}
                onchange={this.onchangeWrong}
                onCheckAllChange={this.onCheckAllChangeWrong}
                loadinOriginOrder={loadinOriginOrder}
                loadingGetDeliveries={loadingGetDeliveries}
                deliveries={deliveriesWrong}
                deliveriesSize={deliveriesWrongSize}
                deleteDelivery={this.deleteDeliveryWrong}
                searchTrackCode={this.searchTrackCodeWrong}
                searchingTrackCode={this.searchingTrackCodeWrong}
                offer={this.offer}
                deleteOriginal={this.deleteOriginal}
                getFieldDecorator={getFieldDecorator}
                validateFieldsAndScroll={validateFieldsAndScroll}
                sendEmail={this.sendEmail}
                visibleModalEmail={visibleModalEmail}
                handleOk={this.sendEmail}
                openCloseModal={this.openCloseModalEmail}
                type="unlocated"
                updateData={this.updateData}
                enterpriseId={
                  this.props.userEnterpriseData &&
                  this.props.userEnterpriseData.enterpriseID
                }
                loadingEvent={loadingEvent}
                loadingDelete={loadingDelete}
                emails={emails}
                handleSubmit={this.handleSubmitWrong}
              />
            </TabPane>
          </ContainerTab>
        </CustomFilterContainer>
        {visibleDrawer && (
          <DrawerComponent
            getFieldDecorator={getFieldDecorator}
            selectedDelvery={selectedDelvery}
            onClose={this.onCloseDrawer}
            visible={visibleDrawer}
            updateDelivery={this.updateDelivery}
            loadingUpdateOrder={loadingUpdateOrder}
          />
        )}
      </Container>
    );
  }
}

const WraperPreviewDelivery = withGlobal(PreviewDelivery);
const PreviewDeliveryForm = Form.create()(WraperPreviewDelivery);

export default compose(withApollo)(PreviewDeliveryForm);
