import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import { notification, Empty, message } from "antd";
import { Container, TitleGoBack } from "../../components/Container";
import { withGlobal } from "../../component-global";
import { Header, Body } from "./components";
import { getOrdersForB2B } from "../../graphQl/queries/labelPrint";
import { captureException } from "../../utils/sentry";
import { CustomFilterContainer } from "../../components/Form/SearchContainer";

class LabelPrinting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      totalOrders: 0,
      pagination: { limit: 15, page: 0 },
      loadingActions: false,
      loadingBtnSearch: false,
      loadingInitialData: false,
      currentFilter: {
        ...(this.props.userEnterpriseData && {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
        }),
      },
      selectedAllRows: false,
      selectedRowKeys: [],
      dataRowSelected: [],
    };
    props.changeMenu(["labelPrint"]);

    // Verify Allowed View for user
  }

  componentDidMount = async () => {
    this.setState({ loadingInitialData: true });
    try {
      if (
        this.props.userEnterpriseData &&
        this.props.userEnterpriseData.enterpriseID
      ) {
        const { pagination } = this.state;
        const {
          data: { GetOrdersForB2B },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getOrdersForB2B,
          variables: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
            limit: pagination.limit,
            offset: pagination.page,
          },
        });

        this.setState({
          data: GetOrdersForB2B.rows,
          totalOrders: GetOrdersForB2B.count,
          loadingInitialData: false,
        });
      }
    } catch (err) {
      this.setState({ loadingInitialData: false });
      message.error("Error en la carga de datos.");
      captureException(err);
      console.log(err);
    }
  };

  onChange = async (page, pageSize) => {
    try {
      this.setState({ loadingActions: true });
      const { pagination, currentFilter } = this.state;
      const {
        data: { GetOrdersForB2B },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getOrdersForB2B,
        variables: {
          ...currentFilter,
          limit: pagination.limit,
          offset: (page - 1) * pagination.limit,
        },
      });

      this.setState({
        pagination: { limit: pageSize, page: page - 1 },
        data: GetOrdersForB2B,
        loadingActions: false,
        selectedAllRows: false,
        selectedRowKeys: [],
        dataRowSelected: [],
      });
    } catch (err) {
      captureException(err);
      this.setState({ loadingActions: false });
      console.log(err);
      notification.error({
        message: `Error en el sistema`,
      });
    }
  };

  searchOrders = async filter => {
    try {
      this.setState({
        loadingBtnSearch: true,
        currentFilter: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          ...filter,
        },
      });
      const { pagination } = this.state;
      const {
        data: { GetOrdersForB2B },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getOrdersForB2B,
        variables: {
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
          ...filter,
          limit: pagination.limit,
          offset: 0 * pagination.limit,
        },
      });

      this.setState({
        pagination: { limit: pagination.limit, page: 0 },
        data: GetOrdersForB2B.rows,
        totalOrders: GetOrdersForB2B.count,
        loadingBtnSearch: false,
        selectedAllRows: false,
        selectedRowKeys: [],
        dataRowSelected: [],
      });
    } catch (err) {
      console.log("Error");
    }
  };

  onChangePagination = async (page, pageSize) => {
    try {
      this.setState({ loadingActions: true });
      const { pagination, currentFilter } = this.state;
      const {
        data: { GetOrdersForB2B },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getOrdersForB2B,
        variables: {
          ...currentFilter,
          limit: pagination.limit,
          offset: (page - 1) * pagination.limit,
        },
      });

      this.setState({
        pagination: { limit: pageSize, page: page - 1 },
        data: GetOrdersForB2B.rows,
        loadingActions: false,
        selectedAllRows: false,
        selectedRowKeys: [],
        dataRowSelected: [],
      });
    } catch (err) {
      captureException(err);
      this.setState({ loadingActions: false });
      console.log(err);
      notification.error({
        message: `Error en el sistema`,
      });
    }
  };

  updateSelectedAllrows = value => {
    this.setState({ selectedAllRows: value });
  };

  updateSelectedRows = (selectedRowKeys, selectData) => {
    this.setState({
      selectedRowKeys,
      dataRowSelected: selectData,
    });
  };

  render() {
    const {
      data,
      totalOrders,
      loadingInitialData,
      loadingActions,
      loadingBtnSearch,
      filter,
      pagination,
      currentFilter,
      selectedAllRows,
      selectedRowKeys,
      dataRowSelected,
    } = this.state;

    return (
      <Container>
        <TitleGoBack title="Impresión de etiquetas" />
        <CustomFilterContainer
          style={{ padding: "24px ", marginBottom: "0px" }}
        >
          <Header
            SearchPrePlanner={this.searchOrders}
            loadingBtnSearch={loadingBtnSearch}
          />
          {data && data.length ? (
            <div>
              <Body
                filter={filter}
                currentFilter={currentFilter}
                loading={
                  loadingActions || loadingInitialData || loadingBtnSearch
                }
                dataSource={data}
                pagination={pagination}
                totalOrders={totalOrders}
                onChangePagination={this.onChangePagination}
                updateSelectedRows={this.updateSelectedRows}
                updateSelectedAllrows={this.updateSelectedAllrows}
                selectedAllRows={selectedAllRows}
                selectedRowKeys={selectedRowKeys}
                dataRowSelected={dataRowSelected}
              />{" "}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "calc(100vh - 350px)",
                padding: "0 8px 0 0",
              }}
            >
              <Empty
                image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
                imageStyle={{
                  height: 136,
                }}
                description={null}
              >
                <span
                  style={{
                    color: "#8C8C8C",
                    
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                >
                  {loadingInitialData
                    ? "Cargando..."
                    : "No hemos encontrado información disponible."}
                </span>
              </Empty>
            </div>
          )}
        </CustomFilterContainer>
      </Container>
    );
  }
}

const WraperLabelPrinting = withGlobal(LabelPrinting);

export default compose(withApollo)(WraperLabelPrinting);
