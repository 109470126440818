import React from "react";
import { Row, Col, Select, Icon } from "antd";
import {
  SelectWithBorderComponent,
  InputComponentWithBorder,
} from "../../../../components/FormFields";

const { Option } = Select;

const renderPoint = (
  cityOptions,
  getFieldDecorator,
  key,
  openMapLocation,
  address
) => {
  const renderCity = (cityOptions || []).map(obj => (
    <Option key={obj.id} value={obj.id}>
      {obj.name}
    </Option>
  ));
  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <SelectWithBorderComponent
            style={{ width: "100%" }}
            getFieldDecorator={getFieldDecorator}
            title="Ciudad"
            placeholder="Selecciona una ciudad"
            decodator={`${key}City`}
            options={renderCity}
            required
          />
        </Col>
      </Row>

      <Row gutter={24} style={{ marginTop: 10 }}>
        <Col span={24}>
          <InputComponentWithBorder
            getFieldDecorator={getFieldDecorator}
            title={`Dirección de ${key === "origin" ? "origen" : "destino"}`}
            decodator={`${key}Address`}
            onClick={() => openMapLocation(key === "origin" ? 1 : 2)}
            addonAfter={
              <Icon
                type="environment"
                style={{ cursor: "pointer" }}
                onClick={() => openMapLocation(key === "origin" ? 1 : 2)}
              />
            }
            placeholder={`Ingresa la dirección de ${key === "origin" ? "origen" : "destino"}`}
            value={address ? address.address : ""}
            required
          />
        </Col>
      </Row>

      <Row gutter={24} style={{ marginTop: 10 }}>
        <Col span={24}>
          <InputComponentWithBorder
            getFieldDecorator={getFieldDecorator}
            title="Referencia"
            placeholder="Ingresa una referencia"
            value={""}
            decodator={`${key}Reference`}
            required
          />
        </Col>
      </Row>

      <Row gutter={24} style={{ marginTop: 10 }}>
        <Col span={12}>
          <InputComponentWithBorder
            getFieldDecorator={getFieldDecorator}
            title="Contacto"
            placeholder="Ingresa un nombre de contacto"
            value={""}
            decodator={`${key}Contact`}
            required
          />
        </Col>
        <Col span={12}>
          <InputComponentWithBorder
            getFieldDecorator={getFieldDecorator}
            title="Celular"
            placeholder="Ingresa un celular"
            value={""}
            decodator={`${key}Cellphone`}
            required
          />
        </Col>
      </Row>

      <Row gutter={24} style={{ marginTop: 10 }}>
        <Col span={12}>
          <InputComponentWithBorder
            getFieldDecorator={getFieldDecorator}
            title="Teléfono fijo"
            placeholder="Ingresa un teléfono"
            value={""}
            decodator={`${key}Phone`}
            required
          />
        </Col>
      </Row>

      {key === "destination" &&
        <Row gutter={24} style={{ marginTop: 10 }}>
          <Col span={24}>
            <InputComponentWithBorder
              getFieldDecorator={getFieldDecorator}
              title="Detalle de producto a entregar"
              placeholder="Ingresa un detalle"
              value={""}
              decodator={`${key}Detail`}
              required
            />
          </Col>
        </Row>
      }
    </>
  );
}

const emptyImg = "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e";
const csvIcon = "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FVector.png?alt=media&token=f0fd9cc9-b8a1-4e68-9bb2-44e2b41fffac";

export { renderPoint, emptyImg, csvIcon };
