import gql from "graphql-tag";

const getEnterpriseByID = gql`
  query getEnterpriseByID($id: ID!) {
    GetEnterpriseByID: getEnterpriseByID(id: $id) {
      ListUsers {
        id
        email
        roleID
      }
    }
  }
`;

const getUsersEnterprise = gql`
  query getUsersEnterprise(
    $offset: Int
    $limit: Int
    $enterpriseID: Int!
    $userNames: String
  ) {
    UsersEnterprise: getUsersEnterprise(
      offset: $offset
      limit: $limit
      enterpriseID: $enterpriseID
      principalEnterpriseUser: false
      userNames: $userNames
      orderBy: { names: ASC }
    ) {
      count
      rows {
        id
        names
        deleted
        lastname
        email
        createdAt
        Country {
          id
          name
        }
        Role {
          id
          name
        }
        Status {
          subclass
        }
      }
    }
  }
`;

const getGeneralStatusUser = gql`
  query searchGeneralTypesBy {
    GeneralStatus: searchGeneralTypesBy(type: 33) {
      subtype
      subclass
    }
  }
`;

const getUserByID = gql`
  query getUserByID($id: ID!) {
    UserByID: getUserByID(id: $id) {
      id
      names
      lastname
      email
      emailVerified
      # phoneNumber
      # phoneNumberVerified
      picture
      birthdate
      # nationalityID
      statusID
      # genderID
      roleID
      createdAt
      updatedAt
      Status {
        subclass
      }
      allowedDownTime
      timeToDisplayCounter
      enterpriseID
      Role {
        name
      }
      countryID
      Country {
        name
      }
      cityID
      City {
        name
      }
    }
  }
`;

const getTypesDownTime = gql`
  query searchGeneralTypesBy {
    GeneralStatus: searchGeneralTypesBy(type: 75) {
      subtype
      subclass
    }
  }
`;

const getTypesTimeCounter = gql`
  query searchGeneralTypesBy {
    GeneralStatus: searchGeneralTypesBy(type: 76) {
      subtype
      subclass
    }
  }
`;

export {
  getEnterpriseByID,
  getUsersEnterprise,
  getUserByID,
  getGeneralStatusUser,
  getTypesTimeCounter,
  getTypesDownTime,
};
