import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Form, Col, Button, Row, notification } from "antd";
import axios from "axios";
import { withGlobal } from "../../../component-global";
import { Container, TitleGoBack } from "../../../components/Container";
import { CustomFilterContainer } from "../../../components/Form/SearchContainer";
import Body from "./components/body";
import {
  getBranchOfficeByEnterpriseID,
  getPaymentMethod,
  getPaymentProof,
  getAllServices,
  getTariffUniqOrder,
  getPackageSizesBy,
  getAllVehicleTypes,
} from "../../../graphQl/queries/uniqueSend";
//import { createUniqueOrder } from "../../../graphQl/mutations/uniqueSend";
import { captureException } from "../../../utils/sentry";

class UniqueSend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMap: false,
      pointAddressDefault: {
        lat: this.props.authUser.profile.cityCoords[1],
        lng: this.props.authUser.profile.cityCoords[0],
      },
      multiplace: this.props.userEnterpriseData.Enterprise.multiplace
        ? this.props.userEnterpriseData.Enterprise.multiplace
        : false,
      vehicleId: 37,
      lastPointUpdate: "default",

      pointAddressOrigin: null,
      pointAddressDestination: null,

      allCitiesBranch: [],
      allBranchEnterprise: [],
      allServicesBranch: [],
      allServicesEnterprise: [],
      allServicesCity: [],
      allPaymentMethod: [],
      allPaymentProof: [],
      allPackageSizes: [],
      allVehicleTypes: [],
      showTextBoxMap: false,
      titleTextBoxMap: "",
      detailTextBoxMap: "",
      polylineAddress: [],
      loadingSaveData: false,
      addressOrigin: "",
      addressDestination: "",
      selectedOriginRequired: true,
      loadingFields: true,
      disableSucursalOriginPoint: true,
      disableServiceOriginPoint: true,
      disableServiceOrder: true,
      disableVehicleTypeID: true,
      reverseLogistics: false,
      crossdocking: false,
      locationReferenceOrigin: "",
      locationContactPersonOrigin: "",
      locationCellPhoneNumberOrigin: "",
      locationPhoneNumberOrigin: "",
      locationReferenceDestination: "",
      locationContactPersonDestination: "",
      locationCellPhoneNumberDestination: "",
      locationPhoneNumberDestination: "",
      selectCrossdockingRequired: true,
      selectServiceOrderRequired: true,
      replacementOrder: false,
      reversedPolylineAddress: [],
    };
  }

  valueToState = ({ name, value }) => {
    this.setState(() => {
      return { [name]: value };
    });
  };

  updateCrossdockingOption = async e => {
    await this.props.form.setFieldsValue({
      locationCity: undefined,
      locationBranch: undefined,
      locationService: undefined,
      serviceOrder: undefined,
    });
    this.setState({
      crossdocking: e,
      disableSucursalOriginPoint: true,
      disableServiceOriginPoint: true,
      disableServiceOrder: e === undefined ? true : false,
      selectedOriginRequired: true,
      allServicesBranch: this.state.allServicesBranch.map(data => {
        return {
          ...data,
          showInList: e && parseInt(data.typeID, 10) === 276,
        };
      }),
    });
  };

  updateServiceOrderOption = async e => {
    const { allServicesCity } = this.state;
    await this.props.form.setFieldsValue({
      vehicleTypeID: undefined,
    });
    const validateVehicleType =
      allServicesCity.find(sCity => sCity.id === e.toString()).Type.subtype !==
      "276";
    this.setState({
      disableVehicleTypeID: e === undefined ? true : validateVehicleType,
    });
  };

  switchForReverseLogistics = async () => {
    await this.setDataInverse();
    this.props.form.setFieldsValue({
      locationReferenceOrigin: this.state.locationReferenceOrigin,
      locationContactPersonOrigin: this.state.locationContactPersonOrigin,
      locationCellPhoneNumberOrigin: this.state.locationCellPhoneNumberOrigin,
      locationPhoneNumberOrigin: this.state.locationPhoneNumberOrigin,
      locationReferenceDestination: this.state.locationReferenceDestination,
      locationContactPersonDestination: this.state
        .locationContactPersonDestination,
      locationCellPhoneNumberDestination: this.state
        .locationCellPhoneNumberDestination,
      locationPhoneNumberDestination: this.state.locationPhoneNumberDestination,
    });
  };

  setDataInverse = async () => {
    const {
      addressOrigin,
      locationReferenceOrigin,
      locationContactPersonOrigin,
      locationCellPhoneNumberOrigin,
      locationPhoneNumberOrigin,
      addressDestination,
      locationReferenceDestination,
      locationContactPersonDestination,
      locationCellPhoneNumberDestination,
      locationPhoneNumberDestination,
      pointAddressOrigin,
      pointAddressDestination,
    } = this.state;

    this.setState({
      reverseLogistics: !this.state.reverseLogistics,
      locationReferenceOrigin: locationReferenceDestination,
      locationContactPersonOrigin: locationContactPersonDestination,
      locationCellPhoneNumberOrigin: locationCellPhoneNumberDestination,
      locationPhoneNumberOrigin: locationPhoneNumberDestination,
      locationReferenceDestination: locationReferenceOrigin,
      locationContactPersonDestination: locationContactPersonOrigin,
      locationCellPhoneNumberDestination: locationCellPhoneNumberOrigin,
      locationPhoneNumberDestination: locationPhoneNumberOrigin,
      addressOrigin: addressDestination,
      addressDestination: addressOrigin,
      pointAddressOrigin: pointAddressDestination,
      pointAddressDestination: pointAddressOrigin,
    });
  };

  switchForReplacementOrder = async () => {
    const {
      replacementOrder,
      pointAddressOrigin,
      pointAddressDestination,
    } = this.state;

    if (pointAddressOrigin && pointAddressDestination)
      await this.getDirections(
        [pointAddressOrigin, pointAddressDestination],
        !replacementOrder
      );

    this.setState({
      replacementOrder: !replacementOrder,
    });
  };

  componentDidMount = async () => {
    try {
      if (
        this.props.userEnterpriseData &&
        this.props.userEnterpriseData.enterpriseID
      ) {
        const {
          data: { AllBranchOffices },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getBranchOfficeByEnterpriseID,
          variables: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
          },
        });

        const {
          data: { allPaymentMethod },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getPaymentMethod,
          variables: {},
        });

        const {
          data: { allPaymentProof },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getPaymentProof,
          variables: {},
        });

        const {
          data: { allVehicleTypes },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getAllVehicleTypes,
          variables: {},
        });

        const {
          data: { allServices },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getAllServices,
          variables: {},
        });

        const {
          data: { PackageSizePagination },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getPackageSizesBy,
          variables: {},
        });

        // separado los datos de las sucursales en arrays de datos para los combobox
        const {
          allCitiesBranch,
          allBranchEnterprise,
          allServicesBranch,
          allPackageSizes,
        } = this.state;

        const citiesDetailsTemp = [
          ...new Set(
            AllBranchOffices.map(data => `${data.City.id}|||${data.City.name}`)
          ),
        ];
        const serviceDetailsTemp = [
          ...new Set(
            AllBranchOffices.map(data =>
              data.Services.map(
                serviceData =>
                  `${serviceData.id}|||${serviceData.name}|||${data.id}|||${serviceData.typeID}`
              )
            ).flat()
          ),
        ];
        citiesDetailsTemp.forEach(cities => {
          const valCortado = cities.split("|||");
          allCitiesBranch.push({
            id: valCortado[0],
            name: valCortado[1],
            showInList: true,
          });
        });
        serviceDetailsTemp.forEach(service => {
          const valCortado = service.split("|||");
          allServicesBranch.push({
            id: valCortado[0],
            name: valCortado[1],
            branchID: valCortado[2],
            typeID: valCortado[3],
            showInList: false,
          });
        });

        AllBranchOffices.forEach(data => {
          allBranchEnterprise.push({
            id: data.id,
            branchOfficeCode: data.branchOfficeCode,
            branchOfficeName: data.branchOfficeName,
            branchOfficeAddress: data.branchOfficeAddress,
            branchOfficeAddressReference: data.branchOfficeAddressReference,
            branchOfficeAddressPoint: data.branchOfficeAddressPoint,
            cityID: data.cityID,
            serviceIDs: data.serviceIDs,
            showInList: false,
          });
        });
        PackageSizePagination.rows.forEach(row => {
          if (row.countryID === this.props.authUser.profile.countryID)
            allPackageSizes.push(row);
        });

        let allServicesBranchTemp = [];
        for (let index = 0; index < allServicesBranch.length; index++) {
          if (allServicesBranch[index].typeID === "276") {
            allServicesBranchTemp.push(allServicesBranch[index]);
          }
        }
        this.setState({
          allCitiesBranch,
          allBranchEnterprise,
          allServicesBranch: allServicesBranchTemp,
          allServicesEnterprise: this.props.userEnterpriseData.Enterprise
            .Services,
          allPaymentMethod,
          allPaymentProof,
          allVehicleTypes,
          allServicesCity: allServices,
          loadingFields: false,
          allPackageSizes,
        });
      }
    } catch (err) {
      captureException(err);
      console.log(err);
    }
  };

  cleanForm = () => {
    this.props.form.resetFields();
    this.setState({
      pointAddressOrigin: null,
      pointAddressDestination: null,
      lastPointUpdate: "default",
      showTextBoxMap: false,
      polylineAddress: [],
      addressOrigin: "",
      addressDestination: "",
      reverseLogistics: false,
      crossdocking: false,
      locationReferenceOrigin: "",
      locationContactPersonOrigin: "",
      locationCellPhoneNumberOrigin: "",
      locationPhoneNumberOrigin: "",
      locationReferenceDestination: "",
      locationContactPersonDestination: "",
      locationCellPhoneNumberDestination: "",
      locationPhoneNumberDestination: "",
      selectCrossdockingRequired: true,
      selectServiceOrderRequired: true,
      replacementOrder: false,
      reversedPolylineAddress: [],
    });
  };

  saveOrder = () => {
    const { allServicesBranch } = this.state;
    this.props.form.validateFields((err, values) => {
      if (err) {
        notification.error({
          message: "Completa todos los campos.",
        });
      } else if (Object.keys(this.state.pointAddressOrigin).length === 0) {
        notification.error({
          message: "Debe ingresar la direccion de origen.",
        });
      } else if (Object.keys(this.state.pointAddressDestination).length === 0) {
        notification.error({
          message: "Debe ingresar la direccion de destino.",
        });
      } else {
        let branchServiceID = null;

        for (let index = 0; index < allServicesBranch.length; index++) {
          if (allServicesBranch[index].branchID === values.locationBranch)
            branchServiceID = parseInt(allServicesBranch[index].id);
        }

        this.setState({ loadingSaveData: true });
        const variables = {
          order: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
            pickUpBranchID: values.locationBranch
              ? parseInt(values.locationBranch, 10)
              : undefined,
            /*serviceID: values.locationService
              ? parseInt(values.locationService, 10)
              : parseInt(values.serviceOrder, 10),*/
            serviceID: branchServiceID
              ? branchServiceID
              : parseInt(values.serviceOrder, 10),
            pickUpAddress: this.state.addressOrigin,
            pickUpAddressReference: this.state.locationReferenceOrigin,
            pickUpContactName: this.state.locationContactPersonOrigin,
            pickUpContactPhone: this.state.locationCellPhoneNumberOrigin,
            pickUpContactLandline: this.state.locationPhoneNumberOrigin,
            pickUpPoint: {
              type: "POINT",
              coordinates: [
                this.state.pointAddressOrigin.lng,
                this.state.pointAddressOrigin.lat,
              ],
            },
            dropAddress: this.state.addressDestination,
            dropAddressReference: this.state.locationReferenceDestination,
            dropContactName: this.state.locationContactPersonDestination,
            dropContactPhone: this.state.locationCellPhoneNumberDestination,
            dropContactLandline: this.state.locationPhoneNumberDestination,
            dropPoint: {
              type: "POINT",
              coordinates: [
                this.state.pointAddressDestination.lng,
                this.state.pointAddressDestination.lat,
              ],
            },
            ...(values.trackCode && { trackCode: values.trackCode }),
            ...(values.paymentMethodOrder && {
              paymentMethodID: parseInt(values.paymentMethodOrder, 10),
            }),
            ...(values.paymentProofOrder && {
              paymentProofID: parseInt(values.paymentProofOrder, 10),
            }),
            productPrice: parseInt(values.priceProductOrder, 10),
            packageWeight: parseInt(values.productWeight, 10),
            productDescription: values.productDescription,
            packageSizeID: parseInt(values.packageSize, 10),
            crossdocking: false,
            reverseLogistic: this.state.reverseLogistics,
            needValidateAge: false,
            change: this.state.replacementOrder,
          },
          cityID: values.locationCity
            ? parseInt(values.locationCity, 10)
            : parseInt(this.props.userEnterpriseData.Enterprise.cityID, 10),
          vehicleTypeID: parseInt(values.vehicleTypeID, 10),
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.props.authUser.access_token,
          },
        };

        const body = variables;

        try {
          axios
            .post(
              `${process.env.REACT_APP_CFUNCTION_DOMAIN}/oneTimeShipping`,
              body,
              config
            )
            .then(res => {
              if (res.data.status === 200) {
                console.log("Orden creada");
                notification.success({ message: res.data.response });
                this.cleanForm();
              } else {
                console.log("Orden no creada");
                notification.error({ message: res.data.message });
              }
              this.setState({ loadingSaveData: false });
            })
            .catch(error => {
              console.log("error", error.message);
              this.setState({ loadingSaveData: false });
              notification.error({ message: "Orden no creada" });
            });
        } catch (e) {
          this.setState({ loadingSaveData: false });
          notification.error({
            message: `Error en el sistema`,
          });
          console.log("error", e);
        }
      }
    });
  };

  updatePointsInputs = (keyValue, value) => {
    if (keyValue === "pointAddressOrigin") {
      this.setState({ pointAddressOrigin: value, lastPointUpdate: "origin" });
    }
    if (keyValue === "pointAddressDestination")
      this.setState({
        pointAddressDestination: value,
        lastPointUpdate: "destination",
      });

    const {
      pointAddressOrigin,
      pointAddressDestination,
      replacementOrder,
    } = this.state;

    if (pointAddressOrigin && pointAddressDestination)
      this.getDirections(
        [pointAddressOrigin, pointAddressDestination],
        replacementOrder
      );
  };

  // funcion para dibujar la linea y obtener la distancia de los puntos
  getDirections = async (items, replacementOrder = false) => {
    const coordinates = items.map(obj => {
      return {
        lat: obj.lat,
        lng: obj.lng,
      };
    });
    const body = {
      cityID: this.props.authUser.profile.cityID,
      coordinates,
      vehicleTypeID: this.state.vehicleId,
    };
    const getToken = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_PROVIDER}:${process.env.REACT_APP_AUTH_CLIENT_ID}`
      )
    );
    const token =
      getToken &&
      getToken.access_token &&
      getToken.token_type &&
      `${getToken.token_type} ${getToken.access_token}`;

    const config = {
      headers: {
        Authorization: token || "",
        "Content-Type": "application/json",
      },
    };
    const serviceID = this.props.form.getFieldValue("serviceOrigin")
      ? this.props.form.getFieldValue("serviceOrigin")
      : this.props.form.getFieldValue("serviceOrder");

    let tariffResult = null;
    if (serviceID) {
      const {
        data: { TariffUniqOrder },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getTariffUniqOrder,
        variables: {
          dropPoint: {
            coordinates: [
              this.state.pointAddressDestination.lng,
              this.state.pointAddressDestination.lat,
            ],
          },
          pickUpPoint: {
            coordinates: [
              this.state.pointAddressOrigin.lng,
              this.state.pointAddressOrigin.lat,
            ],
          },
          serviceID,
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
        },
      });
      const currency =
        this.props.authUser &&
        this.props.authUser.profile &&
        this.props.authUser.profile.currencyCountry
          ? this.props.authUser.profile.currencyCountry
          : "";
      tariffResult = TariffUniqOrder.baseTariffID
        ? `Valor: ${TariffUniqOrder.baseTariffPrice} ${currency} (inc. Impuesto)`
        : null;
    }
    try {
      const polylinePromises = [
        axios.post(process.env.REACT_APP_POLYLINEURL, body, config),
      ];

      if (replacementOrder) {
        const { coordinates } = body;
        const reversedCoordinates = [coordinates[1], coordinates[0]];

        polylinePromises.push(
          axios.post(
            process.env.REACT_APP_POLYLINEURL,
            {
              ...body,
              coordinates: reversedCoordinates,
            },
            config
          )
        );
      }

      const [polylineResponse, reversedPolylineResponse] = await Promise.all(
        polylinePromises
      );

      if (polylineResponse.data) {
        const { points: polylineAddress, distance } = polylineResponse.data;
        const {
          points: reversedPolylineAddress = [],
          distance: reversedDistance = 0,
        } =
          replacementOrder && reversedPolylineResponse.data
            ? reversedPolylineResponse.data
            : {};

        this.setState({
          showTextBoxMap: true,
          titleTextBoxMap: "Tarifario",
          detailTextBoxMap: (
            <div>
              <div>
                KM aproximado: {(distance + reversedDistance).toFixed(2)} km
              </div>
              <div>{tariffResult}</div>
            </div>
          ),
          polylineAddress,
          reversedPolylineAddress,
        });
      }
    } catch (e) {
      notification.error({
        message: `Error en el sistema, intentelo de nuevo.`,
      });
      console.log("error", e);
    }
  };

  markerDragAddressOrigin = coord => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState({
      pointAddressOrigin: {
        lat,
        lng,
      },
    });

    if (this.state.pointAddressOrigin && this.state.pointAddressDestination)
      this.getDirections(
        [this.state.pointAddressOrigin, this.state.pointAddressDestination],
        this.state.replacementOrder
      );
  };

  markerDragAddressDestination = coord => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState({
      pointAddressDestination: {
        lat,
        lng,
      },
    });

    if (this.state.pointAddressOrigin && this.state.pointAddressDestination)
      this.getDirections(
        [this.state.pointAddressOrigin, this.state.pointAddressDestination],
        this.state.replacementOrder
      );
  };

  // cambio del select dependiendo de los 3 parametros
  updateSelect = (keyValue, value) => {
    const {
      allBranchEnterprise,
      allServicesBranch,
      crossdocking,
      reverseLogistics,
    } = this.state;

    if (keyValue === "city") {
      this.props.form.setFieldsValue({
        locationBranch: undefined,
        locationService: undefined,
      });
      this.setState({
        selectedOriginRequired: !!value,
        allBranchEnterprise: allBranchEnterprise.map(data => {
          return {
            ...data,
            showInList: parseInt(data.cityID, 10) === parseInt(value, 10),
          };
        }),
        allServicesBranch: allServicesBranch.map(data => {
          return {
            ...data,
            showInList: false,
          };
        }),
        disableSucursalOriginPoint: !value,
        disableServiceOriginPoint: true,
      });
    }
    if (keyValue === "branch") {
      this.props.form.setFieldsValue({
        locationService: undefined,
      });
      const branchSelected = allBranchEnterprise.find(
        val => parseInt(val.id, 10) === parseInt(value, 10)
      );

      if (!reverseLogistics) {
        this.setState(
          {
            allServicesBranch: allServicesBranch.map(data => {
              return {
                ...data,
                showInList:
                  parseInt(data.branchID, 10) === parseInt(value, 10) &&
                  !(crossdocking && parseInt(data.typeID, 10) === 276),
              };
            }),
            addressOrigin: branchSelected
              ? branchSelected.branchOfficeAddress
              : null,
            locationReferenceOrigin: branchSelected
              ? branchSelected.branchOfficeAddressReference
              : null,
            pointAddressOrigin:
              branchSelected &&
              branchSelected.branchOfficeAddressPoint &&
              branchSelected.branchOfficeAddressPoint.coordinates
                ? {
                    lat: branchSelected.branchOfficeAddressPoint.coordinates[1],
                    lng: branchSelected.branchOfficeAddressPoint.coordinates[0],
                  }
                : null,
            lastPointUpdate: "origin",
            disableSucursalOriginPoint: false,
            disableServiceOriginPoint: !branchSelected,
          },
          () => {
            if (
              this.state.pointAddressOrigin &&
              this.state.pointAddressDestination
            ) {
              this.getDirections([
                this.state.pointAddressOrigin,
                this.state.pointAddressDestination,
              ]);
            }
          }
        );
      }
      if (reverseLogistics) {
        this.setState(
          {
            allServicesBranch: allServicesBranch.map(data => {
              return {
                ...data,
                showInList:
                  parseInt(data.branchID, 10) === parseInt(value, 10) &&
                  !(crossdocking && parseInt(data.typeID, 10) === 276),
              };
            }),
            addressDestination: branchSelected
              ? branchSelected.branchOfficeAddress
              : null,
            locationReferenceDestination: branchSelected
              ? branchSelected.branchOfficeAddressReference
              : null,
            pointAddressDestination:
              branchSelected &&
              branchSelected.branchOfficeAddressPoint &&
              branchSelected.branchOfficeAddressPoint.coordinates
                ? {
                    lat: branchSelected.branchOfficeAddressPoint.coordinates[1],
                    lng: branchSelected.branchOfficeAddressPoint.coordinates[0],
                  }
                : null,
            lastPointUpdate: "destination",
            disableSucursalOriginPoint: false,
            disableServiceOriginPoint: !branchSelected,
          },
          () => {
            if (
              this.state.pointAddressOrigin &&
              this.state.pointAddressDestination
            ) {
              this.getDirections([
                this.state.pointAddressOrigin,
                this.state.pointAddressDestination,
              ]);
            }
          }
        );
      }
    }
  };

  updateAddressOrigin = address => {
    if (!this.state.reverseLogistics)
      this.props.form.setFieldsValue({
        locationCity: undefined,
        locationBranch: undefined,
        locationService: undefined,
      });
    this.setState({ addressOrigin: address });
  };

  updateAddressDestination = address => {
    if (this.state.reverseLogistics)
      this.props.form.setFieldsValue({
        locationCity: undefined,
        locationBranch: undefined,
        locationService: undefined,
      });
    this.setState({ addressDestination: address });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      loadingMap,
      pointAddressDefault,
      pointAddressOrigin,
      pointAddressDestination,
      lastPointUpdate,
      showTextBoxMap,
      titleTextBoxMap,
      detailTextBoxMap,
      polylineAddress,
      allCitiesBranch,
      allBranchEnterprise,
      allServicesBranch,
      allServicesEnterprise,
      allPaymentMethod,
      allPaymentProof,
      allVehicleTypes,
      allPackageSizes,
      loadingSaveData,
      addressOrigin,
      addressDestination,
      selectedOriginRequired,
      allServicesCity,
      loadingFields,
      disableSucursalOriginPoint,
      disableServiceOriginPoint,
      disableServiceOrder,
      disableVehicleTypeID,
      reverseLogistics,
      crossdocking,
      locationReferenceOrigin,
      locationContactPersonOrigin,
      locationCellPhoneNumberOrigin,
      locationPhoneNumberOrigin,
      locationReferenceDestination,
      locationContactPersonDestination,
      locationCellPhoneNumberDestination,
      locationPhoneNumberDestination,
      replacementOrder,
      reversedPolylineAddress,
    } = this.state;

    return (
      <Container>
        <TitleGoBack title="Solicitud de envío único" />
        <CustomFilterContainer
          style={{ padding: "24px ", marginBottom: "0px" }}
        >
          <Row gutter={24} type="flex" justify="end">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Body
                getFieldDecorator={getFieldDecorator}
                allCitiesBranch={allCitiesBranch}
                allBranchEnterprise={allBranchEnterprise}
                allServicesBranch={allServicesBranch}
                allServicesEnterprise={allServicesEnterprise}
                allServicesCity={allServicesCity}
                allPaymentMethod={allPaymentMethod}
                allPaymentProof={allPaymentProof}
                allVehicleTypes={allVehicleTypes}
                updatePointsInputs={this.updatePointsInputs}
                pointAddressDefault={pointAddressDefault}
                pointAddressOrigin={pointAddressOrigin}
                pointAddressDestination={pointAddressDestination}
                lastPointUpdate={lastPointUpdate}
                loadingMap={loadingMap}
                showTextBoxMap={showTextBoxMap}
                titleTextBoxMap={titleTextBoxMap}
                detailTextBoxMap={detailTextBoxMap}
                polylineAddress={polylineAddress}
                markerDragAddressOrigin={this.markerDragAddressOrigin}
                markerDragAddressDestination={this.markerDragAddressDestination}
                updateSelect={this.updateSelect}
                updateAddressOrigin={this.updateAddressOrigin}
                addressOrigin={addressOrigin}
                updateAddressDestination={this.updateAddressDestination}
                addressDestination={addressDestination}
                selectedOriginRequired={selectedOriginRequired}
                loadingFields={loadingFields}
                disableSucursalOriginPoint={disableSucursalOriginPoint}
                disableServiceOriginPoint={disableServiceOriginPoint}
                disableServiceOrder={disableServiceOrder}
                disableVehicleTypeID={disableVehicleTypeID}
                allPackageSizes={allPackageSizes}
                reverseLogistics={reverseLogistics}
                crossdocking={crossdocking}
                locationReferenceOrigin={locationReferenceOrigin}
                locationContactPersonOrigin={locationContactPersonOrigin}
                locationCellPhoneNumberOrigin={locationCellPhoneNumberOrigin}
                locationPhoneNumberOrigin={locationPhoneNumberOrigin}
                locationReferenceDestination={locationReferenceDestination}
                locationContactPersonDestination={
                  locationContactPersonDestination
                }
                locationCellPhoneNumberDestination={
                  locationCellPhoneNumberDestination
                }
                locationPhoneNumberDestination={locationPhoneNumberDestination}
                switchForReverseLogistics={this.switchForReverseLogistics}
                updateCrossdockingOption={this.updateCrossdockingOption}
                updateServiceOrderOption={this.updateServiceOrderOption}
                valueToState={this.valueToState}
                replacementOrder={replacementOrder}
                reversedPolylineAddress={reversedPolylineAddress}
                switchForReplacementOrder={this.switchForReplacementOrder}
              />
            </Col>
          </Row>
          <Row gutter={24} type="flex" justify="end">
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Button
                htmlType="button"
                type="primary"
                loading={loadingSaveData}
                onClick={this.saveOrder}
                style={{ margin: "16px 0 0", height: 40, width: "100%" }}
              >
                Solicitar envío
              </Button>
            </Col>
          </Row>
        </CustomFilterContainer>
      </Container>
    );
  }
}

const WraperUniqueSend = withGlobal(UniqueSend);
const UniqueSendForm = Form.create()(WraperUniqueSend);

export default compose(withApollo)(UniqueSendForm);
