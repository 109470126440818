import React from "react";
import { Checkbox, Row } from "antd";
import { FormItemInvisible } from "../Form";

class GroupCheckBoxComponent extends React.Component {
  render() {
    const { getFieldDecorator, decodator, title, value, options } = this.props;
    return (
      <div style={{ marginBottom: 20 }}>
        <FormItemInvisible
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: value || undefined,
            rules: [
              {
                required: true,
                message: "Por favor, ingrese la razón social",
              },
            ],
          })(
            <Checkbox.Group style={{ width: "100%" }}>
              <Row>{options}</Row>
            </Checkbox.Group>
          )}
        </FormItemInvisible>
      </div>
    );
  }
}

export default GroupCheckBoxComponent;
