import styled from "styled-components";
import React from "react";
import { Col, Row } from "antd";

const ContainerLabel = styled.div`
  & .test {
    width: 560px;
    height: auto;
    border: 2px solid #000000;
  }
`;

const ContentInfo = styled.div`
  margin-bottom: 22px;

  & .title-drawer {
    
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 13px;
    color: #595959;
  }
`;
const DescrDrawer = styled.p`
      
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #595959;
      margin-bottom: 12px;
    }
  `;
const LabelDrawer = styled.p`
  
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #8c8c8c;
  margin-bottom: 12px;
`;

const ContainerCols = props => {
  const { text, label } = props;
  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <LabelDrawer>{label}</LabelDrawer>
      </Col>
      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
        <DescrDrawer>{text}</DescrDrawer>
      </Col>
    </Row>
  );
};

export { ContentInfo, ContainerCols, ContainerLabel };
