import React from "react";
import { Router } from "@reach/router"; // Redirect
import { ApolloConsumer } from "react-apollo";
import { Statistics } from "pages";
import { GlobalLayout } from "../components/Layout";
import * as route from "./routes";
import DashboardWrapper from "../components/Layout/DashboardWrapper";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home/Home";
import MyCompany from "../pages/ClientConfig/MyCompany/MyCompany";
import UniqueSend from "../pages/Operations/UniqueSend/UniqueSend";
import TrackingPackage from "../pages/Operations/TrackingPackage/TrackingPackage";
import RouteTracking from "../pages/Operations/RouteTracking";
import NonExistent from "../pages/Operations/NonExistent/NonExistent";
import Branches from "../pages/ClientConfig/Branches/Branches";
// import NewBranch from "../pages/ClientConfig/Branches/NewBranch/NewBranch";
import BranchOffice from "../pages/ClientConfig/Branches/SaveBranch/BranchOffice";
import Uploadcsv from "../pages/Uploadcsv/Uploadcsv";
import Previewcsv from "../pages/Previewcsv/Previewcsv";
import LabelPrint from "../pages/LabelPrint/LabelPrint";
import Reports from "../pages/Reports/Reports";
import OrderHistory from "../pages/Operations/OdersHistory/OdersHistory";
import Accounts from "../pages/ClientConfig/Accounts/Accounts";
import SaveAccount from "../pages/ClientConfig/Accounts/SaveAccount/SaveAccount";

import Landing from "../pages/Landing/Landing";
import RegisterShopify from "../pages/RedirectPlatform/RegisterShopify";
import IntegrationPlatforms from "../pages/ClientConfig/Integration/CreatePlatforms";
import { RouteDetails } from "../pages/Operations";
import EditPlatforms from "../pages/ClientConfig/Integration/EditPlatforms";

const RouterApp = () => (
  <GlobalLayout>
    <ApolloConsumer>
      {// eslint-disable-next-line no-unused-vars
      client => (
        <Router>
          <Landing path="/" />
          <DashboardWrapper path={route.dashboard}>
            <Home path={route.home} />
            <Statistics path={route.statistics} />
            <MyCompany path={route.myCompany} />
            {/** Accounts */}
            <Accounts path={route.accounts} />
            <SaveAccount path={route.newAccount} />
            <SaveAccount path={`${route.editAccount}/:userID`} />
            {/** Branches */}
            <Branches path={route.branches} />
            <BranchOffice path={route.branchOffice} />
            <BranchOffice path={`${route.editBranch}/:branchID`} />
            {/* Order Historial */}
            <OrderHistory path={`${route.orderHistory}/:orderId`} />
            <Uploadcsv path={route.uploadcsv} />
            <UniqueSend path={route.uniqueSend} />
            <TrackingPackage path={route.trackingPackage} />
            <RouteDetails path={`${route.routeDetails}/:routeCode`} />
            <RouteTracking path={route.routeTracking} />
            <NonExistent path={route.nonExistent} />
            <Previewcsv path={`${route.previewcsv}`} />
            <LabelPrint path={`${route.labelPrint}`} />
            <Reports path={`${route.Reports}`} />
            <RegisterShopify path={`${route.SaveIntegration}/:platform`} />
            <IntegrationPlatforms path={`${route.Integration}/:platform`} />
            <EditPlatforms path={`${route.EditIntegration}/:platform`} />
          </DashboardWrapper>
          <NotFound default />
        </Router>
      )}
    </ApolloConsumer>
  </GlobalLayout>
);

export default RouterApp;
