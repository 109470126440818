import React, { Component, Fragment } from "react";
import { Icon, Input } from "antd";
import PlacesAutocomplete from "react-places-autocomplete";

export class LocationSearchInput extends Component {
  state = {
    address: this.props.address,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.address !== this.props.address) {
      this.setState({ address: nextProps.address });
    }
  }

  handleAddressChange = address => {
    this.setState({ address }, () => this.props.onChange(address));
  };

  render() {
    const { address } = this.state;
    const { google } = window;

    const { color, theme, required } = this.props;
    const searchOptions = {
      location: new google.maps.LatLng(-34, 151),
      radius: 100000,
      types: ["address"],
      componentRestrictions: {
        country:
          (this.props.codeRegion && this.props.codeRegion.toLowerCase()) ||
          "PE",
      },
    };

    return (
      <PlacesAutocomplete
        onChange={this.handleAddressChange}
        onSelect={this.props.onAddressSelect}
        value={address}
        searchOptions={searchOptions}
        onError={() => {}}
      >
        {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => {
          return (
            <Fragment key="1">
              <Input
                required={required}
                size="large"
                {...getInputProps({
                  id: "address-input",
                  placeholder: "Ingresa la dirección",
                  className: "location-search-input",
                })}
                suffix={
                  <Icon
                    theme={theme}
                    onClick={this.props.openMap}
                    title="Selecciona tu ubicación"
                    type="environment"
                    style={{ color: color || "#595959", cursor: "pointer" }}
                  />
                }
              />
              <div className="autocomplete-dropdown-container">
                {loading ? <div>Loading...</div> : null}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#1890ff",
                        cursor: "pointer",
                        padding: "0.5rem 0.5rem",
                        color: "white",
                      }
                    : {
                        backgroundColor: "#ffffff",
                        cursor: "pointer",
                        padding: "0.5rem 0.5rem",
                      };

                  const spread = {
                    ...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    }),
                  };
                  console.log("sif", suggestion.index);
                  return (
                    <div {...spread} key={suggestion.index}>
                      <div>{suggestion.description}</div>
                    </div>
                  );
                })}
              </div>
            </Fragment>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}
