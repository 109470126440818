import gql from "graphql-tag";

const getBranchOfficeByEnterpriseID = gql`
  query getBranchOfficeByEnterpriseID($enterpriseID: Int!) {
    AllBranchOffices: getBranchOfficeByEnterpriseID(
      enterpriseID: $enterpriseID
    ) {
      id
      branchOfficeCode
      branchOfficeName
      branchOfficeAddress
      branchOfficeAddressReference
      branchOfficeAddressPoint {
        type
        coordinates
      }
      cityID
      serviceIDs
      Services {
        id
        name
        typeID
      }
      City {
        id
        name
      }
      Enterprise {
        id
        address
        businessName
        comercialName
      }
      createdAt
      updatedAt
    }
  }
`;

const getPaymentMethod = gql`
  query {
    allPaymentMethod: getGeneralTypesByType(type: 43) {
      subtype
      subclass
    }
  }
`;

const getPaymentProof = gql`
  query {
    allPaymentProof: getGeneralTypesByType(type: 44) {
      subtype
      subclass
    }
  }
`;

const getAllVehicleTypes = gql`
  query {
    allVehicleTypes: getGeneralTypesByType(type: 13) {
      subtype
      subclass
    }
  }
`;

const getAllServices = gql`
  query {
    allServices: getAllServices {
      id
      name
      Type {
        subtype
        subclass
      }
    }
  }
`;

const getTariffUniqOrder = gql`
  query getTariffUniqOrder(
    $dropPoint: PointReq!
    $pickUpPoint: PointReq!
    $serviceID: String!
    $enterpriseID: Int!
  ) {
    TariffUniqOrder: getTariffUniqOrder(
      dropPoint: $dropPoint
      pickUpPoint: $pickUpPoint
      serviceID: $serviceID
      enterpriseID: $enterpriseID
    ) {
      baseTariffID
      baseTariffPrice
    }
  }
`;

const getPackageSizesBy = gql`
  query searchPackageSizesBy {
    PackageSizePagination: searchPackageSizesBy {
      count
      rows {
        id
        name
        countryID
      }
    }
  }
`;

export {
  getBranchOfficeByEnterpriseID,
  getPaymentProof,
  getPaymentMethod,
  getAllServices,
  getTariffUniqOrder,
  getPackageSizesBy,
  getAllVehicleTypes,
};
