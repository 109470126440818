import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Form, Col, message, Progress } from "antd"; // Icon
import axios from "axios";
// import { CSVLink } from "react-csv";
import SearchCompanyHistoryForm from "./SearchCompany";
import UploadFile from "./UploadFile";
// import { ButtonCreate } from "../../../../components/Button";
import { CustomFilterContainer } from "../../../components/Form/SearchContainer";
import { CustomRow } from "../../../components/Grid";
import { TitleGoBack } from "../../../components/Container";
import ModalSuccess from "./ModalSuccess";
import ModalFailed from "./ModalFailed";

let intervalo;
class ComponetUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // enterpriseID: null,
      urlFile: null,
      filename: null,
      loadingProcess: false,
      tope: 0,
      response: null,
    };
  }

  handleSubmit = (urlFile, filename, fileStamp) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState(
          {
            urlFile,
            loadingProcess: true,
            filename,
            // enterpriseID: parseInt(values.company, 10),
          },
          () => {
            intervalo = setInterval(this.funProcess, 1000);
            this.processCsv(
              `csv/${fileStamp}`,
              this.props.allCitiesBranch.length > 1 ||
                (this.props.allCitiesBranch.length === 1 &&
                  parseInt(this.props.enterpriseData.cityID, 10) !==
                    parseInt(this.props.allCitiesBranch[0].id, 10))
                ? values.cityID
                : undefined,
              this.props.enterpriseData.id
            );
          }
        );
      } else {
        message.error("Es obligatorio seleccionar un cliente.");
      }
    });
  };

  processCsv = (filename, cityID, enterpriseId) => {
    const { urlFile } = this.state;
    const { userID } = this.props;
    const data2 = {
      filename,
      filenameUrl: urlFile,
      userID,
      enterpriseID: parseInt(enterpriseId, 10),
      ...(cityID && { cityID }),
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      axios
        .post(`${process.env.REACT_APP_CFUNCTION_DOMAIN}/csvgg`, data2, config)
        .then(res => {
          if (res.data && res.data.errors) {
            this.setState({
              visibleFailed: true,
              loadingProcess: true,
              tope: 100,
              response: res.data,
            });
          } else {
            this.setState({
              visibleSuccess: true,
              loadingProcess: true,
              tope: 100,
              response: res.data,
            });
          }
        })
        .catch(error => {
          message.error("Error al cargar CSV, inténtalo nuevamente.");
          clearInterval(intervalo);
          this.setState({
            urlFile: null,
            loadingProcess: false,
            tope: 0,
            filename: null,
            response: null,
          });
          console.log(error);
        });
    } catch (e) {
      message.error("Error al cargar CSV, inténtalo nuevamente.");
      clearInterval(intervalo);
      console.log("error", e);
      this.setState({
        urlFile: null,
        loadingProcess: false,
        tope: 0,
        filename: null,
        response: null,
      });
      // console.log("Cannot send the push notification", e);
    }
  };

  cancelProcess = () => {
    this.props.form.resetFields();
    this.setState({
      urlFile: null,
      loadingProcess: false,
      tope: 0,
      filename: null,
      response: null,
      // enterpriseID: null,
    });
  };

  funProcess = () => {
    this.setState(({ tope }) => ({ tope: tope + 10 }));
    if (this.state.tope >= 90) {
      clearInterval(intervalo);
    }
  };

  handleCancel = () => {
    this.setState({
      visibleSuccess: false,
      visibleFailed: false,
      urlFile: null,
      loadingProcess: false,
      tope: 0,
      response: null,
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const {
      urlFile,
      loadingProcess,
      tope,
      response,
      visibleSuccess,
      visibleFailed,
      filename,
    } = this.state;

    const citySelected = getFieldValue("cityID");
    const {
      enterpriseData,
      allCitiesBranch,
      onClickProcess,
      loadingBtnProcess,
      countryID,
    } = this.props;
    const tableHeaders = ["Line", "Column", "Type", "Message", "Input"];

    const shapedData = [tableHeaders];

    const dataToDownload = response && response.errors;
    if (dataToDownload) {
      dataToDownload.forEach(record => {
        const s = [record.line, record.label, record.type, record.messages.join("|"), record.input];
        shapedData.push(s);
      });
    }

    const errorsView =
      response &&
      response.errors &&
      response.errors.map((x, index) => {
        return (
          <div
            key={index.toString()}
            style={{
              background: "#FFF1F0",
              border: "1px solid #CF1322",
              boxSizing: "border-box",
              borderRadius: "4px",
              color: "#CF1322",
              padding: "8px 16px",
              
              marginBottom: "4px",
            }}
          >
            <p
              style={{
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                marginBottom: "2px",
              }}
            >{`Linea ${x.line}`}</p>
            <p
              style={{
                fontSize: "10px",
                lineHeight: "12px",
                marginBottom: "0px",
              }}
            >{`${x.label}: ${x.messages}`}</p>
          </div>
        );
      });

    const showCitiesForm = !enterpriseData
      ? false
      : enterpriseData.multiplace &&
        (allCitiesBranch.length > 1 ||
          (allCitiesBranch.length === 1 &&
            parseInt(enterpriseData.cityID, 10) !==
              parseInt(allCitiesBranch[0].id, 10)));
    const showUploadCSV = !enterpriseData
      ? false
      : !enterpriseData.multiplace ||
        (allCitiesBranch.length > 1 && citySelected) ||
        (allCitiesBranch.length === 1 &&
          parseInt(enterpriseData.cityID, 10) ===
            parseInt(allCitiesBranch[0].id, 10));

    return (
      <Form>
        <TitleGoBack title="Cargar CSV" />
        {showCitiesForm && (
          <SearchCompanyHistoryForm
            getFieldDecorator={getFieldDecorator}
            citiesData={allCitiesBranch}
          />
        )}
        {showUploadCSV && (
          <CustomFilterContainer
            style={{
              height: "380px",
            }}
          >
            {loadingProcess ? (
              <CustomRow gutter={24} type="flex" justify="center">
                <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 8 }}>
                  <p className="title">Carga un archivo CSV</p>

                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      textAlign: "center",
                      border: "1px dashed #d9d9d9",
                      borderRadius: "4px",
                      cursor: "pointer",
                      transition: "border-color 0.3s",
                      height: "215px",
                      padding: "24px",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "48px",
                        
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "20px",
                        textAlign: "center",
                        color: "#8C8C8C",
                      }}
                    >
                      Cargando CSV al
                    </p>
                    <Progress percent={tope} status="active" />
                  </div>
                </Col>
              </CustomRow>
            ) : (
              <UploadFile urlFile={urlFile} handleSubmit={this.handleSubmit} countryID={countryID} />
            )}
          </CustomFilterContainer>
        )}
        {visibleSuccess && (
          <ModalSuccess
            visible={visibleSuccess}
            filename={filename}
            handleCancel={this.handleCancel}
            handleOk={() =>
              onClickProcess(enterpriseData.id, response.validatedOrderIDs)
            }
            loadingBtnProcess={loadingBtnProcess}
          />
        )}
        {visibleFailed && (
          <ModalFailed
            numSuccess={
              response &&
              response.validatedOrderIDs.length - response.noFailedOrders
            }
            numFailed={response && response.noFailedOrders}
            total={response && response.validatedOrderIDs.length}
            shapedData={shapedData}
            visible={visibleFailed}
            errorsView={errorsView}
            filename={filename}
            handleCancel={this.handleCancel}
            handleOk={() =>
              onClickProcess(enterpriseData.id, response.validatedOrderIDs)
            }
            loadingBtnProcess={loadingBtnProcess}
          />
        )}
      </Form>
    );
  }
}

const WraperComponetUpload = Form.create()(ComponetUpload);

export default compose(withApollo)(WraperComponetUpload);
