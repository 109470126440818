import React, { useState } from "react";
import moment from "moment";
import { Row, Col, Button, Modal } from "antd";

import { InputComponentWithBorder } from "../../../../components/FormFields";
import { statusesColors, orderToCompleteColor } from "../../../../utils";
import { StyledCarousel } from ".";

const OrderInfo = props => {
  const { order, orderToComplete } = props;
  const colors = statusesColors[parseInt(order.LastStatus.subtype, 10)];
  const [openedImagesModal, setOpenedImagesModal] = useState(false);

  const openImagesModal = () => setOpenedImagesModal(true);
  const closeImagesModal = () => setOpenedImagesModal(false);

  return (
    <>
      <Row gutter={24}>
        <Col xl={8}>
          <InputComponentWithBorder
            title="Dirección"
            disabled
            inputValue={
              order.Orders.dropAddress ? order.Orders.dropAddress : ""
            }
          />
        </Col>
        <Col xl={7}>
          <InputComponentWithBorder
            title="Referencia"
            disabled
            inputValue={
              order.Orders.dropAddressReference
                ? order.Orders.dropAddressReference
                : ""
            }
          />
        </Col>
        <Col xl={5}>
          <InputComponentWithBorder
            title="Fecha"
            disabled
            inputValue={
              order.OrderServiceHistorial.length === 1
                ? moment(order.OrderServiceHistorial[0].createdAt).format(
                    "DD/MM/YYYY"
                  )
                : ""
            }
          />
        </Col>
        <Col xl={4}>
          <InputComponentWithBorder
            title="Hora"
            disabled
            inputValue={
              order.OrderServiceHistorial.length === 1
                ? moment(order.OrderServiceHistorial[0].createdAt).format(
                    "HH:mm"
                  )
                : ""
            }
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xl={8}>
          <InputComponentWithBorder
            title="Recibido por"
            disabled
            inputValue={
              order.Orders.receptorAcuse ? order.Orders.receptorAcuse : ""
            }
          />
        </Col>
        <Col xl={7}>
          <InputComponentWithBorder
            title="N° Documento"
            disabled
            inputValue={
              order.Orders.receptorDocument ? order.Orders.receptorDocument : ""
            }
          />
        </Col>
        <Col xl={5}>
          <div
            style={{
              cursor: "default",
            }}
          >
            <span
              style={{
                fontSize: 12,
                marginBottom: 4,
              }}
            >
              Estado
            </span>
            <div
              style={{
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 4,
                backgroundColor: orderToComplete
                  ? orderToCompleteColor.background
                  : colors.background,
                fontWeight: "bold",
                color: orderToComplete
                  ? orderToCompleteColor.textColor
                  : colors.textColor,
                border: `1px solid ${
                  orderToComplete ? orderToCompleteColor.border : colors.border
                }`,
              }}
            >
              {order.LastStatus.subclass}
            </div>
          </div>
        </Col>
        <Col xl={4}>
          <Button
            type="link"
            block
            size="large"
            icon="search"
            style={{ marginTop: 21 }}
            onClick={openImagesModal}
          >
            Evidencias
          </Button>
        </Col>
      </Row>
      <Modal
        title="Imágenes"
        visible={openedImagesModal}
        bodyStyle={{ padding: "24px 48px" }}
        footer={[
          <Button key="close" onClick={closeImagesModal}>
            Cerrar
          </Button>,
        ]}
      >
        {order.Orders.OrdersImages.length === 0 ? (
          <div style={{ textAlign: "center" }}>No hay imágenes</div>
        ) : (
          <StyledCarousel arrows dots={false}>
            {order.Orders.OrdersImages.map(orderImage => (
              <img
                key={orderImage.url}
                alt={orderImage.url}
                src={orderImage.url}
                style={{ width: "100%" }}
              />
            ))}
          </StyledCarousel>
        )}
      </Modal>
    </>
  );
};

export default OrderInfo;
