import React from "react";
import { Drawer, Button, Row, Col, Icon } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Geocode from "react-geocode";
import { withGlobal } from "../../../component-global";
import { TitleDrawer } from "./style";
import { InputComponentWithBorder } from "../../../components/FormFields";
import ModalMap from "./modalMap";
import { captureException } from "../../../utils/sentry";

const renderArray = array => {
  let result = "";
  array.forEach(x => {
    result += `*${x}`;
  });
  return result;
};

class DrawerComponent extends React.Component {
  constructor(props) {
    super(props);
    const { selectedDelvery } = this.props;
    this.state = {
      latLng: null,
      addressPick: selectedDelvery.orderInfo.pickUpAddress,
      addressDelivery: selectedDelvery.orderInfo.dropAddress,
    };

    this.state.pickupGPS = selectedDelvery
      ? `${selectedDelvery.orderInfo.pickUpPoint &&
          selectedDelvery.orderInfo.pickUpPoint
            .coordinates[1]}, ${selectedDelvery.orderInfo.pickUpPoint &&
          selectedDelvery.orderInfo.pickUpPoint.coordinates[0]}`
      : null;
    this.state.deliveryGPS = selectedDelvery
      ? `${selectedDelvery.orderInfo.dropPoint.coordinates[1]}, ${
          selectedDelvery.orderInfo.dropPoint.coordinates[0]
        }`
      : null;
    console.log("this.state.deliveryGPS ");
    console.log(this.state.deliveryGPS);
    console.log("this.state.pickupGPS");
    console.log(this.state.pickupGPS);
    this.state.isPickupGPSValid = !!selectedDelvery;
    this.state.isDeliveryGPSValid = !!selectedDelvery;
  }

  componentDidMount() {
    this.setState({
      latLng: {
        lat: this.props.selectedDelvery.orderInfo.pickUpPoint.coordinates[1],
        lng: this.props.selectedDelvery.orderInfo.pickUpPoint.coordinates[0],
      } || {
          lat: this.props.selectedDelvery.orderInfo.dropPoint.coordinates[1],
          lng: this.props.selectedDelvery.orderInfo.dropPoint.coordinates[0],
        } || {
          lat: this.props.authUser.profile.cityCoords[1],
          lng: this.props.authUser.profile.cityCoords[0],
        },
    });
  }

  onOpenModalEditAddress = () => {
    this.setState({
      visibleEditAddress: true,
    });
  };

  onCancelEditAddress = () => {
    this.setState({
      visibleEditAddress: false,
    });
  };

  onChangeAddressPick = addressPick => {
    this.setState({ addressPick });
  };

  handleAddressSelectPick = addressPick => {
    this.setState({ addressPick });

    geocodeByAddress(addressPick)
      .then(async results => {
        return getLatLng(results[0]);
      })
      .then(latLngCal => {
        this.setState({ latLngPick: latLngCal, latLng: latLngCal });
        this.setState({
          pickupGPS: latLngCal ? `${latLngCal.lat}, ${latLngCal.lng}` : null,
        });
      })
      .catch(error => {
        captureException(error);
        console.error("Error", error);
      });
  };

  onChangeAddressDelivery = addressDelivery => {
    this.setState({ addressDelivery });
  };

  handleAddressSelectDelivery = addressDelivery => {
    this.setState({ addressDelivery });

    geocodeByAddress(addressDelivery)
      .then(async results => {
        return getLatLng(results[0]);
      })
      .then(latLngCal => {
        this.setState({ latLngDelivery: latLngCal, latLng: latLngCal });
        this.setState({
          deliveryGPS: latLngCal ? `${latLngCal.lat}, ${latLngCal.lng}` : null,
        });
      })
      .catch(error => {
        captureException(error);
        console.error("Error", error);
      });
  };

  handleChangePickupGPS = async e => {
    const pickupGPS = e.target.value;
    this.setState({ pickupGPS });
    const regex = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/;
    if (regex.test(pickupGPS)) {
      const coords = pickupGPS.split(",");
      const lat = parseFloat(coords[0].trim());
      const lng = parseFloat(coords[1].trim());
      this.setState({ latLngPick: { lat, lng }, isPickupGPSValid: true });
      const addressPick = await this.reverseGeocode(`${lat}`, `${lng}`);
      this.setState({ addressPick });
    } else {
      this.setState({ isPickupGPSValid: false });
    }
  };

  handleChangeDeliveryGPS = async e => {
    const deliveryGPS = e.target.value;
    this.setState({ deliveryGPS });
    const regex = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/;
    if (regex.test(deliveryGPS)) {
      const coords = deliveryGPS.split(",");
      const lat = parseFloat(coords[0].trim());
      const lng = parseFloat(coords[1].trim());
      this.setState({ latLngDelivery: { lat, lng }, isDeliveryGPSValid: true });
      const addressDelivery = await this.reverseGeocode(`${lat}`, `${lng}`);
      this.setState({ addressDelivery });
    } else {
      this.setState({ isDeliveryGPSValid: false });
    }
  };

  markerDragPick = async coord => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState({ latLngPick: { lat, lng } });
    this.setState({
      pickupGPS: `${lat}, ${lng}`,
    });
    const addressPick = await this.reverseGeocode(`${lat}`, `${lng}`);
    this.setState({ addressPick });
  };

  markerDragDelivery = async coord => {
    // console.log(coord);

    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState({ latLngDelivery: { lat, lng } });
    this.setState({
      deliveryGPS: `${lat}, ${lng}`,
    });
    const addressDelivery = await this.reverseGeocode(`${lat}`, `${lng}`);
    this.setState({ addressDelivery });
  };

  onEditAddress = e => {
    const { selectedDelvery } = this.props;
    e.preventDefault();
    this.setState({ visibleEditAddress: false });

    this.props.updateDelivery(
      this.state.latLngPick || {
        lat: selectedDelvery.orderInfo.pickUpPoint.coordinates[1],
        lng: selectedDelvery.orderInfo.pickUpPoint.coordinates[0],
      },
      this.state.latLngDelivery || {
        lat: selectedDelvery.orderInfo.dropPoint.coordinates[1],
        lng: selectedDelvery.orderInfo.dropPoint.coordinates[0],
      },
      this.state.addressPick,
      this.state.addressDelivery
    );
  };

  reverseGeocode = async (lat, lng) => {
    let response;
    try {
      // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
      Geocode.setApiKey("AIzaSyB7CjrGpR0jAfYkCC_sex8rbv2GWxmct7Y");
      response = await Geocode.fromLatLng(lat, lng);
    } catch (error) {
      console.log(error);
    }
    return response.results[0].formatted_address;
  };

  render() {
    const {
      visibleEditAddress,
      latLng,
      latLngPick,
      latLngDelivery,
      addressPick,
      addressDelivery,
      pickupGPS,
      isPickupGPSValid,
      deliveryGPS,
      isDeliveryGPSValid,
    } = this.state;
    const {
      onClose,
      visible,
      selectedDelvery,
      getFieldDecorator,
      loadingUpdateOrder,
      // onOpenModalEditAddress,
    } = this.props;
    // console.log("selectedDelvery", selectedDelvery);

    const formDinamic =
      selectedDelvery.errors &&
      selectedDelvery.errors.map((x, index) => {
        if (
          x.field !== "pickUpBranchID" &&
          x.field !== "pickUpAddress" &&
          x.field !== "dropBranchID" &&
          x.field !== "dropAddress" &&
          x.field !== "pickUpAddressReference" &&
          x.field !== "dropAddressReference"
        )
          return (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              key={index.toString()}
              // title={renderArray(x.messages)}
              style={{ marginBottom: "16px" }}
            >
              <InputComponentWithBorder
                required
                getFieldDecorator={getFieldDecorator}
                title={x.label}
                decodator={x.field}
                value={x.input}
                validateStatus="error"
                help={renderArray(x.messages)}
              />
            </Col>
          );
        return null;
      });

    const pickUpAddressLocatedFail =
      selectedDelvery &&
      selectedDelvery.errors &&
      selectedDelvery.errors.findIndex(
        x => x.field === "pickUpBranchID" || x.field === "pickUpAddress"
      );
    const dropAddressLocatedFail =
      selectedDelvery &&
      selectedDelvery.errors &&
      selectedDelvery.errors.findIndex(
        x => x.field === "dropBranchID" || x.field === "dropAddress"
      );

    return (
      <Drawer
        title={null}
        width={520}
        onClose={onClose}
        visible={visible} // visibleDrawer
      >
        <div
          style={{
            width: "100%",
            padding: "48px",
          }}
        >
          <TitleDrawer>
            <p className="title-drawer">
              {selectedDelvery && !selectedDelvery.errors
                ? "Trayecto de la orden"
                : "¡Ups! Encontramos errores en esta orden."}
            </p>
            <p className="descp-drawer">
              {selectedDelvery && !selectedDelvery.errors
                ? "Modifica las direcciones de recolección o envío."
                : "Corrige los siguientes campos para que puedan ser procesados"}
            </p>
          </TitleDrawer>
          <Row gutter={16}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginBottom: "16px" }}
            >
              <InputComponentWithBorder
                allowClear={false}
                getFieldDecorator={getFieldDecorator}
                title="Dirección de recolección"
                decodator="pickUpAddress"
                value={addressPick}
                onClick={this.onOpenModalEditAddress}
                validateStatus={
                  pickUpAddressLocatedFail !== null &&
                  pickUpAddressLocatedFail > -1 &&
                  !addressPick
                    ? "error"
                    : null
                }
                help={
                  pickUpAddressLocatedFail !== null &&
                  pickUpAddressLocatedFail > -1 &&
                  !addressPick
                    ? "*La dirección no se encuentra o está fuera del área de cobertura"
                    : null
                }
                // /onChange={this.handleChangePickupAddress}

                suffix={
                  <Icon
                    onClick={this.onOpenModalEditAddress}
                    type="environment"
                    theme="filled"
                    style={{ color: "#347be3", cursor: "pointer" }}
                  />
                }
              />
            </Col>
            {selectedDelvery && selectedDelvery.orderInfo.pickUpPostalCode && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: "16px" }}
              >
                <InputComponentWithBorder
                  getFieldDecorator={getFieldDecorator}
                  title="Código postal (dirección de recolección)"
                  decodator="pickUpPostalCode"
                  value={
                    selectedDelvery &&
                    selectedDelvery.orderInfo.pickUpPostalCode
                  }
                />
              </Col>
            )}
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginBottom: "16px" }}
            >
              <InputComponentWithBorder
                getFieldDecorator={getFieldDecorator}
                title="Referencia de dirección de recolección"
                decodator="pickUpAddressReference"
                value={
                  selectedDelvery &&
                  selectedDelvery.orderInfo.pickUpAddressReference
                }
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginBottom: "16px" }}
            >
              <InputComponentWithBorder
                onClick={this.onOpenModalEditAddress}
                getFieldDecorator={getFieldDecorator}
                title="Dirección de entrega"
                decodator="deliveryAddress"
                value={addressDelivery}
                validateStatus={
                  dropAddressLocatedFail !== null &&
                  dropAddressLocatedFail > -1 &&
                  !addressDelivery
                    ? "error"
                    : null
                }
                help={
                  dropAddressLocatedFail !== null &&
                  dropAddressLocatedFail > -1 &&
                  !addressDelivery
                    ? "*La dirección no se encuentra o está fuera del área de cobertura"
                    : null
                }
                // onChange={e => this.handleChangeDropAddress(e)}
                suffix={
                  <Icon
                    onClick={this.onOpenModalEditAddress}
                    type="environment"
                    theme="filled"
                    style={{ color: "#9b50e0", cursor: "pointer" }}
                  />
                }
              />
            </Col>
            {selectedDelvery && selectedDelvery.orderInfo.dropPostalCode && (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginBottom: "16px" }}
              >
                <InputComponentWithBorder
                  getFieldDecorator={getFieldDecorator}
                  title="Código postal (dirección de entrega)"
                  decodator="dropPostalCode"
                  value={
                    selectedDelvery && selectedDelvery.orderInfo.dropPostalCode
                  }
                />
              </Col>
            )}
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ marginBottom: "16px" }}
            >
              <InputComponentWithBorder
                getFieldDecorator={getFieldDecorator}
                title="Referencia de dirección de entrega"
                decodator="dropAddressReference"
                value={
                  selectedDelvery &&
                  selectedDelvery.orderInfo.dropAddressReference
                }
              />
            </Col>
            {formDinamic}
          </Row>

          <div
            style={{
              textAlign: "center",
            }}
          >
            <Button
              type="primary"
              ghost
              loading={loadingUpdateOrder}
              onClick={this.onEditAddress}
              style={{
                fontWeight: "500",
                fontSize: "14px",
                height: 40,
              }}
            >
              Actualizar datos
            </Button>
          </div>
        </div>
        {visibleEditAddress && (
          <ModalMap
            pickUpAddressLocatedFail={pickUpAddressLocatedFail}
            dropAddressLocatedFail={dropAddressLocatedFail}
            handleCancel={this.onCancelEditAddress}
            handleOk={this.onCancelEditAddress}
            latLng={latLng}
            latLngPick={
              latLngPick ||
              (selectedDelvery
                ? {
                    lat: selectedDelvery.orderInfo.pickUpPoint.coordinates[1],
                    lng: selectedDelvery.orderInfo.pickUpPoint.coordinates[0],
                  }
                : null)
            }
            pickupGPS={pickupGPS}
            onChangePickupGPS={this.handleChangePickupGPS}
            isPickupGPSValid={isPickupGPSValid}
            deliveryGPS={deliveryGPS}
            onChangeDeliveryGPS={this.handleChangeDeliveryGPS}
            isDeliveryGPSValid={isDeliveryGPSValid}
            latLngDelivery={
              latLngDelivery ||
              (selectedDelvery
                ? {
                    lat: selectedDelvery.orderInfo.dropPoint.coordinates[1],
                    lng: selectedDelvery.orderInfo.dropPoint.coordinates[0],
                  }
                : null)
            }
            visible={visibleEditAddress}
            onChangeAddressPick={this.onChangeAddressPick}
            handleAddressSelectPick={this.handleAddressSelectPick}
            onChangeAddressDelivery={this.onChangeAddressDelivery}
            handleAddressSelectDelivery={this.handleAddressSelectDelivery}
            addressPickSelected={addressPick}
            addressDeliverySelected={addressDelivery}
            addressPick={addressPick}
            addressDelivery={addressDelivery}
            markerDragPick={this.markerDragPick}
            markerDragDelivery={this.markerDragDelivery}
          />
        )}
      </Drawer>
    );
  }
}

const WraperDrawerComponent = withGlobal(DrawerComponent);

export default compose(withApollo)(WraperDrawerComponent);
