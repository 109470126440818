import gql from "graphql-tag";

// Borrado de una sucursal
const deleteBranchOffice = gql`
  mutation deleteBranchOffice(
    $branchIDs: [Int]
    $initDate: Date
    $endDate: Date
    $enterpriseID: Int!
    $branchOfficeID: Int
  ) {
    DeleteBranchOffice: deleteBranchOffice(
      branchIDs: $branchIDs
      initDate: $initDate
      endDate: $endDate
      enterpriseID: $enterpriseID
      branchOfficeID: $branchOfficeID
    ) {
      message
      motive
    }
  }
`;

const createBranchOffice = gql`
  mutation createBranchOffice($data: InputBranchOffice) {
    CreateBranchOffice: createBranchOffice(data: $data) {
      message
      motive
    }
  }
`;

const updateBranchOffice = gql`
  mutation updateBranchOffice($id: ID, $data: InputBranchOffice) {
    UpdateBranchOffice: updateBranchOffice(id: $id, data: $data) {
      message
      motive
    }
  }
`;

export { deleteBranchOffice, createBranchOffice, updateBranchOffice };
