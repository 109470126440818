import { Empty } from "antd";
import React from "react";

const imgEmpty =
  "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e";

export default props => (
  <Empty
    image={imgEmpty}
    imageStyle={{
      height: 136,
    }}
    description={
      <span
        style={{
          color: "#8C8C8C",
          
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "20px",
        }}
      >
        {props.loading ? "Cargando..." : `${props.description}`}
      </span>
    }
  ></Empty>
);
