import React from "react";
import { Form, Modal, Row, Col, Button, Tooltip, Table } from "antd";
import moment from "moment";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import { withGlobal } from "../../../../component-global";
import { CustomRowModal } from "./style";
import { CustomFilterContainer } from "../../../../components/Form/SearchContainer";
import { CardTable } from "../../../../components/Table/styles";

class ModalReoffert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      onCancel,
      visible,
      onConfirm,
      loading,
      acceptMessage,
      cancelMessage,
      selectedRowKeys,
      selectRowData,
      selectedAllRows,
      // currentFilter,
    } = this.props;

    const columns = [
      {
        title: "Fecha de fallo",
        dataIndex: "dateIncidence",
        key: "dateIncidence",
        render: (text, record) => (
          <Tooltip title={record.createdAt}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.OrderService &&
              record.OrderService.length > 0 &&
              record.OrderService[0].OrderServiceHistorial &&
              record.OrderService[0].OrderServiceHistorial.length > 0 &&
              record.OrderService[0].OrderServiceHistorial[0].createdAt
                ? moment(
                    record.OrderService[0].OrderServiceHistorial[0].createdAt
                  ).format("DD/MM/YYYY")
                : ""}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "TrackCode",
        dataIndex: "trackCode",
        key: "trackCode",
        render: (text, record) => (
          <Tooltip title={record.createdAt}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.trackCode}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Motivo",
        dataIndex: "incidence",
        key: "incidence",
        render: (text, record) => (
          <Tooltip title={record.createdAt}>
            <span style={{ fontSize: "14px", lineHeight: "20px" }}>
              {record.OrderService &&
              record.OrderService.length > 0 &&
              record.OrderService[0].OrderServiceHistorial &&
              record.OrderService[0].OrderServiceHistorial.length > 0 &&
              record.OrderService[0].OrderServiceHistorial[0].Incidence
                ? record.OrderService[0].OrderServiceHistorial[0].Incidence.name
                : ""}
            </span>
          </Tooltip>
        ),
      },
    ];

    return (
      <Modal
        title={null}
        visible={visible}
        footer={null}
        onCancel={onCancel}
        width="500px"
        bodyStyle={{ maxHeight: "820px", padding: 40 }}
        centered
      >
        <div style={{ textAlign: "center" }}>
          <p
            style={{
              
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "32px",
              textAlign: "center",
              color: "#595959",
              marginBottom: 20,
            }}
          >
            {!selectedAllRows
              ? `¿Deseas reprogramar ${selectedRowKeys.length} TrackCode(s)?`
              : `¿Deseas reprogramar todas las ordenes?`}
          </p>
        </div>
        <div
          style={{
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: "24px",
            color: "#8C8C8C",
            textAlign: "center",
          }}
        >
          <p>
            Analizaremos los TrackCodes seleccionados. Te avisaremos cuando sean reprogramados.
          </p>
        </div>
        {!selectedAllRows ? (
          <Row gutter={16} style={{ marginBottom: 24 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <CustomFilterContainer>
                <CardTable>
                  <Table
                    pagination={false}
                    size="middle"
                    dataSource={selectRowData}
                    columns={columns}
                    scroll={{ y: "calc(100vh - 490px)" }}
                  />
                </CardTable>
              </CustomFilterContainer>
            </Col>
          </Row>
        ) : (
          ""
        )}

        <CustomRowModal gutter={24} type="flex" justify="space-around">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
            <Button
              type="primary"
              ghost
              style={{
                width: "100%",
                
                fontWeight: "500",
                fontSize: "14px",
                height: "40px",
              }}
              onClick={onCancel}
              loading={loading}
            >
              {cancelMessage}
            </Button>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
            <Button
              type="primary"
              style={{
                width: "100%",
                
                fontWeight: "500",
                fontSize: "14px",
                height: "40px",
              }}
              onClick={onConfirm}
              loading={loading}
            >
              {acceptMessage}
            </Button>
          </Col>
        </CustomRowModal>
      </Modal>
    );
  }
}

const WraperModalReoffert = withGlobal(ModalReoffert);
const NewModalReoffertForm = Form.create()(WraperModalReoffert);

export default compose(withApollo)(NewModalReoffertForm);
