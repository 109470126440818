import React from "react";
import { Button, Col, Row, TimePicker, Icon, Typography } from "antd";
import moment from "moment";

import { CustomFormItemTimer } from "../../../../../components/Form";
import { CheckBoxWithBorderComponent } from "../../../../../components/FormFields";
import Loading from "../../../../../components/Utils/Loading";

import { hourFormat, optionsWithDisabled, days } from "./static";

const { Text } = Typography;

class FormAttentionScheduler extends React.Component {
  render() {
    const {
      branchOffice,
      PackageSizes,
      AllServicesActive,
      changeHours,
      getFieldDecorator,
      packageSizesSelected,
      attentionDays,
      attentionHours,
      addAttention,
      deleteAttention,
    } = this.props;

    let formattedPackageSizes = [];
    formattedPackageSizes = PackageSizes.map(packageSize => {
      return { id: parseInt(packageSize.id, 10), name: packageSize.name };
    }).sort((a, b) => {
      return a.id - b.id;
    });

    const isAttentionHoursMoreThanOne = attentionHours.length > 1;

    const attentionsQtyLikeArr = Array(
      Math.min(attentionDays.length, attentionHours.length)
    ).fill(0);

    const attentionHoursRender =
      attentionHours.length &&
      attentionHours.map((attentionHour, index) => {
        return (
          <Row key={index.toString()} gutter={24} style={{ marginBottom: 24 }}>
            <Col
              xs={24}
              sm={24}
              md={isAttentionHoursMoreThanOne ? 22 : 24}
              lg={isAttentionHoursMoreThanOne ? 22 : 24}
              xl={isAttentionHoursMoreThanOne ? 22 : 24}
            >
              <Row gutter={24}>
                <Col xs={24} sm={24} md={11} lg={12} xl={12}>
                  <CustomFormItemTimer label="De">
                    {getFieldDecorator(`startHour-${attentionHour.id}`, {
                      initialValue:
                        (attentionHour.startHour &&
                          moment(attentionHour.startHour, "HH:mm")) ||
                        undefined,
                      rules: [
                        {
                          required: true,
                          message: "El campo no puede quedar vacio.",
                        },
                      ],
                    })(
                      <TimePicker
                        style={{ width: "100%" }}
                        placeholder="Hora"
                        format={hourFormat}
                        className="startHour"
                      />
                    )}
                  </CustomFormItemTimer>
                </Col>

                <Col xs={24} sm={24} md={11} lg={12} xl={12}>
                  <CustomFormItemTimer label="A">
                    {getFieldDecorator(`endHour-${attentionHour.id}`, {
                      initialValue:
                        (attentionHour.endHour &&
                          moment(attentionHour.endHour, "HH:mm")) ||
                        undefined,
                      rules: [
                        {
                          required: true,
                          message: "El campo no puede quedar vacio.",
                        },
                      ],
                    })(
                      <TimePicker
                        style={{ width: "100%" }}
                        placeholder="Hora"
                        format={hourFormat}
                        onChange={e => changeHours("star", e)}
                        className="endHour"
                      />
                    )}
                  </CustomFormItemTimer>
                </Col>
              </Row>
            </Col>
            {isAttentionHoursMoreThanOne && (
              <Col
                xs={24}
                sm={24}
                md={2}
                lg={2}
                xl={2}
                style={{
                  display: "grid",
                  justifyContent: "end",
                  alignItems: "end",
                  height: "72px",
                }}
              >
                <Button
                  type="link"
                  onClick={() => deleteAttention(attentionHour.id)}
                >
                  <Icon
                    type="delete"
                    style={{
                      color: "#307FE2",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  />
                </Button>
              </Col>
            )}
          </Row>
        );
      });

    const attentionDaysRender =
      attentionDays.length &&
      attentionDays.map((attentionDaySelected, index) => (
        <CheckBoxWithBorderComponent
          key={index.toString()}
          getFieldDecorator={getFieldDecorator}
          decodator={`atentionDays-${attentionDaySelected.id}`}
          options={optionsWithDisabled(days)}
          value={branchOffice && attentionDaySelected.days}
          required
        />
      ));

    return AllServicesActive.length ? (
      <Row gutter={24}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 24 }}>
          <CheckBoxWithBorderComponent
            style={{ width: "100%" }}
            getFieldDecorator={getFieldDecorator}
            title="Servicios"
            placeholder="Seleccione un servicio"
            decodator="services"
            options={optionsWithDisabled(AllServicesActive)}
            value={branchOffice && branchOffice.serviceIDs}
            disabled={!!branchOffice}
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          xl={{ span: 24 }}
        >
          <Text strong="true">Días de atención</Text>
          {attentionsQtyLikeArr.length > 0 &&
            attentionsQtyLikeArr.map((schedule, index) => (
              <React.Fragment key={index.toString()}>
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 16 }}
                    xl={{ span: 12 }}
                    style={{ height: 40 }}
                  >
                    {attentionDaysRender[index]}
                  </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    xl={{ span: 12 }}
                  >
                    {attentionHoursRender[index]}
                  </Col>
                </Row>
              </React.Fragment>
            ))}
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div
                style={{
                  marginTop: "14px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  style={{
                    border: "none",
                    backgroundColor: "#fff",
                    cursor: "pointer",
                    outline: "none",
                  }}
                  onClick={addAttention}
                >
                  <div style={{ color: "#307FE2" }}>
                    <p>
                      Añadir horario{" "}
                      <Icon
                        type="plus"
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      />
                    </p>
                  </div>
                </button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 24 }}>
          <CheckBoxWithBorderComponent
            style={{ width: "100%" }}
            getFieldDecorator={getFieldDecorator}
            title="Tamaños de paquetes"
            decodator="packageSizes"
            options={optionsWithDisabled(formattedPackageSizes)}
            value={branchOffice && packageSizesSelected}
            disabled={!!branchOffice}
          />
        </Col>
      </Row>
    ) : (
      <Loading />
    );
  }
}

export default FormAttentionScheduler;
