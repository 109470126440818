import React from "react";
import { Row, Col, Form, Select, Button } from "antd";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import moment from "moment";
import { withGlobal } from "../../../../component-global";
import {
  SelectWithBorderComponent,
  RangePickerWithBorderCompoment,
} from "../../../../components/FormFields";
import { ContainerGlobal } from "../../../../components/Form/SearchContainer";

const { Option } = Select;
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const filter = {};
      if (values.date && values.date.length === 2) {
        filter.rangeDate = {
          createdAt: {
            greaterEqual: moment(values.date[0]).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            }).format("YYYY-MM-DD HH:mm:ss ZZ"),
            lessEqual: moment(values.date[1]).set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            }).format("YYYY-MM-DD HH:mm:ss ZZ"),
          },
        };
      }
      if (values.city) {
        filter.cityID = parseInt(values.city, 10);
      }

      this.props.searchTrackcode(filter);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      cityOptions,
      loadingBtnSearch,
      loadingData,
    } = this.props;

    const renderCity = (cityOptions || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    ));

    return (
      <ContainerGlobal gutter={24}>
        <Row gutter={16}>
          <Form onSubmit={this.handleSubmit}>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <RangePickerWithBorderCompoment
                title="Fecha"
                getFieldDecorator={getFieldDecorator}
                decodator="date"                
                placeholder={["Inicio", "Fin "]}
                loading={loadingData}
              />
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="Ciudad"
                decodator="city"
                options={renderCity}
                placeholder="Selecciona una ciudad"
                loading={loadingData}
              />
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Button
                type="primary"
                style={{
                  marginTop: "20px",
                  width: "100%",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
                size="large"
                loading={loadingData || loadingBtnSearch}
                htmlType="submit"
              >
                Buscar
              </Button>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8} />
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Button
                type="primary"
                ghost
                style={{
                  marginTop: "20px",
                  width: "100%",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
                size="large"
                onClick={this.props.openModalCSV}
              >
                Validar por CSV
              </Button>
            </Col>
          </Form>
        </Row>
      </ContainerGlobal>
    );
  }
}

const WraperUserProfile = withGlobal(Header);
const HeaderForm = Form.create()(WraperUserProfile);

export default compose(withApollo)(HeaderForm);
