import React from "react";
import { Row, Col, Form, Button, Icon } from "antd";
import { navigate } from "@reach/router";
import { ContainerGlobal } from "../../../components/Form/SearchContainer";
import InputComponent from "../../../components/FormFields/InputWithBorderComponent";
import { branchOffice } from "../../../routes/routes";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const filter = {};
      console.log("Values", values);

      if (values.branchOfficeName) {
        filter.branchOfficeName = values.branchOfficeName;
      }

      this.props.SearchPrePlanner(filter);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <ContainerGlobal gutter={24}>
        <Row gutter={24} justify="start" onSubmit={this.onSubmit}>
          <Form onSubmit={this.handleSubmit}>
            <Col xs={24} sm={24} md={10} lg={6} xl={6} xxl={6}>
              <InputComponent
                getFieldDecorator={getFieldDecorator}
                decodator="branchOfficeName"
                title="Sucursal"
                placeholder="Ingresa una sucursal"
              />
            </Col>
            <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  marginTop: "20px",
                  width: "100%",
                  borderColor: "#307FE2",
                  color: "#307FE2",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
                size="large"
                ghost
                loading={this.props.loadingBtnSearch}
              >
                Buscar
              </Button>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}></Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
              <Button
                type="primary"
                style={{
                  marginTop: "20px",
                  width: "100%",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
                size="large"
                onClick={() => navigate(`/dashboard/${branchOffice}`)}
                ghost
              >
                Nueva sucursal <Icon type="plus" />
              </Button>
            </Col>
          </Form>
        </Row>
      </ContainerGlobal>
    );
  }
}

const FormHeader = Form.create()(Header);

export default FormHeader;
