import React from "react";
import { Button, Icon } from "antd";
import styled from "styled-components";
import { FormItemInvisible } from "../Form";
import UploadFile from "../Upload";

const IconWhite = styled(Icon)`
  color: #ffffff;
  font-size: 12px !important;
  & svg {
    color: #ffffff !important;
  }
`;

class UploadComponent extends React.Component {
  render() {
    const { title, folder, onUpdateFields, value, btn } = this.props;

    const btnDefault = (
      <Button style={{ background: "#2A7DE1", color: "#ffffff" }}>
        <IconWhite type="reload" /> Actualizar
      </Button>
    );
    return (
      <div style={{ marginBottom: 20 }}>
        <FormItemInvisible
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          <UploadFile
            accept="image/*,.pdf"
            multiple
            folderName="preafiliados"
            target={folder}
            btn={btn || btnDefault}
            onUpdateFields={onUpdateFields}
          />
        </FormItemInvisible>
        {value && (
          <a href={value} rel="noopener noreferrer" target="_blank">
            Link de doc.
          </a>
        )}
      </div>
    );
  }
}

export default UploadComponent;
