import styled from "styled-components";
import { Tabs } from "antd";

const ContainerTab = styled(Tabs)`
  & .ant-tabs-tab-active {
    & .ant-badge-count {
      margin-left: 16px;
      background: #307fe2 !important;
      
    }
  }

  & .ant-tabs-tab {
    & .ant-badge-count {
      margin-left: 16px;
      background: #8c8c8c;
      
    }
  }

  & .ant-tabs-bar {
    margin: 0 0 24px 0;
  }
`;

export { ContainerTab };
