import React from "react";
import { Button, Col, Icon, Radio, Row } from "antd";
import InputComponent from "../../../../../components/FormFields/InputWithBorderComponent";

class FormContactInformation extends React.Component {
  render() {
    const {
      contacts,
      currentContact,
      getFieldDecorator,
      addContact,
      deleteContact,
      onChangeDefaultContact,
    } = this.props;

    const isContactsMoreThanOne = contacts.length > 1;

    return (
      <React.Fragment>
        <Radio.Group
          onChange={onChangeDefaultContact}
          style={{ width: "100%" }}
          value={currentContact}
        >
          {contacts.map((contact, index) => {
            return (
              <Row key={index.toString()} style={{ marginBottom: 24 }}>
                <Row gutter={24}>
                  <Col
                    xs={24}
                    sm={24}
                    md={isContactsMoreThanOne ? 23 : 24}
                    lg={isContactsMoreThanOne ? 23 : 24}
                    xl={isContactsMoreThanOne ? 23 : 24}
                  >
                    <Row gutter={[24, 12]}>
                      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <InputComponent
                          required
                          style={{ width: "100%" }}
                          getFieldDecorator={getFieldDecorator}
                          title="Nombres y apellidos"
                          placeholder="Ingresa los nombres y apellidos"
                          decodator={`names-${contact.id}`}
                          message="Campo de nombres y apellidos vacio"
                          value={contact.name}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <InputComponent
                          required
                          getFieldDecorator={getFieldDecorator}
                          decodator={`email-${contact.id}`}
                          title="Correo de contacto"
                          message="Campo de correo vacio"
                          placeholder="Ingresa el correo"
                          value={contact.email}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <InputComponent
                          required
                          getFieldDecorator={getFieldDecorator}
                          decodator={`phone-${contact.id}`}
                          placeholder="Ingresa el teléfono"
                          title="Teléfono"
                          message="Campo de teléfono vacio"
                          value={contact.phone}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <InputComponent
                          required
                          getFieldDecorator={getFieldDecorator}
                          decodator={`area-${contact.id}`}
                          placeholder="Ingresa el área"
                          title="Área"
                          message="Campo de área vacio"
                          value={contact.area}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {isContactsMoreThanOne && (
                    <Col
                      xs={24}
                      sm={24}
                      md={1}
                      lg={1}
                      xl={1}
                      style={{
                        display: "grid",
                        justifyContent: "end",
                        alignItems: "end",
                        height: "61px",
                      }}
                    >
                      <Button
                        type="link"
                        style={{ height: 40 }}
                        onClick={() => deleteContact(contact.id)}
                      >
                        <Icon
                          type="delete"
                          style={{
                            color: "#307FE2",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                        />
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row
                  gutter={24}
                  type="flex"
                  justify="space-between"
                  style={{ marginTop: 16, marginLeft: 0 }}
                >
                  <Radio
                    value={index}
                    decodator={`currentContact-${contact.id}`}
                    className="currentContact"
                  >
                    Contacto prederminado
                  </Radio>
                </Row>
              </Row>
            );
          })}
        </Radio.Group>
        <Row type="flex" justify="end" style={{ marginTop: 40 }}>
          <Button
            type="link"
            style={{
              border: "none",
              color: "#307FE2",
              backgroundColor: "#fff",
              cursor: "pointer",
              outline: "none",
            }}
            onClick={addContact}
          >
            Añadir contacto{" "}
            <Icon
              type="plus"
              style={{
                fontSize: "14px",
                cursor: "pointer",
              }}
            />
          </Button>
        </Row>
      </React.Fragment>
    );
  }
}

export default FormContactInformation;
