import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { Col, Row, Spin, message } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";

import { withGlobal } from "../../../component-global";
import { getRouteDetails } from "../../../graphQl/queries/routeDetails";
import { Container, TitleGoBack } from "../../../components/Container";
import {
  AffiliateInfo,
  PriceInfo,
  OrdersInfo,
  StatusLegend,
} from "./components";
import Map from "../../../components/Maps/MapForRouteDetails";
import { markersImages } from "../../../utils";

const getMarkerIcon = (isPickUp, orderToComplete, os) => ({
  url: isPickUp
    ? markersImages.blue
    : chooseMarkerIcon(
        orderToComplete,
        parseInt(os.Orders.id, 10),
        parseInt(os.LastStatus.subtype, 10)
      ),
  scaledSize: new window.google.maps.Size(24, 32),
});

const chooseMarkerIcon = (currentOrderToComplete, orderID, status) => {
  if ([166, 172, 190].includes(status)) {
    return markersImages.success;
  }
  if ([168, 171, 161, 163, 159, 169].includes(status)) {
    return markersImages.failed;
  }
  if ([162, 164].includes(status) && orderID === currentOrderToComplete) {
    return markersImages.green;
  }
  return markersImages.bluesky;
};

const getLatLngFromCoordinates = coordinates => ({
  lat: coordinates[1],
  lng: coordinates[0],
});

const getAllMarkersFromRoute = route => {
  const os = route.OrderServices;
  const orderToComplete = route.currentOrderToComplete;
  const hasNotOrders = os && os.length === 0;
  const multiOrder = !hasNotOrders && os.length > 1;
  const multiPickUp =
    multiOrder && os[0].Orders.pickUpAddress !== os[1].Orders.pickUpAddress;

  const markers = multiPickUp
    ? os
        .map((os, index) => {
          const { pickUpPoint, dropPoint } = os.Orders;
          return [
            {
              icon: getMarkerIcon(true, orderToComplete, os),
              label: (index + 1).toString(),
              position: getLatLngFromCoordinates(pickUpPoint.coordinates),
            },
            {
              icon: getMarkerIcon(false, orderToComplete, os),
              label: (index + 1).toString(),
              position: getLatLngFromCoordinates(dropPoint.coordinates),
            },
          ];
        })
        .flat()
    : !hasNotOrders
    ? [
        {
          icon: getMarkerIcon(true, orderToComplete, os[0]),
          label: "R",
          position: getLatLngFromCoordinates(
            os[0].Orders.pickUpPoint.coordinates
          ),
        },
      ].concat(
        os.map((os, index) => ({
          icon: getMarkerIcon(false, orderToComplete, os),
          label: (index + 1).toString(),
          position: getLatLngFromCoordinates(os.Orders.dropPoint.coordinates),
        }))
      )
    : [];

  return markers;
};

const getCenterFromOrderServices = orderServices => {
  const bounds = new window.google.maps.LatLngBounds();
  if (orderServices.length === 0) return { lat: 0, lng: 0 };

  orderServices.forEach(os => {
    bounds.extend({
      lat: os.Orders.dropPoint.coordinates[1],
      lng: os.Orders.dropPoint.coordinates[0],
    });
  });

  return bounds.getCenter();
};

const RouteDetails = props => {
  const {
    routeCode,
    client,
    authUser: { profile, access_token, token_type },
    userEnterpriseData: { enterpriseID },
  } = props;
  console.log(props.authUser);
  const [loadingRoute, setLoadingRoute] = useState(false);
  const [loadingDirections, setLoadingDirections] = useState(false);
  const [route, setRoute] = useState();
  const [directions, setDirections] = useState();

  const getDirections = useCallback(
    async route => {
      setLoadingDirections(true);
      try {
        const { OrderServices, vehicleTypeID, cityID } = route;

        const pickUpPoints = OrderServices.map(os => {
          const { pickUpPoint } = os.Orders;

          const pickLatLng = {
            lat: pickUpPoint.coordinates[1],
            lng: pickUpPoint.coordinates[0],
          };

          return pickLatLng;
        });

        const dropPoints = OrderServices.map(os => {
          const { dropPoint } = os.Orders;

          const dropLatLng = {
            lat: dropPoint.coordinates[1],
            lng: dropPoint.coordinates[0],
          };

          return dropLatLng;
        });

        const coordinates = pickUpPoints.concat(dropPoints);

        const body = {
          cityID: cityID || profile.cityID,
          coordinates,
          vehicleTypeID,
        };

        const token = `${token_type} ${access_token}`;

        const config = {
          headers: {
            Authorization: token || "",
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `${process.env.REACT_APP_POLYLINEURL}`,
          body,
          config
        );

        if (data) setDirections(data.points);
        setLoadingDirections(false);
      } catch (e) {
        setLoadingDirections(false);
        console.log("error", e);
        message.error("Error en el sistema, intentelo de nuevo");
      }
    },
    [access_token, profile.cityID, token_type]
  );

  const callGetRouteDetails = useCallback(async () => {
    setLoadingRoute(true);
    try {
      const { data } = await client.query({
        fetchPolicy: "network-only",
        query: getRouteDetails,
        variables: {
          enterpriseID,
          routeCode,
        },
      });

      await getDirections(data.getRouteDetails);
      setRoute(data.getRouteDetails);
      setLoadingRoute(false);
    } catch (error) {
      setLoadingRoute(false);
      message.error("Error en la carga de datos");
    }
  }, [routeCode, client, enterpriseID, getDirections]);

  useEffect(() => {
    callGetRouteDetails();
  }, [callGetRouteDetails]);

  return (
    <Container>
      <TitleGoBack title={`Detalle de ruta: ${routeCode}`} />
      {loadingRoute || loadingDirections || !route || !directions ? (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Row
            gutter={24}
            style={{ marginBottom: 24, height: 550, padding: "0 12px" }}
          >
            <Map
              showInfoBox
              zoom={10}
              center={getCenterFromOrderServices(route.OrderServices)}
              markers={getAllMarkersFromRoute(route)}
              polyline={directions}
              colorsLegend={<StatusLegend />}
            />
          </Row>
          <Row gutter={24} style={{ marginBottom: 24 }}>
            <Col xl={6} lg={8}>
              <AffiliateInfo affiliate={route.Affiliates} />
              <PriceInfo route={route} profile={profile} />
            </Col>
            <Col xl={18} lg={16}>
              <OrdersInfo
                orders={route.OrderServices}
                orderToComplete={route.currentOrderToComplete}
                chooseMarkerIcon={chooseMarkerIcon}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

const WraperRouteDetails = withGlobal(RouteDetails);

export default compose(withApollo)(WraperRouteDetails);
