import React from "react";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import { Form, Col, Row, Select } from "antd";
import { ContainerForm, CustomIcon } from "./style";
import {
  InputComponentWithBorder,
  SelectWithBorderComponent,
  SwitchComponent,
} from "../../../../components/FormFields";
import { PopoverInfo } from ".";

const { Option } = Select;
class OrderInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      getFieldDecorator,
      // allServicesEnterprise, // todos los servicios de la empresa
      allPaymentMethod,
      allPaymentProof,
      allVehicleTypes,
      allPackageSizes,
      multiplaceEnterprise,
      allServicesCity,
      loadingFields,
      handleSwitchForReverseLogistics,
      updateCrossdockingOption,
      updateServiceOrderOption,
      selectCrossdockingRequired,
      selectServiceOrderRequired,
      disableService,
      disableVehicleTypeID,
      handleSwitchForReplacementOrder,
      reverseLogistics,
      replacementOrder,
    } = this.props;

    const renderPackageSizes = (allPackageSizes || []).map(obj => (
      <Option key={obj.id} value={obj.id}>
        {obj.name}
      </Option>
    ));

    const renderPaymentMethod = (allPaymentMethod || []).map(obj => (
      <Option key={obj.subtype} value={obj.subtype}>
        {obj.subclass}
      </Option>
    ));

    const renderPaymentProof = (allPaymentProof || []).map(obj => (
      <Option key={obj.subtype} value={obj.subtype}>
        {obj.subclass}
      </Option>
    ));

    const renderVehicleTypes = (allVehicleTypes || []).map(obj => (
      <Option key={obj.subtype} value={obj.subtype}>
        {obj.subclass}
      </Option>
    ));

    const renderTrueFalseOptions = [
      { value: true, label: "Sí" },
      { value: false, label: "No" },
    ].map(obj => (
      <Option key={obj.value} value={obj.value}>
        {obj.label}
      </Option>
    ));

    const renderServices = (allServicesCity || [])
      // .filter(s => s.Type.subtype === "5")
      .map(obj => (
        <Option key={obj.id} value={obj.id}>
          {obj.name}
        </Option>
      ));
    // servicios de la empresa
    /* const renderServices = (allServicesEnterprise || [])
      .filter(s => s.Type.subtype === "5")
      .map(obj => (
        <Option key={obj.id} value={obj.id}>
          {obj.name}
        </Option>
      )); */

    return (
      <ContainerForm
        style={{
          marginBottom: "10px",
          marginTop: "5px",
        }}
      >
        <Row gutter={16} style={{ marginBottom: 5 }}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "5px" }}
          >
            <InputComponentWithBorder
              getFieldDecorator={getFieldDecorator}
              title="TrackCode"
              decodator="trackCode"
              placeholder="Ingresa un TrackCode"
              required
              style={{ marginBottom: "0px" }}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "5px" }}
          >
            <SelectWithBorderComponent
              style={{ width: "100%" }}
              getFieldDecorator={getFieldDecorator}
              title="Método de pago"
              decodator="paymentMethodOrder"
              required
              options={renderPaymentMethod}
              placeholder="Selecciona el método de pago"
              loading={loadingFields}
            />
          </Col>
        </Row>
        {!multiplaceEnterprise ? (
          <Row gutter={16} style={{ marginBottom: 5 }}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: "5px" }}
            >
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="¿Tu orden es crossdocking?"
                decodator="crossDocking"
                required
                options={renderTrueFalseOptions}
                onChange={e => updateCrossdockingOption(e)}
                placeholder="Selecciona"
                loading={loadingFields}
              />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: "5px" }}
            >
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="Tipo de servicio"
                decodator="serviceOrder"
                options={renderServices}
                onChange={e => updateServiceOrderOption(e)}
                placeholder="Seleccione un tipo de servicio"
                required={selectCrossdockingRequired}
                message="Debe seleccionar un servicio"
                disabled={disableService}
              />
            </Col>
          </Row>
        ) : (
          ""
        )}

        {disableVehicleTypeID && (
          <Row gutter={16} style={{ marginBottom: 5 }}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: "5px" }}
            >
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="Tipo de Vehículo"
                decodator="vehicleTypeID"
                options={renderVehicleTypes}
                placeholder="Selecciona un tipo"
                loading={loadingFields}
                required={selectServiceOrderRequired}
                message="Debe seleccionar un tipo de vehículo"
                //disabled={disableVehicleTypeID}
              />
            </Col>
          </Row>
        )}

        <Row gutter={16} style={{ marginBottom: 5 }}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "5px" }}
          >
            <InputComponentWithBorder
              getFieldDecorator={getFieldDecorator}
              title="Descripción del producto"
              decodator="productDescription"
              placeholder="Ingresa la descripción del producto"
              required
              style={{ marginBottom: "0px" }}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "5px" }}
          >
            <SelectWithBorderComponent
              style={{ width: "100%" }}
              getFieldDecorator={getFieldDecorator}
              title="Facturación"
              decodator="paymentProofOrder"
              required
              options={renderPaymentProof}
              placeholder="Selecciona un tipo"
              loading={loadingFields}
            />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 5 }}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "5px" }}
          >
            <InputComponentWithBorder
              getFieldDecorator={getFieldDecorator}
              title="Precio del producto"
              decodator="priceProductOrder"
              placeholder="Ingresa un precio"
              required
              style={{ marginBottom: "0px" }}
            />
          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "5px" }}
          >
            <InputComponentWithBorder
              getFieldDecorator={getFieldDecorator}
              title="Peso del paquete"
              decodator="productWeight"
              placeholder="Ingresa el peso"
              required
              style={{ marginBottom: "0px" }}
            />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 5 }}>
          <Col
            xs={24}
            sm={24}
            md={multiplaceEnterprise ? 12 : 24}
            lg={multiplaceEnterprise ? 12 : 24}
            xl={multiplaceEnterprise ? 12 : 24}
            style={{ marginTop: "5px" }}
          >
            <SelectWithBorderComponent
              style={{ width: "100%" }}
              getFieldDecorator={getFieldDecorator}
              title="Tamaño del paquete"
              decodator="packageSize"
              options={renderPackageSizes}
              placeholder="Selecciona un tamaño"
              loading={loadingFields}
            />
          </Col>

          {/*multiplaceEnterprise ? (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ marginTop: "5px" }}
            >
              <SelectWithBorderComponent
                style={{ width: "100%" }}
                getFieldDecorator={getFieldDecorator}
                title="¿Tu orden es crossdocking?"
                decodator="crossDocking"
                required
                options={renderTrueFalseOptions}
                onChange={e => updateCrossdockingOption(e)}
                placeholder="Selecciona"
                loading={loadingFields}
              />
            </Col>
          ) : (
            ""
          )*/}
        </Row>
        <Row gutter={16} style={{ marginBottom: 5 }}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "5px" }}
          >
            <SwitchComponent
              getFieldDecorator={getFieldDecorator}
              title="Logística inversa"
              decodator="reverseLogistics"
              placeholder="Logística Inversa"
              onChange={handleSwitchForReverseLogistics}
              disabled={replacementOrder}
              required
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: "5px" }}
          >
            <SwitchComponent
              getFieldDecorator={getFieldDecorator}
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  Orden de recambio
                  <PopoverInfo description="Crea una órden de recojo adicional a la de entrega. Tiene un costo adicional.">
                    <CustomIcon type="info-circle" />
                  </PopoverInfo>
                </div>
              }
              decodator="replacementOrder"
              placeholder="Orden de recambio"
              onChange={handleSwitchForReplacementOrder}
              disabled={reverseLogistics}
              required
            />
          </Col>
        </Row>
      </ContainerForm>
    );
  }
}

const FormOrderInformation = Form.create()(OrderInformation);

export default compose(withApollo)(FormOrderInformation);
