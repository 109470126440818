import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.secondaryApp = app.initializeApp(config, "Secondary");
    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.authSecondary = this.secondaryApp.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.refStorage = app.storage().ref();
    this.firestore = app.firestore();
    this.messaging = app.messaging();
    this.initializePush(app);
    this.firestore
      .enablePersistence()
      .then(() => {
        this.persistence = true; // enable persistense
      })
      .catch(error => console.log("ERROR enabling offline persistence", error));
  }

  initializePush = () => {
    Notification.requestPermission()
      .then(() => {
        console.log("Permission is granted by user");
        return this.messaging.getToken();
      })
      .then(token => {
        console.log("FCM Token:", token);
        // Write your logic to send token to your server
      })
      .catch(error => {
        console.log("Error Occurred", error);
      });
  };

  // FIRESTORE API
  addItemToDatabase = (
    collection,
    field,
    callback = () => {},
    fallback = () => {}
  ) => {
    this.firestore
      .collection(collection)
      .add(field)
      .then(docRef => {
        console.log("Document written with ID: ", docRef.id);
        callback();
      })
      .catch(error => {
        console.error("Error adding document: ", error);
        fallback();
      });
  };

  updateItemFromDatabase = (
    collection,
    id,
    field,
    callback = () => {},
    fallback = () => {}
  ) => {
    this.firestore
      .collection(collection)
      .doc(id)
      .update(field)
      .then(docRef => {
        console.log("Document written with ID: ", docRef.id);
        callback();
      })
      .catch(error => {
        console.error("Error adding document: ", error);
        fallback();
      });
  };

  // *** Auth API ***

  getCurrentUser = () => {
    return this.auth.currentUser;
  };

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.authSecondary.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    console.log("SIGN OUT FIREBASE");
    this.auth.signOut();
  };

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.getUserInfo(authUser.uid)
          .then(doc => {
            const dbUser = doc.data();
            // if (!dbUser.levelAccess) {
            //   dbUser.levelAccess = {};
            // }

            const userData = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              dbUser,
            };

            next(userData);
          })
          .catch(e => {
            console.log(e);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  getUserInfo = uid =>
    this.firestore
      .collection("users")
      .doc(uid)
      .get();

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref("users");

  // *** Message API ***

  message = uid => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref("messages");
}

export default Firebase;
