import React from "react";
import { Collapse, Icon } from "antd";
import styled from "styled-components";

const { Panel } = Collapse;

const StyledCollapse = styled.div`
  .ant-collapse-borderless {
    background-color: white;
    border: 0;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  }
  .ant-collapse-header {
    color: #595959;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding: 24px 24px;
    color: #595959;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 24px;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 24px;
    left: auto;
    color: #307fe2;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`;

const CollapseButton = styled.div``;

class CustomCollapse extends React.Component {
  render() {
    const { onChange, headerTitle, style } = this.props;
    return (
      <StyledCollapse>
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          onChange={onChange}
          style={style}
          expandIconPosition="right"
          expandIcon={({ isActive }) => {
            return isActive ? (
              <CollapseButton>
                Ocultar <Icon type="up" />
              </CollapseButton>
            ) : (
              <CollapseButton>
                Mostrar <Icon type="down" />
              </CollapseButton>
            );
          }}
        >
          <Panel header={headerTitle} key="1">
            {this.props.children}
          </Panel>
        </Collapse>
      </StyledCollapse>
    );
  }
}

export default CustomCollapse;
