import React, { FC } from "react";
import { Doughnut } from "react-chartjs-2";

import { ChartContainer } from "./styles";
import { DataElement } from "types";
import { showQuantityLabel, defaultColors } from "utils";

interface DonutChartProps {
  colors?: string[];
  data: DataElement[];
  height: number;
  title: string;
}

const DonutChart: FC<DonutChartProps> = props => {
  const { colors = defaultColors, data, height, title } = props;

  return (
    <ChartContainer height={height}>
      <span style={{ fontSize: 14, color: "#595959" }}>{title}</span>
      <div
        style={{
          height: "95%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Doughnut
          plugins={[showQuantityLabel]}
          options={{
            radius: "70%",
            cutout: "55%",
            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                onClick: () => {},
                position: "bottom",
                labels: {
                  font: {
                    size: 14,
                  },
                  usePointStyle: true,
                },
              },
            },
          }}
          data={{
            labels: data.map(e => e.name),
            datasets: [
              {
                data: data.map(e => e.quantity),
                backgroundColor: data.map(
                  (e, index) => colors[index % colors.length]
                ),
              },
            ],
          }}
        />
      </div>
    </ChartContainer>
  );
};

export default DonutChart;
