import React from "react";
import {
  Icon,
  Form,
  Modal,
  Col,
  Button,
  Row,
  Steps,
  Empty,
  message,
  notification,
} from "antd";
import axios from "axios";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { withGlobal } from "../../../../component-global";
import { csvIcon } from "./Util";
import Upload from "./Upload";

const { Step } = Steps;

const styles = {
  button: {
    width: "100%",
    
    fontWeight: "500",
    fontSize: "14px",
    height: "40px",
  },
  box: {
    background: "#FFF1F0",
    border: "1px solid #CF1322",
    boxSizing: "border-box",
    borderRadius: "4px",
    color: "#CF1322",
    padding: "8px 16px",
    
    margin: "8px 0px",
  },
  title: {
    
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "32px",
    textAlign: "center",
    color: "#595959",
    marginBottom: 10,
  },
  trackcode: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    marginBottom: "2px",
  },
  message: {
    fontSize: "10px",
    lineHeight: "12px",
    marginBottom: "0px",
  },
  subtitle: {
    
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#8C8C8C",
  },
  notFound: {
    width: "100%",
    background: "#F5F5F5",
    height: "32px",
    fontSize: "14px",
    padding: "6px",
    textAlign: "center",
  },
  table: {
    maxHeight: "292px",
    overflowY: "auto",
    margin: "10px 0px",
  }
}

class ModalCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      tope: 0,
      file: null,
      response: null,
      loading: false,
      disabled: false,
    };
  }

  validateCsv = (file) => {
    this.setState({ loading: true });
    const data = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_PROVIDER}:${process.env.REACT_APP_AUTH_CLIENT_ID}`
      )
    );
    const token = (data && data.access_token && data.token_type) && `${data.token_type} ${data.access_token}`;
    const config = {
      headers: {
        Authorization: token || "",
        "Content-Type": "application/json",
      },
    };

    const { userID, enterpriseID } = this.props;
    const body = {
      filename: `csv/${file.fileStamp}`,
      filenameUrl: file.url,
      userID,
      enterpriseID,
    };

    try {
      axios
        .post(`${process.env.REACT_APP_CFUNCTION_DOMAIN}/processCSVOrdersNoLocated`, body, config)
        .then(res => {
          if (res.data && res.data.errors) {
            this.setState({
              response: {
                errors: res.data.errors,
                nFailed: res.data.noFailedOrders,
              },
              loading: false,
            });
          } else {
            notification.success({
              message: "Trackcodes procesados con éxito",
              description: "Los TC cargados fueron procesados y actualizados"
            });
            this.onCancel();
            this.props.refreshList({});
          }

          this.setState({
            loading: false,
            step: 2,
          });
        })
        .catch(err => {
          if (err.response
            && err.response.data
            && err.response.data.errors
            && err.response.data.errors.message
          ) {
            notification.error({ message: err.response.data.errors.message })
          } else {
            notification.error({ message: "Error al cargar CSV, inténtalo nuevamente." })
          }
          this.setState({
            file: null,
            loading: false,
          });
        });
    } catch (e) {
      message.error("Error al cargar CSV, inténtalo nuevamente.");
      this.setState({ file: null });
    }
  };


  onDownload = () => {
    this.setState({ loading: true });
    const data = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_PROVIDER}:${process.env.REACT_APP_AUTH_CLIENT_ID}`
      )
    );
    const token = (data && data.access_token && data.token_type) && `${data.token_type} ${data.access_token}`;
    const config = {
      headers: {
        Authorization: token || "",
        "Content-Type": "application/json",
      },
    };

    let body;
    if (this.props.orderIDs.length > 0) {
      body = {
        ordersIDs: this.props.orderIDs.map(id => parseInt(id)),
      }
    }
    else {
      body = {
        filters: {
          ...this.props.filter,
          enterpriseID: this.props.enterpriseID,
        },
      }
    }

    try {
      axios
        .post(`${process.env.REACT_APP_CFUNCTION_DOMAIN}/templateCSVOrdersNoLocated`, body, config)
        .then(res => {
          if (res.data) {
            notification.success({ message: "Descarga exitosa" });
            const url = res.data.urlTemplate;
            window.open(url, "_blank");
            this.setState({ loading: false });
          }
        })
        .catch(e => {
          this.setState({ loading: false });
          message.error("Error en la descarga CSV, intentelo de nuevo.");
        });
    } catch (e) {
      this.setState({ loading: false });
      message.error("Error en la descarga CSV, intentelo de nuevo.");
    }
  }

  onCancel = () => {
    this.setState({
      step: 0,
      file: null,
      disabled: false,
    });
    this.props.onClose();
  }

  onContinue = () => {
    if (this.state.step === 0) {
      this.setState({
        step: 1,
        disabled: true,
      });
    }
    else if (this.state.step === 1) {
      this.validateCsv(this.state.file)
    }
    else {
      notification.success({
        message: "Trackcodes procesados con éxito",
        description: `${this.state.response.nFailed} TC presentan errores.`,
      });
      this.setState({
        file: null,
        response: null,
      });
      this.onCancel();
      this.props.refreshList({});
    }
  }

  setFile = (file) => {
    this.setState({
      file,
      disabled: (this.state.step === 1 && !file) ? true : false,
    });
  }

  render() {
    const { visible } = this.props;
    const { step, loading, disabled, response, file } = this.state;

    return (
      <Modal
        title={null}
        visible={visible}
        footer={null}
        onCancel={this.onCancel}
        width="500px"
        bodyStyle={{ maxHeight: "820px", padding: 40 }}
        centered
      >
        <div style={{ textAlign: "center" }}>
          <p style={styles.title}>
            {step === 2 ? "El CSV contiene errores" : "Validar por CSV"}
          </p>
        </div>

        {(step === 2 && response) ?
          (
            <>
              <p style={styles.subtitle}>
                {`Hemos detectado Trackcodes ${file.filename}.`}
              </p>

              <div style={styles.notFound}>
                <span style={{ color: "#CF1322" }}>{`TC-Con errores: ${response.nFailed}`}</span>
              </div>

              <div style={styles.table}>
                {response.errors.map((x, index) => {
                  return (
                    <div key={index.toString()} style={styles.box}>
                      <p style={styles.trackcode}>{`Trackcode: ${x.trackCode}`}</p>
                      <p style={styles.message}>{`${x.label}: ${x.messages.join(" - ")}`}</p>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <Steps current={step} style={{ paddingRight: 40, paddingLeft: 40 }} labelPlacement="vertical">
                <Step title="Descargar CSV" />
                <Step title="Cargar CSV" />
              </Steps>

              <div
                style={{
                  fontWeight: "normal",
                  fontSize: "14px",
                  lineHeight: "20px",
                  marginBottom: "24px",
                  marginTop: "10px",
                  color: step === 0 && "red",
                  textAlign: "center",
                }}
              >
                {step === 0 ?
                  <b>Aviso: No modificar el TrackCode, solo completar los datos faltantes del CSV descargado.</b> :
                  <b>Carga el nuevo CSV con los datos completos.</b>}
              </div>

              <Row>
                <Col span={24}>
                  {(step === 0) ?
                    <Empty
                      image={csvIcon}
                      imageStyle={{ height: 43 }}
                      style={{ height: 171, paddingTop: 35 }}
                      description={
                        // eslint-disable-next-line 
                        <a style={{ marginTop: 20, textDecorationLine: "underline" }} onClick={() => this.onDownload()}>
                          Descargar archivo <Icon type="download" style={{ fontSize: "16px" }} />
                        </a>
                      }
                    />
                    : <Upload setFile={this.setFile} />
                  }
                </Col>
              </Row>
            </>
          )}

        <Row gutter={24} type="flex" justify="space-around" style={{ marginTop: 15 }}>
          {step === 1 &&
            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
              <Button
                type="primary"
                ghost
                style={styles.button}
                onClick={this.onCancel}
              >
                Cancelar
            </Button>
            </Col>
          }
          <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
            <Button
              type="primary"
              style={styles.button}
              loading={loading}
              disabled={disabled}
              onClick={this.onContinue}
            >
              {step !== 1 ? "Continuar" : "Validar"}
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const WraperModalCSV = withGlobal(ModalCSV);
const NewModalCSVForm = Form.create()(WraperModalCSV);

export default compose(withApollo)(NewModalCSVForm);
