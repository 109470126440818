import React from "react";
import { InputNumber } from "antd";
import { CustomFormItemInputWithBorder } from "../Form";

class InputNumberComponent extends React.Component {
  validatePrimeNumber = (rule, value, callback) => {
    if (!value) return;
    const number = Number(value);

    const validate = Number.isNaN(number);
    if (validate) {
      callback("Solo números");
    }
  };

  render() {
    const {
      getFieldDecorator,
      title,
      decodator,
      value,
      required,
      disabled,
      placeholder,
      suffix,
      addonAfter,
      allowClear,
      style,
      size,
      onChange,
      addonBefore,
    } = this.props;

    return (
      <div>
        <CustomFormItemInputWithBorder
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: value || undefined,
            rules: [
              {
                required,
                message: "Este campo es obligatorio",
              },
              /* {
                validator: this.validatePrimeNumber,
              }, */
            ],
          })(
            <InputNumber
              addonBefore={addonBefore || null}
              onChange={onChange}
              size={size || "large"}
              style={style || { width: "100%" }}
              min={0}
              allowClear={allowClear}
              disabled={disabled}
              placeholder={placeholder}
              suffix={suffix}
              addonAfter={addonAfter}
            />
          )}
        </CustomFormItemInputWithBorder>
      </div>
    );
  }
}

export default InputNumberComponent;
