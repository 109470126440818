import gql from "graphql-tag";

const getRoutesForTracking = gql`
  query getRoutesForTracking(
    $enterpriseID: Int!
    $cityID: Int!
    $offset: Int
    $limit: Int
    $rangeBy: ZoneDateRangeInput
    $routeStatus: String
    $routeCode: String
    $trackCode: String
    $vehicleType: String
    $riskType: String
    $percentage: String
    $serviceType: String
  ) {
    getRoutesForTracking(
      offset: $offset
      limit: $limit
      enterpriseID: $enterpriseID
      cityID: $cityID
      rangeBy: $rangeBy
      routeStatus: $routeStatus
      routeCode: $routeCode
      trackCode: $trackCode
      vehicleType: $vehicleType
      riskType: $riskType
      percentage: $percentage
      serviceType: $serviceType
    ) {
      count
      rows {
        id
        routeCode
        createdAt
        deliveries
        priceEnterprise
        priceEnterpriseOriginal
        routeAlias
        originalRouteID
        percentage
        VehicleType {
          subclass
        }
        Status {
          subclass
        }
        meta {
          key
          value
        }
        OrderServices {
          id
          Orders {
            id
            pickUpAddress
            Status {
              subclass
            }
            Service {
              name
            }
          }
        }
        Risk {
          lastUpdatedDate
          riskType
        }
      }
    }
  }
`;

export { getRoutesForTracking };
