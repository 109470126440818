import styled from "styled-components";
import { Form } from "antd";

const FormItemInvisible = styled(Form.Item)`
  margin-bottom: 1rem;
  .autocomplete-dropdown-container {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    padding: 1rem;
    position: absolute;
    top: 5rem;
    width: 500px;
    z-index: 5;
  }

  & .ant-select-selection-selected-value {
    color: #59616a;
    font-weight: 500;
    font-size: 1.6rem;
    width: 100%;
  }

  .autocomplete-dropdown-container:empty {
    display: none;
  }
`;

export { FormItemInvisible };
