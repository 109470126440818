import { markerIconPath } from ".";
import {
  GetMarkerIconForUniqueOrder,
  GetMarkersForUniqueOrder,
  MarkerForUniqueOrder,
  GetPolylinesForUniqueOrder,
} from "types";

export const pickingColor = "#40A9FF";
export const returningColor = "#F5222D";

export const getMarkerIconForUniqueOrder: GetMarkerIconForUniqueOrder = args => {
  const { color, filled = true, anchor } = args;

  return {
    anchor,
    path: markerIconPath,
    fillColor: filled ? color : "#FFF",
    strokeColor: color,
    strokeOpacity: 1,
    fillOpacity: 1,
    crossOnDrag: false,
    scaledSize: new window.google.maps.Size(22, 31),
    labelOrigin: new window.google.maps.Point(13, 15),
  };
};

export const getPolylinesForUniqueOrder: GetPolylinesForUniqueOrder = args => {
  const { polylineAddress, reversedPolylineAddress } = args;

  return [
    {
      path: polylineAddress,
      color: pickingColor,
    },
    {
      path: reversedPolylineAddress,
      color: returningColor,
    },
  ];
};

export const getMarkersForUniqueOrder: GetMarkersForUniqueOrder = args => {
  const {
    replacementOrder,
    pointAddressOrigin,
    pointAddressDestination,
    markerDragAddressOrigin,
    markerDragAddressDestination,
  } = args;

  let markers: MarkerForUniqueOrder[] = [];

  // * Add origin marker if it exists
  if (pointAddressOrigin)
    markers.push({
      label: "1",
      icon: getMarkerIconForUniqueOrder({
        color: pickingColor,
      }),
      position: pointAddressOrigin,
      onDragEnd: markerDragAddressOrigin,
    });

  // * Add destination marker if it exists
  if (pointAddressDestination)
    markers.push({
      label: "2",
      icon: getMarkerIconForUniqueOrder({
        color: pickingColor,
      }),
      position: pointAddressDestination,
      onDragEnd: markerDragAddressDestination,
    });

  // * Add reversed markers if replacementOrder
  // * is enabled and there is two markers stacked
  if (replacementOrder && markers.length === 2) {
    markers = markers.map(marker => ({
      ...marker,
      icon: getMarkerIconForUniqueOrder({
        color: pickingColor,
        anchor: new window.google.maps.Point(12, 0),
      }),
    }));

    const reversedMarkers = markers.map((marker, index) => ({
      ...marker,
      label: (markers.length - index).toString(),
      icon: getMarkerIconForUniqueOrder({
        color: returningColor,
        anchor: new window.google.maps.Point(-12, 0),
      }),
    }));

    markers = markers.concat(reversedMarkers);
  }

  return markers;
};
