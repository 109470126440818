import gql from "graphql-tag";

const deleteOrdersNoLocated = gql`
  mutation deleteOrdersNoLocated(
    $startDate: Date
    $endDate: Date
    $cityID: Int
    $ordersNLID: [Int]
    $enterpriseID: Int
  ){
    deleteOrdersNoLocated(
      startDate: $startDate
      endDate: $endDate
      cityID: $cityID
      ordersNLID: $ordersNLID
      enterpriseID: $enterpriseID
    ){
      message
      motive
      statusCode
    }
  }  
`;

const createOrderFromOrderNoLocated = gql`
  mutation createOrderFromOrderNoLocated(
    $order: OrderInput!
    $orderNoLocatedID: Int!  
  ){
    createOrderFromOrderNoLocated(
      order: $order
      orderNoLocatedID: $orderNoLocatedID
    ){
      message
      motive
      statusCode
    }
  }
`;

const returnPackageNoLocated = gql`
  mutation returnPackageNoLocated(
    $addressReturn: addressReturnOrder!
    $orderNoLocatedIDs: [Int!]!
  ){
    returnPackageNoLocated(
      addressReturn: $addressReturn
      orderNoLocatedIDs: $orderNoLocatedIDs
    ){
      message
      motive
      statusCode
    }
  }
`;

export { deleteOrdersNoLocated, createOrderFromOrderNoLocated, returnPackageNoLocated };
