import React, { FC } from "react";
import { Typography } from "antd";

import { ChartsContainer } from "./styles";
import { DonutChart, HorizontalBarChart } from "..";
import { DataElement } from "types";

const { Title } = Typography;

interface IndicatorsByOrderProps {
  title: string;
  incidences: DataElement[];
  indicators: DataElement[];
}

const IndicatorsByOrder: FC<IndicatorsByOrderProps> = props => {
  const { title, incidences, indicators } = props;

  return (
    <div style={{ marginTop: 16, marginBottom: 32 }}>
      <Title level={3} style={{ color: "#595959" }}>
        {title}
      </Title>
      <ChartsContainer>
        <DonutChart data={indicators} height={600} title="Despacho" />
        <HorizontalBarChart
          data={incidences}
          placeholder="Seleccione una incidencia"
          title="Órdenes fallidas por motivo de fallo"
          width={500}
        />
      </ChartsContainer>
    </div>
  );
};

export default IndicatorsByOrder;
