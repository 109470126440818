import React from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Empty, Tag, Button, Row, Col, Icon } from "antd";
import { ContainerTables } from "../../../components/Form/SearchContainer";
import TableList from "../../../components/Table/tableList";

class SenReport extends React.Component {
  render() {
    // GRAFICO
    const data = {
      labels: this.props.reportDatalabel,
      datasets: this.props.reportDatasets,
    };

    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    const GroupedBar = () => {
      return (
        <>
          <div className="header">
            <h1 style={{ fontSize: 15, fontWeight: "bold" }} className="title">
              Chart: Envíos solo ida
            </h1>
            <h1
              style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}
            >
              {this.props.currentFilterE.rangeDate
                ? `del ${moment(
                    this.props.currentFilterE.rangeDate.createdAt.greaterEqual,
                    "YYYY/MM/DD"
                  ).format("DD/MM/YYYY")} al ${moment(
                    this.props.currentFilterE.rangeDate.createdAt.lessEqual,
                    "YYYY/MM/DD"
                  ).format("DD/MM/YYYY")}`
                : "Datos totales del año"}
            </h1>
          </div>
          <div>
            <Bar data={data} options={options} height={70} />
          </div>
        </>
      );
    };

    const EmptyCard = loading => (
      <div
        style={{
          height: "calc(90vh - 400px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Empty
          image="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/ZeldaB2B%2Fempty.png?alt=media&token=f75af418-98b3-4bf7-b173-24927b73d29e"
          imageStyle={{
            height: 136,
          }}
          description={
            <span
              style={{
                color: "#8C8C8C",
                
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "20px",
              }}
            >
              {loading
                ? "Cargando..."
                : "No hemos encontrado información disponible"}
            </span>
          }
        />
      </div>
    );

    // TABLA
    const columns = [
      {
        title: "Código",
        dataIndex: "trackCode",
        key: "trackCode",
        render: (text, record) => (
          <span>{record.trackCode ? record.trackCode : "--"}</span>
        ),
      },
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        render: (text, record) => (
          <span>{moment(record.createdAt).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        title: "Tipo de envío",
        dataIndex: "service",
        key: "service",
        align: "center",
        render: (text, record) => (
          <span>
            {record.Service && record.Service.name
              ? record.Service && record.Service.name
              : "--"}
          </span>
        ),
      },
      {
        title: "Ciudad",
        dataIndex: "city",
        key: "city",
        align: "center",
        render: (text, record) => (
          <span>
            {record.City && record.City.name
              ? record.City && record.City.name
              : "--"}
          </span>
        ),
      },
      {
        title: "Estado de paquete",
        dataIndex: "status",
        key: "status",
        align: "center",
        render: (text, record) => (
          <ContainerTables>
            <Tag
              style={{ fontSize: "14px", padding: 7 }}
              color={this.props.getTag(record.Status.subclass).color}
            >
              <span>
                {this.props.getTag(record.Status.subclass).name === null
                  ? record.Status.subclass
                  : this.props.getTag(record.Status.subclass).name}
              </span>
            </Tag>
          </ContainerTables>
        ),
      },
      {
        title: "Fecha de estado",
        dataIndex: "statusdate",
        key: "statusdate",
        align: "center",
        render: (text, record) => (
          <span>
            {moment(
              record.OrderService && record.OrderService[0].dateLastStatus
            ).format("DD/MM/YYYY")}
          </span>
        ),
      },
    ];

    return (
      <div>
        {this.props.loadingChart ? (
          <EmptyCard loading={this.props.loadingChart} />
        ) : (
          <GroupedBar />
        )}
        {this.props.loadingTable ? (
          <EmptyCard loading={this.props.loadingTable} />
        ) : (
          <div>
            <div style={{ paddingTop: 50 }}></div>
            <div>
              <Row gutter={24} type="flex" justify="end">
                <Col span={12}>
                  <h1
                    style={{ fontSize: 15, fontWeight: "bold" }}
                    className="title"
                  >
                    Tabla: Envíos solo ida (
                    {this.props.datadetailtotal
                      ? this.props.datadetailtotal
                      : 0}
                    )
                  </h1>
                </Col>
                <Col span={12} align="right">
                  <div>
                    <Button
                      size="large"
                      type="primary"
                      ghost
                      onClick={this.props.onExportCSV}
                      style={{ marginRight: 10 }}
                      loading={this.props.loadingButonExport}
                    >
                      Exportar <Icon type="download" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            <ContainerTables>
              <TableList
                columns={columns}
                currentPage={this.props.currentPage}
                pageSize={this.props.pageSize}
                // scroll={{ y: "calc(100vh - 560px)" }}
                // heightcustom="calc(100vh - 560px)"
                data={this.props.reportDataDetail}
                onChange={this.props.onChange}
                totalData={this.props.datadetailtotal}
              />
            </ContainerTables>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withApollo)(SenReport);
