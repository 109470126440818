import React from "react";
import TitleGoBack from "../../../components/Container/titleGoBack";
import { Container } from "../../../components/Container";
import Body from "./Components/Body";

class MyCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container>
        <TitleGoBack title="Datos de la empresa" />
        <Body />
      </Container>
    );
  }
}

export default MyCompany;
