import styled from "styled-components";

export const ColorsLegendContainer = styled.div`
  background-color: white;
  width: 250px;
  padding: 16px;
  position: absolute;
  bottom: 24px;
  right: 10px;
  z-index: 99;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15);
`;

export const ColorsLegendTitle = styled.div`
  
  font-weight: bold;
  font-size: 20px;
`;

export const ColorsLegendItemIcon = styled.div`
  background-color: ${props => props.color};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const ColorsLegendItemTitle = styled.div`
  color: #8c8c8c;
`;
