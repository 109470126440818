import gql from "graphql-tag";

const updateEnterprise = gql`
  mutation updateEnterprise(
    $id: ID!
    $documentType: Int
    $documentNumber: String
    $businessName: String
    $comercialName: String
    $address: String!
    $point: PointInput
    $photo: String
    $contactPeople: [ContactPersonInput]
    $extra: ExtraEnterpriseInput
  ) {
    UpdateEnterprise: updateEnterprise(
      id: $id
      documentType: $documentType
      documentNumber: $documentNumber
      businessName: $businessName
      comercialName: $comercialName
      address: $address
      point: $point
      photo: $photo
      contactPeople: $contactPeople
      extra: $extra
    ) {
      message
      motive
      statusCode
    }
  }
`;

const createEnterprise = gql`
  mutation createEnterprise(
    $documentType: Int
    $documentNumber: String!
    $address: String!
    $point: PointInput
    $businessName: String!
    $comercialName: String!
    $status: Int
    $integration: Boolean!
    $countryID: Int
    $cityID: Int
    $photo: String
    $contactPeople: [ContactPersonInput!]!
    $enterpriseUser: UserEnterpriseCreate
    $serviceIDs: [Int]
    $multiplace: Boolean
    $extra: ExtraEnterpriseInput
  ) {
    CreateEnterprise: createEnterprise(
      documentType: $documentType
      documentNumber: $documentNumber
      address: $address
      point: $point
      businessName: $businessName
      comercialName: $comercialName
      status: $status
      integration: $integration
      countryID: $countryID
      cityID: $cityID
      photo: $photo
      contactPeople: $contactPeople
      enterpriseUser: $enterpriseUser
      serviceIDs: $serviceIDs
      multiplace: $multiplace
      extra: $extra
    )
  }
`;

const createEmailConfigurationByEnterprise = gql`
  mutation createEmailConfigurationByEnterprise(
    $enterpriseID: Int
    $emailConfigurationServices: [EmailConfigurationServicesInput]
  ) {
    createEmailConfigurationByEnterprise: createEmailConfigurationByEnterprise(
      enterpriseID: $enterpriseID
      emailConfigurationServices: $emailConfigurationServices
    ) {
      message
      motive
      statusCode
    }
  }
`;

const updateEmailConfigurationByEnterprise = gql`
  mutation updateEmailConfigurationByEnterprise(
    $enterpriseID: Int
    $emailConfigurationServices: [EmailConfigurationServicesInput]
  ) {
    updateEmailConfigurationByEnterprise: updateEmailConfigurationByEnterprise(
      enterpriseID: $enterpriseID
      emailConfigurationServices: $emailConfigurationServices
    ) {
      message
      motive
      statusCode
    }
  }
`;

const createSuperAdminRecord = gql`
  mutation createSuperAdminRecord(
    $operationType: String
    $operatorUserID: ID
    $clientUserID: ID
    $enterpriseID: Int
  ) {
    CreateSuperAdminRecord: createSuperAdminRecord(
      operationType: $operationType
      operatorUserID: $operatorUserID
      clientUserID: $clientUserID
      enterpriseID: $enterpriseID
    ) {
      message
      statusCode
      motive
    }
  }
`;

export {
  updateEnterprise,
  createEnterprise,
  createEmailConfigurationByEnterprise,
  updateEmailConfigurationByEnterprise,
  createSuperAdminRecord,
};
