import React from "react";
import { Modal, Row, Col, Form, Button, Select, notification } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import { withGlobal } from "../../component-global";
import { CustomRow } from "../../components/Grid";
import { SelectWithBorderComponent } from "../../components/FormFields";
import { updateStatusUsers } from "../../graphQl/mutations/accounts";

const { Option } = Select;

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingButtonsModal: false,
      disableButtonsModal: true,
      stateSelected: null,
    };
  }

  updateStateInput = value => {
    this.setState({
      stateSelected: value,
      disableButtonsModal: !value,
    });
  };

  saveData = () => {
    const { stateSelected } = this.state;
    const { usersIDsSelected, loadTable } = this.props;
    this.setState({
      loadingButtonsModal: true,
    });
    const variables = {
      userIDs: usersIDsSelected,
      statusID: parseInt(stateSelected, 10),
    };

    this.props.client
      .mutate({
        mutation: updateStatusUsers,
        update: (cache, { data: { updateStatusUsers: response } }) => {
          response.statusCode === 200
            ? notification.success({ message: response.message })
            : notification.error({ message: response.message });
          this.props.onHandleCancelModal();
          loadTable();
          this.setState({ loadingButtonsModal: false });
        },
        variables,
      })
      .catch(error => {
        this.setState({ loadingButtonsModal: false });
        console.log("Error: ", error);
        notification.error({
          message: "Hubo un error al actualizar los datos.",
        });
      });
  };

  render = () => {
    const { getFieldDecorator } = this.props.form;
    const { statusUsers, visible, onHandleCancelModal } = this.props;
    const {
      loadingButtonsModal,
      disableButtonsModal,
      stateSelected,
    } = this.state;

    const renderStatusUsers = (statusUsers || []).map(obj => (
      <Option key={obj.subtype} value={obj.subtype}>
        {obj.subclass}
      </Option>
    ));

    return (
      <Modal
        title={null}
        visible={visible}
        footer={null}
        onCancel={onHandleCancelModal}
        width="400px"
        bodyStyle={{ height: "220px" }}
      >
        <div style={{ textAlign: "center" }}>
          <p
            style={{
              
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "32px",
              textAlign: "center",
              color: "#595959",
              marginBottom: 20,
            }}
          >
            Cambiar estado de los usuarios
          </p>
        </div>
        <Row gutter={16} style={{ marginBottom: 15 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <SelectWithBorderComponent
              style={{ width: "100%" }}
              getFieldDecorator={getFieldDecorator}
              title="Estado"
              decodator="statusID"
              value={stateSelected}
              options={renderStatusUsers}
              onChange={e => this.updateStateInput(e)}
              placeholder="Seleccione el estado"
            />
          </Col>
        </Row>

        <CustomRow gutter={24} type="flex" justify="space-around">
          <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
            <Button
              type="primary"
              ghost
              style={{
                width: "100%",
                
                fontWeight: "500",
                fontSize: "14px",
                height: "40px",
              }}
              onClick={onHandleCancelModal}
              loading={loadingButtonsModal}
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
            <Button
              type="primary"
              style={{
                width: "100%",
                
                fontWeight: "500",
                fontSize: "14px",
                height: "40px",
              }}
              onClick={this.saveData}
              disabled={disableButtonsModal}
              loading={loadingButtonsModal}
            >
              Actualizar
            </Button>
          </Col>
        </CustomRow>
      </Modal>
    );
  };
}

const WraperModalUser = withGlobal(ModalComponent);
const NewModalWarehouseForm = Form.create()(WraperModalUser);

export default compose(withApollo)(NewModalWarehouseForm);