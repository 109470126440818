import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { navigate } from "@reach/router";
import { Icon, message } from "antd";
import axios from "axios";
import publicIp from "public-ip";
import { ApiService } from "../../services/ApiService.ts";
import { AuthService } from "../../services/AuthService.ts";
import { captureException } from "../../utils/sentry";
import chazkiClientesSvg from "assets/images/chazki_clientes.svg";
import { chazkiColor } from "utils";

// import { PerfilContainer } from "./style";
// import * as route from "../../../routes/routes";

const authService = new AuthService();
const apiService = new ApiService();

// const growingCircle = keyframes`
//   from {
//     height: 244px;
//     width: 244px;
//   }
//   to {
//     height: 2500px;
//     width: 2500px;
//   }
// `;

// const DownImg = keyframes`
//   0%   {margin-top: 0px}
//   10%  {margin-top: -20px}
//   100% {margin-top: 2000px}
// `;

// const H2white = styled.div`
//   
//   font-style: normal;
//   font-weight: bold;
//   font-size: 32px;
//   line-height: 40px;
//   color: #ffffff;
// `;

// const WhiteCircle = styled.div`
//   background-color: #ffffff;
//   border-radius: 100%;
//   width: 244px;
//   height: 244px;
//   overflow: hidden;
//   text-align: center;
//   padding-top: 8px;
//   animation: ${growingCircle} 3s 3s forwards;
// `;

// const ImgAnimation = styled.img`
//   animation-fill-mode: forwards;
//   animation: ${DownImg} 4s 2s forwards;
// `;

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      api: {},
      shouldCancel: false,
      currentCountryInfo: {},
    };
  }

  componentDidMount = async () => {
    this.getUser();
    await this.getCountryfromIP();
    this.timeoutId = await setTimeout(() => {
      this.login();
    }, 5000); // delay is in milliseconds
  };

  componentWillUnmount() {
    this.setState({ shouldCancel: true });
    clearTimeout(this.timeoutId);
  }

  getCountryfromIP = () => {
    (async () => {
      const ip = await publicIp.v4();
      // console.log(ip);
      axios
        .get(`https://geolocation-db.com/json/${ip}`)
        .then(response => {
          this.setState({ currentCountryInfo: response.data });
          return response;
        })
        .catch(err => console.log(err));
    })();
  };

  login = () => {
    authService.login();
  };

  callApi = () => {
    apiService
      .callApi()
      .then(data => {
        this.setState({ api: data.data });
        message.success(
          "Api return successfully data, check in section - Api response"
        );
      })
      .catch(error => {
        captureException(error);
        console.log("error", error).error(error);
      });
  };

  renewToken = () => {
    authService
      .renewToken()
      .then(user => {
        console.log("user", user);
        message.success("Token has been sucessfully renewed. :-)");
        this.getUser();
      })
      .catch(error => {
        captureException(error);
        console.log("error", error);
      });
  };

  logout = () => {
    authService.logout();
  };

  getUser = () => {
    authService.getUser().then(user => {
      if (user) {
        console.log("user in landing", user);
        navigate("/dashboard");
      } else {
        console.log("You are not logged in.");
      }

      if (!this.state.shouldCancel) {
        this.setState({ user });
      }
    });
  };

  getCountyFlag = () => {
    const countryCode = this.state.currentCountryInfo.country_code;
    switch (countryCode) {
      case "PE":
        return "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fflags%2Fperu-circle.png?alt=media&token=4113134b-49d8-4ca5-b971-48ac5ad45888";
      case "CL":
        return "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fflags%2Fchile-circle.png?alt=media&token=abd5690a-5bd5-43ad-88e5-a4d28f5d3222";
      case "AR":
        return "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fflags%2Fargentina-circle.png?alt=media&token=40d7fc72-9718-42ca-beb0-6c0eecd9ab28";
      case "MX":
        return "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fflags%2Fmexico-circle.png?alt=media&token=77a6dc81-2644-45d2-a82f-7ab0822ab2fb";
      case "CO":
        return "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fflags%2Fcolombia-circle.png?alt=media&token=568583b3-0188-46d8-bbc5-e3bd150b7d5e";
      default:
        return "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fflags%2Fperu-circle.png?alt=media&token=4113134b-49d8-4ca5-b971-48ac5ad45888";
    }
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "#FFF",
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img alt="Chazki Clientes" src={chazkiClientesSvg} />
        <Icon
          type="loading"
          style={{
            fontSize: 45,
            color: chazkiColor,
            marginTop: 100,
          }}
        />
      </div>
    );
  }
}

export default compose(withApollo)(Landing);
