import React, { Component } from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { Location } from "@reach/router";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import jwt from "jsonwebtoken";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { brandColor } from "../Utils";
import { GlobalContext } from "../../component-global";
// graphql
const { Content } = Layout;

const GeneralLayout = styled(Layout)`
  width: 100vw;

  & .trigger {
    font-size: 1.8rem;
    line-height: 64px;
    padding: 0 2rem;
    cursor: pointer;
    transition: color 0.3s;
    color: black;
  }

  & .trigger:hover {
    color: ${brandColor};
  }
`;

const MainLayout = styled(Layout)`
  background: #fafafa !important;

  min-height: 100vh;
  transition: all 0.3s ease-in-out;
  margin-left: ${props => (props.collapsed === "true" ? "80px" : "256px")};
  @media only screen and (max-width: 56.25em) {
    margin-left: 80px;
  }
`;

class DashboardLayout extends Component {
  state = {
    collapsed: true,
    title: null,
    openMenus: null,
    selectedMenu: null,
    showSideBar: false,
    urlGoToBack: null,
    option: null,
    kustomerUserToken: null,
  };

  componentDidMount() {
    const { authUser } = this.props;

    const kustomerUserToken = jwt.sign(
      {
        email: authUser.profile.email,
        iat: Math.floor(Date.now() / 1000),
      },
      `${process.env.REACT_APP_KUSTOMER_SECRET_KEY}`,
      {
        expiresIn: 60 * 60,
        header: {
          alg: "HS256",
          typ: "JWT",
        },
      }
    );

    window.Kustomer.start(
      {
        brandId: "6239f7fceda5ca230299941e",
        chatIconPosition: {
          alignment: "left",
          verticalPadding: 0,
          horizontalPadding: 0,
        },
      },
      () => {
        const __kustomerUserToken = jwt.sign(
          {
            email: authUser.profile.email,
            iat: Math.floor(Date.now() / 1000),
          },
          "84a510998551832d6ae646299cc0a36bb9920e2d11ade291a509a999ff8655d5",
          {
            header: {
              alg: "HS256",
              typ: "JWT",
            },
          }
        );

        window.Kustomer.login(
          {
            jwtToken: `${__kustomerUserToken}`,
          },
          (res, error) => {
            if (!error) {
              console.log("Kustomer user was authenticated!");
            } else {
              console.log("Kustomer user not authenticated!");
            }
          }
        );

        window.Kustomer.addListener("onConversationCreate", res => {
          let countryMap = "";

          switch (authUser.profile.regionCountry) {
            case "CL":
              countryMap = "Chile";
              break;
            case "PE":
              countryMap = "Perú";
              break;
            case "MX":
              countryMap = "México";
              break;
            case "CO":
              countryMap = "Colombia";
              break;
            case "AR":
              countryMap = "Argentina";
              break;
            default:
              countryMap = "NULL";
          }

          window.Kustomer.describeConversation({
            conversationId: res.conversationId,
            customAttributes: {
              paisDeContactoStr: countryMap,
            },
          });
        });
      }
    );

    this.setState({ kustomerUserToken });
  }

  toggle = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  changeMenu = (openMenus, selectedMenu) => {
    this.setState({ openMenus, selectedMenu });
  };

  changeUrlGoToBack = urlGoToBack => {
    if (urlGoToBack === "urlGoToBack") {
      this.setState({ urlGoToBack: null, urlhistory: true });
    } else {
      this.setState({ urlGoToBack, urlhistory: false });
    }
  };

  closeSideBar = () => {
    this.setState(({ showSideBar }) => ({ showSideBar: !showSideBar }));
  };

  setOption = container => {
    this.setState({ option: container });
  };

  render() {
    const {
      collapsed,
      title,
      openMenus,
      selectedMenu,
      showSideBar,
      urlGoToBack,
      option,
      urlhistory,
    } = this.state;
    const {
      children,
      authUser,
      isAllowedView,
      userEnterpriseData,
      getPermissionsView,
      showKustomerRedirect,
    } = this.props;

    return (
      <Location>
        {({ location }) => (
          <GlobalContext
            changeMenu={this.changeMenu}
            urlGoToBack={urlGoToBack}
            authUser={authUser}
            changeUrlGoToBack={this.changeUrlGoToBack}
            setOption={this.setOption}
            isAllowedView={isAllowedView}
            userEnterpriseData={userEnterpriseData}
            getPermissionsView={getPermissionsView}
          >
            <GeneralLayout>
              <Sidebar
                location={location}
                collapsed={collapsed}
                toggle={this.toggle}
                authUser={authUser}
                openMenus={openMenus}
                selectedMenu={selectedMenu}
                closeSideBar={this.closeSideBar}
                showSideBar={showSideBar}
                kustomerUserToken={this.state.kustomerUserToken}
                showKustomerRedirect={showKustomerRedirect}
              />
              <MainLayout collapsed={collapsed.toString()}>
                <Header
                  location={location}
                  authUser={authUser}
                  collapsed={collapsed}
                  toggle={this.toggle}
                  urlGoToBack={urlGoToBack}
                  title={title}
                  showSideBar={showSideBar}
                  option={option}
                  urlhistory={urlhistory}
                  client={this.props.client}
                  userEnterpriseData={userEnterpriseData}
                />
                <Content
                  style={{
                    background: "transparent",
                    marginTop: 64,
                  }}
                >
                  {children}
                </Content>
              </MainLayout>
            </GeneralLayout>
          </GlobalContext>
        )}
      </Location>
    );
  }
}

export default compose(withApollo)(DashboardLayout);
