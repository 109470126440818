import React from "react";
import { GlobalContextConsumer } from "../GlobalProvider";

function withGlobal(Component) {
  return class extends React.Component {
    render() {
      return (
        <GlobalContextConsumer>
          {value => <Component {...this.props} {...value} />}
        </GlobalContextConsumer>
      );
    }
  };
}

export default withGlobal;
