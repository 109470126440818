import React from "react";
import { Row, Col } from "antd";
import InputComponent from "../../../../../components/FormFields/InputWithBorderComponent";
import { InputComponentWithBorder } from "../../../../../components/FormFields";
import { LocationSearchInput } from "../../../../../components/Maps/LocationSearch";
import MapComponent from "../../../../../components/Maps/Maps";

class FormGeneralInformation extends React.Component {
  render() {
    const {
      branchOffice,
      branchOfficeCodePrefix,
      latLng,
      address,
      onChangeAddress,
      handleAddressSelect,
      getFieldDecorator,
      handleCodeRegion,
    } = this.props;
    return (
      <React.Fragment>
        <Row gutter={[24, 12]}>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <InputComponentWithBorder
              title="Código"
              getFieldDecorator={getFieldDecorator}
              placeholder="Ingrese un código"
              decodator="branchOfficeCode"
              value={
                branchOffice &&
                branchOffice.branchOfficeCode &&
                branchOffice.branchOfficeCode.substring(
                  branchOffice.branchOfficeCode.indexOf("-") + 1,
                  branchOffice.branchOfficeCode.length
                )
              }
              addonBefore={branchOfficeCodePrefix || " "}
              required
            />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <InputComponent
              required
              message="Campo de nombre de sucursal vacío"
              getFieldDecorator={getFieldDecorator}
              decodator="branchOfficeName"
              title="Nombre sucursal"
              placeholder="Ingresa el nombre de sucursal"
              value={branchOffice && branchOffice.branchOfficeName}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                zIndex: 100,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "12px",
                  lineHeight: "16px",
                  marginBottom: "4px",
                  zIndex: 100,
                }}
              >
                Dirección completa
              </div>
              <LocationSearchInput
                getFieldDecorator={getFieldDecorator}
                decodator="addressInput"
                required
                message="Campo de dirección vacio"
                key="addressOffice"
                address={address || ""}
                onChange={onChangeAddress}
                onAddressSelect={handleAddressSelect}
                codeRegion={handleCodeRegion}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={24} style={{ marginTop: 20 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {latLng.lat === 0 ? (
              <div
                style={{
                  backgroundColor: "#F6F8FB",
                  width: "100%",
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#BFBFBF",
                }}
              >
                No hay dirección por mostrar.
              </div>
            ) : (
              <div style={{ width: "100%", height: 350 }}>
                <MapComponent
                  polygon={[]}
                  isMarkerShown
                  zoomMap={14}
                  latLng={latLng}
                  colorPolygon="#95DE64"
                />
              </div>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default FormGeneralInformation;
