const ArrayPermissions = [
  "zelda",
  "zelda:uploadcsv",
  "zelda:preview-csv",
  "zelda:operations",
  "zelda:operations:uniquesend",
  "zelda:operations:packagetracking",
  "zelda:operations:nonExistent",
  "zelda:labelPrint",
  "zelda:Reports",
  "zelda:settings",
  "zelda:settings:enterprise",
  "zelda:settings:branchOffices",
  "zelda:usersAdmin",
  "zelda:usersAdmin:users",
  "zelda:operations:routeTracking",
];

const EntrerpriseException = [
  {
    id: 5332,
    users: [
      "farroyoc@sagafalabella.com.pe",
      "lupomachaycoc@sagafalabella.com.pe",
      "mpromerog@sagafalabella.com.pe",
    ],
    exceptions: ["zelda:uploadcsv", "zelda:operations:uniquesend"],
  },
];

export { ArrayPermissions, EntrerpriseException };
