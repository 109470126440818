import gql from "graphql-tag";

const getOrderHistorial = gql`
  query getOrderHistorial(
    $orderID: Int
    $trackCode: String
    $enterpriseID: Int
  ) {
    OrderHistorial: getOrderHistorial(
      orderID: $orderID
      trackCode: $trackCode
      enterpriseID: $enterpriseID
    ) {
      Service {
        name
      }
      id
      trackCode
      pickUpAddress
      dropAddress
      dropContactName
      pickUpContactName
      dropContactPhone
      Enterprise {
        comercialName
      }
      Activities {
        id
        Type {
          subclass
          subtype
        }
        addressPoint {
          coordinates
        }
      }
      Status {
        subtype
        type
        class
        subclass
      }
      OrderService {
        id
        promiseTime
        startTime
        endTime
        deleted
        OrderServiceHistorial {
          createdAt
          id
          motive
          deleted
          Status {
            subtype
            type
            class
            subclass
          }
          Incidence {
            name
          }
        }
        Routes {
          id
          routeCode
          AffiliateVehicles {
            id
            vehiclePlate
            CategoryVehicle {
              subtype
              type
              class
              subclass
            }
          }
          Affiliates {
            userID
            User {
              names
              lastname
              phone
            }
            LocationAffiliate {
              locationPoint {
                coordinates
              }
            }
          }
        }
      }
    }
  }
`;

const getEvidenceImages = gql`
  query getEvidenceImages($orderID: Int, $orderServiceID: Int) {
    EvidenceImages: getEvidenceImages(
      orderID: $orderID
      orderServiceID: $orderServiceID
    ) {
      id
      orderID
      filename
      url
    }
  }
`;

const getAccuseImages = gql`
  query getOrder($orderID: Int) {
    AccuseImage: getOrder(orderID: $orderID) {
      urlFileAcuse
    }
  }
`;

const getCommentariesOrder = gql`
  query getCommentariesOrder($orderID: Int, $orderServiceID: Int) {
    CommentariesOrder: getCommentariesOrder(
      orderID: $orderID
      orderServiceID: $orderServiceID
    ) {
      id
      orderServiceID
      commentary
      userID
      createdAt
      updatedAt
      UserCreated {
        names
        lastname
        picture
      }
      OrderService {
        orderID
        id
      }
      Order {
        id
        trackCode
      }
      TypeUserCommentary {
        subtype
        subclass
      }
    }
  }
`;


export { getEvidenceImages, getAccuseImages, getOrderHistorial,getCommentariesOrder };
