import React from "react";
import { compose, withProps } from "recompose";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  withGoogleMap,
  Polyline,
  // withScriptjs, con este incrustas el googleURL pero como ya esta incrustado alv
  Polygon,
} from "react-google-maps";
import { defaultMapOptions } from "./static";
// https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2Fempty.png?alt=media&token=da051563-ce18-4c8c-b9ae-eac569eadc7d
// const { google } = window;
/* const IconPick = {
  url:
    "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
  size: new google.maps.Size(20, 32),
  scaledSize: new google.maps.Size(25, 30),
  labelOrigin: new google.maps.Point(12, 10),
}; */

const MapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCAHe6y1MxSEcRPFsrgwzoxelOIWZH36w0&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  // withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={props.zoomMap || 12}
    defaultCenter={props.latLng}
    defaultOptions={defaultMapOptions}
    center={props.latLng}
  >
    {props.polyline && (
      <Polyline
        path={props.polyline}
        geodesic
        options={{
          strokeColor: "#40A9FF",
          strokeOpacity: 0.5,
          strokeWeight: 4,
        }}
        onClick={() => {
          console.log("ahmet");
        }}
      />
    )}

    {props.isMarkerShown && (
      <Marker
        draggable
        onDragEnd={props.markerDrag}
        icon={props.icon || null}
        position={props.latLng}
      />
    )}
    {props.markers &&
      props.markers.length > 0 &&
      props.markers.map((x, idx) => (
        <Marker
          key={idx.toString()}
          position={x.position}
          icon={x.icon}
          title={x.title}
          label={x.label}
          onClick={() => props.onMarkerSelect(x.key)}
        />
      ))}
    {props.directions && (
      <DirectionsRenderer
        directions={props.directions}
        options={{
          polylineOptions: {
            strokeColor: "#40A9FF",
            strokeOpacity: 0.5,
            strokeWeight: 4,
          },
          // icon: IconPick,

          /* markerOptions: {
            label: {
              // text: "label 02112",
              fontWeight: "bold",
              fontSize: "12px",
              color: "white",
            },
          }, */
          suppressMarkers: true,
          // routeIndex: 5,
          // markerOptions: { icon: IconPick },
          // icon: { scale: 1 },
        }}
      />
    )}
    {props.polygon && (
      <Polygon
        path={props.polygon}
        key={1}
        options={{
          fillColor: props.colorPolygon ? props.colorPolygon : "#F62A67",
          fillOpacity: 0.4,
          strokeColor: props.colorPolygon ? props.colorPolygon : "#F62A67",
          strokeOpacity: 1,
          strokeWeight: 1,
        }}
        onClick={() => {
          console.log("ahmet");
        }}
      />
    )}
  </GoogleMap>
));

export default MapComponent;
