import React from "react";
import styled from "styled-components";

const GlobalWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export default props => {
  const { children } = props;
  return <GlobalWrapper>{children}</GlobalWrapper>;
};
