import gql from "graphql-tag";

const getAllServices = gql`
  query {
    getAllServices(orderBy: { name: ASC }) {
      id
      name
    }
  }
`;

const getCities = gql`
  query {
    getCities: getCitiesByToken {
      id
      name
    }
  }
`;

const getBranchOfficeByEnterpriseID = gql`
  query getBranchOfficeByEnterpriseID($id: Int!) {
    getBranchOfficeByEnterpriseID(enterpriseID: $id) {
      id
      branchOfficeCode
      branchOfficeName
      City {
        id
        name
      }
    }
  }
`;

const getStatusOrder = gql`
  query {
    getStatusOrder: searchGeneralTypesBy(type: 42, orderBy: { subclass: ASC }) {
      subtype
      subclass
    }
  }
`;

const getTrackingPackage = gql`
  query getTrackingPackage(
    $enterpriseID: Int!
    $offset: Int
    $limit: Int
    $rangeBy: ZoneDateRangeInput
    $serviceID: Int
    $cityID: Int
    $branchOfficeID: Int
    $statusID: Int
    $trackCode: String
  ) {
    getTrackingPackage(
      offset: $offset
      limit: $limit
      enterpriseID: $enterpriseID
      trackCode: $trackCode
      rangeBy: $rangeBy
      serviceID: $serviceID
      cityID: $cityID
      branchOfficeID: $branchOfficeID
      statusID: $statusID
    ) {
      count
      rows {
        id
        trackCode
        packageQuantity
        pickUpAddress
        dropAddress
        dropContactName
        dropContactPhone
        City {
          id
          name
        }
        Service {
          id
          name
        }
        Status {
          subtype
          subclass
        }
        PickUpBranch {
          id
          branchOfficeName
          branchOfficeCode
        }
        OrderService {
          id
          OrderServiceHistorial {
            id
            Incidence {
              id
              name
            }
            createdAt
          }
          Routes {
            id
            routeCode
            Affiliates {
              userID
              User {
                id
                names
                lastname
              }
            }
          }
        }
      }
    }
  }
`;

const getEvidenceImages = gql`
  query getEvidenceImages($orderID: Int, $orderServiceID: Int) {
    EvidenceImages: getEvidenceImages(
      orderID: $orderID
      orderServiceID: $orderServiceID
    ) {
      id
      orderID
      filename
      url
    }
  }
`;

const getAccuseImages = gql`
  query getAccuseImages($orderID: Int, $orderServiceID: Int) {
    AccuseImages: getAccuseImages(
      orderID: $orderID
      orderServiceID: $orderServiceID
    ) {
      id
      orderID
      filename
      url
    }
  }
`;

export {
  getAllServices,
  getCities,
  getBranchOfficeByEnterpriseID,
  getStatusOrder,
  getTrackingPackage,
  getEvidenceImages,
  getAccuseImages,
};
