import gql from "graphql-tag";

const getBranchOfficeByID = gql`
  query getBranchOfficeByID($id: Int) {
    BranchOfficeByID: getBranchOfficeByID(id: $id) {
      id
      enterpriseID
      branchOfficeCode
      branchOfficeName
      branchOfficeAddress
      branchOfficeAddressPoint {
        type
        coordinates
      }
      serviceIDs
      atentionDays
      atentionHours {
        startHour
        endHour
      }
      packageSizes
      contactPeople {
        name
        area
        email
        phone
        currentContact
      }
    }
  }
`;

const getBranchOffices = gql`
  query getBranchOffices(
    $initDate: Date
    $endDate: Date
    $enterpriseID: Int!
    $branchOfficeName: String
    $branchOfficeID: Int
    $offset: Int
    $limit: Int
  ) {
    AllBranchOffices: getBranchOffices(
      initDate: $initDate
      endDate: $endDate
      enterpriseID: $enterpriseID
      branchOfficeName: $branchOfficeName
      branchOfficeID: $branchOfficeID
      offset: $offset
      limit: $limit
    ) {
      count
      rows {
        id
        contactPeople {
          phone
          name
          currentContact
        }
        branchOfficeCode
        branchOfficeName
        branchOfficeAddress
        Enterprise {
          id
          address
          businessName
          comercialName
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export { getBranchOfficeByID, getBranchOffices };
