import React, { Fragment } from "react";
import { Input, Icon } from "antd";
import PlacesAutocomplete from "react-places-autocomplete";
import { CustomFormItemInputWithBorder } from "../Form";

class InputComponent extends React.Component {
  state = {
    address: this.props.address,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.address !== this.props.address) {
      this.setState({ address: nextProps.address });
    }
  }

  handleAddressChange = address => {
    this.setState({ address }, () => this.props.onChange(address));
  };

  render() {
    const { address } = this.state;
    const { google } = window;
    const searchOptions = {
      location: new google.maps.LatLng(-34, 151),
      radius: 100000,
      types: ["address"],
      componentRestrictions: {
        country:
          (this.props.codeRegion && this.props.codeRegion.toLowerCase()) ||
          "PE",
      },
    };

    const {
      title,
      disabled,
      placeholder,
      suffix,
      addonAfter,
      allowClear,
      onChange,
      onPressEnter,
      refProp,
      size,
      onClick,
      validateStatus,
      help,
      prefix,
      onAddressSelect,
    } = this.props;

    return (
      <div>
        <PlacesAutocomplete
          onChange={this.handleAddressChange}
          onSelect={onAddressSelect}
          value={address}
          searchOptions={searchOptions}
          onError={() => {}}
        >
          {({
            getInputProps,
            getSuggestionItemProps,
            suggestions,
            loading,
          }) => {
            return (
              <Fragment key="1">
                <CustomFormItemInputWithBorder
                  labelCol={{
                    xs: { span: 24 },
                    sm: { span: 24 },
                    lg: { span: 24 },
                    xl: { span: 24 },
                  }}
                  wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 24 },
                    lg: { span: 24 },
                    xl: { span: 24 },
                  }}
                  label={title}
                  validateStatus={validateStatus}
                  help={help}
                  disabled={disabled}
                >
                  <Input
                    prefix={
                      prefix && (
                        <Icon
                          type={prefix}
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      )
                    }
                    onClick={onClick}
                    size={size || "large"}
                    ref={refProp}
                    onPressEnter={onPressEnter}
                    onChange={onChange}
                    allowClear={allowClear}
                    disabled={disabled}
                    placeholder={placeholder}
                    suffix={suffix}
                    addonAfter={addonAfter}
                    {...getInputProps({
                      id: "address-input",
                      className: "location-search-input",
                    })}
                  />
                </CustomFormItemInputWithBorder>
                <div className="autocomplete-dropdown-container">
                  {loading ? <div>Loading...</div> : null}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    const style = suggestion.active
                      ? {
                          backgroundColor: "#1890ff",
                          cursor: "pointer",
                          padding: "0.5rem 0.5rem",
                          color: "white",
                        }
                      : {
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                          padding: "0.5rem 0.5rem",
                        };

                    const spread = {
                      ...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      }),
                    };

                    return (
                      <div {...spread} key={suggestion.index}>
                        <div>{suggestion.description}</div>
                      </div>
                    );
                  })}
                </div>
              </Fragment>
            );
          }}
        </PlacesAutocomplete>
      </div>
    );
  }
}

export default InputComponent;
