import React from "react";
import { Modal, Button, Col } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { CSVLink } from "react-csv";
import { CustomRow } from "../../../components/Grid";

class ModalFiled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      visible,
      handleOk,
      handleCancel,
      title,
      filename,
      loadingBtnProcess,
      numFailed,
      numSuccess,
      total,
      errorsView,
      shapedData,
    } = this.props;

    return (
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {" "}
        <div style={{ padding: "29px 24px 24px" }}>
          <p
            style={{
              
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "32px",
              textAlign: "center",
              color: "#595959",
              marginBottom: 16,
            }}
          >
            El CSV contiene errores
          </p>
          <p
            style={{
              
              fontSize: "14px",
              lineHeight: "20px",
              textAlign: "center",
              color: "#8C8C8C",
              marginBottom: 24,
            }}
          >
            {`Detectamos los siguientes estados en ${filename}.`}
          </p>

          <div
            style={{
              width: "100%",
              background: "#F5F5F5",
              borderRadius: "4px",
              height: "32px",
              
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              padding: "6px",
              marginBottom: "16px",
              textAlign: "center",
            }}
          >
            <span
              style={{ color: "#CF1322", margin: "0 2px" }}
            >{`TC-ERRORES: ${numFailed || 0} |`}</span>
            <span style={{ margin: "0 2px" }}>{` TC-CORRECTOS: ${numSuccess ||
              0} |`}</span>
            <span style={{ margin: "0 2px" }}>{` TC-TOTAL: ${total ||
              0}`}</span>
          </div>

          <div
            style={{
              maxHeight: "292px",
              overflowY: "auto",
              marginBottom: "26px",
            }}
          >
            {errorsView}
          </div>

          <CustomRow gutter={24} type="flex" justify="space-around">
            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
              <CSVLink
                data={shapedData}
                filename="error.csv"
                separator=","
                target="_blank"
              >
                <Button
                  type="primary"
                  ghost
                  style={{
                    width: "100%",
                    
                    fontWeight: "500",
                    fontSize: "14px",
                    height: "40px",
                  }}
                  // onClick={handleCancel}
                >
                  Descargar CSV
                </Button>
              </CSVLink>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
              <Button
                type="primary"
                style={{
                  width: "100%",
                  
                  fontWeight: "500",
                  fontSize: "14px",
                  height: "40px",
                }}
                onClick={handleOk}
                loading={loadingBtnProcess}
              >
                Procesar orden
              </Button>
            </Col>
          </CustomRow>
        </div>
      </Modal>
    );
  }
}

export default compose(withApollo)(ModalFiled);
