import styled from "styled-components";

interface ChartContainerProps {
  height: number;
}

export const ChartContainer = styled.div<ChartContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  border: 1px solid #edebe9;
  box-sizing: border-box;
  padding: 16px;

  & > div > canvas {
    height: 100% !important;
    max-height: 500px;
    width: auto !important;
  }
`;
