import { Icon } from "antd";
import styled from "styled-components";

const CustomIcon = styled(Icon)`
  width: 0;
  & svg {
    color: ${props => (props.invisible ? "transparent" : "currentColor")};
  }
`;

const CircleIcon = styled.div`
  text-align: center;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  display: inline-block;
  margin: 0.5rem;
  background-color: ${props =>
    props.color ? props.color : props.active ? "green" : "red"};
  font-size: 1rem;
  color: white;
  padding-top: 0.5rem;
`;

const DivIcon = styled.div``;

const DeleteIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    color: red !important;
    font-size: 2rem;
  }
`;

const AddIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    color: #1b91fe !important;
    font-size: 2rem;
  }
`;

const FavouriteIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    color: #1b91fe !important;
    font-size: 2rem;
  }
`;

const NotFavouriteIcon = styled(Icon)`
  cursor: pointer;
  & svg {
    color: #818e9b !important;
    font-size: 2rem;
  }
`;

export {
  CustomIcon,
  CircleIcon,
  DeleteIcon,
  DivIcon,
  AddIcon,
  FavouriteIcon,
  NotFavouriteIcon,
};
