import React from "react";
import { compose, withProps } from "recompose";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
} from "react-google-maps";
import { defaultMapOptions } from "./static";

const IconPin = {
  url:
    "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/iconsMap%2FpickUpIcon.png?alt=media&token=ac0d41f9-4620-47f5-8c35-19cba0ec99b7",
  scaledSize: { height: 30, width: 25 },
};

const MapsOnePoint = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCAHe6y1MxSEcRPFsrgwzoxelOIWZH36w0&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={props.zoomMap ? props.zoomMap : 30}
    defaultCenter={props.latLng}
    center={props.latLng}
    defaultOptions={{
      ...defaultMapOptions,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
    }}
  >
    {props.isMarkerShown && props.latLng && (
      <Marker
        draggable
        onDragEnd={props.markerDrag}
        position={props.latLng}
        icon={IconPin}
      />
    )}
  </GoogleMap>
));

export default MapsOnePoint;
