import React from "react";
import { Icon } from "antd";
import { navigate } from "@reach/router";
import TableList from "../../../components/Table/tableList";
import { editAccount } from "../../../routes/routes";
import { CustomEmpty } from "../../../components/DataDisplay";

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "names",
        key: "names",
        render: (text, record) => `${record.names} ${record.lastname}`,
      },
      {
        title: "Correo",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "País",
        dataIndex: "Country.name",
        key: "Country.name",
      },
      {
        title: "Rol",
        dataIndex: "Role.name",
        key: "Role.name",
      },
      {
        title: "Estado",
        dataIndex: "Status.subclass",
        key: "Status.subclass",
      },
      {
        title: "Fecha de creación",
        dataIndex: "createdAt",
        key: "createdAt",
      },
      {
        title: "Editar",
        dataIndex: "edit",
        key: "edit",
        render: (text, record) => {
          return (
            <Icon
              type="edit"
              style={{ color: "rgb(48, 127, 226)" }}
              onClick={() => {
                navigate(`/dashboard/${editAccount}/${record.id}`);
              }}
            />
          );
        },
      },
    ];

    return (
      <div>
        {this.props.data.length ? (
          <TableList
            data={this.props.data}
            rowSelection={this.props.rowSelection}
            columns={columns}
            loading={this.props.loading}
            // pagination={pagination}
            currentPage={this.props.currentPage}
            pageSize={this.props.pageSize}
            totalData={this.props.totalOrders}
            onChange={this.props.onChange}
            heightcustom="calc(100vh - 530px)"
            scroll={{ y: "calc(100vh - 530px)" }}
          />
        ) : (
          <div
            style={{
              height: "calc(100vh - 500px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomEmpty
              loading={this.props.loading}
              description="No hemos encontrado información disponible."
            ></CustomEmpty>
          </div>
        )}
      </div>
    );
  }
}

export default Body;
