import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Icon,
  Row,
  notification,
} from "antd";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { navigate } from "@reach/router";
import { withGlobal } from "../../../../component-global";
import { withGlobalContext } from "../../../../utils/globalContext";
import TitleGoBack from "../../../../components/Container/titleGoBack";
import { Container } from "../../../../components/Container";
import BodyContainer from "../../../../components/Layout/BodyContainer";
import Loading from "../../../../components/Utils/Loading";

import { CustomCollapse, CustomExpandIcon } from "./style";
import { branches } from "../../../../routes/routes";
import {
  createBranchOffice,
  updateBranchOffice,
  deleteBranchOffice,
} from "../../../../graphQl/mutations/branches";
import { getBranchOfficeByID } from "../../../../graphQl/queries/branches";
import { getAllPackageSizes } from "../../../../graphQl/queries/packageSizes";
import { getServicesActive } from "../../../../graphQl/queries/services";
import { hourFormat, daysEncode, daysDecodeFormat } from "./components/static";
import FormGeneralInformation from "./components/FormGeneralInformation";
import FormSchedulingInformation from "./components/FormSchedulingInformation";
import FormContactInformation from "./components/FormContactInformation";

const { Panel } = Collapse;

class BranchOffice extends React.Component {
  constructor(props) {
    super(props);
    const attentionId = Math.random()
      .toString(36)
      .substring(2, 15);
    this.state = {
      addressBranchOffice: null,
      latLngBranchOffice: { lat: 0, lng: 0 },
      BranchOfficeByID: null,
      branchOfficeCodePrefix: null,
      loadingOnSave: false,
      loadingDelete: false,
      currentContact: null,
      contacts: [
        {
          name: "",
          area: "",
          email: "",
          phone: "",
          id: Math.random()
            .toString(36)
            .substring(2, 15),
          currentContact: false,
        },
      ],
      atentionDays: [
        {
          days: null,
          id: attentionId,
        },
      ],
      atentionHours: [
        {
          startHour: undefined,
          endHour: undefined,
          id: attentionId,
        },
      ],
      packageSizesSelected: [],
      sizeSelected: [],
      daySelected: [],
      PackageSizes: [],
      AllServicesActive: [],
    };
  }

  componentDidMount = async () => {
    await this.getDataPackages();
    await this.getDataAllServicesActive();
    if (this.props.branchID) {
      await this.getDataEnterprise();
      const { BranchOfficeByID } = this.state;
      this.setDataToForm(BranchOfficeByID);
    } else {
      const { contacts } = this.state;
      this.setDataToFormN(contacts);
    }
  };

  getDataAllServicesActive = async () => {
    const {
      data: { AllServicesActive },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getServicesActive,
    });
    this.setState({
      AllServicesActive: AllServicesActive && AllServicesActive.rows,
    });
  };

  getDataPackages = async () => {
    const {
      data: { PackageSizes },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getAllPackageSizes,
    });
    this.setState({
      PackageSizes,
    });
  };

  getDataEnterprise = async () => {
    const {
      data: { BranchOfficeByID },
    } = await this.props.client.query({
      fetchPolicy: "network-only",
      query: getBranchOfficeByID,
      variables: {
        id: parseInt(this.props.branchID, 10),
      },
    });

    const lngLat = { lat: 0, lng: 0 };

    if (
      BranchOfficeByID &&
      BranchOfficeByID.branchOfficeAddressPoint &&
      BranchOfficeByID.branchOfficeAddressPoint.coordinates
    ) {
      const lat = BranchOfficeByID.branchOfficeAddressPoint.coordinates[1];
      const lng = BranchOfficeByID.branchOfficeAddressPoint.coordinates[0];
      lngLat.lat = lat;
      lngLat.lng = lng;
    }

    this.setState({
      BranchOfficeByID,
      branchOfficeCodePrefix:
        BranchOfficeByID &&
        BranchOfficeByID.branchOfficeCode &&
        BranchOfficeByID.branchOfficeCode.substring(
          0,
          BranchOfficeByID.branchOfficeCode.indexOf("-") + 1
        ),
      contacts: this.sortDefaultContact(BranchOfficeByID.contactPeople),
      latLngBranchOffice: lngLat,
      addressBranchOffice: BranchOfficeByID.branchOfficeAddress,
    });
  };

  setDataToFormN = data => {
    const contacts = data.map(x => {
      return {
        name: x.name,
        area: x.area,
        email: x.email[0],
        phone: x.phone[0],
        id: Math.random()
          .toString(36)
          .substring(2, 15),
        currentContact: x.currentContact,
      };
    });

    const currentContact =
      data &&
      data.contactPeople &&
      data.contactPeople.findIndex(x => x.currentContact === true);

    this.setState({
      contacts,
      currentContact,
    });
  };

  setDataToForm = currentBranchOffice => {
    const contactsSorted = this.sortDefaultContact(
      currentBranchOffice.contactPeople
    );

    const contacts = contactsSorted.map(x => {
      return {
        name: x.name,
        area: x.area,
        email: x.email[0],
        phone: x.phone[0],
        id: Math.random()
          .toString(36)
          .substring(2, 15),
        currentContact: x.currentContact,
      };
    });

    const attentionIds =
      currentBranchOffice &&
      currentBranchOffice.atentionHours.map(x => {
        return {
          id: Math.random()
            .toString(36)
            .substring(2, 15),
        };
      });

    const atentionDays =
      currentBranchOffice &&
      currentBranchOffice.atentionDays &&
      daysDecodeFormat(currentBranchOffice.atentionDays).map((x, index) => {
        return {
          days: x,
          id: attentionIds[index].id,
        };
      });

    const atentionHours =
      currentBranchOffice &&
      currentBranchOffice.atentionHours
        .map((x, index) => {
          if (index < atentionDays.length) {
            return {
              startHour: x.startHour,
              endHour: x.endHour,
              id: attentionIds[index].id,
            };
          }
          return undefined;
        })
        .filter(x => x !== undefined);

    const packageSizesSelected =
      (currentBranchOffice && currentBranchOffice.packageSizes) || [];

    const currentContact =
      currentBranchOffice &&
      currentBranchOffice.contactPeople &&
      contactsSorted.findIndex(x => x.currentContact === true);

    this.setState({
      sizeSelected: (currentBranchOffice.packageSizes || []).map(x =>
        x.toString()
      ),
      contacts,
      atentionDays,
      atentionHours,
      currentContact,
      packageSizesSelected,
    });
  };

  selectDay = code => {
    this.setState(({ daySelected }) => ({
      daySelected:
        daySelected.findIndex(x => x.code === code) > -1
          ? daySelected.filter(x => x.code !== code)
          : daySelected.concat([{ code, active: true }]),
    }));
  };

  viewDay = code => {
    this.setState(({ daySelected }) => ({
      daySelected: daySelected.map(x => ({ ...x, active: x.code === code })),
    }));
  };

  setAttentionDaysSelected = atentionDays => {
    this.setState({ atentionDays });
  };

  onChangeHours = (type, hour) => {
    if (type === "star") {
      this.setState(({ daySelected }) => ({
        daySelected: daySelected.map(x => {
          if (x.active) {
            return { ...x, starHour: hour };
          }
          return { ...x };
        }),
      }));
    } else {
      this.setState(({ daySelected }) => ({
        daySelected: daySelected.map(x => {
          if (x.active) {
            return { ...x, endHour: hour };
          }
          return { ...x };
        }),
      }));
    }
  };

  addAttention = () => {
    this.setState(({ atentionHours, atentionDays }) => {
      const tmpAtentionId = Math.random()
        .toString(36)
        .substring(2, 15);
      return {
        atentionHours: atentionHours.concat([
          {
            fromTime: null,
            toTime: null,
            id: tmpAtentionId,
          },
        ]),
        atentionDays: atentionDays.concat([
          {
            days: null,
            id: tmpAtentionId,
          },
        ]),
      };
    });
  };

  deleteAttention = id => {
    this.setState(({ atentionHours, atentionDays }) => ({
      atentionHours: atentionHours.filter(x => x.id !== id),
      atentionDays: atentionDays.filter(x => x.id !== id),
    }));
  };

  selectSize = code => {
    this.setState(({ sizeSelected }) => ({
      sizeSelected:
        sizeSelected.findIndex(x => x === code) > -1
          ? sizeSelected.filter(x => x !== code)
          : sizeSelected.concat([code]),
    }));
  };

  setPackageSizesSelected = packageSizesSelected => {
    this.setState({ packageSizesSelected });
  };

  addContact = async () => {
    this.setState(({ contacts }) => ({
      contacts: contacts.concat([
        {
          name: null,
          area: null,
          email: null,
          phone: null,
          currentContact: null,
          id: Math.random()
            .toString(36)
            .substring(2, 15),
        },
      ]),
    }));
  };

  sortDefaultContact = contactsUnsorted => {
    const contactsUnsortedTmp = [...contactsUnsorted];
    const contactDefaultIndex = contactsUnsortedTmp.findIndex(
      x => x.currentContact
    );
    const contactsUnsortedWithoutDefault = contactsUnsortedTmp.splice(
      contactDefaultIndex,
      1
    );

    return [contactsUnsortedWithoutDefault[0], ...contactsUnsortedTmp];
  };

  deleteContact = id => {
    const contactOldLength = this.state.contacts.length;
    const contactWillDeleteIndex = this.state.contacts.findIndex(
      x => x.id === id
    );
    const currentContactIndex = this.state.currentContact;

    this.setState(({ contacts }) => ({
      contacts: contacts.filter(x => x.id !== id),
      currentContact:
        currentContactIndex === contactWillDeleteIndex
          ? currentContactIndex % (contactOldLength - 1)
          : currentContactIndex > contactWillDeleteIndex
          ? currentContactIndex - 1
          : currentContactIndex,
    }));
  };

  onChangeDefaultContact = e => {
    this.setState({
      currentContact: e.target.value,
    });
  };

  onChangeAddress = addressBranchOffice => {
    this.setState({ addressBranchOffice });
  };

  onCreateBranch = async filter => {
    this.setState({ loadingOnSave: true });
    const variables = {
      data: {
        ...filter,
        enterpriseID: this.props.userEnterpriseData.enterpriseID,
        cityID: this.props.userEnterpriseData.Enterprise.cityID,
      },
    };
    await this.props.client
      .mutate({
        mutation: createBranchOffice,
        update: (cache, { data: { CreateBranchOffice } }) => {
          console.log(CreateBranchOffice);
          notification.success({
            message: `¡Sucursal creada con exito!`,
          });
          this.setState({ loadingOnSave: false });
          navigate(`/dashboard/${branches}`);
        },
        variables,
      })
      .catch(err => {
        console.log(err);
        notification.error({
          message: "No se pudo guardar, error en el sistema.",
        });
        this.setState({ loadingOnSave: false });
      });
  };

  onSaveBranch = async filter => {
    this.setState({ loadingOnSave: true });
    const variables = {
      id: parseInt(this.props.branchID, 10),
      data: {
        ...filter,
        enterpriseID: this.props.userEnterpriseData.enterpriseID,
        cityID: this.props.userEnterpriseData.Enterprise.cityID,
      },
    };

    await this.props.client
      .mutate({
        mutation: updateBranchOffice,
        update: (cache, { data: { UpdateBranchOffice } }) => {
          console.log("UpdateBranchOffice", UpdateBranchOffice);
          notification.success({
            message: `¡Sucursal actualizada con exito!`,
          });
          this.setState({ loadingOnSave: false });
        },
        variables,
      })
      .catch(err => {
        console.error(err);
        notification.error({
          message: "Error en el sistema. No se pudo guardar.",
        });
        this.setState({ loadingOnSave: false });
      });
  };

  onDeleteBranch = () => {
    this.setState({ loadingDelete: true });
    const branchByID = this.state.BranchOfficeByID.id;
    const enterpriseIDBranch = this.state.BranchOfficeByID.enterpriseID;
    if (this.state.BranchOfficeByID && branchByID) {
      const variables = {
        branchIDs: [parseInt(branchByID, 10)],
        enterpriseID: enterpriseIDBranch,
      };

      this.props.client
        .mutate({
          mutation: deleteBranchOffice,
          update: (cache, { data: { DeleteBranchOffice } }) => {
            console.log("DeleteBranchOffice", DeleteBranchOffice);
            notification.success({
              message: "¡Sucursal eliminada con éxito!",
            });
            this.setState({ loadingDelete: false });
            navigate(`/dashboard/branches`);
          },
          variables,
        })
        .catch(err => {
          this.setState({ loadingDelete: false });
          console.error(err);
          notification.error({
            message: "Error en el sistema. No se pudo guardar.",
          });
        });
    }
  };

  handleAddressSelect = addressBranchOffice => {
    this.setState({ addressBranchOffice });

    geocodeByAddress(addressBranchOffice)
      .then(async results => {
        return getLatLng(results[0]);
      })
      .then(latLng => {
        this.setState({ latLngBranchOffice: latLng });
      })
      .catch(error => {
        console.error("Error", error);
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (
        this.state.currentContact === null ||
        this.state.currentContact === undefined
      ) {
        notification.error({
          message: "¡Contacto predeterminado es requerido!",
        });
        return;
      }

      if (
        !err &&
        this.state.currentContact !== null &&
        this.state.currentContact !== undefined
      ) {
        const filter = {};
        const { contacts, atentionDays, atentionHours } = this.state;
        if (
          values.branchOfficeCode &&
          this.state.addressBranchOffice &&
          values.branchOfficeName
        ) {
          if (values.branchOfficeName) {
            filter.branchOfficeName = values.branchOfficeName;
          }
          if (this.state.addressBranchOffice) {
            filter.branchOfficeAddress = this.state.addressBranchOffice;
          }
          if (values.branchOfficeCode) {
            filter.branchOfficeCode = `${
              this.state.branchOfficeCodePrefix
                ? this.state.branchOfficeCodePrefix
                : ""
            }${values.branchOfficeCode}`;
          }
          if (values.branchOfficeName) {
            filter.branchOfficeName = values.branchOfficeName;
          }
          if (
            this.state.latLngBranchOffice.lat ||
            this.state.latLngBranchOffice.lng
          ) {
            filter.branchOfficeAddressPoint = {
              type: "Point",
              coordinates: [
                this.state.latLngBranchOffice.lng,
                this.state.latLngBranchOffice.lat,
              ],
            };
          }

          const serviceIDs = values.services.map(x => parseInt(x, 10));

          const atentionDaysParser = [];
          for (let i = 0; i < atentionDays.length; i++) {
            atentionDaysParser.push(
              JSON.stringify(
                values[`atentionDays-${atentionDays[i].id}`].map(
                  currDaysSel => daysEncode[currDaysSel]
                )
              )
            );
          }

          const atentionHoursParser = [];
          for (let i = 0; i < atentionDays.length; i++) {
            atentionHoursParser.push({
              startHour: values[`startHour-${atentionHours[i].id}`].format(
                hourFormat
              ),
              endHour: values[`endHour-${atentionHours[i].id}`].format(
                hourFormat
              ),
            });
          }

          const contactPeople = [];
          for (let i = 0; i < contacts.length; i++) {
            if (
              values[`names-${contacts[i].id}`] &&
              values[`area-${contacts[i].id}`] && [
                values[`email-${contacts[i].id}`],
              ] && [values[`phone-${contacts[i].id}`]]
            )
              contactPeople.push({
                name: values[`names-${contacts[i].id}`],
                area: values[`area-${contacts[i].id}`],
                email: [values[`email-${contacts[i].id}`]],
                phone: [values[`phone-${contacts[i].id}`]],
                currentContact: i === this.state.currentContact,
              });
          }

          const { packageSizes } = values;
          if (serviceIDs.length) {
            filter.serviceIDs = serviceIDs;
          }

          if (atentionDaysParser.length) {
            filter.atentionDays = atentionDaysParser;
          }

          if (atentionHoursParser.length) {
            filter.atentionHours = atentionHoursParser;
          }

          if (packageSizes.length) {
            filter.packageSizes = packageSizes;
          }

          if (contactPeople.length) {
            filter.contactPeople = contactPeople;

            if (this.props.branchID) {
              this.onSaveBranch(filter);
            } else {
              this.onCreateBranch(filter);
            }
          } else {
            notification.error({
              message: "Complete los datos de por lo menos un contacto.",
            });
          }
        } else {
          notification.error({
            message: "Completar los datos generales de la sucursal.",
          });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      BranchOfficeByID,
      AllServicesActive,
      PackageSizes,
      contacts,
      currentContact,
    } = this.state;

    return (
      <Container>
        <TitleGoBack title="Datos de sucursal" />
        <Form onSubmit={this.handleSubmit}>
          <BodyContainer gutter={16} justify="center" height="auto">
            <CustomCollapse
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CustomExpandIcon>
                  <span style={{ textDecoration: "none" }}>
                    {isActive ? "Ocultar" : "Ver detalle"}
                  </span>
                  <Icon type="up" rotate={isActive ? 0 : 180} />
                </CustomExpandIcon>
              )}
              expandIconPosition="right"
            >
              <Panel header="Datos generales" key="1" forceRender>
                <FormGeneralInformation
                  branchOffice={BranchOfficeByID}
                  branchOfficeCodePrefix={this.state.branchOfficeCodePrefix}
                  latLng={this.state.latLngBranchOffice}
                  address={this.state.addressBranchOffice || ""}
                  onChangeAddress={this.onChangeAddress}
                  handleAddressSelect={this.handleAddressSelect}
                  getFieldDecorator={getFieldDecorator}
                  handleCodeRegion={this.props.authUser.profile.regionCountry}
                />
              </Panel>
              <Panel header="Datos de sucursal" key="2" forceRender>
                {AllServicesActive.length ? (
                  <FormSchedulingInformation
                    branchOffice={BranchOfficeByID}
                    PackageSizes={PackageSizes}
                    packageSizesSelected={this.state.packageSizesSelected}
                    AllServicesActive={AllServicesActive}
                    changeHours={this.onChangeHours}
                    sizeSelected={this.selectSize}
                    getFieldDecorator={getFieldDecorator}
                    attentionDays={this.state.atentionDays}
                    attentionHours={this.state.atentionHours}
                    addAttention={this.addAttention}
                    deleteAttention={this.deleteAttention}
                  />
                ) : (
                  <Loading />
                )}
              </Panel>
              <Panel header="Datos del contacto" key="3" forceRender>
                <FormContactInformation
                  contacts={contacts}
                  currentContact={currentContact}
                  getFieldDecorator={getFieldDecorator}
                  addContact={this.addContact}
                  deleteContact={this.deleteContact}
                  onChangeDefaultContact={this.onChangeDefaultContact}
                />
              </Panel>
            </CustomCollapse>
            <Divider style={{ marginTop: "30px", marginBottom: "0px" }} />
            <Row
              gutter={24}
              type="flex"
              justify="end"
              style={{ flexDirection: "row" }}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 9 }}
                md={{ span: 6 }}
                xl={{ span: 4 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexContent: "flex-end",
                }}
              >
                <Button
                  htmlType="button"
                  type="secondary"
                  style={{
                    marginTop: "20px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "20px",
                    borderColor: "#F5222D",
                    color: "#F5222D",
                    height: "40px",
                  }}
                  loading={this.state.loadingDelete}
                  onClick={() => this.onDeleteBranch()}
                >
                  Eliminar Sucursal
                </Button>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 9 }}
                md={{ span: 6 }}
                xl={{ span: 4 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexContent: "flex-end",
                }}
              >
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  style={{
                    marginTop: 20,
                    fontSize: "14px",
                    background: "#307fe2",
                    borderColor: "#307fe2",
                    color: "#fff",
                  }}
                  loading={this.state.loadingOnSave}
                >
                  Guardar cambios
                </Button>
              </Col>
            </Row>
          </BodyContainer>
        </Form>
      </Container>
    );
  }
}

const WrapperBranchOffice = withGlobal(BranchOffice);
const FormNewBranch = Form.create()(WrapperBranchOffice);

export default compose(
  withApollo,
  withGlobalContext
)(FormNewBranch);
