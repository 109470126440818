import styled from "styled-components";
import { cardInfoBlueBackground, cardInfoRedBackground } from "assets";
import { chazkiColor, redColor } from "utils";

interface VariantProps {
  color: "blue" | "red";
}

export const CardInfoContainer = styled.div`
  background-color: #fff;
  padding: 8px;
  border: 1px solid #edebe9;
`;

export const CardInfoContent = styled.div<VariantProps>`
  background-image: ${props =>
    props.color === "blue"
      ? `url(${cardInfoBlueBackground})`
      : `url(${cardInfoRedBackground})`};
  display: grid;
  grid-template-rows: auto 1fr;
  background-size: cover;
  background-position: left bottom;
  background-origin: content-box;
  background-clip: content-box;
  width: 100%;
  height: 100%;
`;

export const CardInfoTitle = styled.div`
  font-size: 16px;
`;

export const CardInfoNumber = styled.div<VariantProps>`
  font-size: 50px;
  color: ${props => (props.color === "blue" ? chazkiColor : redColor)};
  align-self: center;
  justify-self: center;

  @media (max-width: 1400px) and (min-width: 992px) {
    font-size: 45px;
  }

  @media (max-width: 992px) and (min-width: 768px) {
    font-size: 42px;
  }

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;
