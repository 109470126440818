import React from "react";
import { Form, message } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { navigate } from "@reach/router";
import { Container } from "../../components/Container";
import { withGlobal } from "../../component-global";
import { undeleteOriginalOrder } from "../../graphQl/mutations/uploadCsv";
import { getBranchOfficeByEnterpriseID } from "../../graphQl/queries/uploadCsv";
import { captureException } from "../../utils/sentry";

import ComponetUpload from "./components/ComponetUpload";
// import { PerfilContainer } from "./style";
// import * as route from "../../../routes/routes";

class Uploadcsv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingBtnProcess: false,
      allCitiesBranch: [],
    };
    // props.isAllowedView("uploadcsv");
    // props.changeTitle("Carga de CSV");
    props.changeMenu(["operations"], ["uploadcsv"]);
    props.changeUrlGoToBack(null);
    props.setOption(true);
  }

  componentDidMount = async () => {
    this.setState({ loadingBtnProcess: true });
    try {
      if (
        this.props.userEnterpriseData &&
        this.props.userEnterpriseData.enterpriseID
      ) {
        const {
          data: { AllBranchOffices },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getBranchOfficeByEnterpriseID,
          variables: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
          },
        });

        // separado de los datos de las sucursales en array de datos
        const { allCitiesBranch } = this.state;
        const citiesDetailsTemp = [
          ...new Set(
            AllBranchOffices.map(
              data => `${data.City.id}--|--${data.City.name}`
            )
          ),
        ];
        citiesDetailsTemp.forEach(cities => {
          const valCortado = cities.split("--|--");
          allCitiesBranch.push({
            id: valCortado[0],
            name: valCortado[1],
          });
        });

        this.setState({
          allCitiesBranch,
          loadingBtnProcess: false,
        });
      }
    } catch (err) {
      message.error("No se pudo cargar los datos de la empresa");
      this.setState({ loadingBtnProcess: false });
      captureException(err);
      console.log(err);
    }
  };

  onClickProcess = (enterpriseID, ids) => {
    this.setState({ loadingBtnProcess: true });
    this.props.client
      .mutate({
        mutation: undeleteOriginalOrder,
        update: (cache, { data: { UndeleteOriginalOrder } }) => {
          console.log("UndeleteOriginalOrder", UndeleteOriginalOrder);
          navigate(`/dashboard/preview-csv`);

          this.setState({ loadingBtnProcess: false });
        },
        variables: {
          ids,
        },
      })
      .catch(error => {
        captureException(error);
        message.error("No se pudo guardar, error en el sistema.");
        this.setState({ loadingBtnProcess: false });
      });
  };

  render() {
    const { authUser, userEnterpriseData } = this.props;
    const { loadingBtnProcess, allCitiesBranch } = this.state;
    return (
      <Container>
        <ComponetUpload
          loadingBtnProcess={loadingBtnProcess}
          onClickProcess={this.onClickProcess}
          userID={authUser && authUser.profile && authUser.profile.sub}
          enterpriseData={userEnterpriseData && userEnterpriseData.Enterprise}
          allCitiesBranch={allCitiesBranch}
          countryID={authUser && authUser.profile && authUser.profile.countryID}
        />
      </Container>
    );
  }
}

const WraperUserProfile = withGlobal(Uploadcsv);
const UserProfile = Form.create()(WraperUserProfile);

export default compose(withApollo)(UserProfile);
