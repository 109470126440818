import React from "react";
import { Paper } from "../../../../components/Container";
import { Regular, Resalted } from ".";
import { getCurrencyFormatter } from "../../../../utils";

const PriceInfo = props => {
  const { route, profile } = props;
  const currencyFormatter = getCurrencyFormatter(profile.currencyCountry);

  return (
    <Paper margin="16px 0 0 0">
      <span
        style={{
          fontWeight: "bold",
          fontSize: 16,
        }}
      >
        Detalle de precio
      </span>
      <div style={{ display: "flex", marginTop: 16, marginBottom: 12 }}>
        <Regular>Precio de envío:</Regular>
        <Resalted style={{ marginLeft: "auto" }}>
          {route && route.priceEnterpriseOriginal
            ? currencyFormatter.format(route.priceEnterpriseOriginal)
            : "-"}
        </Resalted>
      </div>
      {/* <div style={{ display: "flex", marginBottom: 12 }}>
        <Regular>Ajustes:</Regular>
        <Resalted style={{ marginLeft: "auto" }}>
          {currencyFormatter.format(0)}
        </Resalted>
      </div>
      <div style={{ display: "flex", marginBottom: 12 }}>
        <Regular>Tiempo de espera:</Regular>
        <Resalted style={{ marginLeft: "auto" }}>
          {currencyFormatter.format(0)}
        </Resalted>
      </div>
      <div style={{ display: "flex", marginBottom: 12 }}>
        <Regular>Estacionamiento:</Regular>
        <Resalted style={{ marginLeft: "auto" }}>
          {currencyFormatter.format(0)}
        </Resalted>
      </div> */}
      <div style={{ display: "flex" }}>
        <Regular>Total:</Regular>
        <Resalted style={{ marginLeft: "auto" }}>
          {route && route.priceEnterpriseOriginal
            ? currencyFormatter.format(route.priceEnterpriseOriginal)
            : "-"}
        </Resalted>
      </div>
    </Paper>
  );
};

export default PriceInfo;
