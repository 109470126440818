import React from "react";
import { compose } from "recompose";
import styled from "styled-components";
import { Modal } from "antd";
import { withApollo } from "react-apollo";
import { withGlobal } from "../../component-global";

const CustomModal = styled(Modal)`
  top: 150;

  display: flex;
  justify-content: center;
  text-align: center;
  /* height: 436; */

  && .ant-modal-body {
    padding: 0px;
  }
`;

class GenericModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isVisible, onCancel, title, style, content } = this.props;

    return (
      <CustomModal
        centered
        footer={null}
        onCancel={onCancel}
        visible={isVisible}
        width={384}
      >
        <div
          style={{
            width: "384px",
            // height: "248px",
            padding: "48px",
            display: "flex",
            flexDirection: "column",
            ...style,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#595959",
              marginBottom: "16px",
              textAlign: "center",
            }}
          >
            {title}
          </div>
          <div
            style={{
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "24px",
              color: "#8C8C8C",
            }}
          >
            {content}
          </div>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            
          </div> */}
        </div>
      </CustomModal>
    );
  }
}

const WraperConfirmAction = withGlobal(GenericModal);

export default compose(withApollo)(WraperConfirmAction);
