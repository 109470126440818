import gql from "graphql-tag";

const getServices = gql`
  query searchServicesBy(
    $name: String
    $typeID: Int
    $minutes: Float
    $statusID: Int
    $packageSizeIDs: [Int!]
    $offset: Int
    $limit: Int
    $orderBy: ServiceOrderInput
    $rangeBy: ServiceDateRangeInput
    $enterprise: Int
    $enterpriseDocumentNumber: String
    $cityID: Int
  ) {
    AllServices: searchServicesByViewService(
      name: $name
      typeID: $typeID
      minutes: $minutes
      statusID: $statusID
      packageSizeIDs: $packageSizeIDs
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      rangeBy: $rangeBy
      enterprise: $enterprise
      enterpriseDocumentNumber: $enterpriseDocumentNumber
      cityID: $cityID
    ) {
      count
      rows {
        id
        Type {
          subtype
          subclass
        }
        name
        minutes
        typeID
        Type {
          subtype
          subclass
        }
        statusID
        Status {
          subtype
          subclass
        }
        branchName
        packageSizeIDs
        listDaysAttention
        listSizePackage
        attentionSchedule {
          attentionDays
          startHour
          endHour
        }
        PackageSizes {
          id
          name
          dimX
          dimY
          dimZ
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const getEnterprisePlatformsByIDEnterprise = gql`
  query getEnterprisePlatformsByIDEnterprise($id: ID!) {
    GetEnterprisePlatformsByIDEnterprise: getEnterprisePlatformsByIDEnterprise(id: $id) {
        id
        namePlatform
        idPlatform
        token
        refreshToken
        services
        extra{
            code
        }
        deleted
        branchDefaultPlatform
    }
  }
`;

const getEnterprisePlatformsByName = gql`
  query getEnterprisePlatformsByName($id: ID! $name: String) {
    GetEnterprisePlatformsByName: getEnterprisePlatformsByName(id: $id name: $name) {
        id
        namePlatform
        idPlatform
        token
        refreshToken
        services
        extra{
            code
        }
        deleted
        branchDefaultPlatform
        enabled
        manualPrice
        automaticApproval
        localShipping
    }
  }
`;

const getServicesNames = gql`
  query searchServicesBy {
    AllServicesName: searchServicesByViewService {
      rows {
        id
        name
      }
    }
  }
`;

const getCountServicesBy = gql`
  query getCountServicesBy(
    $name: String
    $typeID: Int
    $millisecondsNumber: Float
    $statusID: Int
    $packageSizeIDs: [Int]
    $rangeBy: ServiceDateRangeInput
    $enterprise: Int
    $enterpriseDocumentNumber: String
  ) {
    CountServicesBy: getCountServicesBy(
      name: $name
      typeID: $typeID
      millisecondsNumber: $millisecondsNumber
      statusID: $statusID
      packageSizeIDs: $packageSizeIDs
      rangeBy: $rangeBy
      enterprise: $enterprise
      enterpriseDocumentNumber: $enterpriseDocumentNumber
    )
  }
`;

const getServicesByZones = gql`
  query getServicesByZones(
    $name: String
    $typeID: Int
    $minutes: Float
    $statusID: Int
    $packageSizeIDs: [Int!]
    $offset: Int
    $limit: Int
    $orderBy: ServiceOrderInput
    $rangeBy: ServiceDateRangeInput
  ) {
    ServicesByZones: searchServicesByViewService(
      name: $name
      typeID: $typeID
      minutes: $minutes
      statusID: $statusID
      packageSizeIDs: $packageSizeIDs
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      rangeBy: $rangeBy
    ) {
      rows {
        id
        name
        statusID
        Status {
          subclass
        }
        typeID
        Type {
          subclass
        }
        BaseTariffs {
          code
          zone
          Zone {
            id
            name
          }
          basePrice
          kilometerPrice
        }
      }
    }
  }
`;

const getServicesByZonesById = gql`
  query getServiceByID($id: ID!) {
    ServicesByZonesById: getServiceByID(id: $id) {
      id
      name
      statusID
      Status {
        subclass
      }
      typeID
      Type {
        subclass
      }
      BaseTariffs {
        code
        zone
        Zone {
          id
          name
        }
        basePrice
        kilometerPrice
      }
    }
  }
`;

const getServicesActive = gql`
  query searchServicesBy {
    AllServicesActive: searchServicesByViewService(statusID: 19) {
      rows {
        id
        name
      }
    }
  }
`;

const getAllPackageSizes = gql`
  query getAllPackageSizes {
    AllPackageSizes: getAllPackageSizes {
      id
      name
      dimX
      dimY
      dimZ
      dimensions
      maxWeight
    }
  }
`;

const getServiceByID = gql`
  query getServiceByID($id: ID!) {
    ServiceByID: getServiceByID(id: $id) {
      id
      typeID
      statusID
      name
      minutes
      intents
      extraChargeValue
      attentionSchedule {
        startHour
        endHour
        attentionDays
      }
      minimalDistance
      packageSizeIDs
    }
  }
`;

const getStatusService = gql`
  query {
    statusServicios: searchGeneralTypesBy(type: 7) {
      subtype
      subclass
    }
  }
`;

export {
  getServices,
  getServiceByID,
  getCountServicesBy,
  getServicesNames,
  getServicesByZones,
  getServicesByZonesById,
  getServicesActive,
  getAllPackageSizes,
  getStatusService,
  getEnterprisePlatformsByIDEnterprise,
  getEnterprisePlatformsByName,
};
