import styled from "styled-components";

const ClearFix = styled.div`
  width: 100%;
  height: 100%;

  & .avatar-uploader > .ant-upload {
    width: 100%;
    height: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    border: 1px dashed #756969;
    background-color: #eceef0;
  }
`;

const PreviewImage = styled.div`
  height: 100%;
  position: relative;
  border-radius: 50%;
  margin: 0;

  & img {
    width: 100%;
    height: 100%;
  }

  & .imgDiv {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .img-uploaded {
  }
`;

const ContainerCamera = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #307fe2;
  cursor: pointer;
  margin-left: 20px;

  & i {
    color: #000000;
  }
`;

export { ClearFix, PreviewImage, ContainerCamera };
