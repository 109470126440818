import gql from "graphql-tag";

const getOrdersNoLocated = gql`
  query getOrdersNoLocated(
    $enterpriseID: Int
    $trackCode: String
    $rangeDate: OrdersDateRangeInput
    $cityID: Int
    $limit: Int
    $offset: Int
  ) {
    getOrdersNoLocated(
      enterpriseID: $enterpriseID
      trackCode: $trackCode
      limit: $limit
      offset: $offset
      rangeDate: $rangeDate
      cityID: $cityID
    ) {
      count
      rows {
        id
        enterpriseID
        trackCode
        City {
          id
          name
        }
        Status {
          subtype
          subclass
        }
        createdAt
      }
    }
  }
`;

const getBranchOfficeByEnterpriseID = gql`
  query getBranchOfficeByEnterpriseID($enterpriseID: Int!) {
    getBranchOfficeByEnterpriseID(enterpriseID: $enterpriseID) {
      id
      branchOfficeName
      branchOfficeAddress
      branchOfficeAddressPoint {
        type
        coordinates
      }
      City {
        id
        name
      }
    }
  }
`;

const getCitiesOfBranchOfficeByEnterpriseID = gql`
  query getCitiesOfBranchOfficeByEnterpriseID($enterpriseID: Int!) {
    getCitiesOfBranchOfficeByEnterpriseID(enterpriseID: $enterpriseID) {
      id
      name
    }
  }
`;

const getAllPaymentMethod = gql`
  query {
    getAllPaymentMethod: getGeneralTypesByType(type: 43) {
      subtype
      subclass
    }
  }
`;

const getAllPaymentProof = gql`
  query {
    getAllPaymentProof: getGeneralTypesByType(type: 44) {
      subtype
      subclass
    }
  }
`;

const getAllServices = gql`
  query {
    getAllServices(orderBy: { name: ASC }) {
      id
      name
    }
  }
`;

export {
  getCitiesOfBranchOfficeByEnterpriseID,
  getBranchOfficeByEnterpriseID,
  getOrdersNoLocated,
  getAllServices,
  getAllPaymentMethod,
  getAllPaymentProof,
};
