import React from "react";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { Button, Icon, message, Empty, Row, notification } from "antd";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import moment from "moment";
import { withGlobal } from "../../../component-global";
import { Container, TitleGoBack } from "../../../components/Container";
import TableList from "../../../components/Table/tableList";
import { captureException } from "../../../utils/sentry";
import { CustomFilterContainer } from "../../../components/Form/SearchContainer";

import Header from "./components/Header";
import DrawerEdit from "./components/DrawerEdit";
import DrawerRefund from "./components/DrawerRefund";
import ModalCSV from "./components/ModalCSV";
import ModalMap from "./components/ModalMap";

import {
  getCitiesOfBranchOfficeByEnterpriseID,
  getBranchOfficeByEnterpriseID,
  getAllPaymentMethod,
  getOrdersNoLocated,
  getAllPaymentProof,
  getAllServices,
} from "../../../graphQl/queries/nonExistent";
import { deleteOrdersNoLocated } from "../../../graphQl/mutations/nonExistent";
import { emptyImg } from "./components/Util";

class NonExistent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOptionsPaymentMethod: [],
      dataOptionsPaymentProof: [],
      dataOptionsService: [],
      dataOptionsCities: [],
      dataOptionsBranch: [],
      loadingBtnSearch: false,
      loadingData: false,
      loadingTable: false,
      loadingDelete: false,
      pagination: { limit: 10, page: 0 },
      currentFilter: {},
      dataResult: null,
      totalData: 0,
      selectedAllRows: false,
      selectedRowKeys: [],
      selectRowData: [],
      modalMap: false,
      modalCSV: false,
      drawerEdit: false,
      drawerRefund: false,

      address: "",
      coordinate: {
        lat: this.props.authUser.profile.cityCoords[1],
        lng: this.props.authUser.profile.cityCoords[0],
      },
      validCoordinate: true,

      addressType: 1,
      addressPick: null,
      addressDrop: null,
      selectOrder: null,
    };
  }

  componentDidMount = async () => {
    this.setState({ loadingData: true });
    try {
      if (
        this.props.userEnterpriseData &&
        this.props.userEnterpriseData.enterpriseID
      ) {
        this.searchTrackcode({});

        const {
          data: { getCitiesOfBranchOfficeByEnterpriseID: dataOptionsCities },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getCitiesOfBranchOfficeByEnterpriseID,
          variables: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
          },
        });

        const {
          data: { getAllServices: dataOptionsService },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getAllServices,
          variables: {},
        });

        const {
          data: { getAllPaymentMethod: dataOptionsPaymentMethod },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getAllPaymentMethod,
          variables: {},
        });

        const {
          data: { getAllPaymentProof: dataOptionsPaymentProof },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getAllPaymentProof,
          variables: {},
        });

        const {
          data: { getBranchOfficeByEnterpriseID: dataOptionsBranch },
        } = await this.props.client.query({
          fetchPolicy: "network-only",
          query: getBranchOfficeByEnterpriseID,
          variables: {
            enterpriseID: this.props.userEnterpriseData.enterpriseID,
          },
        });

        this.setState({
          dataOptionsPaymentMethod,
          dataOptionsPaymentProof,
          dataOptionsService,
          dataOptionsCities,
          dataOptionsBranch,
          loadingData: false,
        });
      }
    } catch (err) {
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos.");
      captureException(err);
    }
  };

  openDrawerEdit = order => {
    this.setState({ drawerEdit: true, selectOrder: order });
  };

  closeDrawerEdit = () => {
    this.setState({
      drawerEdit: false,
      addressPick: null,
      addressDrop: null,
    });
  };

  openDrawerRefund = order => {
    this.setState({ drawerRefund: true, selectOrder: order });
  };

  closeDrawerRefund = () => {
    this.setState({ drawerRefund: false });
  };

  openMapLocation = type => {
    this.setState({ modalMap: true, addressType: type });
    if (type === 1 && this.state.addressPick)
      this.setState({
        address: this.state.addressPick.address,
        coordinate: this.state.addressPick.coordinate,
      });
    else if (type === 2 && this.state.addressDrop)
      this.setState({
        address: this.state.addressDrop.address,
        coordinate: this.state.addressDrop.coordinate,
      });
  };

  closeMapLocation = () => {
    this.setState({ modalMap: false });
  };

  openModalCSV = () => {
    this.setState({ modalCSV: true });
  };

  closeModalCSV = () => {
    this.setState({ modalCSV: false });
  };

  searchTrackcode = async filters => {
    this.setState({
      currentFilter: filters,
      loadingTable: true,
    });
    const { pagination } = this.state;
    try {
      const {
        data: { getOrdersNoLocated: dataTable },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getOrdersNoLocated,
        variables: {
          ...filters,
          limit: pagination.limit,
          offset: 0,
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
        },
      });

      this.setState({
        loadingTable: false,
        dataResult: dataTable.rows,
        totalData: dataTable.count,
        pagination: {
          limit: pagination.limit,
          page: 0,
        },
        selectedAllRows: false,
        selectedRowKeys: [],
        selectRowData: [],
      });
    } catch (err) {
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos.");
    }
  };

  onChangePagination = async (page, pageSize) => {
    this.setState({
      loadingTable: true,
    });
    const { pagination } = this.state;
    try {
      const {
        data: { getOrdersNoLocated: dataTable },
      } = await this.props.client.query({
        fetchPolicy: "network-only",
        query: getOrdersNoLocated,
        variables: {
          ...this.state.currentFilter,
          limit: pagination.limit,
          offset: (page - 1) * pagination.limit,
          enterpriseID: this.props.userEnterpriseData.enterpriseID,
        },
      });

      this.setState({
        loadingTable: false,
        dataResult: dataTable.rows,
        totalData: dataTable.count,
        pagination: {
          limit: pageSize,
          page: page - 1,
        },
      });
    } catch (err) {
      this.setState({ loadingTable: false });
      message.error("Error en la carga de datos.");
    }
  };

  onSelectionRow = (selectedRowKeys, selectData) => {
    this.setState({
      selectedRowKeys,
      selectRowData: selectData,
    });
  };

  onDeleteTrackcode = () => {
    const { selectRowData, selectedAllRows, currentFilter } = this.state;
    if (selectRowData.length > 0 || selectedAllRows) {
      this.setState({ loadingDelete: true });
      this.props.client
        .mutate({
          mutation: deleteOrdersNoLocated,
          update: (cache, { data: { deleteOrdersNoLocated: res } }) => {
            if (res.statusCode === 200) {
              notification.success({ message: res.message });
              this.setState({
                loadingDelete: false,
                selectedAllRows: false,
                selectedRowKeys: [],
                selectRowData: [],
              });
              this.searchTrackcode({});
            } else {
              notification.error({ message: res.message });
              this.setState({ loadingDelete: false });
            }
          },
          variables: {
            ...(!selectedAllRows && {
              ordersNLID: selectRowData.map(tc => parseInt(tc.id, 10)),
            }),
            ...(selectedAllRows && { ...currentFilter }),
          },
        })
        .catch(error => {
          captureException(error);
          message.error("No se pudo guardar, error en el sistema.");
          this.setState({ loadingDelete: false });
        });
    }
  };

  onChangeAddress = address => {
    this.setState({ address });
  };

  onSelectAddress = address => {
    this.setState({ address });

    geocodeByAddress(address)
      .then(async results => {
        return getLatLng(results[0]);
      })
      .then(latLng => {
        this.setState({ coordinate: latLng, validCoordinate: true });
      })
      .catch(error => {
        console.log(error);
      });
  };

  onDragAddress = coordinate => {
    const { latLng } = coordinate;
    const lat = latLng.lat();
    const lng = latLng.lng();

    this.setState({ coordinate: { lat, lng }, validCoordinate: true });
  };

  onChangeCoordinate = event => {
    const coordinateText = event.target.value;
    const regex = /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/;
    if (regex.test(coordinateText)) {
      const coordinate = coordinateText.split(",");
      const lat = parseFloat(coordinate[0].trim());
      const lng = parseFloat(coordinate[1].trim());
      this.setState({ coordinate: { lat, lng }, validCoordinate: true });
    } else {
      this.setState({ validCoordinate: false });
    }
  };

  onConfirmModal = () => {
    const { address, coordinate } = this.state;
    if (this.state.addressType === 1)
      this.setState({ addressPick: { address, coordinate } });
    else this.setState({ addressDrop: { address, coordinate } });

    this.setState({
      modalMap: false,
      address: "",
      coordinate: null,
    });
  };

  render() {
    const {
      dataOptionsPaymentMethod,
      dataOptionsPaymentProof,
      dataOptionsService,
      dataOptionsCities,
      dataOptionsBranch,
      loadingBtnSearch,
      loadingData,
      loadingTable,
      loadingDelete,
      dataResult,
      totalData,
      pagination,
      selectOrder,
      selectedRowKeys,
      selectRowData,
      selectedAllRows,
      drawerRefund,
      drawerEdit,
      address,
      coordinate,
      validCoordinate,
      modalMap,
      modalCSV,
      addressPick,
      addressDrop,
    } = this.state;

    const { authUser, userEnterpriseData } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectionRow,
      onSelectAll: selected => {
        this.setState({ selectedAllRows: selected });
      },
    };

    const columns = [
      {
        title: "Fecha",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) => (
          <span>{moment(record.createdAt).format("DD/MM/YYYY")}</span>
        ),
      },
      {
        title: "TrackCode",
        dataIndex: "trackCode",
        key: "trackCode",
        render: (text, record) => <span>{record.trackCode}</span>,
      },
      {
        title: "Ciudad",
        dataIndex: "city",
        key: "city",
        render: (text, record) => (
          <span>{record.City && record.City.name}</span>
        ),
      },
      {
        title: "Estado",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <span>{record.Status && record.Status.subclass}</span>
        ),
      },
      {
        title: "Editar",
        dataIndex: "edit",
        key: "edit",
        render: (text, record) => (
          <div style={{ textAlign: "center" }}>
            <Icon
              onClick={() => this.openDrawerEdit(record)}
              style={{ fontSize: "15px", color: "#307FE2" }}
              type="edit"
            />
          </div>
        ),
      },
      {
        title: "Devolver",
        dataIndex: "refund",
        key: "refund",
        render: (text, record) => (
          <div style={{ textAlign: "center" }}>
            <Icon
              onClick={() => this.openDrawerRefund(record)}
              style={{ fontSize: "15px", color: "#307FE2" }}
              type="rollback"
            />
          </div>
        ),
      },
    ];

    return (
      <Container>
        <TitleGoBack title="TrackCodes inexistentes" />
        <CustomFilterContainer
          style={{ padding: "24px ", marginBottom: "0px" }}
        >
          <Header
            cityOptions={dataOptionsCities}
            loadingBtnSearch={loadingBtnSearch}
            loadingData={loadingData}
            searchTrackcode={this.searchTrackcode}
            openModalCSV={this.openModalCSV}
          />

          {dataResult && dataResult.length > 0 ? (
            <div>
              <TableList
                size="middle"
                rowSelection={rowSelection}
                data={dataResult}
                columns={columns}
                loading={loadingTable || loadingBtnSearch}
                pageSize={pagination.limit}
                totalData={totalData}
                currentPage={pagination.page + 1}
                onChange={this.onChangePagination}
                heightcustom="calc(100vh - 490px)"
                scroll={{ y: "calc(100vh - 490px)" }}
              />
              <Row gutter={24} type="flex" justify="end">
                <Button
                  size="large"
                  type="primary"
                  ghost
                  style={{ marginRight: 10 }}
                  disabled={!selectedRowKeys.length}
                  onClick={this.openDrawerRefund}
                >
                  Solicitar devolución
                </Button>
                <Button
                  size="large"
                  type="danger"
                  onClick={this.onDeleteTrackcode}
                  disabled={!selectedRowKeys.length}
                  loading={loadingDelete}
                >
                  Eliminar
                </Button>
              </Row>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "calc(100vh - 350px)",
                padding: "0 8px 0 0",
              }}
            >
              <Empty
                image={emptyImg}
                imageStyle={{
                  height: 136,
                }}
                description={null}
              >
                <span
                  style={{
                    color: "#8C8C8C",
                    
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                >
                  {loadingTable || loadingData
                    ? "Cargando..."
                    : "No hemos encontrado información disponible."}
                </span>
              </Empty>
            </div>
          )}
        </CustomFilterContainer>

        <DrawerEdit
          order={selectOrder}
          onClose={this.closeDrawerEdit}
          visible={drawerEdit}
          title="TrackCode con datos incompletos"
          nameButton="Validar y procesar TC"
          cityOptions={dataOptionsCities}
          paymentMethodOptions={dataOptionsPaymentMethod}
          paymentProofOptions={dataOptionsPaymentProof}
          serviceOptions={dataOptionsService}
          refreshList={this.searchTrackcode}
          openMapLocation={this.openMapLocation}
          addressPick={addressPick}
          addressDrop={addressDrop}
        />

        <DrawerRefund
          order={selectOrder}
          orderIDs={selectedRowKeys}
          onClose={this.closeDrawerRefund}
          visible={drawerRefund}
          title="Completar datos pendientes para devolución"
          nameButton="Validar y procesar TC"
          cityOptions={dataOptionsCities}
          branchOptions={dataOptionsBranch}
          refreshList={this.searchTrackcode}
        />

        <ModalCSV
          onClose={this.closeModalCSV}
          visible={modalCSV}
          selectedRowKeys={selectedRowKeys}
          selectRowData={selectRowData}
          selectedAllRows={selectedAllRows}
          refreshList={this.searchTrackcode}
          userID={authUser && authUser.profile && authUser.profile.sub}
          enterpriseID={userEnterpriseData && userEnterpriseData.enterpriseID}
          orderIDs={selectedRowKeys}
          filter={this.state.currentFilter}
        />

        <ModalMap
          title="Localizar Dirección"
          subtitle="Ingresa la dirección correcta o utiliza el mapa para localizar la dirección"
          visible={modalMap}
          coordinate={coordinate}
          onChangeCoordinate={this.onChangeCoordinate}
          isValidCoordinate={validCoordinate}
          onCancel={this.closeMapLocation}
          onConfirm={this.onConfirmModal}
          onChangeAddress={this.onChangeAddress}
          onSelectAddress={this.onSelectAddress}
          onDragAddress={this.onDragAddress}
          address={address}
        />
      </Container>
    );
  }
}

const WraperNonExistent = withGlobal(NonExistent);

export default compose(withApollo)(WraperNonExistent);
