import styled from "styled-components";

const TextBox = styled.div`
  background-color: white;
  width: 250px;
  padding: 10px;
  position: absolute;
  bottom: 24px;
  left: 10px;
  z-index: 99;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15);
`;

const TitleBox = styled.div`
  
  font-weight: bold;
  font-size: 20px;
`;
const DescriptionBox = styled.div`
  
  font-size: 14px;
`;

export { TextBox, TitleBox, DescriptionBox };
