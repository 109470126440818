import React from 'react';
import { Avatar, List } from 'antd';
import classNames from 'classnames';
import { BsFillXCircleFill } from "react-icons/bs";
export default function NoticeList({
  data = [],
  onClick,
  onClear,
  title,
  locale,
  emptyText,
  emptyImage,
  showClear = true,
}) {
  if (data.length === 0) {
    return (
      <div >
        {emptyImage ? <img src={emptyImage} alt="not found" /> : null}
        <div>{emptyText || locale.emptyText}</div>
      </div>
    );
  }
  return (
    <div>
      <List >
        {data.map((item, i) => {
          const itemCls = classNames('', {
            ['']: item.read,
          });
          // eslint-disable-next-line no-nested-ternary
          const leftIcon = item.avatar ? (
            typeof item.avatar === 'string' ? (
              <Avatar  src={item.avatar} />
            ) : (
              item.avatar
            )
          ) : null;

          return (
            <List.Item className={itemCls} key={item.key || i} onClick={() => onClick(item)}>
              <List.Item.Meta
                avatar={<span >{leftIcon}</span>}
                title={
                  <div  style={{cursor:'pointer'}}>
                    <BsFillXCircleFill 
                    style={{color:"#F5222D", 
                            fontSize:"20px",
                            border:'none',
                            }}/> 
                    &nbsp; 
                    {item.title}
                    
                  </div>
                }
              />
            </List.Item>
          );
        })}
      </List>
      {showClear ? (
        <div onClick={onClear} style={{color:"#C7C7C7"}} >    
          Corrígelas para que puedan ser procesadas
        </div>
      ) : null}
    </div>
  );
}
