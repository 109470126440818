import React from "react";
import { Modal, Button, Col } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo";
import { CustomRow } from "../../../components/Grid";

class ModalSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      visible,
      handleOk,
      handleCancel,
      title,
      filename,
      loadingBtnProcess,
    } = this.props;

    return (
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {" "}
        <div style={{ textAlign: "center", padding: "29px 24px 24px" }}>
          <img
            alt="img"
            src="https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FCheck.png?alt=media&token=4d2d9116-a2b8-4e9a-b562-73619fd8c9a3"
            width="70px"
            height="70px"
            style={{ marginBottom: 21 }}
          />

          <p
            style={{
              
              fontWeight: "bold",
              fontSize: "24px",
              lineHeight: "32px",
              textAlign: "center",
              color: "#595959",
              marginBottom: 16,
            }}
          >
            El CSV se cargó con éxito
          </p>
          <p
            style={{
              
              fontSize: "14px",
              lineHeight: "20px",
              textAlign: "center",
              color: "#8C8C8C",
              marginBottom: 24,
            }}
          >
            {`El ${filename} no presenta errores en las orden.`}
          </p>
          <CustomRow gutter={24} type="flex" justify="space-around">
            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
              <Button
                type="primary"
                ghost
                style={{
                  width: "100%",
                  
                  fontWeight: "500",
                  fontSize: "14px",
                  height: "40px",
                }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
              <Button
                type="primary"
                style={{
                  width: "100%",
                  
                  fontWeight: "500",
                  fontSize: "14px",
                  height: "40px",
                }}
                onClick={handleOk}
                loading={loadingBtnProcess}
              >
                Procesar orden
              </Button>
            </Col>
          </CustomRow>
        </div>
      </Modal>
    );
  }
}

export default compose(withApollo)(ModalSuccess);
