import React, { FC } from "react";
import { Typography } from "antd";

import { CardInfo } from "..";
import { CardsContainer } from "./styles";
import { DataElement } from "types";

const { Title } = Typography;

interface GeneralIndicatorsProps {
  title: string;
  data: DataElement[];
}

const GeneralIndicators: FC<GeneralIndicatorsProps> = props => {
  const { title, data } = props;

  return (
    <div style={{ marginTop: 16 }}>
      <Title level={3} style={{ color: "#595959" }}>
        {title}
        
      </Title>

      <CardsContainer>
        {data.map(e => (
          <CardInfo
            key={e.id}
            title={e.name}
            quantity={e.quantity}
            info={e.info}
            titleInfo={e.titleInfo}
            color={e.id === 3 ? "red" : "blue"}
          />
          
        ))}   
      </CardsContainer>
      
    </div>
  );
};

export default GeneralIndicators;
