import React from "react";
import { Button, notification, Row, Col } from "antd";
import { compose } from "recompose";
import { withApollo } from "react-apollo"; // graphql
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import TableList from "../../../components/Table/tableList";
import { withFirebase } from "../../../firebase";
import DrawerComponent from "./DrawerComponent";

const ContainerSpan = styled.span`
  
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  color: #307fe2;
  cursor: pointer;
`;

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingActions: false,
    };
  }

  selectDelivery = delivery => {
    this.setState({
      selectedDelvery: delivery,
      visibleDrawer: true,
    });
  };

  onClose = () => {
    this.setState({ visibleDrawer: false, selectedDelvery: null });
  };

  onSelectChange = (selectedRowKeys, selectData) => {
    this.props.updateSelectedRows(selectedRowKeys, selectData);
  };

  printPdf = () => {
    const { currentFilter, dataRowSelected, dataSource } = this.props;
    if (dataRowSelected && dataRowSelected.length > 0) {
      this.setState({ loadingPrint: true });
      const dataToken = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH_PROVIDER}:${process.env.REACT_APP_AUTH_CLIENT_ID}`
        )
      );
      const token =
        dataToken &&
        dataToken.access_token &&
        dataToken.token_type &&
        `${dataToken.token_type} ${dataToken.access_token}`;
      // const { filter } = this.props;
      const config = {
        headers: {
          Authorization: token || "",
          "Content-Type": "application/json",
        },
      };

      let dataAxios = null;
      if (dataRowSelected.length < dataSource.length) {
        dataAxios = {
          filters: {
            enterpriseID: currentFilter.enterpriseID,
            plataform: "Zelda",
          },
          orderIDs: dataRowSelected.map(x => parseInt(x.id, 10)),
        };
      } else {
        dataAxios = {
          filters: {
            ...currentFilter,
            plataform: "Zelda",
          },
        };
      }

      axios
        .post(
          `${process.env.REACT_APP_CFUNCTION_DOMAIN}/createPDFByOrders`,
          dataAxios,
          config
        )
        .then(res => {
          if (res.data.success) {
            this.setState({ loadingPrint: false });
            // console.log(res.data.file);
            window.open(res.data.file, "_blank");
          }
          notification.success({
            message: `El Reporte se esta descargando`,
          });
        })
        .catch(error => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.message
          )
            notification.error({
              message: error.response.data.errors.message,
            });
          else
            notification.error({
              message: "Error en el sistema.",
            });
          this.setState({ loadingPrint: false });

          console.log(error);
        });
    } else {
      notification.error({
        message: `Selecciona una orden.`,
      });
    }
  };

  render() {
    const {
      loadingActions,
      loadingPrint,
      visibleDrawer,
      selectedDelvery,
    } = this.state;
    const {
      loading,
      dataSource,
      selectedRowKeys,
      pagination,
      totalOrders,
      onChangePagination,
    } = this.props;

    const columns = [
      {
        title: "TrackCode",
        dataIndex: "trackCode",
        key: "trackCode",
        // width: 150,
        render: (text, record) => record.trackCode,
      },
      {
        title: "Fecha de creación",
        dataIndex: "createdAtDate",
        key: "createdAtDate",
        render: (text, record) => moment(record.createdAt).format("DD-MM-YYYY"),
      },
      {
        title: "Hora",
        dataIndex: "createdAtTime",
        key: "createdAtTime",
        render: (text, record) =>
          `${moment(record.createdAt).format("HH:mm")} hrs`,
      },
      {
        title: "Estado de etiqueta",
        dataIndex: "ticket",
        key: "ticket",
        render: (text, record) => (record.printed ? "Impresa" : "No impresa"),
      },
      {
        title: "Datos de recolección y entrega",
        dataIndex: "AddressPick",
        key: "addressPick",
        render: (text, record) => (
          <ContainerSpan
            key={record.id}
            onClick={() => this.selectDelivery(record)}
          >
            Ver datos
          </ContainerSpan>
        ),
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      onSelectAll: selected => {
        this.props.updateSelectedAllrows(selected);
      },
    };
    return (
      <div>
        <TableList
          rowSelection={rowSelection}
          data={dataSource}
          columns={columns}
          loading={loadingActions || loading}
          currentPage={pagination.page + 1}
          totalData={totalOrders}
          pageSize={pagination.limit}
          onChange={onChangePagination}
          heightcustom="calc(100vh - 520px)"
          scroll={{ y: "calc(100vh - 520px)" }}
        />
        <Row gutter={24} type="flex" justify="end">
          <Col xs={24} sm={24} md={4} lg={4} xl={4} style={{}}>
            <Button
              htmlType="button"
              type="primary"
              onClick={this.printPdf}
              loading={loadingPrint}
              style={{ margin: "16px 0 0", height: 40, width: "100%" }}
            >
              Imprimir etiquetas
            </Button>
          </Col>
        </Row>
        {visibleDrawer && (
          <DrawerComponent
            selectedDelvery={selectedDelvery}
            onClose={this.onClose}
            visible={visibleDrawer}
          />
        )}
        {/* <div style={{ textAlign: "center" }}>
          <Button
            type="primary"
            style={{ margin: "20px auto", width: "226px" }}
            onClick={this.printPdf}
            loading={loadingPrint}
          >
            Imprimir
          </Button>
    </div> */}
      </div>
    );
  }
}

const BodyWraper = withFirebase(Body);

export default compose(withApollo)(BodyWraper);
