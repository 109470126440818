import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { Icon } from "antd"; // Icon

const TitleGoBack = styled.div`
  
  margin-bottom: 16px;

  & .title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    color: #595959;
  }
`;

const BtnGoBack = styled.p`
  
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #8c8c8c;
  margin-bottom: 10px;
  cursor: pointer;
`;

const ComponentTitleGoBack = props => (
  <TitleGoBack>
    <BtnGoBack
      className="goBack"
      onClick={() => (props.linkBack) ? navigate(props.linkBack) : window.history.state && window.history.back()}
    >
      {" "}
      <Icon type="arrow-left" /> Regresar
    </BtnGoBack>
    <div className="title">{props.title}</div>
  </TitleGoBack>
);

export default ComponentTitleGoBack;
