import styled from "styled-components";
import { Tabs, Modal } from "antd";

const ContainerTab = styled(Tabs)`
  & .ant-tabs-tab-active {
    & .ant-badge-count {
      margin-left: 16px;
      background: #307fe2 !important;
      
    }
  }

  & .ant-tabs-tab {
    & .ant-badge-count {
      margin-left: 16px;
      background: #8c8c8c;
      
    }
  }

  & .ant-tabs-bar {
    margin: 0 0 24px 0;
  }
`;

const ModalCustom = styled(Modal)`
  & .slick-arrow {
    ::before {
      font-size: 22px;
      color: #000;
    }
  }

  .ant-modal-body {
    padding: 46px 28px;
    margin: auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .slick-slider {
    width: 500px;
  }
`;

const ContainerImg = styled.div`
  width: 500px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  && img {
    width: auto;
    height: auto;
    max-height: 450px;
    max-width: 500px;
    margin: 0 auto;
  }
`;

export { ContainerTab, ModalCustom, ContainerImg };