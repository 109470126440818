import React from "react";
import { Switch } from "antd";
import styled from "styled-components";
import { CustomFormItemSwitch } from "../Form";

const CheckboxWrapper = styled.div`
  margin-bottom: 20;
  /* .ant-checkbox-inner {
    border: 1px solid ${props => (props.isEmpty ? "red" : "#BFBFBF")};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid ${props => (props.isEmpty ? "red" : "#BFBFBF")};
  } */
`;

class SwitchComponent extends React.Component {
  state = { isEmpty: null };

  render() {
    const {
      getFieldDecorator,
      decodator,
      title,
      value,
      // textDescripton,
      required,
      message,
      onChange,
      // validator,
      disabled = false,
    } = this.props;
    return (
      <CheckboxWrapper isEmpty={this.state.isEmpty}>
        <CustomFormItemSwitch
          // isVertical={isVertical}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
          }}
          label={title}
        >
          {getFieldDecorator(decodator, {
            initialValue: value || false,
            valuePropName: "checked",
            onChange,
            rules: [
              {
                required,
                message: message || "El campo no puede quedar vacio.",
              },
            ],
          })(<Switch disabled={disabled} />)}
        </CustomFormItemSwitch>
      </CheckboxWrapper>
    );
  }
}

export default SwitchComponent;
