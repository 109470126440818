import gql from "graphql-tag";

export const createEnterprisePlatforms = gql`
  mutation createEnterprisePlatforms(
    $enterpriseID: Int
    $namePlatform: String!
    $idPlatform: String!
    $services: [Int]
    $branchDefaultPlatform: Boolean
    $manualPrice: Boolean
    $automaticApproval: Boolean
    $localShipping: Boolean
  ) {
    CreateEnterprisePlatforms: createEnterprisePlatforms(
      enterpriseID: $enterpriseID
      namePlatform: $namePlatform
      idPlatform: $idPlatform
      services: $services
      branchDefaultPlatform: $branchDefaultPlatform
      manualPrice: $manualPrice
      automaticApproval: $automaticApproval
      localShipping: $localShipping
    ) {
      message
      motive
      statusCode
      extra {
        enterpriseID
      }
    }
  }
`;

export const updateEnterprisePlatforms = gql`
  mutation updateEnterprisePlatforms(
    $id: ID!
    $idPlatform: String
    $branchDefaultPlatform: Boolean
    $services: [Int]
    $token: String
    $refreshToken: String
    $deleted: Boolean
    $enabled: Boolean
    $manualPrice: Boolean
    $automaticApproval: Boolean
    $localShipping: Boolean
  ) {
    UpdateEnterprisePlatforms: updateEnterprisePlatforms(
      id: $id
      idPlatform: $idPlatform
      token: $token
      refreshToken: $refreshToken
      services: $services
      deleted: $deleted
      branchDefaultPlatform: $branchDefaultPlatform
      enabled: $enabled
      manualPrice: $manualPrice
      automaticApproval: $automaticApproval
      localShipping: $localShipping
    ) {
      message
      motive
      statusCode
    }
  }
`;

export const updateEnterprisePlatformsByEnterprise = gql`
  mutation updateEnterprisePlatformsByEnterprise(
    $enterpriseID: Int!
    $namePlatform: String!
    $idPlatform: String
    $branchDefaultPlatform: Boolean
    $services: [Int]
    $token: String
    $refreshToken: String
    $deleted: Boolean
    $enabled: Boolean
    $extra: ExtraEnterprisePlatformsInput
    $manualPrice: Boolean
    $automaticApproval: Boolean
    $localShipping: Boolean
  ) {
    UpdateEnterprisePlatformsByEnterprise: updateEnterprisePlatformsByEnterprise(
      enterpriseID: $enterpriseID
      namePlatform: $namePlatform
      idPlatform: $idPlatform
      token: $token
      refreshToken: $refreshToken
      services: $services
      deleted: $deleted
      branchDefaultPlatform: $branchDefaultPlatform
      enabled: $enabled
      extra: $extra
      manualPrice: $manualPrice
      automaticApproval: $automaticApproval
      localShipping: $localShipping
    ) {
      message
      motive
      statusCode
    }
  }
`;
